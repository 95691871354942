import { Injectable } from '@angular/core';
import { ReportTemplateService } from './report-template.service';

@Injectable({
    providedIn: 'root'
})
//TODO completely as the pe-ui one for cw
export class ReportService extends ReportTemplateService {

}
