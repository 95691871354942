import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UserSettingsService } from '../services/user-settings.service';
import { DesignStandards } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';

export class KnowledgeBaseHelper {

    public static checkDesignStandard(designStandardId: number | undefined) {
        return designStandardId == DesignStandards.ACI || designStandardId == DesignStandards.CSA;
    }

    public static getKnowledgeBaseUrl(userSettingsService: UserSettingsService, regionId: number): string | undefined {
        const selectedRegion = userSettingsService.getCommonRegionById(regionId);
        return selectedRegion?.profis3KBUrlAci;
    }

    public static createKnowledgeBaseLink(kbLinkTemplate: string | undefined, kbNumber: string | undefined) {
        if (kbLinkTemplate == null) {
            return undefined;
        }

        if (kbNumber == null) {
            return kbLinkTemplate;
        }

        return formatKeyValue(kbLinkTemplate, { KBNUMBER: kbNumber });
    }
}