import debounce from 'lodash-es/debounce';
import isEqual from 'lodash-es/isEqual';

import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import {
    CheckboxButtonGroupItem, CheckboxButtonGroupProps, CheckboxButtonItem
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { IModelCW } from '../../gl-model/base-component';
import { IModelDisplayOptions } from '../../entities/user-settings';
import { Design } from '../../entities/design';
import { ApplicationTypes } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { PropertyMetaData } from '../../entities/properties';
import { BaseComponentHelper } from '../../gl-model/base-component-helper';
import { UserService } from '../../services/user.service';
import { DesignHelper } from '../../helpers/design-helper';

export enum DisplayOption {
    Unknown = -1,
    TransparentBaseMaterial,
    TransparentBracket,
    AnchorChannelLength,
    ConcreteDimensions,
    BoltSpacing,
    BracketDimensions,
    BracketOffset,
    AnchorNumber,
    BoltNumber,
    SymmetricCorner,
}

@Component({
    selector: 'cw-display-options',
    templateUrl: './display-options.component.html'
})
export class DisplayOptionsComponent implements OnInit {

    @Input()
    public design?: Design;

    @Output()
    public updateModel = new EventEmitter<IModelCW>();
    public isPostInstallProductSelected: boolean;
    public displayOptionsCheckbox: Pick<CheckboxButtonGroupProps<DisplayOption>, 'selectedValues' | 'groupItems'>;

    private saveUserSettings: () => void;

    constructor(
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        public userService: UserService,
        private ngZone: NgZone
    ) {
        this.displayOptionsCheckbox = {};
        this.isPostInstallProductSelected = this.userService.design.isPostInstalledAnchorSelected;
        this.saveUserSettings = debounce(() => {
            this.userSettingsService.save();
        }, 2000);
    }

    ngOnInit(): void {
        this.displayOptionsCheckbox = {
            groupItems: this.createDisplayOptions(),
            selectedValues: this.getDisplayOptionsFromUserSettings()
        };

        this.design?.onStateChanged((_design, state, oldState) => {
            // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
            const onStateChanged = () => {
                if (!isEqual(oldState.model[PropertyMetaData.Option_CW_ApplicationType.id], state.model[PropertyMetaData.Option_CW_ApplicationType.id]) ||
                    DesignHelper.hasProductFasteningTechologyStateChanged(oldState, state))
                {
                    this.isPostInstallProductSelected = this.design?.isPostInstallAnchorProduct() ?? false;
                    this.displayOptionsCheckbox = {
                        groupItems: this.createDisplayOptions(),
                        selectedValues: this.getDisplayOptionsFromUserSettings()
                    };
                }
            };
            return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
        });
    }

    private isCorner() {
        return BaseComponentHelper.isCorner(this.design?.model[PropertyMetaData.Option_CW_ApplicationType.id] as ApplicationTypes);
    }

    public createDisplayOptions() {
        const groupItems: CheckboxButtonGroupItem<DisplayOption>[] = [];
        const transparencyItems: CheckboxButtonItem<DisplayOption>[] = [];
        const dimensionItems: CheckboxButtonItem<DisplayOption>[] = [];
        const labelItems: CheckboxButtonItem<DisplayOption>[] = [];

        // TRANSPARENCY
        // Transparent base material
        transparencyItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.TransparentBaseMaterial],
            value: DisplayOption.TransparentBaseMaterial,
            text: this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.TransparentBaseMaterial'),
            disabled: false
        });

        // Transparent bracket
        transparencyItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.TransparentBracket],
            value: DisplayOption.TransparentBracket,
            text: this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.TransparentBracket'),
            disabled: false
        });

        // DIMENSIONS
        // Anchor channel length
        dimensionItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorChannelLength],
            value: DisplayOption.AnchorChannelLength,
            text: this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.AnchorChannelLength'),
            disabled: this.isPostInstallProductSelected
        });

        // Concrete dimensions
        dimensionItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.ConcreteDimensions],
            value: DisplayOption.ConcreteDimensions,
            text: this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.ConcreteDimensions'),
            disabled: false
        });

        // Bolt spacing
        dimensionItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.BoltSpacing],
            value: DisplayOption.BoltSpacing,
            text: (this.isPostInstallProductSelected)
                ? this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.AnchorSpacing')
                : this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.BoltSpacing'),
            disabled: false
        });

        // Bracket dimensions
        dimensionItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.BracketDimensions],
            value: DisplayOption.BracketDimensions,
            text: this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.BracketDimensions'),
            disabled: false
        });

        // Bracket offsets
        dimensionItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.BracketOffset],
            value: DisplayOption.BracketOffset,
            text: this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.BracketOffset'),
            disabled: false
        });

        // LABELS
        // Anchor numbers
        if (!this.isPostInstallProductSelected) {
            labelItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorNumber],
                value: DisplayOption.AnchorNumber,
                text: this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.AnchorNumber'),
                disabled: false
            });
        }

        // Bolt numbers
        labelItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.BoltNumber],
            value: DisplayOption.BoltNumber,
            text: (this.isPostInstallProductSelected)
                ? this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.AnchorNumber')
                : this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.BoltNumber'),
            disabled: false
        });

        // Symmetric corner
        labelItems.push({
            id: 'DisplayOption-' + DisplayOption[DisplayOption.SymmetricCorner],
            value: DisplayOption.SymmetricCorner,
            text: this.localizationService.getString('Agito.Hilti.CW.Main.DisplayOptions.SymmetricCorner'),
            disabled: !this.isCorner() || this.isPostInstallProductSelected,
        });

        this.fillGroupItemsList(transparencyItems, 'Transparency', 'TransparencyTitle', groupItems);
        this.fillGroupItemsList(dimensionItems, 'Dimension','DimensionTitle', groupItems);
        this.fillGroupItemsList(labelItems, 'Label','LabelTitle', groupItems);
        return groupItems;
    }

    private fillGroupItemsList(items: CheckboxButtonItem<DisplayOption>[], groupId: string, groupName: string, groupItems: CheckboxButtonGroupItem<DisplayOption>[]) {
        if (items.length > 0) {
            const translationKeyPreffix = 'Agito.Hilti.CW.Main.DisplayOptions.';

            groupItems.push({
                title: this.localizationService.getString(translationKeyPreffix.concat(groupName)),
                testId: 'DisplayOptionGroup-' + groupId,
                items
            });
        }
    }

    private getDisplayOptionsFromUserSettings(): Set<DisplayOption> {
        const displayOptions = this.userSettingsService.settings.application.curtainWall.modelDisplayOptions;
        const values = new Set([
            displayOptions?.transparentBaseMaterial.value ? DisplayOption.TransparentBaseMaterial : DisplayOption.Unknown,
            displayOptions?.transparentBracket.value ? DisplayOption.TransparentBracket : DisplayOption.Unknown,
            displayOptions?.visibleAnchorChannelLen.value && !this.isPostInstallProductSelected ? DisplayOption.AnchorChannelLength : DisplayOption.Unknown,
            displayOptions?.visibleConcreteDimension.value ? DisplayOption.ConcreteDimensions : DisplayOption.Unknown,
            displayOptions?.visibleBoltSpacing.value ? DisplayOption.BoltSpacing : DisplayOption.Unknown,
            displayOptions?.visibleBracketDimensions.value ? DisplayOption.BracketDimensions : DisplayOption.Unknown,
            displayOptions?.visibleBracketOffset.value ? DisplayOption.BracketOffset : DisplayOption.Unknown,
            displayOptions?.visibleAnchorNumber.value ? DisplayOption.AnchorNumber : DisplayOption.Unknown,
            displayOptions?.visibleBoltNumber.value ? DisplayOption.BoltNumber : DisplayOption.Unknown,
            displayOptions?.visibleSymmetricCorner.value && this.isCorner() && !this.isPostInstallProductSelected ? DisplayOption.SymmetricCorner : DisplayOption.Unknown,
        ]);
        values.delete(DisplayOption.Unknown);

        return values;
    }

    public displayOptionsCheckboxItemToggle(displayOption: DisplayOption) {
        const checked = this.displayOptionsCheckbox.selectedValues?.has(displayOption) ?? false;

        switch (displayOption) {
            case DisplayOption.TransparentBaseMaterial: {
                this.updateModel.emit({
                    visibilityProperties: {
                        baseMaterialTransparent: checked
                    }
                } as IModelCW);

                this.updateAndSave('transparentBaseMaterial', checked);

                break;
            }
            case DisplayOption.TransparentBracket: {
                this.updateModel.emit({
                    visibilityProperties: {
                        bracketTransparent: checked
                    }
                } as IModelCW);

                this.updateAndSave('transparentBracket', checked);

                break;
            }
            case DisplayOption.AnchorChannelLength: {
                this.updateModel.emit({
                    visibilityProperties: {
                        anchorChannelLenVisible: checked
                    }
                } as IModelCW);

                this.updateAndSave('visibleAnchorChannelLen', checked);

                break;
            }
            case DisplayOption.ConcreteDimensions: {
                this.updateModel.emit({
                    visibilityProperties: {
                        concreteDimensionVisible: checked
                    }
                } as IModelCW);

                this.updateAndSave('visibleConcreteDimension', checked);

                break;
            }
            case DisplayOption.BoltSpacing: {
                this.updateModel.emit({
                    visibilityProperties: {
                        boltSpacingVisible: checked
                    }
                } as IModelCW);

                this.updateAndSave('visibleBoltSpacing', checked);

                break;
            }
            case DisplayOption.BracketDimensions: {
                this.updateModel.emit({
                    visibilityProperties: {
                        bracketDimensionsVisible: checked
                    }
                } as IModelCW);

                this.updateAndSave('visibleBracketDimensions', checked);

                break;
            }
            case DisplayOption.BracketOffset: {
                this.updateModel.emit({
                    visibilityProperties: {
                        bracketOffsetVisible: checked
                    }
                } as IModelCW);

                this.updateAndSave('visibleBracketOffset', checked);

                break;
            }
            case DisplayOption.AnchorNumber: {
                this.updateModel.emit({
                    visibilityProperties: {
                        anchorNumberVisible: checked
                    }
                } as IModelCW);

                this.updateAndSave('visibleAnchorNumber', checked);

                break;
            }
            case DisplayOption.BoltNumber: {
                this.updateModel.emit({
                    visibilityProperties: {
                        boltNumberVisible: checked
                    }
                } as IModelCW);

                this.updateAndSave('visibleBoltNumber', checked);

                break;
            }
            case DisplayOption.SymmetricCorner: {
                this.updateModel.emit({
                    visibilityProperties: {
                        symmetricCornerVisible: checked
                    }
                } as IModelCW);

                this.updateAndSave('visibleSymmetricCorner', checked);

                break;
            }
        }
    }

    private updateAndSave(displayOption: keyof IModelDisplayOptions, checked: boolean) {
        const displayOptions = this.userSettingsService.settings.application.curtainWall.modelDisplayOptions;
        if (displayOptions == null)
            return;

        displayOptions[displayOption].value = checked;
        this.saveUserSettings();
    }

}
