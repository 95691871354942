import { BaseComponentCW } from '../../../gl-model/base-component';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { Change, Update } from '@profis-engineering/gl-model/base-update';
import { ScopeCheck } from '@profis-engineering/gl-model/components/base-component';

export abstract class SystemComponentCW extends BaseComponentCW {

    public abstract getComponents(): BaseComponentCW[];

    public override getBoundingBoxes(): BoundingInfo[] {
        this.ensureNotDisposed();

        const boundingInfo: BoundingInfo[] = [];

        for (const component of this.getComponents()) {
            boundingInfo.push(...component.getBoundingBoxes());
        }

        return boundingInfo;
    }

    override dispose(): void {
        this.getComponents().forEach(x => x.dispose());
    }

    public override propertyValueChanged(changes: Change[], update: Update, ...args: unknown[]): void {
        this.getComponents().forEach(c => c.propertyValueChanged(changes, update, ...args));
        super.propertyValueChanged(changes, update, ...args);
    }

    public override scopeCheckChanged(scopeChecks: ScopeCheck[], ...args: unknown[]): void {
        this.getComponents().forEach(c => c.scopeCheckChanged(scopeChecks, ...args));
        super.scopeCheckChanged(scopeChecks, ...args);
    }
}