import { Component, ElementRef, Input, NgZone, OnInit, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { SimpleCheckboxButtonHelper } from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import sortBy from 'lodash-es/sortBy';
import difference from 'lodash-es/difference';
import { AnchorFilter as AnchorFilterEntity } from '../../entities/code-lists/anchor-filter';
import { AnchorFilterGroup as AnchorFilterGroupEntity } from '../../entities/code-lists/anchor-filter-groups';
import { DesignCodeList } from '../../entities/enums/design-code-list';
import { ProductFilterEntity, CheckboxFilter } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { IProduct } from '../../entities/product';
import { PropertyMetaData } from '../../entities/properties';
import { CalculationService } from '../../services/calculation.service';
import { LocalizationService } from '../../services/localization.service';
import { ProductService } from '../../services/product-service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitService } from '../../services/unit.service';
import { AnchorFilterGroupOperator } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { FasteningTechnologies } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';

import {
    filterProductFilterGroups,
    filterProductFilters
} from '../../../cw/helpers/product-helper';

export class SelectAnchorFilterGroup {
    public id?: number;
    public groupOperator?: AnchorFilterGroupOperator;
    public titleKey?: string;
    public items?: SelectAnchorFilterGroupItem[];
    public infoPopup?: ISelectAnchorFilterGroupInfoPopup;

    constructor(ctor?: ISelectAnchorFilterGroupConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.groupOperator = ctor.groupOperator;
            this.titleKey = ctor.titleKey;
            this.items = ctor.items;
            this.infoPopup = ctor.infoPopup;
        }
    }
}

export class SelectAnchorFilterGroupItem {
    public id?: number;
    public checkbox?: CheckboxButtonProps<boolean>;

    constructor(ctor?: ISelectAnchorFilterGroupItemConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.checkbox = ctor.checkbox;
        }
    }
}

export interface ISelectAnchorFilterGroupItemConstructor {
    id?: number;
    checkbox?: CheckboxButtonProps<boolean>;
}

export interface ISelectAnchorFilterGroupInfoPopup {
    enabled: boolean;
    show: boolean;
    title: string;
    content: string;
}

export interface ISelectAnchorFilterGroupConstructor {
    id?: number;
    groupOperator?: AnchorFilterGroupOperator;
    titleKey?: string;
    items?: SelectAnchorFilterGroupItem[];
    infoPopup?: ISelectAnchorFilterGroupInfoPopup;
}

export enum SelectAnchorSortBy {
    default,
    name
}

export enum SelectAnchorMode {
    normal,
    calculateAll
}

export const enum CalculateAllCheckboxItem {
    ExcludeFailures,
    IncludeOnlyMinimum
}

@Component({
    templateUrl: './select-product.component.html',
    styleUrls: ['./select-product.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectProductComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public dataLoaded = false;

    public products!: IProduct[];
    public scrollElement!: Element;
    public filter!: string;
    public mode!: SelectAnchorMode;
    public calculateAllLoading = false;
    public sortDropdown!: DropdownProps<SelectAnchorSortBy>;
    public filtersCalculateAllCheckbox!: CheckboxButtonProps<CalculateAllCheckboxItem>;
    public filterGroups!: SelectAnchorFilterGroup[];

    public unitLength: UnitType;

    public fixtureThicknessMin?: number;
    public fixtureThicknessMax?: number;
    public holeDiameterMin?: number;
    public holeDiameterMax?: number;

    public rebarRowHeight = 90;                 // must be the same value as in css

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        protected unitService: UnitService,
        private calculationService: CalculationService,
        private productService: ProductService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone
    ) {
        this.unitLength = this.unitService.getDefaultUnit(UnitGroup.Length);
    }

    ngOnInit(): void {
        this.scrollElement = document.querySelector('.modal') as Element;
        includeSprites(this.elementRef.nativeElement.shadowRoot, 'sprite-no-photo-available' as Sprite);

        this.setModalInstanceClosing();

        this.sortDropdown = {
            id: 'select-anchor-sort-anchor-sort-by-dropdown',
            items: [
                {
                    text: this.translate('Agito.Hilti.CW.SelectProduct.SortBy.Default'),
                    value: SelectAnchorSortBy.default
                },
                {
                    text: this.translate('Agito.Hilti.CW.SelectProduct.SortBy.Name'),
                    value: SelectAnchorSortBy.name
                }
            ],
            selectedValue: SelectAnchorSortBy.default
        };

        // load filters
        const anchorFilters = this.design.designData.designCodeLists[DesignCodeList.AnchorFilter] as AnchorFilterEntity[];
        const anchorFilterGroups = this.design.designData.designCodeLists[DesignCodeList.AnchorFilterGroup] as AnchorFilterGroupEntity[];

        // load saved filters
        const savedAnchorFilters = this.design.model[PropertyMetaData.Product_CW_Filters.id] as ProductFilterEntity;

        const savedAnchorCheckboxFilters: { [id: number]: CheckboxFilter } =
            Object.fromEntries(savedAnchorFilters.checkboxFilters.map((checkboxFilter: CheckboxFilter) => [checkboxFilter.id, checkboxFilter]));

        this.fixtureThicknessMin = savedAnchorFilters?.minMaxFilters?.fixtureThicknessMin;
        this.fixtureThicknessMax = savedAnchorFilters?.minMaxFilters?.fixtureThicknessMax;
        this.holeDiameterMin = savedAnchorFilters?.minMaxFilters?.holeDiameterMin;
        this.holeDiameterMax = savedAnchorFilters?.minMaxFilters?.holeDiameterMax;

        // create filter structure
        const filteredAnchorFilters = filterProductFilters(anchorFilters, savedAnchorFilters);
        const filteredProductFilterGroups = filterProductFilterGroups(anchorFilterGroups, filteredAnchorFilters);

        this.filterGroups = filteredProductFilterGroups.map((anchorFilterGroup) => new SelectAnchorFilterGroup({
            id: anchorFilterGroup.id,
            titleKey: anchorFilterGroup.nameResourceKey,
            groupOperator: anchorFilterGroup.groupOperator,
            items: filteredAnchorFilters.filter((anchorFilter) => anchorFilter.anchorFilterGroupId == anchorFilterGroup.id).map((anchorFilter) => new SelectAnchorFilterGroupItem({
                id: anchorFilter.id,
                checkbox: SimpleCheckboxButtonHelper.createSimpleCheckbox({
                    id: `select-product-${anchorFilter.id}-checkbox`,
                    itemText: this.translate(anchorFilter.nameResourceKey ?? ''),
                    disabled: savedAnchorFilters.checkboxFiltersDisabled.some(disabled => disabled.id == anchorFilter.id),
                    checked: savedAnchorCheckboxFilters[anchorFilter.id ?? -1] != null
                })
            })),
            // infoPopup: this.getAnchorFilterGroupInfoPopup(anchorFilterGroup)
        }));

        this.refreshProducts();
        this.initProductImages();

        this.dataLoaded = true;
    }

    public get design() {
        return this.userService.design;
    }

    public get showPostInstallProductFilters() {
        return this.design.fasteningTechnologies?.includes(FasteningTechnologies.PostInstalled);
    }

    public get selectedProductId() {
        return this.design.selectedProductId;
    }

    public set selectedProductId(value: number) {
        this.design.model[PropertyMetaData.AnchorChannel_CW_Family.id] = value;
    }

    public get filterPlaceholder() {
        return this.translate(`Agito.Hilti.CW.SelectProduct.${this.mode == null || this.mode == SelectAnchorMode.normal
            ? 'FilterInputPlaceholder'
            : 'FilterInputCalculateAllPlaceholder'}`);
    }

    public get calculateAllButtonKey() {
        return 'Agito.Hilti.CW.SelectProduct.' + (this.mode == null || this.mode == SelectAnchorMode.normal
            ? 'CalculateAll'
            : 'CalculateAllClose');
    }

    public getFilterItemCheckbox(checkbox: CheckboxButtonProps<boolean> | undefined) {
        return checkbox as CheckboxButtonProps<boolean>;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public identifyProduct: TrackByFunction<IProduct> = function (index: number, _: IProduct) {
        // Returning index (inside virtual scroll's viewport) to ensure
        // the same components are being reused (performance improvement!).
        return index;
    };
    /* eslint-enable @typescript-eslint/no-unused-vars */

    public async selectProduct(product: IProduct) {
        this.selectedProductId = product.id;
        this.close();
    }

    public isPostInstalledAnchor(productId: number) {
        return productId < 0;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public setModalInstanceClosing() {
        // don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            this.setFiltersToProperties();
            this.ngZone.run(() => this.calculationService.calculateAsync(this.design));
            return true;
        });
    }

    public close() {
        this.modalInstance.close();
    }

    protected refreshProducts() {
        this.products = this.filterProducts();

        this.initProductImages();
    }

    protected filterProducts(noSearchFilter?: boolean) {
        let products = this.productService.loadProducts();

        // search filter
        if (!noSearchFilter) {
            products = this.filterBySearch(products, this.filter);
        }

        // search filter - min max
        products = this.filterByMinMax(products);

        // checkbox filter
        products = this.filterByCheckboxes(products);

        // sort by
        products = this.sortAnchorFamilyBy(products, this.sortDropdown.selectedValue as SelectAnchorSortBy);

        return products;
    }

    public filterByMinMax(products: IProduct[]) {
        return products.filter((product) => {
            const holeDiameters = product?.holeDiameters || [];

            if (this.holeDiameterMin != null && holeDiameters.every(holeDiameter => holeDiameter < (this.holeDiameterMin as number))) {
                return false;
            }

            if (this.holeDiameterMax != null && holeDiameters.every(holeDiameter => holeDiameter > (this.holeDiameterMax as number))) {
                return false;
            }

            if (this.isLess(this.fixtureThicknessMin, product.fixtureThicknessMin)) {
                return false;
            }

            if (this.isMore(this.fixtureThicknessMax, product.fixtureThicknessMax)) {
                return false;
            }

            return true;
        });
    }

    private filterBySearch(products: IProduct[], filter: string) {
        if (this.filter == null || this.filter.trim() == '') {
            return products;
        }

        const filteredProducts: IProduct[] = [];
        const lowerCaseFilter = filter.toLowerCase();

        for (const product of products) {
            const containsName = product.name != null ? product.name.toLowerCase().indexOf(lowerCaseFilter) > -1 : false;

            if (containsName) {
                filteredProducts.push(product);
            }
        }

        return filteredProducts;
    }

    private filterByCheckboxes(products: IProduct[]) {
        const filteredProducts: IProduct[] = [];

        const selectedAnchorGroups = this.filterGroups.filter((filterGroup) => filterGroup.items?.some((filterItem) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(filterItem.checkbox as CheckboxButtonProps<boolean>)));
        const orGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == AnchorFilterGroupOperator.Or);
        const andGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == AnchorFilterGroupOperator.And);

        for (const product of products) {
            if (orGroups.length == 0 && andGroups.length == 0) {
                filteredProducts.push(product);
            }
            else {
                const allowed = product.allowedInAnchorFilters;

                // skip filtering for anchor channel products
                // is allowed in 'or' groups || is allowed in 'and' groups
                const isAnchorAllowed = allowed?.includes(-1) || (this.isAllowedInOrGroups(orGroups, allowed) && this.isAllowedInAndGroups(andGroups, allowed));

                if (isAnchorAllowed) {
                    filteredProducts.push(product);
                }
            }
        }

        return filteredProducts;
    }

    private isAllowedInOrGroups(orGroups: SelectAnchorFilterGroup[], allowed?: number[]) {
        for (const orGroup of orGroups) {
            const allowedGroup = orGroup.items?.filter((item) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox as CheckboxButtonProps<boolean>)).map((item) => item.id);

            if (difference(allowedGroup, allowed ?? []).length == allowedGroup?.length) {
                return false;
            }
        }

        return true;
    }

    private isAllowedInAndGroups(andGroups: SelectAnchorFilterGroup[], allowed?: number[]) {
        for (const andGroup of andGroups) {
            const allowedGroup = andGroup.items?.filter((item) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox as CheckboxButtonProps<boolean>)).map((item) => item.id);

            if (difference(allowedGroup, allowed ?? []).length != 0) {
                return false;
            }
        }

        return true;
    }

    private sortAnchorFamilyBy(products: IProduct[], selectAnchorSortBy: SelectAnchorSortBy) {
        if (selectAnchorSortBy == null) {
            return products;
        }

        switch (selectAnchorSortBy) {
            case SelectAnchorSortBy.default:
                products = sortBy(products, product => product.defaultSortOrder);
                break;

            case SelectAnchorSortBy.name:
                products = sortBy(products, product => product.name.toLowerCase());
                break;

            default:
                throw new Error('Invalid sort by.');
        }

        return products;
    }

    public getControlId(value: string)
    {
        return value.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase() + '-filter';
    }

    public isLess(value1?: number, value2?: number) {
        return value1 && value2 && value1 < value2;
    }

    public isMore(value1?: number, value2?: number) {
        return value1 && value2 && value1 > value2;
    }

    public onMinMaxFixtureThicknessChange(minMax: number, oldMinMax?: number) {
        this.onMinMaxChange(minMax, oldMinMax);
    }

    public onMinMaxHoleDiameterChange(minMax: number, oldMinMax?: number) {
        this.onMinMaxChange(minMax, oldMinMax);
    }

    private onMinMaxChange(minMax: number, oldMinMax?: number) {
        if (minMax !== oldMinMax) {
            this.refreshProducts();
        }
    }

    public resetFilter() {
        this.onFilterChange('');
    }

    public onFilterChange(filter: string) {
        this.filter = filter;

        this.refreshProducts();
    }

    public onSortChange(value: SelectAnchorSortBy) {
        this.sortDropdown.selectedValue = value;
        this.refreshProducts();
    }

    public onClearAllButtonClick() {
        this.filter = '';
        this.fixtureThicknessMax = undefined;
        this.fixtureThicknessMin = undefined;
        this.holeDiameterMax = undefined;
        this.holeDiameterMin = undefined;
        this.sortDropdown.selectedValue = SelectAnchorSortBy.default;

        for (const filterGroup of this.filterGroups) {
            for (const filterItem of filterGroup.items ?? []) {
                SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(filterItem.checkbox as CheckboxButtonProps<boolean>, false);
            }
        }

        this.refreshProducts();
    }

    public filterInputValueChanged(
        numberValue: number,
        property: 'fixtureThicknessMin' | 'fixtureThicknessMax' | 'holeDiameterMin' | 'holeDiameterMax'
    ) {
        // set other value if needed
        switch (property) {
            case 'fixtureThicknessMin': {
                const oldFixtureThicknessMin = this.fixtureThicknessMin;
                this.fixtureThicknessMin = numberValue;

                if (numberValue != null && this.fixtureThicknessMax != null && numberValue > this.fixtureThicknessMax) {
                    this.fixtureThicknessMax = numberValue;
                }

                this.onMinMaxFixtureThicknessChange(numberValue, oldFixtureThicknessMin);
            }
                break;

            case 'fixtureThicknessMax': {
                const oldFixtureThicknessMax = this.fixtureThicknessMax;
                this.fixtureThicknessMax = numberValue;

                if (numberValue != null && this.fixtureThicknessMin != null && numberValue < this.fixtureThicknessMin) {
                    this.fixtureThicknessMin = numberValue;
                }

                this.onMinMaxFixtureThicknessChange(numberValue, oldFixtureThicknessMax);
            }
                break;

            case 'holeDiameterMin': {
                const oldHoleDiameterMin = this.holeDiameterMin;
                this.holeDiameterMin = numberValue;

                if (numberValue != null && this.holeDiameterMax != null && numberValue > this.holeDiameterMax) {
                    this.holeDiameterMax = numberValue;
                }

                this.onMinMaxHoleDiameterChange(numberValue, oldHoleDiameterMin);
            }
                break;

            case 'holeDiameterMax': {
                const oldHoleDiameterMax = this.holeDiameterMax;
                this.holeDiameterMax = numberValue;

                if (numberValue != null && this.holeDiameterMin != null && numberValue < this.holeDiameterMin) {
                    this.holeDiameterMin = numberValue;
                }

                this.onMinMaxHoleDiameterChange(numberValue, oldHoleDiameterMax);
            }
                break;
        }
    }

    public onFilterCheckboxChange(filterItem?: SelectAnchorFilterGroupItem, filterGroup?: SelectAnchorFilterGroup) {
        this.refreshProducts();

        // tracking
        if (filterItem && filterGroup) {
            this.trackFilters();
        }
    }

    public get calculateAllDisabled() {
        return true;
    }

    public onCalculateAllButtonClick() {
        // TODO
    }

    public openFilterGroupInfoPopup() {
        // TODO
    }

    protected setFiltersToProperties() {
        const filteredGroups = this.filterGroups.filter((filterGroup) => filterGroup.items?.some((filterItem) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(filterItem.checkbox as CheckboxButtonProps<boolean>)));

        this.design.model[PropertyMetaData.Product_CW_Filters.id] = {
            minMaxFilters: {
                fixtureThicknessMin: this.fixtureThicknessMin,
                fixtureThicknessMax: this.fixtureThicknessMax,
                holeDiameterMin: this.holeDiameterMin,
                holeDiameterMax: this.holeDiameterMax,
            },
            checkboxFilters: filteredGroups.flatMap(group => group.items?.filter(item => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox as CheckboxButtonProps<boolean>)).map((item): CheckboxFilter => ({ id: item.id as number }))),
            checkboxFiltersDisabled: filteredGroups.flatMap(group => group.items?.filter(item => item.checkbox?.disabled).map((item): CheckboxFilter => ({ id: item.id as number }))),
            checkboxFiltersRemoved: filteredGroups.flatMap(group => group.items?.filter(item => item.checkbox).map((item): CheckboxFilter => ({ id: item.id as number })))
        } as ProductFilterEntity;
    }

    protected setAvailableProduct() {
        const selectedProduct = this.selectedProductId;
        const isAvailable = this.products.some(product => product.id == selectedProduct);

        if (!isAvailable) {
            this.selectedProductId = this.products.length > 0 ? this.products[0].id : 0;
        }
    }

    protected trackFilters() {
        this.design.usageCounter.FilterUsed = this.filterGroups.some((filterGroup) => filterGroup.items?.some((item) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {})));
        this.design.usageCounter.trackFilterGroupUsed(this.filterGroups.filter(group =>
            group.items?.some(item => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {}) ?? false)
        ).map(group => group.titleKey ?? ''));
    }

    private initProductImages() {
        this.products.forEach(product => {
            includeSprites(this.elementRef.nativeElement.shadowRoot, product.image as Sprite);
        });
    }
}
