import {
    ICodeListConstructor
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import { CodeList } from '../code-list';
import { PlateShapesEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class PlateShape extends CodeList {

    public static fromService(codeList: PlateShapesEntity) {
        return new PlateShape(CodeList.baseFromService<ICodeListConstructor>('PlateShapesEntity', codeList, {
            image: codeList.image
        } as ICodeListConstructor));
    }

    public override cloneDeep(): PlateShape {
        return super.cloneDeep() as PlateShape;
    }
}
