import {
    CommonRegion as Region
} from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    ApplicationSettingsCommon, GeneralValue, IGeneralApplicationSettingsBase,
    IQuickStartSettingsBase, UserSettings as UserSettingsCommon
} from '@profis-engineering/pe-ui-common/entities/user-settings';
import {
    IQuickStartUserSettings
} from '@profis-engineering/pe-ui-common/services/user-settings.common';

import { DesignMethodGroup } from './code-lists/design-method-group';
import { DesignStandard } from './code-lists/design-standard';
import { Unit } from './code-lists/unit';
import { ImportLoadsNumberType } from './enums/import-loads-number-type';

export interface ICwQuickStartSettingEntity extends IQuickStartUserSettings {
    general_region: Region;
    units_area?: Unit;
    units_force?: Unit;
    units_length?: Unit;
    units_moment?: Unit;
    units_stress?: Unit;
    units_temperature?: Unit;
    units_force_per_length?: Unit;
    units_moment_per_length?: Unit;
    units_density?: Unit;

    calculation_designMethod?: DesignMethodGroup;
    calculation_designStandard?: DesignStandard;

    basePlateFactor?: number;
    safetyFactorPermLoad?: number;
    safetyFactorVarLoad?: number;
    minAnchorProfileDist?: number;
    minConcreteCover?: number;
    concreteSafetyFactorGammaC: number;
}

export class CustomUserSettingsCW {
    // Empty for now, but we can create our own user settings here, check S&P module for example
}

export class QuickStartSettingsCW {
    curtainWall = {
        generalRegionId: new GeneralValue(null),
        unitsLengthId: new GeneralValue(null),
        unitsAreaId: new GeneralValue(null),
        unitsStressId: new GeneralValue(null),
        unitsForceId: new GeneralValue(null),
        unitsMomentId: new GeneralValue(null),
        unitsTemperatureId: new GeneralValue(null),
        unitsForcePerLengthId: new GeneralValue(null),
        unitsMomentPerLengthId: new GeneralValue(null),
        unitsDensity: new GeneralValue(null),
        basePlateFactor: new GeneralValue(null),
        safetyFactorPermLoad: new GeneralValue(null),
        safetyFactorVarLoad: new GeneralValue(null),
        minAnchorProfileDist: new GeneralValue(null),
        minConcreteCover: new GeneralValue(null),
        concreteSafetyFactorGammaC: new GeneralValue(null),
        calculationDesignMethodGroupId: new GeneralValue(null),
        calculationDesignStandardId: new GeneralValue(null)
    };
}

export class ApplicationSettingsCW {
    curtainWall = {
        modelDisplayOptions: {
            transparentBaseMaterial: new GeneralValue(true),
            transparentBracket: new GeneralValue(true),
            visibleAnchorChannelLen: new GeneralValue(true),
            visibleAnchorNumber: new GeneralValue(true),
            visibleBoltNumber: new GeneralValue(true),
            visibleSymmetricCorner: new GeneralValue(true),
            visibleConcreteDimension: new GeneralValue(true),
            visibleBoltSpacing: new GeneralValue(true),
            visibleAnchorSpacing: new GeneralValue(false),
            visibleBracketDimensions: new GeneralValue(true),
            visibleBracketOffset: new GeneralValue(true)
        },
        applicationImportLoads: {
            numberTypeOne: new GeneralValue(ImportLoadsNumberType.ForceX),
            numberTypeTwo: new GeneralValue(ImportLoadsNumberType.ForceY),
            numberTypeThree: new GeneralValue(ImportLoadsNumberType.ForceZ),
            numberTypeFour: new GeneralValue(ImportLoadsNumberType.MomentX),
            numberTypeFive: new GeneralValue(ImportLoadsNumberType.MomentY),
            numberTypeSix: new GeneralValue(ImportLoadsNumberType.MomentZ),
            numberTypeSeven: new GeneralValue(ImportLoadsNumberType.ForceZSustained),
            numberTypeEight: new GeneralValue(ImportLoadsNumberType.MomentXSustained),
            numberTypeNine: new GeneralValue(ImportLoadsNumberType.MomentYSustained),
            numberTypeTen: new GeneralValue(ImportLoadsNumberType.ForceXVariable),
            numberTypeEleven: new GeneralValue(ImportLoadsNumberType.ForceYVariable),
            numberTypeTwelve: new GeneralValue(ImportLoadsNumberType.MomentZVariable),
            forceUnit: new GeneralValue(null),
            momentUnit: new GeneralValue(null)
        }
    };
}

export interface IQuickStartModuleSettings {
    curtainWall: ICwQuickStartSettings;
}

export interface ICwQuickStartSettings extends IQuickStartSettingsBase {
    unitsForcePerLengthId: GeneralValue<number | null>;
    unitsMomentPerLengthId: GeneralValue<number | null>;
    unitsDensity: GeneralValue<number | null>;
    basePlateFactor: GeneralValue<number | null>;
    safetyFactorPermLoad: GeneralValue<number | null>;
    safetyFactorVarLoad: GeneralValue<number | null>;
    minAnchorProfileDist: GeneralValue<number | null>;
    minConcreteCover: GeneralValue<number | null>;
    concreteSafetyFactorGammaC: GeneralValue<number | null>;
}

export class ApplicationModuleSettings extends ApplicationSettingsCommon<IGeneralApplicationSettingsBase, object> {
    curtainWall!: ICwApplicationSettings;
}

export class ICwApplicationSettings {
    modelDisplayOptions?: IModelDisplayOptions;
    applicationImportLoads?: IApplicationImportLoads;
}

export interface IModelDisplayOptions {
    transparentBaseMaterial: GeneralValue<boolean>;
    transparentBracket: GeneralValue<boolean>;
    visibleAnchorChannelLen: GeneralValue<boolean>;
    visibleAnchorNumber: GeneralValue<boolean>;
    visibleBoltNumber: GeneralValue<boolean>;
    visibleSymmetricCorner: GeneralValue<boolean>;
    visibleConcreteDimension: GeneralValue<boolean>;
    visibleBoltSpacing: GeneralValue<boolean>;
    visibleAnchorSpacing: GeneralValue<boolean>;
    visibleBracketDimensions: GeneralValue<boolean>;
    visibleBracketOffset: GeneralValue<boolean>;
}

export interface IApplicationImportLoads {
    numberTypeOne: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwo: GeneralValue<ImportLoadsNumberType>;
    numberTypeThree: GeneralValue<ImportLoadsNumberType>;
    numberTypeFour: GeneralValue<ImportLoadsNumberType>;
    numberTypeFive: GeneralValue<ImportLoadsNumberType>;
    numberTypeSix: GeneralValue<ImportLoadsNumberType>;
    numberTypeSeven: GeneralValue<ImportLoadsNumberType>;
    numberTypeEight: GeneralValue<ImportLoadsNumberType>;
    numberTypeNine: GeneralValue<ImportLoadsNumberType>;
    numberTypeTen: GeneralValue<ImportLoadsNumberType>;
    numberTypeEleven: GeneralValue<ImportLoadsNumberType>;
    numberTypeTwelve: GeneralValue<ImportLoadsNumberType>;
    forceUnit: GeneralValue<number | null>;
    momentUnit: GeneralValue<number | null>;
}

export class UserSettings extends UserSettingsCommon<object, IQuickStartModuleSettings, ApplicationModuleSettings> {
    constructor() {
        super();

        this.quickStart = {
            curtainWall: {
                generalRegionId: new GeneralValue(null),
                unitsLengthId: new GeneralValue(null),
                unitsAreaId: new GeneralValue(null),
                unitsStressId: new GeneralValue(null),
                unitsForceId: new GeneralValue(null),
                unitsMomentId: new GeneralValue(null),
                unitsTemperatureId: new GeneralValue(null),
                unitsForcePerLengthId: new GeneralValue(null),
                unitsMomentPerLengthId: new GeneralValue(null),
                unitsDensity: new GeneralValue(null),
                basePlateFactor: new GeneralValue(null),
                safetyFactorPermLoad: new GeneralValue(null),
                safetyFactorVarLoad: new GeneralValue(null),
                minAnchorProfileDist: new GeneralValue(null),
                minConcreteCover: new GeneralValue(null),
                concreteSafetyFactorGammaC: new GeneralValue(null),
                calculationDesignMethodGroupId: new GeneralValue(null),
                calculationDesignStandardId: new GeneralValue(null)
            }
        };

        this.application = {
            general: UserSettingsCommon.getBaseGeneralApplicationSettingsDefaults(),
            curtainWall: {
                modelDisplayOptions: {
                    transparentBaseMaterial: new GeneralValue(true),
                    transparentBracket: new GeneralValue(true),
                    visibleAnchorChannelLen: new GeneralValue(true),
                    visibleAnchorNumber: new GeneralValue(true),
                    visibleBoltNumber: new GeneralValue(true),
                    visibleSymmetricCorner: new GeneralValue(true),
                    visibleConcreteDimension: new GeneralValue(true),
                    visibleBoltSpacing: new GeneralValue(true),
                    visibleAnchorSpacing: new GeneralValue(false),
                    visibleBracketDimensions: new GeneralValue(true),
                    visibleBracketOffset: new GeneralValue(true)
                },
                applicationImportLoads: {
                    numberTypeOne: new GeneralValue(ImportLoadsNumberType.ForceX),
                    numberTypeTwo: new GeneralValue(ImportLoadsNumberType.ForceY),
                    numberTypeThree: new GeneralValue(ImportLoadsNumberType.ForceZ),
                    numberTypeFour: new GeneralValue(ImportLoadsNumberType.MomentX),
                    numberTypeFive: new GeneralValue(ImportLoadsNumberType.MomentY),
                    numberTypeSix: new GeneralValue(ImportLoadsNumberType.MomentZ),
                    numberTypeSeven: new GeneralValue(ImportLoadsNumberType.ForceZSustained),
                    numberTypeEight: new GeneralValue(ImportLoadsNumberType.MomentXSustained),
                    numberTypeNine: new GeneralValue(ImportLoadsNumberType.MomentYSustained),
                    numberTypeTen: new GeneralValue(ImportLoadsNumberType.ForceXVariable),
                    numberTypeEleven: new GeneralValue(ImportLoadsNumberType.ForceYVariable),
                    numberTypeTwelve: new GeneralValue(ImportLoadsNumberType.MomentZVariable),
                    forceUnit: new GeneralValue(null),
                    momentUnit: new GeneralValue(null),
                } as IApplicationImportLoads
            }
        } as ApplicationModuleSettings;

        this.applicationCollapsedState = new GeneralValue({});
    }
}