import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { IAnchoringSystem, IBasePlateSystem, IModelCW } from '../../../gl-model/base-component';
import { BaseUpdateCW } from '../../../gl-model/base-update';
import { Design } from '../../../entities/design';
import { LoadTolerances as LoadToleranceEnum } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { DesignCodeList } from '../../../entities/enums/design-code-list';
import { LoadTolerances } from '../../../entities/code-lists/load-tolerances';
import { AnchorPlateFactors } from '../../../helpers/app-settings-helper';

export class PlateBracketUpdater extends BaseUpdateCW {

    @propertyValueChanged(UIProperty.Plate_CW_Width)
    private Plate_CW_Width(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.width = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.width;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_Length)
    private Plate_CW_Length(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.length = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.length;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_Thickness)
    private Plate_CW_Thickness(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.thickness = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.thickness;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_Shape)
    private Plate_CW_Shape(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.shape = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.plateShape;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_AngleBracketHeight)
    private Plate_CW_Height(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.angleBracketHeight = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.angleBracketHeight;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_OffsetX)
    private Plate_CW_OffsetX(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.offsetX = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.offsetX;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_OffsetY)
    private Plate_CW_OffsetY(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.offsetY = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.offsetY;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_OffsetYNegative)
    private Plate_CW_OffsetYNegative(value: number, model: IModelCW) {
        model.anchoringSystems.forEach(acs => {
            this.applyToBasePlateSystem(acs, (bps) => {
                bps.plateBracket.offsetYNegative = value;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_PiBracket_FlangeOffset)
    private Plate_CW_PiBracket_FlangeOffset(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.piBracket.flangeOffset = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.flangeOffset;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_PiBracket_FlangeSpacing)
    private Plate_CW_PiBracket_FlangeSpacing(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.piBracket.flangeSpacing = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.flangeSpacing;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_PiBracket_HoleDiameter)
    private Plate_CW_PiBracket_HoleDiameter(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.piBracket.holeDiameter = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.holeDiameter;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_PiBracket_HoleFromBoltOffset)
    private Plate_CW_PiBracket_HoleFromBoltOffset(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.piBracket.holeFromBoltOffset = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.holeFromBoltOffset;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_PiBracket_Thickness)
    private Plate_CW_PiBracket_Thickness(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.piBracket.thickness = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.thickness;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_PiBracket_WidthFromHoleCenterToEdge)
    private Plate_CW_PiBracket_WidthFromHoleCenterToEdge(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.piBracket.widthFromHoleCenterToEdge = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.widthFromHoleCenterToEdge;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_PiBracket_WidthFromPlateToHoleCenter)
    private Plate_CW_PiBracket_WidthFromPlateToHoleCenter(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.piBracket.widthFromPlateToHoleCenter = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.widthFromPlateToHoleCenter;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_Standoff_Type)
    private Plate_CW_Standoff_Type(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.standoff.type = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.standoff.type;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_Standoff_Distance)
    private Plate_CW_Standoff_Distance(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.standoff.distance = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.standoff.distance;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_Standoff_Width)
    private Plate_CW_Standoff_Width(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.standoff.width = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.standoff.width;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_Standoff_Offset)
    private Plate_CW_Standoff_Offset(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.standoff.offset = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.standoff.offset;
            });
        });
    }

    @propertyValueChanged(UIProperty.Loads_CW_EccentricityX)
    private Loads_CW_EccentricityX(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.loadEccentricity.x = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.loadEccentricity.x;
            });
        });
    }

    @propertyValueChanged(UIProperty.Loads_CW_EccentricityY)
    private Loads_CW_EccentricityY(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.loadEccentricity.y = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.loadEccentricity.y;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_SlottedHole_Enabled)
    private Plate_CW_SlottedHole_Enabled(value: boolean, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.slottedHole.enabled = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.slottedHole.isEnabled;
            });
        });
    }

    @propertyValueChanged(UIProperty.Plate_CW_SlottedHole_WasherHeight)
    private Plate_CW_SlottedHole_WasherHeight(value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.bolt.washerHeight = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.slottedHole.washerHeight;
                bps.plateBracket.slottedHole.washerHeight = bps.bolt.washerHeight;
            });
        });
    }

    @propertyValueChanged(UIProperty.Loads_CW_ToleranceY_Type)
    private Loads_CW_ToleranceY_Type(value: LoadToleranceEnum, model: IModelCW, design: Design) {
        const tolerance = design.designData.designCodeLists[DesignCodeList.LoadTolerancesY].find(x => x.id === value) as LoadTolerances | undefined;

        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                const ds = (design.projectDesign.options.basePlateFactor ?? AnchorPlateFactors.DefaultValue) * design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.bolt.geometry.d;
                const maxToleranceY = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.length - 2 * ds;

                if (value == LoadToleranceEnum.BracketLength) {
                    bps.plateBracket.slottedHole.toleranceY = maxToleranceY;
                }
                else if (value == LoadToleranceEnum.Customize) {
                    bps.plateBracket.slottedHole.toleranceY = 2 * design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.slottedHole.toleranceY.custom;
                }
                else {
                    bps.plateBracket.slottedHole.toleranceY = 2 * (tolerance?.value ?? 25);
                }
            });
        });
    }

    @propertyValueChanged(UIProperty.Loads_CW_ToleranceZ_Type)
    private Loads_CW_ToleranceZ_Type(value: LoadToleranceEnum, model: IModelCW, design: Design) {
        const tolerance = design.designData.designCodeLists[DesignCodeList.LoadTolerancesZ].find(x => x.id === value) as LoadTolerances | undefined;

        model.anchoringSystems.forEach((acs, i) => {
            this.applyToBasePlateSystem(acs, (bps, j) => {
                bps.plateBracket.slottedHole.toleranceZ = tolerance?.value ?? 25;

                const widthFromHoleCenterToEdge = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.widthFromHoleCenterToEdge;
                const widthFromPlateToHoleCenter = design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.piBracket.widthFromPlateToHoleCenter;
                const maxToleranceZ = widthFromHoleCenterToEdge + widthFromPlateToHoleCenter;
                bps.plateBracket.slottedHole.fixedZ = false;

                if (value == LoadToleranceEnum.FlangeLength) {
                    bps.plateBracket.slottedHole.toleranceZ = maxToleranceZ;
                    bps.plateBracket.slottedHole.fixedZ = true;
                }
                else if (value == LoadToleranceEnum.Customize) {
                    bps.plateBracket.slottedHole.toleranceZ = 2 * design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.slottedHole.toleranceZ.custom;
                }
                else {
                    bps.plateBracket.slottedHole.toleranceZ = 2 * (tolerance?.value ?? 25);
                }
            });
        });
    }

    private applyToBasePlateSystem(anchoringSystem: IAnchoringSystem, callbackfn: (value: IBasePlateSystem, index: number) => void) {
        anchoringSystem.basePlateSystems.forEach(callbackfn);
    }
}