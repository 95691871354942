
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { PropertyMetaData } from '../../entities/properties';
import { CalculationService } from '../../services/calculation.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { CodeListService } from '../../services/code-list.service';
import { UIProperty } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { LoadCombinationEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { LoggerService } from '../../services/logger.service';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

@Component({
    templateUrl: './loads-input.component.html',
    styleUrls: ['./loads-input.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsInputComponent implements OnInit {

    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;

    public forceZTextBox!: NumericTextBoxProps;
    public forceXTextBox!: NumericTextBoxProps;
    public forceYTextBox!: NumericTextBoxProps;

    public momentZTextBox!: NumericTextBoxProps;
    public momentXTextBox!: NumericTextBoxProps;
    public momentYTextBox!: NumericTextBoxProps;

    public title!: string;
    public subtitle!: string;

    public propertyMetaData = PropertyMetaData;

    private pendingSave = false;

    private loadCombination: LoadCombinationEntity | undefined;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private unitService: UnitService,
        private codeListService: CodeListService,
        private logger: LoggerService,
        private calculationService: CalculationService
    ) { }

    ngOnInit(): void {
        this.loadCombination = this.design.loads.find((loadCombination) => loadCombination.id == this.design.selectedLoadCombinationId);

        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return !this.pendingSave;
        });

        this.title = this.localizationService.getString('Agito.Hilti.CW.LoadsInput.TitleSeismic');
        this.subtitle = this.localizationService.getString('Agito.Hilti.CW.LoadsInput.SubtitleDesign');

        const rangeForceZ = this.getPropertyRange(UIProperty.LoadCombination_CW_ForceZ);
        const rangeForceX = this.getPropertyRange(UIProperty.LoadCombination_CW_ForceX);
        const rangeForceY = this.getPropertyRange(UIProperty.LoadCombination_CW_ForceY);
        const rangeMomentZ = this.getPropertyRange(UIProperty.LoadCombination_CW_MomentZ);
        const rangeMomentX = this.getPropertyRange(UIProperty.LoadCombination_CW_MomentX);
        const rangeMomentY = this.getPropertyRange(UIProperty.LoadCombination_CW_MomentY);

        this.forceZTextBox = {
            id: 'input-loads-forceZ',
            title: this.localizationService.getString('Agito.Hilti.CW.LoadsInput.ForceZ'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Force),
            minValue: rangeForceZ.min,
            maxValue: rangeForceZ.max,
            value: this.loadCombination?.forceZ
        };

        this.forceXTextBox = {
            id: 'input-loads-forceX',
            title: this.localizationService.getString('Agito.Hilti.CW.LoadsInput.ForceX'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Force),
            minValue: rangeForceX.min,
            maxValue: rangeForceX.max,
            value: this.loadCombination?.forceX
        };

        this.forceYTextBox = {
            id: 'input-loads-forceY',
            title: this.localizationService.getString('Agito.Hilti.CW.LoadsInput.ForceY'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Force),
            minValue: rangeForceY.min,
            maxValue: rangeForceY.max,
            value: this.loadCombination?.forceY
        };

        this.momentZTextBox = {
            id: 'input-loads-momentZ',
            title: this.localizationService.getString('Agito.Hilti.CW.LoadsInput.MomentZ'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Moment),
            minValue: rangeMomentZ.min,
            maxValue: rangeMomentZ.max,
            value: this.loadCombination?.momentZ
        };

        this.momentXTextBox = {
            id: 'input-loads-momentX',
            title: this.localizationService.getString('Agito.Hilti.CW.LoadsInput.MomentX'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Moment),
            minValue: rangeMomentX.min,
            maxValue: rangeMomentX.max,
            value: this.loadCombination?.momentX
        };

        this.momentYTextBox = {
            id: 'input-loads-momentY',
            title: this.localizationService.getString('Agito.Hilti.CW.LoadsInput.MomentY'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Moment),
            minValue: rangeMomentY.min,
            maxValue: rangeMomentY.max,
            value: this.loadCombination?.momentY
        };
    }

    public get design() {
        return this.userService.design;
    }

    public save(form: NgForm) {
        if (this.submitted || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const loadsInputToDesign = () => {
            if (this.loadCombination == null)
                return;

            this.loadCombination.forceZ = this.forceZTextBox.value ?? 0;
            this.loadCombination.forceX = this.forceXTextBox.value ?? 0;
            this.loadCombination.forceY = this.forceYTextBox.value ?? 0;

            this.loadCombination.momentZ = this.momentZTextBox.value ?? 0;
            this.loadCombination.momentX = this.momentXTextBox.value ?? 0;
            this.loadCombination.momentY = this.momentYTextBox.value ?? 0;
        };

        this.calculationService.calculateAsync(this.design,
            () => loadsInputToDesign(),
            { forceCalculation: true }
        )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    this.logger.log(err.message, LogType.error);
                }

                this.submitted = false;
            });
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private getPropertyRange(uiProperty: UIProperty): IValueRange {
        const propertyValue = this.codeListService.getPropertyValue(uiProperty, this.design.region.id ?? -1);

        return {
            max: propertyValue?.maxValue,
            min: propertyValue?.minValue
        };
    }
}
