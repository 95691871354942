import { CodeList } from '../code-list';
import { AnchorChannelDataEntity, RebarChannelEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export interface IRebarChannelCodeList {
    d_s?: number;
    l?: number;
    b?: number;
    d_b?: number;
    k?: number;
    kinkAngle?: number;
    hasKink?: boolean;
}
export class AnchorChannelEmbedmentDepth extends CodeList {
    public sizeLabel?: string;
    public b_ch?: number;
    public h_ch?: number;
    public h_ef?: number;
    public d_1?: number;
    public d_2?: number;
    public t_h?: number;
    public rebarChannel?: IRebarChannelCodeList;


    constructor(codeList: AnchorChannelDataEntity) {
        super(codeList);

        if (codeList != null) {
            this.sizeLabel = codeList.sizeLabel;
            this.b_ch = codeList.b_ch;
            this.h_ch = codeList.h_ch;
            this.h_ef = codeList.h_ef;
            this.d_1 = codeList.d_1;
            this.d_2 = codeList.d_2;
            this.t_h = codeList.t_h;
            this.rebarChannel = {
                d_s: codeList.rebarChannel.d_s,
                l: codeList.rebarChannel.l,
                b: codeList.rebarChannel.b,
                d_b: codeList.rebarChannel.d_b,
                k: codeList.rebarChannel.k,
                kinkAngle: codeList.rebarChannel.kinkAngle,
                hasKink: codeList.rebarChannel.hasKink
            } as IRebarChannelCodeList;
        }
    }

    public static fromService(codeList: AnchorChannelDataEntity) {
        return new AnchorChannelEmbedmentDepth(CodeList.baseFromService('AnchorChannelEmbedmentDepthEntity', codeList, {
            sizeLabel: codeList.sizeLabel,
            b_ch: codeList.b_ch,
            h_ch: codeList.h_ch,
            h_ef: codeList.h_ef,
            d_1: codeList.d_1,
            d_2: codeList.d_2,
            t_h: codeList.t_h,
            rebarChannel: {
                b: codeList.rebarChannel.b,
                d_b: codeList.rebarChannel.d_b,
                d_s: codeList.rebarChannel.d_s,
                k: codeList.rebarChannel.k,
                kinkAngle: codeList.rebarChannel.kinkAngle,
                l: codeList.rebarChannel.l,
                hasKink: codeList.rebarChannel.hasKink
            } as RebarChannelEntity
        } as AnchorChannelDataEntity));
    }

    public override cloneDeep(): AnchorChannelEmbedmentDepth {
        const codeList = super.cloneDeep() as AnchorChannelEmbedmentDepth;
        codeList.sizeLabel = this.sizeLabel;
        codeList.b_ch = this.b_ch;
        codeList.h_ch = this.h_ch;
        codeList.h_ef = this.h_ef;
        codeList.d_1 = this.d_1;
        codeList.d_2 = this.d_2;
        codeList.t_h = this.t_h;
        codeList.rebarChannel = {
            b: this.rebarChannel?.b,
            d_b: this.rebarChannel?.d_b,
            d_s: this.rebarChannel?.d_s,
            k: this.rebarChannel?.k,
            kinkAngle: this.rebarChannel?.kinkAngle,
            l: this.rebarChannel?.l,
            hasKink: this.rebarChannel?.hasKink
        } as IRebarChannelCodeList;

        return codeList;
    }
}
