import { CodeList } from '../code-list';
import { AnchorChannelLengthsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class AnchorChannelLength extends CodeList {
    public channelLength?: number;
    public projection?: number; // distance of anchor from edge of channel
    public spacing?: number; // between anchors

    constructor(codeList: AnchorChannelLengthsEntity) {
        super(codeList);

        if (codeList != null) {
            this.channelLength = codeList.channelLength;
            this.projection = codeList.projection;
            this.spacing = codeList.s;
            this.displayKey = codeList.displayKey;
        }
    }

    public static fromService(codeList: AnchorChannelLengthsEntity) {
        return new AnchorChannelLength(CodeList.baseFromService('AnchorChannelLengthsEntity', codeList, {
            channelLength: codeList.channelLength,
            projection: codeList.projection,
            s: codeList.s,
            displayKey: codeList.displayKey
        } as AnchorChannelLengthsEntity));
    }

    public override cloneDeep(): AnchorChannelLength {
        const codeList = super.cloneDeep() as AnchorChannelLength;
        codeList.channelLength = this.channelLength;
        codeList.projection = this.projection;
        codeList.spacing = this.spacing;
        codeList.displayKey = this.displayKey;
        return codeList;
    }
}
