import { CodeList } from '../code-list';
import { FasteningTechnologiesEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class FasteningTechnologies extends CodeList {
    public allowed?: boolean;
    public disabled?: boolean;

    constructor(codeList: FasteningTechnologiesEntity) {
        super(codeList);

        if (codeList != null) {
            this.allowed = codeList.allowed;
            this.disabled = codeList.disabled;
            this.image = codeList.image;
        }
    }

    public static fromService(codeList: FasteningTechnologiesEntity) {
        return new FasteningTechnologies(CodeList.baseFromService('FasteningTechnologiesEntity', codeList, {
            allowed: codeList.allowed,
            disabled: codeList.disabled,
            image: codeList.image
        } as FasteningTechnologiesEntity));
    }

    public override cloneDeep(): FasteningTechnologies {
        const codeList = super.cloneDeep() as FasteningTechnologies;
        codeList.image = this.image;
        codeList.allowed = this.allowed;
        codeList.disabled = this.disabled;

        return codeList;
    }
}
