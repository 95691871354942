import { BaseComponentCW, IBaseComponentConstructorCW } from '../../../gl-model/base-component';
import { GLModelUpdateCW } from '../../../gl-model/gl-model';
import { BoltManager } from './bolt-manager';
import { PlateBracket } from './plate-bracket';
import { SystemComponentCW } from './system-component';

export type IBasePlateSystemBaseConstructor = IBaseComponentConstructorCW & {
    id: string;
    plateSystemId: string;
};

export type IBasePlateConstructor = IBasePlateSystemBaseConstructor & {
    modelUpdates?: GLModelUpdateCW;
    setComponents: (component: BaseComponentCW) => void;
};

export class BasePlateSystem extends SystemComponentCW {

    private components: BaseComponentCW[] = [];

    constructor(private ctor: IBasePlateConstructor) {
        super(ctor);

        this.id = ctor.id;
        this.plateSystemId = ctor.plateSystemId;

        this.components.push(new PlateBracket({ ...ctor, updateModelCtor: ctor.modelUpdates?.plateBracketCtor }));
        this.components.push(new BoltManager({ ...ctor, updateModelCtor: ctor.modelUpdates?.boltUpdaterCtor }));

        this.components.forEach(c => ctor.setComponents(c));
    }

    override update(): void {
        if (this.model.basePlateSystem(this.model, this.plateSystemId, this.id)) {
            this.components.forEach(c => {
                c.id = this.id;
                c.plateSystemId = this.plateSystemId;
                c.update();
            });
        }
        else {
            this.components.forEach(c => {
                c.hide();
            });
        }
    }

    override hide(): void {
        this.components.forEach(x => x.hide());
    }

    public override getComponents() {
        return this.components;
    }

}