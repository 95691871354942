import { BaseComponentCW, IBaseComponentConstructorCW } from '../../../gl-model/base-component';
import { GLModelUpdateCW } from '../../../gl-model/gl-model';
import { AnchorChannel } from './anchor-channel';
import { AnchorChannelLip } from './anchor-channel-lip';
import { RebarPlate } from './rebar-plate';
import { BasePlateSystemManager } from './base-plate-system-manager';
import { SystemComponentCW } from './system-component';

export type IAnchoringSystemBaseConstructor = IBaseComponentConstructorCW & {
    id: string;
};

export type IAnchoringSystemConstructor = IAnchoringSystemBaseConstructor & {
    modelUpdates?: GLModelUpdateCW;
    setComponents: (component: BaseComponentCW) => void;
};

export class AnchoringSystem extends SystemComponentCW {

    private components: BaseComponentCW[] = [];

    constructor(ctor: IAnchoringSystemConstructor) {
        super(ctor);

        this.id = ctor.id;

        this.components.push(new AnchorChannel({ ...ctor, updateModelCtor: ctor.modelUpdates?.anchorChannelCtor }));
        this.components.push(new AnchorChannelLip({ ...ctor, updateModelCtor: ctor.modelUpdates?.anchorChannelLipCtor }));
        this.components.push(new RebarPlate({ ...ctor, updateModelCtor: ctor.modelUpdates?.rebarPlateCtor }));
        this.components.push(new BasePlateSystemManager({ ...ctor, modelUpdates: ctor.modelUpdates, setComponents: ctor.setComponents, id: this.id, plateSystemId: this.plateSystemId }));
        this.components.forEach(c => ctor.setComponents(c));
    }

    override update(): void {
        this.components.forEach(c => {
            if (this.isAnchorChannelAvailable() || this.isPostInstalledAvailable(c)) {
                c.id = this.id;
                c.update();
            }
            else {
                c.hide();
            }
        });
    }

    private isAnchorChannelAvailable() {
        return this.model.anchoringSystem(this.model, this.id) && this.model.isAnchorChannelAvailable(this.model, this.id);
    }

    private isPostInstalledAvailable(component: any) {
        return this.model.isPostInstallAnchorProduct && component instanceof BasePlateSystemManager;
    }

    public override hide(): void {
        this.components.forEach(c => c.hide());
    }

    public override getComponents() {
        return this.components;
    }
}
