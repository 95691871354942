import earcut from 'earcut';

import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { PolygonMeshBuilder } from '@babylonjs/core/Meshes/polygonMesh';
import { Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BaseComponentCW, IModelCW } from '../../../gl-model/base-component';
import { AnchorChannel, AnchorChannelHelper, IAnchorChannelValues, ILipStrengthClip } from './anchor-channel';
import { CSG } from '@babylonjs/core/Meshes/csg';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { IAnchoringSystemBaseConstructor } from './anchoring-system';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { isCounterClockwise } from '../helpers/polygon-helpers';

export interface IAnchorChannelLipValues {
    polygon: Vector2[];
    lipPlate: LipPlate;
    channelPolygon: Vector2[];
    lipArc: Vector2[];
    lipArcStart: Vector2;
    lipArcEnd: Vector2;
    minimumY: number;

    lipCharacteristics?: ILipStrengthClip;
}

export interface LipPlate {
    start: Vector2;
    end: Vector2;
}

export interface MinMax {
    minimumY: number;
    maximumY: number;
}

export class AnchorChannelLip extends BaseComponentCW {
    public mesh?: Mesh;
    private transformNode?: TransformNode;

    private anchorChannelSize = new Vector3();
    private anchorChannelPosition = new Vector3();
    private values = {} as IAnchorChannelLipValues;
    private readonly meshName = 'CW.AnchorChannel.Lip';
    private readonly subtractName = 'CW.Lip.Subtract';
    private readonly anchorWidthOffset = 2;

    constructor(ctor: IAnchoringSystemBaseConstructor) {
        super(ctor);

        this.id = ctor.id;
    }

    public override update(): void {
        this.dispose();

        this.values = AnchorChannelLipHelper.getAnchorChannelLipValues(this.model, this.id);

        if (!this.values.lipCharacteristics) {
            return;
        }

        const anchoringSystem = this.model.anchoringSystem(this.model, this.id);
        this.transformNode = this.getGlobalTransformNode(anchoringSystem.geometry.position, anchoringSystem.geometry.rotation);

        const anchorChannelValues = AnchorChannelHelper.getAnchorChannelValues(this.model, this.id);
        this.anchorChannelSize = anchorChannelValues.size;
        this.anchorChannelPosition = anchorChannelValues.position;

        // mesh creation
        this.createMesh(anchorChannelValues);

        // substract so mesh results with anchor clamps
        this.subtract();
    }

    private createMesh(anchorChannelValues: IAnchorChannelValues) {
        const vertexData = this.calculateLip();
        this.mesh = new Mesh(this.meshName);
        vertexData.applyToMesh(this.mesh);
        this.mesh.material = this.cache.materialCache.steelMaterial;
        this.mesh.position.y = anchorChannelValues.position.y;
    }

    public override hide(): void {
        this.dispose();
    }

    public override dispose(): void {
        this.mesh?.dispose();
    }

    public override getBoundingBoxes(): BoundingInfo[] {
        if (this.mesh) {
            return [this.mesh.getBoundingInfo()];
        }

        return [];
    }

    private subtract() {
        const anchorPositions = AnchorChannelHelper.getAnchorsPositions(this.model, this.id)
            .reverse()
            .map(x => x.position);

        const anchorOffset = AnchorChannelHelper.getAnchorValues(this.model, this.id).anchorDiameter + this.anchorWidthOffset;
        const subtractingPaths = this.getSubtractingPath(anchorPositions, anchorOffset);

        const subtractor = this.getSubtractorMesh(subtractingPaths);
        const csgMesh = CSG.FromMesh(this.mesh as Mesh);
        this.mesh?.dispose();

        const subtractedMesh = csgMesh.subtract(subtractor);
        this.mesh = subtractedMesh.toMesh(this.meshName, this.cache.materialCache.steelMaterial, this.scene);

        if (this.transformNode)
            this.mesh.parent = this.transformNode;
    }

    private getSubtractorMesh(anchorClamps: Vector2[]) {
        // Minimum and maximum of channel polygon path
        const { minimumY, maximumY } = AnchorChannelLipHelper.getMinMaxY(this.values.channelPolygon);
        const yOffset = (maximumY - minimumY) / 3;
        const limitY = minimumY + yOffset;

        // Filter channel polygon
        const filteredPath: Vector2[] = [];
        for (const point of this.values.polygon) {
            if (point.y <= limitY) {
                filteredPath.push(point);
            } else {
                break;
            }
        }

        const filteredPolygon = filteredPath.concat(AnchorChannelLipHelper.replicatePerpendicular(filteredPath, (this.values.lipCharacteristics as ILipStrengthClip).thickness).reverse());
        filteredPolygon.push(filteredPolygon[0]);

        // Construct subtractor meshes between edges to anchors and between anchors
        const subtractMeshes: Mesh[] = [];
        const triangulatePolygon = new PolygonMeshBuilder(this.subtractName, filteredPolygon, undefined, earcut);
        for (let i = 0; i < anchorClamps.length; i++) {
            const vertexData = triangulatePolygon.buildVertexData(anchorClamps[i].x - anchorClamps[i].y);
            vertexData.transform(Matrix.RotationX(-Math.PI / 2).multiply(Matrix.RotationY(-Math.PI / 2)).multiply(Matrix.Translation(anchorClamps[i].x, this.anchorChannelPosition.y, 0)));
            const mesh = new Mesh(`${this.subtractName}.${i}`);
            vertexData.applyToMesh(mesh);
            subtractMeshes.push(mesh);
        }

        // Bake subtractor mesh and put it into csg and dispose original meshes
        const subtractMesh = new Mesh(this.subtractName);
        Mesh.MergeMeshes(subtractMeshes, true, true, subtractMesh);
        const subtractor = CSG.FromMesh(subtractMesh);
        subtractMesh.dispose();
        for (const mesh of subtractMeshes) {
            mesh.dispose();
        }

        return subtractor;
    }

    private getSubtractingPath(anchorPositions: Vector3[], anchorOffset: number) {
        const anchorStartAndEnd: number[] = [];

        for (const anchorPosition of anchorPositions) {
            const start = anchorPosition.x + anchorOffset;
            const end = start - anchorOffset * 2;
            anchorStartAndEnd.push(start, end);
        }

        const startToEndSubtractingPath: Vector2[] = [];
        let current = this.anchorChannelPosition.x;
        for (let i = 0; i < anchorStartAndEnd.length; i += 2) {
            const startX = current;
            const endX = anchorStartAndEnd[i];
            current = anchorStartAndEnd[i + 1];
            startToEndSubtractingPath.push(new Vector2(startX, endX));
        }

        startToEndSubtractingPath.push(new Vector2(current, -this.anchorChannelPosition.x));
        return startToEndSubtractingPath;
    }

    private calculateLip(): VertexData {
        const lipPolygon2D = this.values.polygon;
        const triangulate = new PolygonMeshBuilder('CW.LipPolygon', lipPolygon2D, undefined, earcut);
        const vertexData = triangulate.buildVertexData(this.anchorChannelSize.x);
        vertexData.transform(Matrix.RotationX(-Math.PI / 2).multiply(Matrix.RotationY(-Math.PI / 2)).multiply(Matrix.Translation(this.anchorChannelSize.x / 2, 0, 0)));

        return vertexData;
    }
}

export class AnchorChannelLipHelper {
    static lipOffset = 5;

    public static getAnchorChannelLipValues(model: IModelCW, id: string): IAnchorChannelLipValues {
        const anchoringSystem = model.anchoringSystem(model, id);
        const lipCharacteristics = anchoringSystem.anchorChannel.lipStrengthClip;
        if (!lipCharacteristics) {
            return {} as IAnchorChannelLipValues;
        }

        const anchorChannelValues = AnchorChannelHelper.getAnchorChannelValues(model, id);
        let anchorChannelPolygon = AnchorChannelHelper.getAnchorChannelShape(model, id).map(x => new Vector2(x.x, x.y));

        // Close polygon if not closed
        const firstPoint = anchorChannelPolygon[0];
        const lastPoint = anchorChannelPolygon[anchorChannelPolygon.length - 1];
        if (!firstPoint.equals(lastPoint)) {
            anchorChannelPolygon.push(anchorChannelPolygon[0]);
        }
        // PolygonMeshBuilder requires clockwise orientation
        if (isCounterClockwise(anchorChannelPolygon, AnchorChannel.NumberOfSamplesForOrientationDetection)) {
            anchorChannelPolygon = anchorChannelPolygon.reverse();
        }
        const { minimumY, maximumY } = this.getMinMaxY(anchorChannelPolygon);

        const lipPlate = this.getLipPlate(maximumY, lipCharacteristics, anchorChannelValues);
        const channelPolygon = this.getChannelPolygon(anchorChannelPolygon, maximumY - lipCharacteristics.thickness - this.lipOffset, minimumY);
        const lipArcStartAndEnd = this.getAnchorChannelLipArcStartAndEndPoints(channelPolygon, lipPlate);
        const lipArc: Vector2[] = this.getLipArc(lipArcStartAndEnd);

        const lipPath = [...channelPolygon, ...lipArc, lipPlate.start, lipPlate.end];
        const lipPolygon2D = lipPath.concat(this.replicatePerpendicular(lipPath, lipCharacteristics.thickness).reverse());

        lipPolygon2D.push(lipPolygon2D[0]);

        return {
            polygon: lipPolygon2D,
            lipPlate,
            channelPolygon,
            lipArc,
            lipArcStart: lipArcStartAndEnd[0],
            lipArcEnd: lipArcStartAndEnd[1],
            minimumY,
            lipCharacteristics
        } as IAnchorChannelLipValues;
    }

    public static getAnchorChannelLipArcStartAndEndPoints(channelPolygon: Vector2[], lipPlate: LipPlate): Vector2[] {
        return [channelPolygon[channelPolygon.length - 1], lipPlate.start];
    }

    private static getLipPlate(yPosition: number, lipCharacteristics: ILipStrengthClip, anchorChannelValues: IAnchorChannelValues): LipPlate {
        const width = lipCharacteristics.flangeWidth - lipCharacteristics.thickness - this.lipOffset;
        const start: Vector2 = new Vector2();

        start.y = yPosition;
        start.x = anchorChannelValues.position.z - anchorChannelValues.size.z / 2 - lipCharacteristics.thickness - this.lipOffset;

        const end: Vector2 = start.clone();
        end.x -= width;

        return { start, end };
    }

    public static getMinMaxY(array: Vector2[]): MinMax {
        let maximumY = Number.MIN_SAFE_INTEGER;
        let minimumY = Number.MAX_SAFE_INTEGER;

        for (const point of array) {
            if (point.y > maximumY) {
                maximumY = point.y;
            }
            if (point.y < minimumY) {
                minimumY = point.y;
            }
        }

        return { minimumY: minimumY, maximumY: maximumY };
    }

    private static getChannelPolygon(anchorChannelPolygon: Vector2[], maximumY: number, minimumY: number): Vector2[] {
        const ret: Vector2[] = [];
        //First add all positive x points with same as starting y
        for (let i = anchorChannelPolygon.length - 1; i >= 0; i--) {
            const point = anchorChannelPolygon[i];
            if (point.x > 0 && point.y == minimumY) {
                ret.push(point);
            }
        }
        ret.reverse();

        // Continue with wanted path of polygon
        for (const point of anchorChannelPolygon) {
            if (point.y <= maximumY && point.x <= 0) {
                ret.push(point);
            } else {
                break;
            }
        }

        //set last point to have limitY as y because it might take slightly smaller point depends on definition of anchor channel polygon
        const lastPoint = ret[ret.length - 1].clone();
        lastPoint.y = maximumY;
        ret.push(lastPoint);

        return ret;
    }

    /**
    *
    * @param start is where channel polygon ends
    * @param end is where lip plate starts
    * @returns arc
    */
    private static getLipArc(startAndEndArray: Vector2[]): Vector2[] {
        const start = startAndEndArray[0];
        const end = startAndEndArray[1];
        const numberOfPoints = 25;
        const x = end.x;
        const y = start.y;
        const radius = Math.abs(y - end.y);
        const centerStartAngle = Math.atan2(start.y - y, start.x - x);
        const centerEndAngle = Math.atan2(end.y - y, end.x - x);
        const angle = (centerEndAngle - centerStartAngle) / (numberOfPoints - 1);

        const arcPoints: Vector2[] = [];
        // skip first and last point as they overlap with plate and channel
        for (let i = 1; i < numberOfPoints - 2; i++) {
            const angleForPoint = angle * i;
            const pointX = x + Math.cos(angleForPoint) * radius;
            const pointY = y + Math.sin(angleForPoint) * radius;
            arcPoints.push(new Vector2(pointX, pointY));
        }

        return arcPoints;
    }

    public static replicatePerpendicular(points: Vector2[], thickness: number) {
        const reflectPath: Vector2[] = [];
        for (let i = 0; i < points.length; i++) {
            const currentPoint = points[i];
            const nextPoint = points[i + 1];
            const previousPoint = points[i - 1];

            let reflectedPoint: Vector2 | undefined = undefined;
            if (nextPoint && previousPoint) {
                const nextAngle = this.getAngleBetweenTwoPoints(nextPoint, currentPoint);
                const previousAngle = this.getAngleBetweenTwoPoints(currentPoint, previousPoint);
                reflectedPoint = this.getNewPointWithAngleAndDistance(currentPoint, ((nextAngle + previousAngle) / 2) + Math.PI / 2, thickness);
            }
            else if (nextPoint && !previousPoint) {
                const nextAngle = this.getAngleBetweenTwoPoints(nextPoint, currentPoint) + Math.PI / 2;
                reflectedPoint = this.getNewPointWithAngleAndDistance(currentPoint, nextAngle, thickness);
            }
            else if (!nextPoint && previousPoint) {
                const angle = this.getAngleBetweenTwoPoints(currentPoint, previousPoint) + Math.PI / 2;
                reflectedPoint = this.getNewPointWithAngleAndDistance(currentPoint, angle, thickness);
            }

            if (reflectedPoint) {
                reflectPath.push(reflectedPoint);
            }
        }

        return reflectPath;
    }

    private static getAngleBetweenTwoPoints(a: Vector2, b: Vector2): number {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        return Math.atan2(dy, dx);
    }

    private static getNewPointWithAngleAndDistance(a: Vector2, angle: number, distance: number): Vector2 {
        const x = a.x + Math.cos(angle) * distance;
        const y = a.y + Math.sin(angle) * distance;
        return new Vector2(x, y);
    }
}