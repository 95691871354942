import { Component, Input, ViewEncapsulation } from '@angular/core';

export enum TableCellContentType {
    Constant = 0,
    TranslatableString = 1,
    LaTex = 2,
    Image = 3
}

interface IInfoDialogTableCellContent {
    value: string;
    contentType: TableCellContentType;
}

interface IInfoDialogTableCell {
    style?: string;
    rowspan?: number;
    colspan?: number;
    contents: IInfoDialogTableCellContent[];
}

interface IInfoDialogTableRow {
    style?: string;
    cells: IInfoDialogTableCell[];
    isHeader: boolean;
}

export interface IInfoDialogTable {
    id: string;
    rows: IInfoDialogTableRow[];
}

export class InfoDialogTable implements IInfoDialogTable {

    private _id: string;
    private _rows: IInfoDialogTableRow[] = [];

    public get id(): string {
        return this._id;
    }

    public get rows(): IInfoDialogTableRow[] {
        return this._rows.filter(x => !x.isHeader);
    }

    constructor(data: IInfoDialogTable) {
        this._id = data.id;
        this._rows = data.rows;
    }

    public addRow(row: IInfoDialogTableRow) {
        this._rows.push(row);
    }

    public get headerRows(): IInfoDialogTableRow[] {
        return this._rows.filter(x => x.isHeader);
    }
}

@Component({
    templateUrl: './info-dialog-table.component.html',
    styleUrls: ['./info-dialog-table.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogTableComponent {
    TableCellContentType = TableCellContentType;

    @Input()
    public table!: InfoDialogTable;
}
