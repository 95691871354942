export class RomanNumberHelper {
    public static arabicToRoman(number: number): string {
        const arabicNumerals = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
        const romanNumerals = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];

        if (number <= 0 || number >= 4000) {
            throw new Error('Number out of range (1-3999)');
        }

        let result = '';

        for (let i = 0; i < arabicNumerals.length; i++) {
            while (number >= arabicNumerals[i]) {
                result += romanNumerals[i];
                number -= arabicNumerals[i];
            }
        }

        return result;
    }
}
