<div class="load-forces box-section box-section-white" *ngIf="displayTable">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static load-forces-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.CW.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-cw-sprite"></div>
            </div>
            <span class="bar-title" [innerHtml]="title"></span>
        </div>

        <pe-collapse
            [container]="'tolerance-table'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>
    <div class="load-forces-table" *ngIf="!collapsed">
        <table>
            <caption>Most unfavorable tolerance</caption>
            <tbody>
                <tr>
                    <th><div [innerHtml]="positionCaption"></div></th>
                    <th class="notranslate" [innerHtml]="'X'"></th>
                    <th class="notranslate" [innerHtml]="'Y'"></th>
                    <th class="notranslate" [innerHtml]="'Z'"></th>
                </tr>
                <tr class="notranslate force-row">
                    <td [innerHtml]="positionCaption"></td>
                    <td [innerHtml]="formatPosition(tolerance.x, 'x')"></td>
                    <td [innerHtml]="formatPosition(tolerance.y, 'y')"></td>
                    <td [innerHtml]="formatPosition(tolerance.z, 'z')"></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
