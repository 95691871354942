import { Injectable } from '@angular/core';
import { IUserMenuFavorites } from '@profis-engineering/pe-ui-common/entities/favorites';
import { MenuType } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { FavoritesServiceInjected } from '@profis-engineering/pe-ui-common/services/favorites.common';
import { DesignType } from '../entities/enums/design-type';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';


const menu3DCurtainWall = 'menu3DCurtainWall';


@Injectable({
    providedIn: 'root'
  })
  export class FavoritesService extends FavoritesServiceInjected {

    public initFavorites(data: IUserMenuFavorites) {
        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, DesignType.CurtainWall),
            data?.favorites?.[menu3DCurtainWall]
        );
    }

    private getInfo(menuType: MenuType, designType: DesignType) {
        const favoritesType = this.getFavoritesType(menuType, designType);
        if (favoritesType == null) {
            throw new Error('Could not find favorites type.');
        }

        if (this.infoBase[favoritesType] == null) {
            this.infoBase[favoritesType] = {
                favoritesType,
                dbFavorites: [],
                favorites: [],
                setDefer: new Deferred<string[]>(),
                updateId: undefined,
                updating: false
            };
        }

        return this.infoBase[favoritesType];
    }

    public getMenuRegionIdFavorites(id: string, designType: DesignType): string | undefined {
        if (designType == DesignType.CurtainWall) {
            return id;
        }

        return undefined;
    }

    public getFavoritesType(menuType: MenuType, designType: DesignType) {
        let favoritesType: string | undefined = undefined;

        if (menuType == MenuType.Menu3D) {
            switch (designType) {
                case DesignType.CurtainWall:
                    favoritesType = menu3DCurtainWall;
                    break;
            }
        }

        return favoritesType;
    }

  }