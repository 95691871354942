<pe-modal-header [title]="title" [modalInstance]="modalInstance" [closeButtonDisabled]="loading != null"></pe-modal-header>

<pe-modal-body>
    <div class="modal-body-container">
        <!-- PROFIS file to device -->
        <div class="tooltip-wrapper">
            <button class="profis-file export-button" (click)="exportDesign()" type="button">
                <div class="report-wrapper">
                    <div class="export-button-image">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-profis-file"></span>
                    </div>
                    <div class="export-button-message">
                        <span class="export-button-message-name"
                            l10n="Agito.Hilti.CW.DesignSectionExport.ProfisFile"></span>
                        <span class="export-button-message-description"
                            l10n="Agito.Hilti.CW.DesignSectionExport.ProfisFile.Description"></span>
                    </div>
                    <div class="export-button-caret">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-arrow-right"></span>
                    </div>
                </div>
            </button>
        </div>

        <!-- PROFIS File to Trimble Connect -->
        <div class="tooltip-wrapper">
            <button class="trimble-connect-file export-button" type="button" [disabled]="true">
                <div class="report-wrapper">
                    <div class="export-button-image">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-trimble-file"></span>
                    </div>
                    <div class="export-button-message">
                        <span class="export-button-message-name"
                            l10n="Agito.Hilti.CW.DesignSectionExport.TrimbleConnect"></span>
                        <span class="export-button-message-description"
                            l10n="Agito.Hilti.CW.DesignSectionExport.TrimbleConnect.Description"></span>
                    </div>
                    <div class="export-button-caret">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-arrow-right"></span>
                    </div>
                </div>

                <div class="export-loader-container">
                    <pe-loading [color]="'#524f53'"></pe-loading>
                </div>
            </button>
        </div>

        <!-- Specification text -->
        <div class="tooltip-wrapper">
            <button class="specification-text export-button" type="button" [disabled]="true">
                <div class="report-wrapper">
                    <div class="export-button-image">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-specification-text"></span>
                    </div>
                    <div class="export-button-message">
                        <span class="export-button-message-name"
                            l10n="Agito.Hilti.CW.DesignSectionExport.SpecificationText"></span>
                        <span class="export-button-message-description"
                            l10n="Agito.Hilti.CW.SpecificationText.Tooltip"></span>
                    </div>
                    <div class="export-button-caret">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-arrow-right"></span>
                    </div>
                </div>
            </button>
        </div>

        <!-- Calculation log -->
        <div class="tooltip-wrapper" *ngIf="isCalculationLogAvailable">
            <button class="calculation-log export-button" (click)="exportCalculationLog()" type="button" [disabled]="!canDownloadCalculationLog">
                <div class="report-wrapper">
                    <div class="export-button-image">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-profis-file"></span>
                    </div>
                    <div class="export-button-message">
                        <span class="export-button-message-name"
                            l10n="Agito.Hilti.CW.DesignSectionExport.CalculationLog"></span>
                        <span class="export-button-message-description"
                            l10n="Agito.Hilti.CW.DesignSectionExport.CalculationLog.Description"></span>
                    </div>
                    <div class="export-button-caret">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-arrow-right"></span>
                    </div>
                </div>
            </button>
        </div>

        <!-- External S2C mapped design -->
        <div class="tooltip-wrapper" *ngIf="isExternalDesignAvailable">
            <button class="download-external-design export-button" (click)="exportExternalDesign()" type="button">
                <div class="report-wrapper">
                    <div class="export-button-image">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-profis-file"></span>
                    </div>
                    <div class="export-button-message">
                        <span class="export-button-message-name"
                            l10n="Agito.Hilti.CW.DesignSectionExport.ExportExternalDesign"></span>
                        <span class="export-button-message-description"
                            l10n="Agito.Hilti.CW.DesignSectionExport.ExportExternalDesign.Description"></span>
                    </div>
                    <div class="export-button-caret">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-arrow-right"></span>
                    </div>
                </div>
            </button>
        </div>
    </div>
</pe-modal-body>