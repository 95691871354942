<div id="resize-product-dropdown" class="product-dropdown box-section box-section-white"
    [class.openedPosition]="!collapsed">
    <!--
        Note: change this value if CSS is changed!
        correction: accomodates for target element's margins and other fixes
    -->
    <pe-resizer
        *ngIf="design != null"
        [disabled]="productHidden || collapsed"
        [targetHtmlElement]="infoSectionContainer"
        [parentHtmlElement]="parentElement"
        [correction]="-16"
        (sizeChange)="onResize()">
        <div id="product-dropdown-tour" class="box-section-header">
            <span class="product-dropdown-header-collapsed-anchor-image pe-ui-cw-sprite pe-ui-cw-sprite-product-dropdown"></span>

            <span class="box-section-title"
                *ngIf="!collapsed"
                [innerHtml]="title"></span>

            <div class="product-dropdown-header-collapsed"
                [class.product-dropdown-shortcuts-hidden]="!collapsed">
                <div class="product-dropdown-header-collapsed-anchor-name" [ngbTooltip]="productName" [innerHtml]="productName"></div>
            </div>

            <pe-collapse
                [container]="'box-section-content'"
                [collapsed]="collapsed"
                (collapsedChange)="onInfoSectionCollapse($any($event).detail)">
            </pe-collapse>
        </div>

        <div class="box-section-content">
            <div class="product-dropdown-anchor-detailed product-dropdown-region">
                <div id="product-dropdown-content" class="product-dropdown-region-content">

                    <!-- products -->
                    <div class="product-dropdown-anchor-detailed-anchors" id="product-portfolio-productlist-section"
                        *ngIf="!productHidden && products != null && products.length > 0">
                        <cw-product-drop-down-item
                            *ngFor="let product of products; let last = last; trackBy: trackProductById"
                            class="product-dropdown-anchor-detailed-anchor product-dropdown-anchor-detailed-down-anchor"
                            [id]="product.id"
                            [image]="product.image"
                            [name]="product.name"
                            [isSelected]="product.id == selectedProductId"
                            [isReadOnlyDesignMode]="design.isReadOnlyDesignMode"
                            [isLastElement]="last"
                            [isPostInstalledAnchor]="isPostInstalledAnchor(product.id)"
                            (productChange)="setPropertyValue($event, propertyMetaData.AnchorChannel_CW_Family)">
                        </cw-product-drop-down-item>
                    </div>

                    <div class="component-section" *ngIf="displayComponentSection()">
                        <div class="component" id="product-portfolio-product-type" *ngIf="!isPostInstallAnchorProduct()">
                            <pe-dropdown
                                [id]="productType.id"
                                [title]="productType.title"
                                [items]="productType.items"
                                [selectedValue]="design.model[propertyMetaData.AnchorChannel_CW_Type.id]"
                                (selectedValueChange)="setPropertyValue($any($event).detail, propertyMetaData.AnchorChannel_CW_Type)">
                            </pe-dropdown>
                        </div>

                        <div class="component" id="product-portfolio-post-install-anchor-type" *ngIf="isPostInstallAnchorProduct()" [hidden]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_Type.id).hidden">
                            <pe-dropdown
                                [id]="postInstallAnchorType.id"
                                [title]="postInstallAnchorType.title"
                                [items]="postInstallAnchorType.items"
                                [selectedValue]="design.model[propertyMetaData.PostInstalledAnchor_CW_Type.id]"
                                (selectedValueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_Type)">
                            </pe-dropdown>
                        </div>

                        <div class="component" id="product-portfolio-channel-length" *ngIf="!isPostInstallAnchorProduct()">
                            <pe-dropdown
                                [id]="channelLength.id"
                                [title]="channelLength.title"
                                [items]="channelLength.items"
                                [selectedValue]="design.model[propertyMetaData.AnchorChannel_CW_Length.id]"
                                (selectedValueChange)="setPropertyValue($any($event).detail, propertyMetaData.AnchorChannel_CW_Length)">
                            </pe-dropdown>
                        </div>

                        <div class="component" id="product-portfolio-user-defined-channel-length" *ngIf="!isPostInstallAnchorProduct() && userDefinedChannelLength">
                            <pe-numeric-text-box
                                [id]="userDefinedChannelLength.id"
                                [title]="userDefinedChannelLength.title"
                                [unit]="userDefinedChannelLength.unit"
                                [value]="userDefinedChannelLength.value"
                                (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.AnchorChannel_CW_UserDefinedLength)"
                                [required]="true"
                                [stepValue]="5">
                            </pe-numeric-text-box>
                        </div>

                        <div class="component" id="product-portfolio-embedment-depth" *ngIf="!isPostInstallAnchorProduct()">
                            <pe-dropdown
                                [id]="embedmentDepth.id"
                                [title]="embedmentDepth.title"
                                [items]="embedmentDepth.items"
                                [selectedValue]="design.model[propertyMetaData.AnchorChannel_CW_EmbedmentDepth.id]"
                                (selectedValueChange)="setPropertyValue($any($event).detail, propertyMetaData.AnchorChannel_CW_EmbedmentDepth)"
                                [disabled]="isEmbedmentDepthDisabled(this.design)">
                            </pe-dropdown>
                        </div>

                        <div class="component" id="product-portfolio-anchor-channel-custom-rebar-length" *ngIf="!isPostInstallAnchorProduct() && customRebarLength">
                            <pe-numeric-text-box
                                [id]="customRebarLength.id"
                                [title]="customRebarLength.title"
                                [unit]="customRebarLength.unit"
                                [value]="this.design.model[propertyMetaData.AnchorChannel_CW_CustomRebarLength.id]"
                                (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.AnchorChannel_CW_CustomRebarLength)"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="component" id="product-portfolio-post-install-anchor-size" *ngIf="isPostInstallAnchorProduct()" [hidden]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_Size.id).hidden">
                            <pe-dropdown
                                [id]="postInstallAnchorSize.id"
                                [title]="postInstallAnchorSize.title"
                                [items]="postInstallAnchorSize.items"
                                [selectedValue]="design.model[propertyMetaData.PostInstalledAnchor_CW_Size.id]"
                                (selectedValueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_Size)">
                            </pe-dropdown>
                        </div>

                        <div class="component" id="product-portfolio-view-approval" [hidden]="design.properties.get(propertyMetaData.AnchorChannel_CW_Approvals.id).hidden">
                            <pe-button
                                [id]="'view-approval-button'"
                                class="button view-approval"
                                [look]="'Default'"
                                width="100%"
                                [text]="getViewApprovalButtonText()"
                                (buttonClick)="openApproval()"
                                [disabled]="approvalsProperty.disabled">
                            </pe-button>
                        </div>

                        <div class="component" id="product-portfolio-anchor-item-number">
                            <pe-label
                                [id]="'anchor-item-number-label'"
                                class="label anchor-item-number"
                                width="100%"
                                [title]="itemNumberTitle()"
                                [text]="design.model[propertyMetaData.AnchorChannel_CW_ItemNumber.id]"
                                [hidden]="hideChannelItemNumber">
                            </pe-label>
                        </div>

                        <div class="info-section-anchor-detailed-eu-cip-container">
                            <pe-numeric-text-box class="info-section-anchor-detailed-eu-cip-container-element"
                                [id]="'cip-head-thickness-drop-down-input'"
                                [title]="translate('Agito.Hilti.CW.Navigation.TabAnchorLayout.RegionAnchor.HeadThickness')"
                                *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipHeadThickness.id).hidden"
                                [value]="design.model[propertyMetaData.PostInstalledAnchor_CW_CipHeadThickness.id]"
                                (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_CipHeadThickness)"
                                [unit]="design.unitLength"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipHeadThickness.id).disabled">
                            </pe-numeric-text-box>

                            <pe-numeric-text-box class="info-section-anchor-detailed-eu-cip-container-element"
                                [id]="'cip-head-diameter-drop-down-input'"
                                [title]="translate('Agito.Hilti.CW.Navigation.TabAnchorLayout.RegionAnchor.HeadDiameter')"
                                *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipHeadDiameter.id).hidden"
                                [value]="design.model[propertyMetaData.PostInstalledAnchor_CW_CipHeadDiameter.id]"
                                (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_CipHeadDiameter)"
                                [unit]="design.unitLength"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipHeadDiameter.id).disabled">
                            </pe-numeric-text-box>


                            <pe-numeric-text-box class="info-section-anchor-detailed-eu-cip-container-element"
                                [id]="'cip-head-area-drop-down-input'"
                                [title]="translate('Agito.Hilti.CW.Navigation.TabAnchorLayout.RegionAnchor.CipAh.Head')"
                                *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipHeadArea.id).hidden"
                                [value]="design.model[propertyMetaData.PostInstalledAnchor_CW_CipHeadArea.id]"
                                (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_CipHeadArea)"
                                [unit]="design.unitArea"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipHeadArea.id).disabled">
                            </pe-numeric-text-box>

                            <pe-numeric-text-box class="info-section-anchor-detailed-eu-cip-container-element"
                                [id]="'cip-min-spacing-drop-down-input'"
                                [title]="translate('Agito.Hilti.CW.Navigation.TabAnchorLayout.RegionAnchor.CipMinSpacing')"
                                *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipMinSpacing.id).hidden"
                                [value]="design.model[propertyMetaData.PostInstalledAnchor_CW_CipMinSpacing.id]"
                                (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_CipMinSpacing)"
                                [unit]="design.unitLength"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipMinSpacing.id).disabled">
                            </pe-numeric-text-box>

                            <pe-numeric-text-box
                                [id]="'cip-min-edge-distance-drop-down-input'"
                                *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipMinEdgeDistance.id).hidden"
                                class="info-section-anchor-detailed-eu-cip-container-element"
                                [title]="translate('Agito.Hilti.CW.Navigation.TabAnchorLayout.RegionAnchor.CipMinimumEdgeDistance')"
                                [value]="design.model[propertyMetaData.PostInstalledAnchor_CW_CipMinEdgeDistance.id]"
                                (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_CipMinEdgeDistance)"
                                [unit]="design.unitLength"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipMinEdgeDistance.id).disabled">
                            </pe-numeric-text-box>

                            <pe-numeric-text-box
                                [id]="'cip-k8-drop-down-input'"
                                *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipK8.id).hidden"
                                class="info-section-anchor-detailed-eu-cip-container-element"
                                [title]="translate('Agito.Hilti.CW.Navigation.TabAnchorLayout.RegionAnchor.CipK8')"
                                [value]="design.model[propertyMetaData.PostInstalledAnchor_CW_CipK8.id]"
                                (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_CipK8)"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_CipK8.id).disabled">
                            </pe-numeric-text-box>
                        </div>

                        <div class="component" id="product-portfolio-adhesive-number" *ngIf="isPostInstallAnchorProduct() && hasAdhesiveValue()">
                            <pe-label
                                [id]="'adhesive-item-number-label'"
                                class="label anchor-item-number"
                                width="100%"
                                [title]="adhesiveNumberTitle()"
                                [text]="design.model[propertyMetaData.AnchorChannel_CW_AdhesiveNumber.id]"
                                [hidden]="hideChannelItemNumber">
                            </pe-label>
                        </div>

                        <div class="component" id="product-portfolio-capsule-number" *ngIf="isPostInstallAnchorProduct() && hasCapsuleValue()">
                            <pe-label
                                [id]="'capsule-item-number-label'"
                                class="label anchor-item-number"
                                width="100%"
                                [title]="capsuleNumberTitle()"
                                [text]="design.model[propertyMetaData.AnchorChannel_CW_CapsuleNumber.id]"
                                [hidden]="hideChannelItemNumber">
                            </pe-label>
                        </div>

                        <div class="product-dropdown-anchor-detailed-embedment-depth-container" *ngIf="isPostInstallAnchorProduct() && !embedmentDepthHidden">
                            <pe-control-title
                                [title]="translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.EmbedmentDepth')"
                                [tooltip]="embedmentDepthTooltip">
                            </pe-control-title>

                            <div class="product-dropdown-anchor-detailed-embedment-depth-content">
                                <pe-toggle-button-group
                                    [id]="'product-portfolio-post-install-embedment-depth-optimization-type'"
                                    class="product-dropdown-anchor-detailed-embedment-depth-type"
                                    *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType.id).hidden"
                                    [items]="embedmentDepthTypeToggleButtonGroup.items"
                                    [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType.id).disabled"
                                    [model]="design.model[propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType.id]"
                                    (modelChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthOptimizationType)">
                                </pe-toggle-button-group>

                                <pe-numeric-text-box
                                    [id]="'product-portfolio-post-install-embedment-depth-variable'"
                                    class="product-dropdown-anchor-detailed-embedment-depth-value-input"
                                    *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthVariable.id).hidden"
                                    [value]="design.model[propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthVariable.id]"
                                    (valueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthVariable)"
                                    [unit]="design.unitLength"
                                    [updateOnBlur]="true"
                                    [debounceStepperChanges]="true"
                                    [required]="true"
                                    [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthVariable.id).disabled">
                                </pe-numeric-text-box>

                                <pe-dropdown
                                    [id]="'product-portfolio-post-install-embedment-depth-fixed-multiple'"
                                    class="product-dropdown-anchor-detailed-embedment-depth-value-dropdown"
                                    *ngIf="!design.properties.get(propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthFixedMultiple.id).hidden"
                                    [notSelectedText]=""
                                    [items]="embedmentDepthValueDropdown.items"
                                    [openUp]="true"
                                    [selectedValue]="design.model[propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthFixedMultiple.id]"
                                    (selectedValueChange)="setPropertyValue($any($event).detail, propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthFixedMultiple)"
                                    [disabled]="design.properties.get(propertyMetaData.PostInstalledAnchor_CW_EmbedmentDepthFixedMultiple.id).disabled">
                                </pe-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="product-dropdown-space-filler"></div>
                </div>
            </div>
        </div>
    </pe-resizer>
</div>
