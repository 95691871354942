import { Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IBolt } from '../../entities/bolt';
import { PropertyMetaData } from '../../entities/properties';
import { CalculationService } from '../../services/calculation.service';
import { LocalizationService } from '../../services/localization.service';
import { BoltService } from '../../services/bolt.service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';

@Component({
    templateUrl: './select-bolt.component.html',
    styleUrls: ['./select-bolt.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectBoltComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public dataLoaded = false;

    public bolts!: IBolt[];
    public scrollElement!: Element;

    public boltRowHeight = 90;                 // must be the same value as in css

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private calculationService: CalculationService,
        private boltService: BoltService,
        private elementRef: ElementRef<HTMLElement>) { }

    ngOnInit(): void {
        this.scrollElement = document.querySelector('.modal') as Element;
        includeSprites(this.elementRef.nativeElement.shadowRoot, 'sprite-no-photo-available' as Sprite);

        this.bolts = this.boltService.loadBolts();
        this.initBoltImages();
        this.dataLoaded = true;
    }

    public get design() {
        return this.userService.design;
    }

    public get selectedBoltId() {
        return this.design.selectedBoltId;
    }

    public set selectedBoltId(value: number) {
        this.design.model[PropertyMetaData.Bolt_CW_Type.id] = value;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public identifyBolt: TrackByFunction<IBolt> = function (index: number, _: IBolt) {
        // Returning index (inside virtual scroll's viewport) to ensure
        // the same components are being reused (performance improvement!).
        return index;
    };
    /* eslint-enable @typescript-eslint/no-unused-vars */

    public async selectBolt(bolt: IBolt) {
        this.selectedBoltId = bolt.id;
        this.calculationService.calculateAsync(this.design, d => d.model[PropertyMetaData.Bolt_CW_Type.id] = bolt.id);

        this.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    private initBoltImages() {
        this.bolts.forEach(bolt => {
            includeSprites(this.elementRef.nativeElement.shadowRoot, bolt.image as Sprite);
        });
    }
}
