<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.CW.LoadsImport.Title')"
    [closeButtonDisabled]="submitted">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="saveLoadCombinations(ngForm)" novalidate>
    <div id="import-loads-dialog" class="modal-body">
        <div class="copy-paste-view" *ngIf="view == viewEnum.copyPaste">
            <p class="paste-here" [innerHtml]="translate('Agito.Hilti.CW.LoadsImport.CopyPasteText')"></p>

            <p class="paste-here" [innerHtml]="translate('Agito.Hilti.CW.LoadsImport.CopyPasteTextCtrlV')"></p>

            <textarea class="copy-paste-input" name="importDataTextArea" [(ngModel)]="importData"
                (paste)="onImportPaste()" (ngModelChange)="onImportChange()"
                (keydown)="onImportKeyDown($event)" (blur)="onImportBlur()"
                spellcheck="false">
            </textarea>
        </div>

        <div class="loads-header" *ngIf="view == viewEnum.import">
            <div class="column-name">
                <div class="column-name-container" [innerHtml]="translate('Agito.Hilti.CW.LoadsImport.Name')"></div>
            </div>
            <div class="column-type">
                <div class="column-type-container" [innerHtml]="translate('Agito.Hilti.CW.LoadsImport.Type')"></div>
            </div>
            <ng-container *ngFor="let header of loadsDropdownHeaders; trackBy:identifyField">
                <div class="column-number" ngClass="column-number-{{header.class}}" *ngIf="isColumnVisible(header.index)">
                    <div class="dropdown">
                        <pe-dropdown
                            [id]="header.dropdown.id"
                            [items]="header.dropdown.items"
                            [selectedValue]="header.dropdown.selectedValue"
                            (selectedValueChange)="numberTypeDropdownSelectedValueChange($any($event).detail, header.dropdown)"
                            [validators]="[requiredValidator]"
                            [disabled]="submitted">
                        </pe-dropdown>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="loads-container" *ngIf="view == viewEnum.import">
            <virtual-scroller #scrollLoads
                [items]="importedLoads" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
                [ngStyle]="{ 'height': (importedLoads.length * importedLoadRowHeight) + 'px' }"
                (vsChange)="virtualScrollChange()">
                <div class="loads-item" *ngFor="let load of importedLoads; trackBy:identifyLoad">
                    <div class="column-name">
                        <pe-text-box
                            [value]="load.name"
                            (inputBlur)="load.name = $any($event).detail"
                            [updateOnBlur]="true">
                        </pe-text-box>
                    </div>
                    <div class="column-type" >
                        <pe-toggle-button-group
                            class="type-buttons"
                            [model]="load.loadType"
                            (modelChange)="load.loadType = $any($event).detail"
                            [items]="loadTypeToggleItems"
                            [disabled]="submitted">
                        </pe-toggle-button-group>
                    </div>
                    <ng-container *ngFor="let header of loadsDropdownHeaders; trackBy:identifyField">
                        <div class="column-number" ngClass="column-number-{{header.class}}" *ngIf="isColumnVisible(header.index)">
                            <div class="textBox">
                                <pe-numeric-text-box
                                    [value]="getLoadValue(load, header.index)"
                                    (valueChange)="updateLoadValue($any($event).detail, load, header.index)"
                                    [updateOnBlur]="true"
                                    [required]="true"
                                    [appendUnit]="false"
                                    [showStepper]="false"
                                    [disabled]="submitted">
                                </pe-numeric-text-box>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </virtual-scroller>
        </div>

        <div class="limit-reached" *ngIf="limitReached && view == viewEnum.import">
            <span>{{maxLoads}} </span>
            <span [innerHtml]="translate('Agito.Hilti.CW.LoadsImport.Limit')"></span>
        </div>
    </div>

    <pe-modal-footer>
        <div class="unit-dropdown force-unit-dropdown"*ngIf="view == viewEnum.import">
            <div class="control-header">
                <pe-control-title [title]="forceUnitDropdown.title"></pe-control-title>
            </div>

            <div class="control-container">
                <pe-dropdown
                    [id]="forceUnitDropdown.id"
                    [items]="forceUnitDropdown.items"
                    [openUp]="true"
                    [selectedValue]="forceUnitDropdown.selectedValue"
                    (selectedValueChange)="forceUnitDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]"
                    [disabled]="submitted">
                </pe-dropdown>
            </div>
        </div>

        <div class="unit-dropdown moment-unit-dropdown" *ngIf="view == viewEnum.import">
            <div class="control-header">
                <pe-control-title [title]="momentUnitDropdown.title"></pe-control-title>
            </div>

            <div class="control-container">
                <pe-dropdown
                    [id]="momentUnitDropdown.id"
                    [items]="momentUnitDropdown.items"
                    [openUp]="true"
                    [selectedValue]="momentUnitDropdown.selectedValue"
                    (selectedValueChange)="momentUnitDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]"
                    [disabled]="submitted">
                </pe-dropdown>
            </div>
        </div>

        <div class="spacer"></div>

        <pe-button
            id="import-loads-cancel-button"
            class="modal-button"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.CW.General.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            id="import-loads-save-button"
            class="modal-button"
            type="submit"
            [look]="'Cta'"
            [disabled]="view != viewEnum.import || (ngForm.enabled && !ngForm.valid) || submitted || !hasImportedLoads">
            <pe-loading-button
                [loading]="submitted"
                [text]="translate('Agito.Hilti.CW.General.Apply')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
