import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CodeList, ICodeListTextDeps } from '../code-list';
import { AnchorTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class AnchorType extends CodeList {
    public familyId?: number;

    constructor(codeList: AnchorTypeEntity) {
        super(codeList);

        if (codeList != null) {
            this.familyId = codeList.familyId;
        }
    }

    public static fromService(codeList: AnchorTypeEntity) {
        return new AnchorType(CodeList.baseFromService('AnchorTypeEntity', codeList, {
            familyId: codeList.familyId
        } as AnchorTypeEntity));
    }

    public override cloneDeep(): AnchorType {
        const codeList = super.cloneDeep() as AnchorType;
        codeList.familyId = this.familyId;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        if (_codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        const years = _codeListDeps.localizationService.getString('Agito.Hilti.CW.Anchors.Years');
        return formatKeyValue(this.displayKey ?? '', { years });
    }
}
