import { CodeList } from '../code-list';
import { KBNumberEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class KBNumber extends CodeList {

    public static fromService(codeList: KBNumberEntity) {
        return new KBNumber(CodeList.baseFromService('KBNumberEntity', codeList, {
            id: codeList.id,
            kbNumberRegion: Object.fromEntries(codeList.kbNumbers.map(item => [item.regionId, item.kbNumber]))
        }));
    }

    public override cloneDeep(): KBNumber {
        return super.cloneDeep() as KBNumber;
    }
}
