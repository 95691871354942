
<pe-modal-header id="firestop-dialog" [title]="title" [modalInstance]="modalInstance"></pe-modal-header>

<pe-modal-body>
    <div class="section">
        <span l10n="Agito.Hilti.CW.TabFirestop.Description.PartOne"></span>
        <div class="firestop-image">
            <img src="{{images.firestopImage}}" alt="firestop-image">
        </div>
        <span l10n="Agito.Hilti.CW.TabFirestop.Description.PartTwo"></span>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button id="cw-firestop-discover" look="Cta" class="modal-button discover-button"
        (buttonClick)="openLink()" [text]="translate('Agito.Hilti.CW.TabFirestop.Discover')">
    </pe-button>
</pe-modal-footer>