import { CodeList, ICodeListTextDeps } from '../code-list';
import { BoltFamilyEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class BoltFamily extends CodeList {
    public sortOrder?: number;

    constructor(codeList: BoltFamilyEntity) {
        super(codeList);

        if (codeList != null) {
            this.sortOrder = codeList.sortOrder;
        }
    }

    public static fromService(codeList: BoltFamilyEntity) {
        return new BoltFamily(CodeList.baseFromService('BoltFamilyEntity', codeList, {
            image: codeList.image != null && codeList.image != '' ?  `bolt-family-${codeList.image}` : 'no-photo-available',
            sortOrder: codeList.sortOrder
        } as BoltFamilyEntity));
    }

    public override cloneDeep(): BoltFamily {
        const codeList = super.cloneDeep() as BoltFamily;
        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
}
