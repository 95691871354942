import sortBy from 'lodash-es/sortBy';
import { UserService } from '../services/user.service';
import { DesignCodeList } from '../entities/enums/design-code-list';
import { AnchorChannelFamilyGroup } from '../entities/code-lists/anchor-channel-family-group';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { LocalizationService } from '../services/localization.service';
import { NumberService } from '../services/number.service';
import { PropertyMetaData } from '../entities/properties';
import { SelectProductSortBy } from '../entities/enums/select-product-sort-by';
import { Injectable } from '@angular/core';
import { IProduct } from '../entities/product';
import { AnchorFamily } from '../entities/code-lists/anchor-family';
import { AnchorSize } from '../entities/code-lists/anchor-size';
import { AnchorType } from '../entities/code-lists/anchor-type';
import { AnchorEmbedmentDepth } from '../entities/code-lists/anchor-embedment-depth';
import { EmbedmentOptimizationType } from '../entities/code-lists/embedment-optimization-types';
import { SelectProductFilterGroup } from '../components/product-drop-down/product-drop-down.component';
import { AnchorFilterGroupOperator as GroupOperator } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import difference from 'lodash-es/difference';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    private products: IProduct[] = [];
    private productsData: IProduct[] = [];

    constructor(
        private localizationService: LocalizationService,
        private userService: UserService,
        private numberService: NumberService
    ) { }

    public get design() {
        return this.userService.design;
    }

    public get anchorFamilies() {
        return this.design.designData.designCodeLists[DesignCodeList.AnchorFamilies] as AnchorFamily [];
    }

    public get anchorTypes() {
        return this.design.designData.designCodeLists[DesignCodeList.AnchorTypes] as AnchorType [];
    }

    public get anchorSizes() {
        return this.design.designData.designCodeLists[DesignCodeList.AnchorSizes] as AnchorSize [];
    }

    public get embedmentOptimizationTypes() {
        return this.design.designData.designCodeLists[DesignCodeList.EmbedmentOptimizationTypes] as EmbedmentOptimizationType[];
    }

    public get anchorEmbedmentDepths() {
        return this.design.designData.designCodeLists[DesignCodeList.AnchorChannelEmbedmentDepth] as AnchorEmbedmentDepth[];
    }

    public get anchorChannelFamilyGroups() {
        return this.design.designData.designCodeLists[DesignCodeList.AnchorChannelFamilyGroup] as AnchorChannelFamilyGroup[];
    }

    public get selectedProductProperty() {
        return this.design.properties.get(PropertyMetaData.AnchorChannel_CW_Family.id);
    }

    private translate(key?: string) {
        return this.localizationService.getString(key ?? '');
    }

    public loadProducts(productFilterGroups?: SelectProductFilterGroup[]) {
        const anchorChannelIds = this.anchorChannelFamilyGroups.map(p => p.id);
        const postInstalledAnchorsIds = this.anchorFamilies.map(p => p.id);

        this.setProductsData(anchorChannelIds, postInstalledAnchorsIds);
        return this.filterProducts(productFilterGroups);
    }

    public filterProductsByAllowedValues(productIds: number[], allowedValues: number[] | undefined) {
        if(allowedValues != null) {
            productIds = productIds.filter(p => allowedValues.includes(p));
        }

        return productIds;
    }

    public updateProductNameText() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        this.products.forEach(productData => {
            const product = this.anchorChannelFamilyGroups.find((r) => r.id == productData.id);
            productData.name = product?.getTranslatedNameText(codeListDeps) as string;
        });
    }

    private setProductsData(anchorChannelFamilyGroupIds?: (number | undefined)[], postInstalledAnchorIds?: (number | undefined)[]) {
        const anchorChannels = this.anchorChannelFamilyGroups.filter(p => anchorChannelFamilyGroupIds?.some((productId) => productId == p.id));
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        const anchorChannelData = anchorChannels.map((p): IProduct => ({
            id: p.id,
            defaultSortOrder: p.sortOrder as number,
            image: `sprite-${p.image}`,
            name: p.getTranslatedNameText(codeListDeps) ?? '',
            tag: p.tag != null && p.tag.length > 0 ? this.translate(p.tag) : '',
            allowedInAnchorFilters: [-1] // we don't want to apply filters on anchor channel products
        }));

        const postInstalledAnchors = this.anchorFamilies.filter(p => postInstalledAnchorIds?.some(productId => productId == p.id));

        const postInstalledAnchorData = postInstalledAnchors.map((p): IProduct => ({
            id: p.id,
            defaultSortOrder: p.sortOrder as number,
            image: `sprite-${p.image}`,
            name: p.getTranslatedNameText(codeListDeps) ?? '',
            tag: p.tag != null && p.tag.length > 0 ? this.translate(p.tag) : '',
            allowedInAnchorFilters: p.allowedInAnchorFilters,
            holeDiameters: p.holeDiameters,
            fixtureThicknessMin: p.fixtureThicknessMin,
            fixtureThicknessMax: p.fixtureThicknessMax
        }));

        this.productsData = anchorChannelData.concat(postInstalledAnchorData);
    }

    private filterProducts(productFilterGroups?: SelectProductFilterGroup[]) {
        if (productFilterGroups == null) {
            productFilterGroups = [];
        }

        let products = this.productsData.slice();

        // allowed values
        const allowedValues = this.selectedProductProperty.allowedValues;
        if (allowedValues != null) {
            products = products.filter(p => allowedValues.includes(p.id));
        }

        // checkbox filter
        products = this.filterAnchorsByCheckboxes(products, productFilterGroups);

        // sort by
        products = this.sortProductsFamilyBy(products ?? [], SelectProductSortBy.default);

        return products;
    }

    private isAllowedInOrGroups(orGroups: SelectProductFilterGroup[], allowed: number[]): boolean {
        for (const orGroup of orGroups) {
            const allowedGroup = orGroup.items.filter((item) => item.selected).map((item) => item.id);
            if (difference(allowedGroup, allowed).length == allowedGroup?.length && allowed.length != 0) {
                return false;
            }
        }
        return true;
    }

    private isAllowedInAndGroups(andGroups: SelectProductFilterGroup[], allowed: number[]): boolean {
        for (const andGroup of andGroups) {
            const allowedGroup = andGroup.items.filter((item) => item.selected).map((item) => item.id);
            if (difference(allowedGroup, allowed ?? []).length != 0) {
                return false;
            }
        }
        return true;
    }

    private filterAnchorsByCheckboxes(anchors: IProduct[], anchorFilterGroups: SelectProductFilterGroup[]): IProduct[] {
        const filteredAnchors: IProduct[] = [];

        const selectedAnchorGroups = anchorFilterGroups.filter((filterGroup) => filterGroup.items.some((filterItem) => filterItem.selected));
        const orGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == GroupOperator.Or);
        const andGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == GroupOperator.And);

        for (const anchor of anchors) {
            if(anchor.allowedInAnchorFilters?.includes(-1) || (orGroups.length == 0 && andGroups.length == 0))
            {
                filteredAnchors.push(anchor);
            }
            else {
                const allowed = anchor.allowedInAnchorFilters ?? [];

                // is allowed in 'or' groups || is allowed in 'and' groups
                const isAnchorAllowed = this.isAllowedInOrGroups(orGroups, allowed) && this.isAllowedInAndGroups(andGroups, allowed);

                if (isAnchorAllowed) {
                    filteredAnchors.push(anchor);
                }
            }
        }

        return filteredAnchors;
    }

    private sortProductsFamilyBy(products: IProduct[], selectBy: SelectProductSortBy) {
        if (selectBy == null) {
            return products ?? [];
        }

        switch (selectBy) {
            case SelectProductSortBy.default:
                return sortBy(products, p => p.defaultSortOrder);

            case SelectProductSortBy.name:
                return sortBy(products, p => p.name.toLowerCase());

            default:
                throw new Error('Invalid sort by.');
        }
    }
}
