import { CodeList } from '../code-list';
import { TorquingTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class TorquingTypes extends CodeList {
    constructor(codeList: TorquingTypeEntity) {
        super(codeList);
    }

    public static fromService(codeList: TorquingTypeEntity) {
        return new TorquingTypes(CodeList.baseFromService('TorquingTypeEntity', codeList));
    }

    public override cloneDeep(): TorquingTypes {
        return super.cloneDeep() as TorquingTypes;
    }
}
