import { CodeList } from '../code-list';
import { TensionConditionsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class TensionConditions extends CodeList {

    constructor(codeList: TensionConditionsEntity) {
        super(codeList);
    }

    public static fromService(codeList: TensionConditionsEntity) {
        return new TensionConditions(CodeList.baseFromService('TensionConditionsEntity', codeList));
    }

    public override cloneDeep(): TensionConditions {
        const codeList = super.cloneDeep() as TensionConditions;
        return codeList;
    }

}
