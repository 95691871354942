import { CodeList } from '../code-list';
import { LoadCombinationFactorEntity, LoadCombinationFactorsLoadFactorEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { DesignMethodGroups, LoadCombinationFactorsTypes } from '../generated-modules/Hilti.CW.CalculationService.Shared.Enums';

export class LoadCombinationFactor extends CodeList {
    public designMethodGroup?: DesignMethodGroups;
    public factorsType?: LoadCombinationFactorsTypes;
    public foreignId?: number;
    public loadFactorDefault?: number;
    public loadFactorEnabled?: boolean;
    public loadFactorValues?: LoadCombinationFactorsLoadFactorEntity[];
    public sustainedLoadFactorDefault?: number;
    public sustainedLoadFactorEnabled?: boolean;
    public sustainedLoadFactorMax?: number;
    public sustainedLoadFactorMin?: number;


    constructor(codeList: LoadCombinationFactor) {
        super(codeList);

        if (codeList != null) {
            this.designMethodGroup = codeList.designMethodGroup;
            this.factorsType = codeList.factorsType;
            this.foreignId = codeList.foreignId;
            this.loadFactorDefault = codeList.loadFactorDefault;
            this.loadFactorEnabled = codeList.loadFactorEnabled;
            this.loadFactorValues = codeList.loadFactorValues;
            this.sustainedLoadFactorDefault = codeList.sustainedLoadFactorDefault;
            this.sustainedLoadFactorEnabled = codeList.sustainedLoadFactorEnabled;
            this.sustainedLoadFactorMax = codeList.sustainedLoadFactorMax;
            this.sustainedLoadFactorMin = codeList.sustainedLoadFactorMin;
        }
    }

    public static fromService(codeList: LoadCombinationFactorEntity) {
        return new LoadCombinationFactor(CodeList.baseFromService('LoadCombinationFactorEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
            designMethodGroup: codeList.designMethodGroup,
            factorsType: codeList.factorsType,
            foreignId: codeList.foreignId,
            loadFactorDefault: codeList.loadFactorDefault,
            loadFactorEnabled: codeList.loadFactorEnabled,
            loadFactorValues: codeList.loadFactorValues,
            sustainedLoadFactorDefault: codeList.sustainedLoadFactorDefault,
            sustainedLoadFactorEnabled: codeList.sustainedLoadFactorEnabled,
            sustainedLoadFactorMax: codeList.sustainedLoadFactorMax,
            sustainedLoadFactorMin: codeList.sustainedLoadFactorMin,
        } as LoadCombinationFactor));
    }

    public override cloneDeep(): LoadCombinationFactor {
        const codeList = super.cloneDeep() as LoadCombinationFactor;
        codeList.designMethodGroup = this.designMethodGroup;
        codeList.factorsType = this.factorsType;
        codeList.foreignId = this.foreignId;
        codeList.loadFactorDefault = this.loadFactorDefault;
        codeList.loadFactorEnabled = this.loadFactorEnabled;
        codeList.loadFactorValues = this.loadFactorValues;
        codeList.sustainedLoadFactorDefault = this.sustainedLoadFactorDefault;
        codeList.sustainedLoadFactorEnabled = this.sustainedLoadFactorEnabled;
        codeList.sustainedLoadFactorMax = this.sustainedLoadFactorMax;
        codeList.sustainedLoadFactorMin = this.sustainedLoadFactorMin;

        return codeList;
    }
}