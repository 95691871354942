import { CodeList, ICodeListTextDeps } from '../code-list';
import { BoltSizeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class BoltSize extends CodeList {
    public sizeLabel?: string;

    constructor(codeList: BoltSizeEntity) {
        super(codeList);

        if (codeList != null) {
            this.sizeLabel = codeList.sizeLabel;
        }
    }

    public static fromService(codeList: BoltSizeEntity) {
        return new BoltSize(CodeList.baseFromService('BoltSizeEntity', codeList, {
            sizeLabel: codeList.sizeLabel,
        } as BoltSizeEntity));
    }

    public override cloneDeep(): BoltSize {
        const codeList = super.cloneDeep() as BoltSize;
        codeList.sizeLabel = this.sizeLabel;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
}
