<div [id]="'product-dropdown-select-product-' + id + '-row'" class="product-dropdown-select-button"
    [class.active]="isSelected" [class.lastElement]="isLastElement" [attr.disabled]="isReadOnly ? true : null"
    (click)="selectProduct()">
    <div class="product-dropdown-select-button-container">
        <div class="product-dropdown-detailed-image" [ngClass]="isPostInstalledAnchor ? 'post-installed-anchor' : ''">
            <span class="pe-ui-cw-sprite sprite-responsive" [ngClass]="'pe-ui-cw-' + image"></span>
        </div>

        <div class="line-seperator"></div>

        <div class="product-dropdown-detailed-name" [innerHtml]="name"></div>
    </div>
</div>