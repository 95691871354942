import { Component, Input, OnInit, TrackByFunction, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { IModalGridComponentInput, IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { SimpleCheckboxButtonHelper } from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { CalculationService } from '../../services/calculation.service';
import { ProjectCodeList } from '../../entities/enums/project-code-list';
import { DesignMethodGroup } from '../../entities/code-lists/design-method-group';
import { DesignCodeList } from '../../entities/enums/design-code-list';
import { PropertyMetaData } from '../../entities/properties';
import { ILoadsWizardComponentInput } from '../loads/loads.component';
import { ModalGridHelper } from '../../helpers/modal-grid-helper';
import { LoadCombinationEquation } from '../../entities/code-lists/load-combination-equation';
import { LoadCombinationFactor } from '../../entities/code-lists/load-combination-factor';
import { DesignMethodGroups, DesignStandards, LoadTypes, SeismicShearTypes, SeismicTensionTypes } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { LoadCombinationWizardEquationEntity, LoadCombinationWizardFactorEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Dialogs';
import { UIProperty } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { SeismicStressTypeEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { Constants } from '../../entities/constants';
import { LoggerService } from '../../services/logger.service';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

enum Step {
    Equations,
    Factors
}

interface IEquation {
    id: number;
    name: string;
    description: string;
    descriptionParams: number[];

    selectedCheckbox: CheckboxButtonProps<boolean>;
}

interface IFactors {
    id: number;
    name: string;
    displayKey: string;

    sustainedLoadFactorEnabled?: boolean;
    sustainedLoadFactorTextBox: NumericTextBoxProps;

    loadFactorEnabled?: boolean;
    loadFactorDropdown: DropdownProps<number>;

    forceXTextBox: NumericTextBoxProps;
    forceYTextBox: NumericTextBoxProps;
    forceZTextBox: NumericTextBoxProps;

    momentXTextBox: NumericTextBoxProps;
    momentYTextBox: NumericTextBoxProps;
    momentZTextBox: NumericTextBoxProps;
}

interface ISustainedFactors {
    sustainedFD: number;
    sustainedFF: number;
    sustainedFT: number;
    sustainedFL: number;
    sustainedFH: number;
    sustainedFLr: number;
    sustainedFS: number;
    sustainedFR: number;
    sustainedFW: number;
    sustainedFE: number;
}

interface ICalculateWizardSustainedLoadEquation {
    equation: IEquation;
    D: number;
    F: number;
    T: number;
    L: number;
    H: number;
    Lr: number;
    S: number;
    R: number;
    W: number;
    E: number;
    fD: number;
    fL: number;
    fS: number;
    sustained: ISustainedFactors;
}

@Component({
    templateUrl: './loads-wizard.component.html',
    styleUrls: ['./loads-wizard.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsWizardComponent implements OnInit {
    @Input()
    modalInstance!: ModalInstance<ILoadsWizardComponentInput>;

    @ViewChild('equationsForm')
    public equationsForm!: NgForm;

    @ViewChild('factorsForm')
    public factorsForm!: NgForm;

    private static readonly numberOfDecimals = 6;
    private static readonly defaultLoadValue = 0;

    public submitted = false;
    public pendingSave = false;

    public step = Step.Equations;
    public equations!: IEquation[];
    public factors!: IFactors[];

    public selectAllCheckbox!: CheckboxButtonProps<boolean>;
    public loadCombinationFactors;
    public loadCombinationEquation;

    public stepEnum = Step;
    public unitGroupEnum = {
        Force: UnitGroup.Force,
        Moment: UnitGroup.Moment,
    };

    constructor(
        public localizationService: LocalizationService,
        private user: UserService,
        private codeListService: CodeListService,
        private unitService: UnitService,
        private modalService: ModalService,
        private logger: LoggerService,
        private calculationService: CalculationService,
        private numberService: NumberService
    ) {
        this.loadCombinationEquation = (this.design.designData.designCodeLists[DesignCodeList.LoadCombinationEquation] as LoadCombinationEquation[]);
        this.loadCombinationFactors = (this.design.designData.designCodeLists[DesignCodeList.LoadCombinationFactor] as LoadCombinationFactor[]);
    }

    public get title() {
        switch (this.step) {
            case Step.Equations: {
                const designMethodCl = (this.codeListService.projectCodeLists[ProjectCodeList.DesignMethodGroups] as DesignMethodGroup[]).find(x => x.id == this.design.designMethodGroup?.id);
                return this.getEquationsStepTitle(designMethodCl?.id ?? 0);
            }

            case Step.Factors:
                return this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Factors.Title');

            default:
                throw new Error('Unknown step.');
        }
    }

    public get isNextButtonDisabled() {
        return !this.equations.some(eq => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(eq.selectedCheckbox));
    }

    private get design() {
        return this.user.design;
    }

    private get sustainedLoadFactorAll() {
        return {
            sustainedFD: 1,
            sustainedFE: 1,
            sustainedFF: 1,
            sustainedFH: 1,
            sustainedFL: 1,
            sustainedFLr: 1,
            sustainedFR: 1,
            sustainedFS: 1,
            sustainedFT: 1,
            sustainedFW: 1
        } as ISustainedFactors;
    }

    private get sustainedLoadFactorEOnly() {
        return {
            sustainedFD: 0,
            sustainedFE: 1,
            sustainedFF: 0,
            sustainedFH: 0,
            sustainedFL: 0,
            sustainedFLr: 0,
            sustainedFR: 0,
            sustainedFS: 0,
            sustainedFT: 0,
            sustainedFW: 0
        } as ISustainedFactors;
    }

    ngOnInit(): void {
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        const equationsCl = this.loadCombinationEquation.filter(x => x.designMethodGroup == this.design.designData.projectDesign.options.designMethodGroup);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        this.equations = equationsCl.map(cl => {
            const checked = (this.design.loadCombinationWizard?.loadCombinationWizardEquations?.length > 0)
                ? this.design.loadCombinationWizard.loadCombinationWizardEquations.some(x => x.id == cl.id)
                : false;

            return {
                id: cl.id,
                name: cl.getTranslatedNameText(codeListDeps),
                description: format(cl.descriptionFormat ?? '', ...cl.descriptionParams?.map(x => this.unitService.formatNumber(x, 2)) ?? ''),
                descriptionParams: cl.descriptionParams,

                selectedCheckbox: SimpleCheckboxButtonHelper.createSimpleCheckbox({
                    itemId: `loads-wizard-equations-checkbox-${cl.id}`,
                    itemText: '',
                    testId: `equations-${cl.displayKey}`,
                    checked
                })
            } as IEquation;
        });

        const forceXRange: IValueRange = this.getPropertyRange(PropertyMetaData.LoadCombination_CW_ForceX.id);
        const forceYRange: IValueRange = this.getPropertyRange(PropertyMetaData.LoadCombination_CW_ForceY.id);
        const forceZRange: IValueRange = this.getPropertyRange(PropertyMetaData.LoadCombination_CW_ForceZ.id);
        const momentXRange: IValueRange = this.getPropertyRange(PropertyMetaData.LoadCombination_CW_MomentX.id);
        const momentYRange: IValueRange = this.getPropertyRange(PropertyMetaData.LoadCombination_CW_MomentY.id);
        const momentZRange: IValueRange = this.getPropertyRange(PropertyMetaData.LoadCombination_CW_MomentZ.id);
        const unitForce = this.unitService.getDefaultUnit(UnitGroup.Force, this.design);
        const unitMoment = this.unitService.getDefaultUnit(UnitGroup.Moment, this.design);
        const factorsCl = (this.design.designData.designCodeLists[DesignCodeList.LoadCombinationFactor] as LoadCombinationFactor[]).filter(x => x.designMethodGroup == this.design.designData.projectDesign.options.designMethodGroup);
        this.factors = factorsCl.map(cl => {
            const values = (this.design.loadCombinationWizard?.loadCombinationWizardFactors?.length > 0)
                ? this.design.loadCombinationWizard.loadCombinationWizardFactors.find(x => x.id == cl.id)
                : null;

            return {
                id: cl.id,
                name: cl.getTranslatedNameText(codeListDeps),
                displayKey: cl.displayKey,

                sustainedLoadFactorEnabled: cl.sustainedLoadFactorEnabled,
                sustainedLoadFactorTextBox: {
                    unit: Unit.None,
                    value: values != null ? values.sustainedLoadFactor : cl.sustainedLoadFactorDefault,
                    minValue: cl.sustainedLoadFactorMin,
                    maxValue: cl.sustainedLoadFactorMax,
                },
                loadFactorEnabled: cl.loadFactorEnabled,
                loadFactorDropdown: {
                    id: `loads-wizard-factors-load-factor-${cl.id}`,
                    items: cl.loadFactorValues?.map(x => {
                        return {
                            value: x.id,
                            text: this.unitService.formatNumber(x.value, 2)
                        };
                    }),
                    selectedValue: values != null ? values.loadFactorId : cl.loadFactorDefault,
                    notSelectedText: !cl.loadFactorEnabled ? ' ' : null
                },
                forceXTextBox: {
                    unit: unitForce,
                    value: values != null ? values.forceX : LoadsWizardComponent.defaultLoadValue,
                    minValue: forceXRange.min,
                    maxValue: forceXRange.max,
                },
                forceYTextBox: {
                    unit: unitForce,
                    value: values != null ? values.forceY : LoadsWizardComponent.defaultLoadValue,
                    minValue: forceYRange.min,
                    maxValue: forceYRange.max,
                },
                forceZTextBox: {
                    unit: unitForce,
                    value: values != null ? values.forceZ : LoadsWizardComponent.defaultLoadValue,
                    minValue: forceZRange.min,
                    maxValue: forceZRange.max,
                },
                momentXTextBox: {
                    unit: unitMoment,
                    value: values != null ? values.momentX : LoadsWizardComponent.defaultLoadValue,
                    minValue: momentXRange.min,
                    maxValue: momentXRange.max,
                },
                momentYTextBox: {
                    unit: unitMoment,
                    value: values != null ? values.momentY : LoadsWizardComponent.defaultLoadValue,
                    minValue: momentYRange.min,
                    maxValue: momentYRange.max,
                },
                momentZTextBox: {
                    unit: unitMoment,
                    value: values != null ? values.momentZ : LoadsWizardComponent.defaultLoadValue,
                    minValue: momentZRange.min,
                    maxValue: momentZRange.max,
                }
            } as IFactors;
        });

        this.selectAllCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemText: ''
        });
        this.itemCheckboxSelectedValuesChange();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public identifyEquation: TrackByFunction<IEquation> = function (_: number, item: IEquation) {
        return item.id;
    };

    public identifyFactor: TrackByFunction<IFactors> = function (_: number, item: IFactors) {
        return item.id;
    };

    public nextStep() {
        switch (this.step) {
            case Step.Equations:
                if (this.submitted || (this.equationsForm.enabled && !this.equationsForm.valid)) {
                    return;
                }

                this.step = Step.Factors;

                return;

            case Step.Factors:
                if (this.submitted || (this.factorsForm.enabled && !this.factorsForm.valid)) {
                    return;
                }

                this.save();
                return;

            default:
                throw new Error('Unknown step.');
        }
    }

    public prevStep() {
        if (this.step == Step.Factors) {
            this.step = Step.Equations;
            return;
        }

        throw new Error('Unknown step.');
    }

    public close() {
        this.modalInstance.close();
    }

    public getLocalizedTextAndUnit(key: string, unit: UnitGroup) {
        return `${this.localizationService.getString(key)} [${this.unitService.formatUnit(this.unitService.getDefaultUnit(unit))}]`;
    }

    public toggleCheckbox(checkBox: CheckboxButtonProps<boolean>) {
        const value = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(checkBox);
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(checkBox, !value);
    }

    public selectAllCheckboxSelectedValuesChange() {
        const value = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.selectAllCheckbox) ?? false;
        for (const eq of this.equations) {
            SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(eq.selectedCheckbox, value);
        }
    }

    public itemCheckboxSelectedValuesChange() {
        const selectAllChecked = this.equations.every(eq => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(eq.selectedCheckbox));
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.selectAllCheckbox, selectAllChecked);
    }

    private save() {
        if (
            this.submitted
            || (this.equationsForm.enabled && !this.equationsForm.valid)
            || (this.factorsForm.enabled && !this.factorsForm.valid)
        ) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const selectedEquations = this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox));

        if (selectedEquations.length > Constants.MaxLoadCombinations) {
            this.submitted = false;
            this.pendingSave = false;

            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.LoadsHNAWizard.TooManyLoadCases.Title'),
                format(this.localizationService.getString('Agito.Hilti.Profis3.LoadsHNAWizard.TooManyLoadCases.Message'), Constants.MaxLoadCombinations)
            );
        }
        else {
            this.wizardRulesEnforcer();
        }
    }

    private wizardRulesEnforcer() {
        // Start popup chain only if equations are selected
        if (this.equations.some(equation => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(equation.selectedCheckbox))) {
            // Start a chain of popups to gather necessary information from user
            this.wizardTensionHelper();
        }
        else {
            this.submitWizardData(false);
        }
    }

    public getEquationsStepTitle(designMethodId: number) {
        switch (designMethodId) {
            case DesignMethodGroups.CSAA23314:
            case DesignMethodGroups.CSAA23319:
                return format(this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Equations.Title'), this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Equations.CSATitleExtension'));
            case DesignMethodGroups.ACI31811:
                return format(this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Equations.Title'), this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Equations.ACI318_11.TitleExtension'));
            case DesignMethodGroups.ACI31814:
                return format(this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Equations.Title'), this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Equations.ACI318_14.TitleExtension'));
            case DesignMethodGroups.ACI31819:
                return format(this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Equations.Title'), this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.Equations.ACI318_19.TitleExtension'));
            default:
                throw new Error('Unsupported design method group.');
        }
    }

    private wizardTensionNonSeismicEquation() {
        const tensionSeismicDesign = false;
        const tensionType: SeismicTensionTypes | undefined = undefined;

        // Static Load type
        if (this.design.loadType == LoadTypes.Static) {
            this.wizardShearHelper(tensionSeismicDesign, tensionType);

            // Seismic Load type
        }
        else if (this.design.loadType == LoadTypes.Seismic) {

            // If seismic design is selected when entering the load combination wizard, and no equations with the E component are selected,
            // and a value is entered for E, show a popup informing he needs to revise his inputs.
            if (this.checkEPresence()) {
                this.modalService.openConfirmChange({
                    id: '-wizard-confirm-dialog-combined-01',
                    title: this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.OnSiteTestsEmbedmentDepth.Title'),
                    message: this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.NoEarthquakeEquationForSeismic.Combined.Message'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.CW.General.Ok'),
                    onConfirm: (modal) => {
                        this.submitted = false;
                        this.pendingSave = false;
                        modal.close();
                    }
                }).closed.then(() => {
                    this.submitted = false;
                    this.pendingSave = false;
                });
            } else {
                this.wizardShearHelper(tensionSeismicDesign, tensionType);
            }
        }
    }

    private wizardTensionSeismicEquationSeismic() {
        const tensionE = this.checkTension();
        let tensionSeismicDesign = false;
        let tensionType: SeismicTensionTypes | undefined = undefined;
        let alreadySet: boolean;
        const isTensionTypeNull = this.design.loadCombinationWizard.seismicTensionType == 16;

        // E > 20% for tension
        if (tensionE) {
            tensionSeismicDesign = true;
            this.wizardShearHelper(tensionSeismicDesign, tensionType);

            // E <= 20% for tension
        }
        else if (isTensionTypeNull) {
            this.openConfirmChangeModalNonTension(tensionSeismicDesign, tensionType);
        }
        else {
            alreadySet = false;
            this.modalService.openConfirmChange({
                id: '-wizard-confirm-dialog-tension-03',
                title: this.localizationService.getString('Agito.Hilti.CW.General.Warning'),
                message: this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.NoEarthquakeComponentForSeismic.Tension.LessThan20.Message'),
                confirmButtonText: this.localizationService.getString('Agito.Hilti.CW.General.Yes'),
                cancelButtonText: this.localizationService.getString('Agito.Hilti.CW.General.No'),
                onConfirm: (modal) => {
                    tensionSeismicDesign = true;
                    alreadySet = true;
                    modal.close();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            }).closed.then(() => {
                if (!alreadySet) {
                    tensionSeismicDesign = false;
                    tensionType = SeismicTensionTypes.None;
                }

                this.wizardShearHelper(tensionSeismicDesign, tensionType);
            });
        }
    }

    private wizardTensionSeismicEquationStatic() {
        const tensionE = this.checkTension();
        let tensionSeismicDesign = false;
        let tensionType: SeismicTensionTypes | undefined = undefined;
        let alreadySet: boolean;

        // E > 20% for tension
        if (tensionE) {
            // Open Seismic Tension type grid modal
            const props = this.prepareModalGridItemsProp(DesignCodeList.SeismicTensionTypes);

            props.onSelect = (item) => {
                tensionType = item.value as SeismicTensionTypes;
                tensionSeismicDesign = true;

                // If None is selected
                if (tensionType == SeismicTensionTypes.None) {
                    alreadySet = false;
                    this.modalService.openConfirmChange({
                        id: '-wizard-confirm-dialog-tension-01',
                        title: this.localizationService.getString('Agito.Hilti.CW.General.Warning'),
                        message: this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.NoEarthquakeComponentForSeismic.Tension.MoreThan20.Message'),
                        confirmButtonText: this.localizationService.getString('Agito.Hilti.CW.General.Yes'),
                        cancelButtonText: this.localizationService.getString('Agito.Hilti.CW.General.No'),
                        onConfirm: (modal) => {
                            // Open Seismic Tension type grid modal without None value
                            const props2 = this.prepareModalGridItemsProp(DesignCodeList.SeismicTensionTypes);
                            props2.onSelect = (item2) => {
                                tensionType = item2.value as SeismicTensionTypes;
                                tensionSeismicDesign = true;
                                alreadySet = true;
                                modal.close();
                            };
                            this.modalService.openModalGridInternal(props2);
                        },
                        onCancel: (modal) => {
                            modal.close();
                        }
                    }).closed.then(() => {
                        if (!alreadySet) {
                            tensionSeismicDesign = false;
                            tensionType = SeismicTensionTypes.None;
                        }

                        this.wizardShearHelper(tensionSeismicDesign, tensionType);
                    });
                }
                else {
                    this.wizardShearHelper(tensionSeismicDesign, tensionType);
                }
            };

            this.modalService.openModalGridInternal(props);

            // E <= 20% for tension
        }
        else {
            this.openConfirmChangeModalNonTension(tensionSeismicDesign, tensionType);
        }
    }

    private openConfirmChangeModalNonTension(tensionSeismicDesign: boolean, tensionType?: SeismicTensionTypes) {
        let alreadySet = false;
        this.modalService.openConfirmChange({
            id: '-wizard-confirm-dialog-tension-02',
            title: this.localizationService.getString('Agito.Hilti.CW.General.Warning'),
            message: this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.NoEarthquakeComponentForSeismic.Tension.LessThan20.Message'),
            confirmButtonText: this.localizationService.getString('Agito.Hilti.CW.General.Yes'),
            cancelButtonText: this.localizationService.getString('Agito.Hilti.CW.General.No'),
            onConfirm: (modal) => {
                // Open Seismic Tension type grid modal without None value
                const props = this.prepareModalGridItemsProp(DesignCodeList.SeismicTensionTypes);
                props.onSelect = (item) => {
                    tensionType = item.value as SeismicTensionTypes;
                    tensionSeismicDesign = true;
                    alreadySet = true;
                    modal.close();
                };
                this.modalService.openModalGridInternal(props);
            },
            onCancel: (modal) => {
                modal.close();
            }
        }).closed.then(() => {
            if (!alreadySet) {
                tensionSeismicDesign = false;
                tensionType = SeismicTensionTypes.None;
            }

            this.wizardShearHelper(tensionSeismicDesign, tensionType);
        });
    }

    private wizardTensionHelper() {
        // Equations with Earthquake component are present
        if (this.areSeismicEquationsSelected()) {

            // Static Load type
            if (this.design.loadType == LoadTypes.Static) {
                this.wizardTensionSeismicEquationStatic();

                // Seismic Load type
            }
            else if (this.design.loadType == LoadTypes.Seismic) {
                this.wizardTensionSeismicEquationSeismic();
            }

            // Equations with Earthquake component are NOT present
        }
        else {
            this.wizardTensionNonSeismicEquation();
        }
    }

    private openConfirmChangeModal(id: string, message: string, tensionType?: SeismicTensionTypes, tensionSeismicDesign?: boolean) {
        let seismicDesign = true;
        let shearType: SeismicShearTypes;
        let alreadySet = false;

        this.modalService.openConfirmChange({
            id,
            title: this.localizationService.getString('Agito.Hilti.CW.General.Warning'),
            message: this.localizationService.getString(message),
            confirmButtonText: this.localizationService.getString('Agito.Hilti.CW.General.Yes'),
            cancelButtonText: this.localizationService.getString('Agito.Hilti.CW.General.No'),
            onConfirm: (modal) => {
                seismicDesign = true;
                alreadySet = true;
                modal.close();
            },
            onCancel: (modal) => {
                modal.close();
            }
        }).closed.then(() => {
            if (!alreadySet) {
                shearType = SeismicShearTypes.None;
                seismicDesign = tensionSeismicDesign ?? false;
            }

            this.submitWizardData(seismicDesign, tensionType, shearType);
        });
    }

    private wizardShearNonSeismicEquation(tensionType?: SeismicTensionTypes) {
        let seismicDesign = true;

        if (this.design.loadType == LoadTypes.Static) {
            seismicDesign = false;
            this.submitWizardData(seismicDesign, tensionType);
        }
        else if (this.design.loadType == LoadTypes.Seismic) {
            this.openConfirmChangeModal(
                '-wizard-confirm-dialog-combined-02',
                'Agito.Hilti.CW.LoadsWizard.NoEarthquakeComponentForSeismic.Combined.Message',
                tensionType
            );
        }
    }

    private wizardShearSeismicEquationSeismic(tensionSeismicDesign: boolean, tensionType?: SeismicTensionTypes) {
        const shearE = this.checkShear();
        let seismicDesign = true;
        const isSheerTypeNull = this.design.loadCombinationWizard.seismicShearType == 8;

        if (shearE) {
            seismicDesign = true;
            this.submitWizardData(seismicDesign, tensionType);
        }
        else if (isSheerTypeNull) {
            this.wizardShearSeismicEquationStaticNonShearE(tensionSeismicDesign, tensionType);
        }
        else {
            this.openConfirmChangeModal(
                '-wizard-confirm-dialog-shear-03',
                'Agito.Hilti.CW.LoadsWizard.NoEarthquakeComponentForSeismic.Shear.LessThan20.Message',
                tensionType,
                tensionSeismicDesign
            );
        }
    }

    private wizardShearSeismicEquationStaticNonShearE(tensionSeismicDesign: boolean, tensionType?: SeismicTensionTypes) {
        let seismicDesign = true;
        let shearType: SeismicShearTypes;
        let alreadySet: boolean;

        alreadySet = false;
        this.modalService.openConfirmChange({
            id: '-wizard-confirm-dialog-shear-02',
            title: this.localizationService.getString('Agito.Hilti.CW.General.Warning'),
            message: this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.NoEarthquakeComponentForSeismic.Shear.LessThan20.Message'),
            confirmButtonText: this.localizationService.getString('Agito.Hilti.CW.General.Yes'),
            cancelButtonText: this.localizationService.getString('Agito.Hilti.CW.General.No'),
            onConfirm: (modal) => {
                // Open Seismic Shear type grid modal without None value
                const props = this.prepareModalGridItemsProp(DesignCodeList.SeismicShearTypes);
                props.onSelect = (item) => {
                    shearType = item.value as SeismicShearTypes;
                    seismicDesign = true;
                    alreadySet = true;
                    modal.close();
                };
                this.modalService.openModalGridInternal(props);
            },
            onCancel: (modal) => {
                modal.close();
            }
        }).closed.then(() => {
            if (!alreadySet) {
                shearType = SeismicShearTypes.None;
                if (tensionSeismicDesign) {
                    seismicDesign = true;
                }
                else {
                    seismicDesign = false;
                }
            }

            this.submitWizardData(seismicDesign, tensionType, shearType);
        });
    }

    private wizardShearSeismicEquationStaticShearE(tensionSeismicDesign: boolean, tensionType?: SeismicTensionTypes) {
        let seismicDesign = true;
        let shearType: SeismicShearTypes;
        let alreadySet: boolean;

        // Open Seismic Shear type grid modal
        const props = this.prepareModalGridItemsProp(DesignCodeList.SeismicShearTypes);

        props.onSelect = (item) => {
            shearType = item.value as SeismicShearTypes;

            // If None is selected
            if (shearType == SeismicShearTypes.None) {
                alreadySet = false;
                this.modalService.openConfirmChange({
                    id: '-wizard-confirm-dialog-shear-01',
                    title: this.localizationService.getString('Agito.Hilti.CW.General.Warning'),
                    message: this.localizationService.getString('Agito.Hilti.CW.LoadsWizard.NoEarthquakeComponentForSeismic.Shear.MoreThan20.Message'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.CW.General.Yes'),
                    cancelButtonText: this.localizationService.getString('Agito.Hilti.CW.General.No'),
                    onConfirm: (modal) => {
                        // Open Seismic Shear type grid modal without None value
                        const props2 = this.prepareModalGridItemsProp(DesignCodeList.SeismicShearTypes);
                        props2.onSelect = (item2) => {
                            shearType = item2.value as SeismicShearTypes;
                            seismicDesign = true;
                            alreadySet = true;
                            modal.close();
                        };
                        this.modalService.openModalGridInternal(props2);
                    },
                    onCancel: (modal) => {
                        modal.close();
                    }
                }).closed.then(() => {
                    if (!alreadySet) {
                        shearType = SeismicShearTypes.None;
                        if (tensionSeismicDesign) {
                            seismicDesign = true;
                        }
                        else {
                            seismicDesign = false;
                        }
                    }

                    this.submitWizardData(seismicDesign, tensionType, shearType);
                });
            }
            else {
                this.submitWizardData(seismicDesign, tensionType, shearType);
            }
        };

        this.modalService.openModalGridInternal(props);
    }

    private wizardShearHelper(tensionSeismicDesign: boolean, tensionType?: SeismicTensionTypes) {
        const shearE = this.checkShear();

        // Equations with Earthquake component are present
        if (this.areSeismicEquationsSelected()) {

            // Static Load type
            if (this.design.loadType == LoadTypes.Static) {

                // E > 20% for shear
                if (shearE) {
                    this.wizardShearSeismicEquationStaticShearE(tensionSeismicDesign, tensionType);

                    // E <= 20% for shear
                }
                else {
                    this.wizardShearSeismicEquationStaticNonShearE(tensionSeismicDesign, tensionType);
                }

                // Seismic Load type
            }
            else if (this.design.loadType == LoadTypes.Seismic) {
                this.wizardShearSeismicEquationSeismic(tensionSeismicDesign, tensionType);
            }

            // Equations with Earthquake component are NOT present
        }
        else {
            this.wizardShearNonSeismicEquation(tensionType);
        }
    }

    public setDesignProperties(design: any, selectedEquations: any[], seismicDesign: boolean, tensionType?: any, shearType?: any) {
        design.loadCombinationWizard.loadCombinationWizardEquations = selectedEquations.map((x): LoadCombinationWizardEquationEntity => ({
            id: x.id
        }));

        design.loadCombinationWizard.loadCombinationWizardFactors = this.factors.map((x): LoadCombinationWizardFactorEntity => ({
            id: x.id,
            sustainedLoadFactor: x.sustainedLoadFactorTextBox.value ?? 0,
            loadFactorId: x.loadFactorDropdown.selectedValue ?? 0,
            forceX: x.forceXTextBox.value ?? 0,
            forceY: x.forceYTextBox.value ?? 0,
            forceZ: x.forceZTextBox.value ?? 0,
            momentX: x.momentXTextBox.value ?? 0,
            momentY: x.momentYTextBox.value ?? 0,
            momentZ: x.momentZTextBox.value ?? 0
        }));

        design.loadCombinationWizard.seismicDesign = seismicDesign;

        if (tensionType) {
            design.loadCombinationWizard.seismicTensionType = tensionType;
        }

        if (shearType) {
            design.loadCombinationWizard.seismicShearType = shearType;
        }
    }

    private submitWizardData(seismicDesign: boolean, tensionType?: number, shearType?: number) {
        // Save wizard data and trigger calculation
        const selectedEquations = this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox));

        this.calculationService.calculateAsync(this.design,
            (design) => {
                this.setDesignProperties(design, selectedEquations, seismicDesign, tensionType, shearType);
            },
            { suppressLoadingFlag: true }
        )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                if (this.modalInstance.input?.onSave != null) {
                    this.modalInstance.input.onSave();
                }
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    this.logger.log(err.message, LogType.error);
                }

                this.submitted = false;
            });
    }

    private areSeismicEquationsSelected() {
        return this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox) && x.description.indexOf('E') !== -1).length > 0;
    }

    private getFactors(factorIds: number[]) {
        return this.factors.filter(x => factorIds.includes(x.id)).find(() => true);
    }

    private getFactorMomentValue(factor?: IFactors) {
        return factor != null && factor.momentZTextBox.value ? factor.momentZTextBox.value : 0;
    }

    private getFactorForceValue(factor?: IFactors) {
        return factor != null && factor.forceZTextBox.value ? factor.forceZTextBox.value : 0;
    }

    private getParameters() {
        const factorsD = this.getFactors([1, 11, 21, 31]);
        const factorsF = this.getFactors([2, 12, 22]);
        const factorsT = this.getFactors([3, 13, 23]);
        const factorsL = this.getFactors([4, 14, 24, 32]);
        const factorsH = this.getFactors([5, 15, 25]);
        const factorsLr = this.getFactors([6, 16, 26]);
        const factorsS = this.getFactors([7, 17, 27, 33]);
        const factorsR = this.getFactors([8, 18, 28]);
        const factorsW = this.getFactors([9, 19, 29, 34]);
        const factorsE = this.getFactors([10, 20, 30, 35]);

        const parameters: ICalculateWizardSustainedLoadEquation = {
            equation: {} as IEquation,
            D: this.getFactorMomentValue(factorsD),
            F: this.getFactorForceValue(factorsF),
            T: this.getFactorForceValue(factorsT),
            L: this.getFactorForceValue(factorsL),
            H: this.getFactorForceValue(factorsH),
            Lr: this.getFactorForceValue(factorsLr),
            S: this.getFactorForceValue(factorsS),
            R: this.getFactorForceValue(factorsR),
            W: this.getFactorForceValue(factorsW),
            E: this.getFactorForceValue(factorsE),
            fD: factorsD != null ? this.getLoadFactorValue(factorsD) : 0,
            fL: factorsL != null ? this.getLoadFactorValue(factorsL) : 0,
            fS: factorsS != null ? this.getLoadFactorValue(factorsS) : 0,
            sustained: this.sustainedLoadFactorAll
        };

        return parameters;
    }

    private checkTension() {
        const selectedEquations = this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox));
        const parameters = this.getParameters();

        let check = false;

        for (const equation of selectedEquations) {
            let seismicDesign = false;
            let compression = false;
            // If any but N pass the E(*)/sum(*) > 20% then compression is ignored.
            let ignoreCompression = false;
            parameters.equation = equation;

            // N
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumN = this.calculateWizardSustainedLoadEquation(parameters);

            // We're looking for value of E alone
            parameters.sustained = this.sustainedLoadFactorEOnly;
            const eN = this.calculateWizardSustainedLoadEquation(parameters);

            compression = sumN < 0 || eN < 0;
            seismicDesign = seismicDesign || (Math.abs(eN / sumN) > 0.2);


            // Mx
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumMx = this.calculateWizardSustainedLoadEquation(parameters);

            // // We're looking for value of E alone
            parameters.sustained = this.sustainedLoadFactorEOnly;
            const eMx = this.calculateWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eMx / sumMx) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eMx / sumMx) > 0.2);


            // My
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumMy = this.calculateWizardSustainedLoadEquation(parameters);

            // We're looking for value of E alone
            parameters.sustained = this.sustainedLoadFactorEOnly;
            const eMy = this.calculateWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eMy / sumMy) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eMy / sumMy) > 0.2);


            // Is equation relevant for seismic design
            check = check || ((!compression || ignoreCompression) && seismicDesign);
        }

        return check;
    }

    private checkEPresence() {
        const factorsE = this.factors.filter(x => x.id == 10 || x.id == 20 || x.id == 30 || x.id == 35).find(() => true);
        return factorsE != null
            && (
                factorsE.forceXTextBox.value != 0
                || factorsE.forceYTextBox.value != 0
                || factorsE.forceZTextBox.value != 0
                || factorsE.momentXTextBox.value != 0
                || factorsE.momentYTextBox.value != 0
                || factorsE.momentZTextBox.value != 0
            );
    }

    private checkShear() {
        const selectedEquations = this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox));

        const parameters = this.getParameters();

        let check = false;

        for (const equation of selectedEquations) {
            let seismicDesign = false;
            const compression = false;
            // If any but N pass the E(*)/sum(*) > 20% then compression is ignored.
            let ignoreCompression = false;
            parameters.equation = equation;


            // Vx
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumVx = this.calculateWizardSustainedLoadEquation(parameters);

            parameters.sustained = this.sustainedLoadFactorEOnly; // We're looking for value of E alone
            const eVx = this.calculateWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eVx / sumVx) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eVx / sumVx) > 0.2);


            // Vy
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumVy = this.calculateWizardSustainedLoadEquation(parameters);

            parameters.sustained = this.sustainedLoadFactorEOnly; // We're looking for value of E alone
            const eVy = this.calculateWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eVy / sumVy) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eVy / sumVy) > 0.2);


            // Mz
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumMz = this.calculateWizardSustainedLoadEquation(parameters);

            // We're looking for value of E alone
            parameters.sustained = this.sustainedLoadFactorEOnly;
            const eMz = this.calculateWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eMz / sumMz) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eMz / sumMz) > 0.2);


            // Is equation relevant for seismic design
            check = check || ((!compression || ignoreCompression) && seismicDesign);
        }

        return check;
    }

    private getLoadFactorValue(factor: IFactors) {
        const factorCL = this.loadCombinationFactors.filter(x => x.designMethodGroup == this.design.designData.projectDesign.options.designMethodGroup && x.id == factor.id)[0];
        const loadFactorValue = factorCL.loadFactorValues?.find(x => x.id == factor.loadFactorDropdown.selectedValue);
        return loadFactorValue != null ? loadFactorValue.value : 0;
    }

    private calculateWizardSustainedLoadEquation(parameters: ICalculateWizardSustainedLoadEquation) {
        switch (parameters.equation.id) {
            case 1:
            case 17:
            case 33:
            case 57:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F);
            case 2:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F + parameters.sustained.sustainedFT * parameters.T) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFLr * parameters.Lr);
            case 3:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F + parameters.sustained.sustainedFT * parameters.T) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFS * parameters.S);
            case 4:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F + parameters.sustained.sustainedFT * parameters.T) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFR * parameters.R);
            case 5:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFLr * parameters.Lr) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 6:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 7:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFR * parameters.R) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 8:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFLr * parameters.Lr) + 0.8 * (parameters.sustained.sustainedFW * parameters.W);
            case 9:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S) + 0.8 * (parameters.sustained.sustainedFW * parameters.W);
            case 10:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFR * parameters.R) + 0.8 * (parameters.sustained.sustainedFW * parameters.W);
            case 11:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFLr * parameters.Lr);
            case 12:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFS * parameters.S);
            case 13:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFR * parameters.R);
            case 14:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + (parameters.sustained.sustainedFE * parameters.E) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + (parameters.sustained.sustainedFS * parameters.fS * parameters.S);
            case 15:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H);
            case 16:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + (parameters.sustained.sustainedFE * parameters.E) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H);
            case 18:
            case 34:
            case 58:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFLr * parameters.Lr);
            case 19:
            case 35:
            case 59:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFS * parameters.S);
            default:
                return this.calculateWizardSustainedLoadEquation2(parameters);
        }
    }

    private calculateWizardSustainedLoadEquation2(parameters: ICalculateWizardSustainedLoadEquation) {
        switch (parameters.equation.id) {
            case 20:
            case 36:
            case 60:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFR * parameters.R);
            case 21:
            case 37:
            case 61:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFLr * parameters.Lr) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 22:
            case 38:
            case 62:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 23:
            case 39:
            case 63:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFR * parameters.R) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 24:
            case 40:
            case 64:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFLr * parameters.Lr) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[3] * (parameters.sustained.sustainedFW * parameters.W);
            case 25:
            case 41:
            case 65:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[3] * (parameters.sustained.sustainedFW * parameters.W);
            case 26:
            case 42:
            case 66:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFR * parameters.R) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[3] * (parameters.sustained.sustainedFW * parameters.W);
            case 27:
            case 43:
            case 67:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFLr * parameters.Lr);
            case 28:
            case 44:
            case 68:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFS * parameters.S);
            case 29:
            case 45:
            case 69:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFR * parameters.R);
            case 30:
            case 46:
            case 70:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFE * parameters.E) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H) + (parameters.sustained.sustainedFS * parameters.fS * parameters.S);
            case 31:
            case 47:
            case 71:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + (parameters.sustained.sustainedFW * parameters.W) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H);
            case 32:
            case 48:
            case 72:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFE * parameters.E) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H);
            case 49:
            case 89:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D);
            case 50:
            case 90:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S);
            case 51:
            case 91:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W);
            case 52:
            case 92:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFS * parameters.S) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L);
            case 53:
            case 93:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFS * parameters.S) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W);
            case 54:
            case 94:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFW * parameters.W) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L);
            case 55:
            case 95:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFW * parameters.W) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S);
            case 56:
            case 96:
                return (parameters.sustained.sustainedFD * parameters.D) + (parameters.sustained.sustainedFE * parameters.E) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S);
            default:
                throw new Error(`Unsupported load combination  equation ${parameters.equation.id}.`);
        }
    }

    private prepareModalGrid(codeList: number, title: string, allowedValues: number[]) {
        const itemMapping = ModalGridHelper.getPopupGridItemMapping(this.localizationService, this.unitService, codeList);
        const allItems = ModalGridHelper.createPopupGridItems(codeList, this.design.designData.designCodeLists, itemMapping);

        // filter group items
        const filteredItems = allItems?.filter((item) => allowedValues != null ? allowedValues.includes(item.value as number) : true);

        const props: IModalGridComponentInput<IModalGridItem<number>> = {
            popupTitle: title,
            items: filteredItems,
            selectedItem: allItems != null && allItems.length > 0
                ? allItems[0]
                : undefined,
            numberOfColumns: 1,
            preventClose: true
        };
        return props;
    }

    public getDesignCodeListsAllowedItems(type: DesignCodeList, designStandard: DesignStandards, designMethodGroup: DesignMethodGroups) {
        const allowedCodeList = this.design.designData.designCodeLists[type] as unknown as SeismicStressTypeEntity[];
        return (designStandard == DesignStandards.ACI )
            ? allowedCodeList.filter(x => x.designStandardId == designStandard && x.designMethodGroupId == designMethodGroup).map(x => x.id)
            : allowedCodeList.filter(x => x.designStandardId == designStandard && x.displayKey != 'None').map(x => x.id);
    }

    private prepareModalGridItemsProp(type: DesignCodeList) {
        const title = (type == DesignCodeList.SeismicTensionTypes)
            ? 'Agito.Hilti.CW.LoadsWizard.RegionSeismicDesign.ControlSeismicTensionType.GridTitle'
            : 'Agito.Hilti.CW.LoadsWizard.RegionSeismicDesign.ControlSeismicShearType.GridTitle';

        return this.prepareModalGrid(
            type,
            this.localizationService.getString(title),
            this.getDesignCodeListsAllowedItems(type, this.design.designData.projectDesign.options.designStandard, this.design.designData.projectDesign.options.designMethodGroup)
        );
    }

    private getPropertyRange(uiProperty: UIProperty): IValueRange {

        const propertyValue = this.codeListService.getPropertyValue(uiProperty, this.design.region.id ?? -1);

        return {
            max: propertyValue?.maxValue,
            min: propertyValue?.minValue
        };
    }
}
