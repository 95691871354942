import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { IModelCW } from '../../../gl-model/base-component';
import { BaseUpdateCW } from '../../../gl-model/base-update';
import { KeyValue } from '@angular/common';
import { Design } from '../../../entities/design';
import { ILoadValue } from '../components/loads-manager';

export class LoadsUpdater extends BaseUpdateCW {

    @propertyValueChanged(UIProperty.LoadCombination_CW_ForceX)
    private LoadCombination_CW_ForceX(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    @propertyValueChanged(UIProperty.LoadCombination_CW_ForceY)
    private LoadCombination_CW_ForceY(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    @propertyValueChanged(UIProperty.LoadCombination_CW_ForceZ)
    private LoadCombination_CW_ForceZ(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    @propertyValueChanged(UIProperty.LoadCombination_CW_MomentX)
    private LoadCombination_CW_MomentX(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    @propertyValueChanged(UIProperty.LoadCombination_CW_MomentY)
    private LoadCombination_CW_MomentY(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    @propertyValueChanged(UIProperty.LoadCombination_CW_MomentZ)
    private LoadCombination_CW_MomentZ(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    @propertyValueChanged(UIProperty.LoadCombination_CW_SustainedForceZ)
    private LoadCombination_CW_SustainedForceZ(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    @propertyValueChanged(UIProperty.LoadCombination_CW_SustainedMomentX)
    private LoadCombination_CW_SustainedMomentX(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    @propertyValueChanged(UIProperty.LoadCombination_CW_SustainedMomentY)
    private LoadCombination_CW_SustainedMomentY(load: KeyValue<string, number>, model: IModelCW, design: Design) {
        this.setLoadCombinations(design, model);
    }

    private setLoadCombinations(design: Design, model: IModelCW) {
        model.loadCombinations = [];
        const loadEntity = design.designData.projectDesign.loads;
        for (const lc of loadEntity.loadCombinations) {
            model.loadCombinations.push({
                loadCombinationId: lc.id,
                isSelected: lc.id === loadEntity.selectedLoadCombinationId,
                hasSustainedLoads: lc.hasSustainedLoads,
                loads: [
                    {
                        uiProperty: UIProperty.LoadCombination_CW_ForceX,
                        value: lc.forceX
                    } as ILoadValue,
                    {
                        uiProperty: UIProperty.LoadCombination_CW_ForceY,
                        value: lc.forceY
                    } as ILoadValue,
                    {
                        uiProperty: UIProperty.LoadCombination_CW_ForceZ,
                        value: lc.forceZ
                    } as ILoadValue,
                    {
                        uiProperty: UIProperty.LoadCombination_CW_MomentX,
                        value: lc.momentX
                    } as ILoadValue,
                    {
                        uiProperty: UIProperty.LoadCombination_CW_MomentY,
                        value: lc.momentY
                    } as ILoadValue,
                    {
                        uiProperty: UIProperty.LoadCombination_CW_MomentZ,
                        value: lc.momentZ
                    } as ILoadValue],
                sustainedLoads: [
                    {
                        uiProperty: UIProperty.LoadCombination_CW_SustainedForceZ,
                        value: lc.dynamicForceZ
                    } as ILoadValue,
                    {
                        uiProperty: UIProperty.LoadCombination_CW_SustainedMomentX,
                        value: lc.dynamicMomentX
                    } as ILoadValue,
                    {
                        uiProperty: UIProperty.LoadCombination_CW_SustainedMomentY,
                        value: lc.dynamicMomentY
                    } as ILoadValue,
                ]
            });
        }
    }
}
