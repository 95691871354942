import { CodeList } from '../code-list';
import { InstallationDirectionEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class InstallationDirections extends CodeList {
    constructor(codeList: InstallationDirectionEntity) {
        super(codeList);
    }

    public static fromService(codeList: InstallationDirectionEntity) {
        return new InstallationDirections(CodeList.baseFromService('InstallationDirectionEntity', codeList));
    }

    public override cloneDeep(): InstallationDirections {
        return super.cloneDeep() as InstallationDirections;
    }
}
