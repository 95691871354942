import { CodeList } from '../code-list';
import { BoltLengthEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class BoltLength extends CodeList {
    public lengthLabel?: string;
    public ls?: number;

    constructor(codeList: BoltLengthEntity) {
        super(codeList);

        if (codeList != null) {
            this.lengthLabel = codeList.lengthLabel;
            this.ls = codeList.ls;
        }
    }

    public static fromService(codeList: BoltLengthEntity) {
        return new BoltLength(CodeList.baseFromService('BoltLengthEntity', codeList, {
            lengthLabel: codeList.lengthLabel,
            ls: codeList.ls
        } as BoltLengthEntity));
    }

    public override cloneDeep(): BoltLength {
        const codeList = super.cloneDeep() as BoltLength;
        codeList.lengthLabel = this.lengthLabel;
        codeList.ls = this.ls;

        return codeList;
    }
}
