
<div class="content-group" *ngIf="dataLoaded">
    <div class="content design-region">
        <p class="title-strike">
            {{translate("Agito.Hilti.CW.ApplicationSettings.General")}}
        </p>

        <div class="content-controls">
            <pe-dropdown class="control"
                [id]="regionDropdown.id"
                [items]="regionDropdown.items"
                [selectedValue]="fixing.region"
                (selectedValueChange)="onRegionDropdownChange($any($event).detail)"
                [title]="regionDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>

            <div class="design-type-status control-row info">
                <p [class.error]="!regionSettingsStatusOk">{{allowStatus}}</p>
            </div>
        </div>
        <div class="content-controls">
            <span class="disclaimer">
                {{translate("Agito.Hilti.CW.ApplicationSettings.Disclaimer")}}
            </span>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="allowQuickStart">
        <p class="title-strike">
            {{translate("Agito.Hilti.CW.ApplicationSettings.Units")}}
        </p>

        <div class="content-controls">
            <pe-dropdown class="control"
                [id]="lengthDropdown.id"
                [items]="lengthDropdown.items"
                [selectedValue]="fixing.length"
                (selectedValueChange)="fixing.length = $any($event).detail"
                [title]="lengthDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown class="control"
                [id]="areaDropdown.id"
                [items]="areaDropdown.items"
                [selectedValue]="fixing.area"
                (selectedValueChange)="fixing.area = $any($event).detail"
                [title]="areaDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown class="control"
                [id]="stressDropdown.id"
                [items]="stressDropdown.items"
                [selectedValue]="fixing.stress"
                (selectedValueChange)="fixing.stress = $any($event).detail"
                [title]="stressDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown class="control"
                [id]="forceDropdown.id"
                [items]="forceDropdown.items"
                [selectedValue]="fixing.force"
                (selectedValueChange)="fixing.force = $any($event).detail"
                [title]="forceDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown class="control"
                [id]="momentDropdown.id"
                [items]="momentDropdown.items"
                [selectedValue]="fixing.moment"
                (selectedValueChange)="fixing.moment = $any($event).detail"
                [title]="momentDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown class="control"
                [id]="temperatureDropdown.id"
                [items]="temperatureDropdown.items"
                [selectedValue]="fixing.temperature"
                (selectedValueChange)="fixing.temperature = $any($event).detail"
                [title]="temperatureDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown class="control"
                [id]="forcePerLengthDropdown.id"
                [items]="forcePerLengthDropdown.items"
                [selectedValue]="fixing.forcePerLength"
                (selectedValueChange)="fixing.forcePerLength = $any($event).detail"
                [title]="forcePerLengthDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown class="control"
                [id]="momentPerLengthDropdown.id"
                [items]="momentPerLengthDropdown.items"
                [selectedValue]="fixing.momentPerLength"
                (selectedValueChange)="fixing.momentPerLength = $any($event).detail"
                [title]="momentPerLengthDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown class="control"
                [id]="densityDropdown.id"
                [items]="densityDropdown.items"
                [selectedValue]="fixing.density"
                (selectedValueChange)="fixing.density = $any($event).detail"
                [title]="densityDropdown.title"
                [disabled]="submitted">
            </pe-dropdown>
            <div class="control-row cw-base-plate-factor">
                <pe-numeric-text-box
                    [id]="basePlateFactor.id"
                    [title]="basePlateFactor.title"
                    [value]="basePlateFactor.value"
                    [placeholder]="basePlateFactor.placeholder"
                    (valueChange)="fixing.basePlateFactor = $any($event).detail"
                    [minValue]="basePlateFactor.minValue"
                    [maxValue]="basePlateFactor.maxValue"
                    [precision]="getBasePlateFactorPrecision">
                </pe-numeric-text-box>
            </div>
            <div class="control-row cw-safety-factor-perm-load" *ngIf="isEuropeanDesignStandard">
                <pe-numeric-text-box
                    [id]="safetyFactorPermLoad.id"
                    [title]="safetyFactorPermLoad.title"
                    [value]="safetyFactorPermLoad.value"
                    [placeholder]="safetyFactorPermLoad.placeholder"
                    (valueChange)="fixing.safetyFactorPermLoad = $any($event).detail"
                    [minValue]="safetyFactorPermLoad.minValue"
                    [maxValue]="safetyFactorPermLoad.maxValue"
                    [precision]="safetyFactorPermLoad.precision">
                </pe-numeric-text-box>
            </div>
            <div class="control-row cw-safety-factor-var-load" *ngIf="isEuropeanDesignStandard">
                <pe-numeric-text-box
                    [id]="safetyFactorVarLoad.id"
                    [title]="safetyFactorVarLoad.title"
                    [value]="safetyFactorVarLoad.value"
                    [placeholder]="safetyFactorVarLoad.placeholder"
                    (valueChange)="fixing.safetyFactorVarLoad = $any($event).detail"
                    [minValue]="safetyFactorVarLoad.minValue"
                    [maxValue]="safetyFactorVarLoad.maxValue"
                    [precision]="safetyFactorVarLoad.precision">
                </pe-numeric-text-box>
            </div>
            <div class="control-row cw-min-anchor-profile-dist" *ngIf="isEuropeanDesignStandard">
                <pe-numeric-text-box
                    [id]="minAnchorProfileDist.id"
                    [title]="minAnchorProfileDist.title"
                    [value]="minAnchorProfileDist.value"
                    [placeholder]="minAnchorProfileDist.placeholder"
                    (valueChange)="fixing.minAnchorProfileDist = $any($event).detail"
                    [unit]="fixing.length"
                    [minValue]="minAnchorProfileDist.minValue"
                    [maxValue]="minAnchorProfileDist.maxValue"
                    [precision]="minAnchorProfileDist.precision">
                </pe-numeric-text-box>
            </div>
            <div class="control-row cw-min-concrete-cover" *ngIf="isEuropeanDesignStandard">
                <pe-numeric-text-box
                    [id]="minConcreteCover.id"
                    [title]="minConcreteCover.title"
                    [value]="minConcreteCover.value"
                    [placeholder]="minConcreteCover.placeholder"
                    (valueChange)="fixing.minConcreteCover = $any($event).detail"
                    [unit]="fixing.length"
                    [minValue]="minConcreteCover.minValue"
                    [maxValue]="minConcreteCover.maxValue"
                    [precision]="minConcreteCover.precision">
                </pe-numeric-text-box>
            </div>
            <pe-dropdown class="control" *ngIf="isEuropeanDesignStandard"
                [id]="concreteSafetyFactorGammaC.id"
                [items]="concreteSafetyFactorGammaC.items"
                [selectedValue]="fixing.concreteSafetyFactorGammaC"
                (selectedValueChange)="fixing.concreteSafetyFactorGammaC = $any($event).detail"
                [title]="concreteSafetyFactorGammaC.title"
                [disabled]="submitted">
            </pe-dropdown>
        </div>
    </div>

    <div class="content calculation" *ngIf="allowQuickStart">
        <p class="title-strike">
            {{translate(designSettingsTitle)}}
        </p>

        <div class="content-controls">
            <div class="design-standard control-row column">
                <div class="content">
                    <pe-dropdown class="control control-design-standard-method"
                        [id]="designStandardDropdown.id"
                        [items]="designStandardDropdown.items"
                        [selectedValue]="fixing.designStandardId"
                        (selectedValueChange)="onDesignStandardDropdownSelectedValueChange($any($event).detail)"
                        [title]="designStandardDropdown.title"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-standard-note" *ngIf="fixing.designStandardId">
                        {{translate(designStandardDescription)}}
                    </div>
                </div>
            </div>

            <div class="design-method-group control-row column">
                <div class="content">
                    <pe-dropdown class="control control-design-standard-method"
                        [id]="designMethodDropdown.id"
                        [items]="designMethodDropdown.items"
                        [selectedValue]="fixing.designMethodGroupId"
                        (selectedValueChange)="onDesignMethodChange($any($event).detail)"
                        [title]="designMethodDropdown.title"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-method-note" *ngIf="fixing.designMethodGroupId">
                        {{translate(designMethodDescription)}}
                    </div>
                </div>
            </div>

            <div class="technical-data control-row column">
                <span class="control-label sub-title">
                    {{translate("Agito.Hilti.CW.ApplicationSettings.TechnicalData")}}
                </span>
            </div>
        </div>
    </div>
</div>
