import { CodeList } from '../code-list';
import { DrillingMethodEntity, GroutTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class GroutTypes extends CodeList {
    constructor(codeList: DrillingMethodEntity) {
        super(codeList);
    }

    public static fromService(codeList: GroutTypeEntity) {
        return new GroutTypes(CodeList.baseFromService('GroutTypeEntity', codeList));
    }

    public override cloneDeep(): GroutTypes {
        return super.cloneDeep() as GroutTypes;
    }
}
