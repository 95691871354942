import { CodeList } from '../code-list';
import { RebarPlatesEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class RebarPlate extends CodeList {
    public length?: number;
    public noOfRebars?: number;

    constructor(codeList: RebarPlatesEntity) {
        super(codeList);

        if (codeList != null) {
            this.length = codeList.length;
            this.noOfRebars = codeList.noOfRebars;
        }
    }

    public static fromService(codeList: RebarPlatesEntity) {
        return new RebarPlate(CodeList.baseFromService('RebarPlatesEntity', codeList, {
            length: codeList.length,
            noOfRebars: codeList.noOfRebars,
        } as RebarPlatesEntity));
    }

    public override cloneDeep(): RebarPlate {
        const codeList = super.cloneDeep() as RebarPlate;
        codeList.length = this.length;
        codeList.noOfRebars = this.noOfRebars;

        return codeList;
    }
}
