import { Injectable } from '@angular/core';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { DisplayDesignType, IDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { IDesignTemplateDocument } from '@profis-engineering/pe-ui-common/services/design-template.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { Design } from '../entities/design';
import { DesignType } from '../entities/enums/design-type';
import { getSpriteAsIconStyle } from '../sprites';
import { CommonCodeListService } from './common-code-list.service';
import { DocumentService } from './document.service';
import { FeatureVisibilityService } from './feature-visibility.service';
import { LocalizationService } from './localization.service';
import { NumberService } from './number.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class DesignService {
    constructor(
        private localization: LocalizationService,
        private commonCodeList: CommonCodeListService,
        private userSettingsService: UserSettingsService,
        private documentService: DocumentService,
        private numberService: NumberService,
        private featureVisibilityService: FeatureVisibilityService
    ) { }

    public designToTemplateDocument(design: Design, templateId?: string, templateName?: string): IDesignTemplateDocument {
        return {
            designTemplateDocumentId: templateId,
            templateName: templateName,
            designTypeId: DesignType.CurtainWall,
            designStandardId: design.projectDesign.options.designStandard,
            regionId: design.projectDesign.options.regionId,
            anchorName: design.productName,
            approvalNumber: design.reportData?.approvals?.map(appr => appr.apprNumber).filter(appr => appr != null).join(',') ?? '',
            projectDesign: JSON.stringify(design.getDocumentDesign()),
        };
    }

    public toDisplayDesign(design: IDesignListItem, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const region = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[]).find(x => x.id == design.metaData?.region);
        const designType = design.metaData?.designType;

        const parentProject = this.documentService.findProjectById(design.projectId);
        const designStandardTextApprovalNumberText = this.createRegionDesignStandardApprovalNumber(undefined, design.metaData.standard, design.metaData.approvalNumber);
        const regionDesignStandardApprovalNumber = this.createRegionDesignStandardApprovalNumber(design.metaData.region, design.metaData.standard, design.metaData.approvalNumber);
        const localCreatedDateTime = this.localization.moment(design.createDate).format('MMM D, YYYY h:mm A');
        return {
            id: design.id,
            name: design.designName,
            created: design.createDate,
            createdDisplay: () => this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? localCreatedDateTime : this.localization.moment(design.createDate).format('LL'),
            rawProject: parentProject,
            projectId: design.projectId,
            productName: design.metaData?.productName ?? '',
            designType,
            approvalNumber: design.metaData?.approvalNumber ?? '',
            region: region as CommonRegion,
            regionDesignStandardApprovalNumber,
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(design.id) : undefined,
            image: getSpriteAsIconStyle(designTypeImage),
            displayDesignType: DisplayDesignType.design,
            regionText: this.createRegionText(region),
            designStandardTextApprovalNumberText,
            isFavorite: design.isFavorite ?? false,
            isSharedByMe: design.isSharedByMe ?? false
        };
    }

    public toDisplayDesignTemplate(template: DesignTemplateEntity, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const region = (this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[]).find(x => x.id == template.RegionId);

        const designStandardTextApprovalNumberText = this.createRegionDesignStandardApprovalNumber(undefined, template.DesignStandardId, template.ApprovalNumber);
        const regionDesignStandardApprovalNumber = this.createRegionDesignStandardApprovalNumber(template.RegionId, template.DesignStandardId, template.ApprovalNumber);

        return {
            id: template.DesignTemplateDocumentId,
            name: template.DesignTemplateName,
            created: template.DateCreate,
            createdDisplay: () => this.localization.moment(template.DateCreate).format('ll'),
            projectName: this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.Facade.DesignType'),
            productName: template.AnchorName,
            designType: template.DesignTypeId,
            approvalNumber: template.ApprovalNumber,
            region: region as CommonRegion,
            regionDesignStandardApprovalNumber,
            image: getSpriteAsIconStyle(designTypeImage),
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(template.DesignTemplateDocumentId) : undefined,
            displayDesignType: DisplayDesignType.template,
            regionText: this.createRegionText(region),
            designStandardTextApprovalNumberText,
            isSharedByMe: template.isSharedByMe ?? false
        } as IDisplayDesign;
    }

    private createRegionText(region?: CommonRegion) {
        if (region?.id == SpecialRegion.Default) {
            const regionCodeList = this.commonCodeList.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
            region = regionCodeList.find(region => region.id == this.userSettingsService.settings.application.general.regionId.value);
        }

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const text = region?.getTranslatedNameText(codeListDeps) ?? '';

        return text == '' ? this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown') : text;
    }

    public createRegionDesignStandardApprovalNumber(regionId: number | undefined, designStandardId: number | undefined, approvalNumber: string | undefined) {
        let region = regionId ? this.userSettingsService.getRegionById(regionId) : undefined;
        const designStandard = this.userSettingsService.getDesignStandardById(designStandardId);

        if (region != null && region.id == SpecialRegion.Default) {
            region = this.userSettingsService.getRegionById(this.userSettingsService.settings.application.general.regionId.value ?? KnownRegion.Unknown);
        }

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const text = [
            region != null ? region.getTranslatedNameText(codeListDeps) : null,
            designStandard != null ? this.localization.getString(designStandard.nameResourceKey ?? '') : null,
            approvalNumber
        ].filter((value) => value != null && value != '').join(', ');

        if (text == null || text == '') {
            return this.localization.getString('Agito.Hilti.CW.ProjectAndDesing.Main.DesignMetaData.Unknown');
        }

        return text;
    }

    public getNewDesignName(): string {
        return `${this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesign.Main.QuickStart.Facade.DesignType')} - ${this.localization.moment(new Date()).format('ll')}`;
    }
}
