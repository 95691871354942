import { LocalizationServiceBase } from '@profis-engineering/pe-ui-common/services/localization.common';
import { CodeList } from '../code-list';
import { GenericRebarsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class GenericRebars extends CodeList {
    public value?: number;

    constructor(codeList: GenericRebarsEntity) {
        super(codeList);

        if (codeList != null) {
            this.value = codeList.value;
        }
    }

    public static fromService(codeList: GenericRebarsEntity) {
        return new GenericRebars(CodeList.baseFromService('GenericRebarsEntity', codeList, {
            value: codeList.value,
        } as GenericRebarsEntity));
    }

    public override cloneDeep(): GenericRebars {
        const codeList = super.cloneDeep() as GenericRebars;
        codeList.value = this.value;

        return codeList;
    }

    public getTrackingValue(localizationService: LocalizationServiceBase): string {
        let trackingValue: string | undefined = undefined;

        if (this.nameResourceKey != null && localizationService.hasTranslation(this.nameResourceKey)) {
            trackingValue = this.displayKey; // use display key when translation is available
        }

        return trackingValue ?? this.value?.toString() ?? '';
    }
}
