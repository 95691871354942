import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';

export enum MainMenuModal {
    brickSize = 0,
    basePlateSize = 1,
    selectMaterial = 3,
    profileSize = 4,
    onSiteTests = 5,
    selectProfile = 6
}

export function buildHtmlTooltip(tooltip?: string, tooltipTitle?: string) {
    let result = '';

    if (tooltipTitle != null && tooltipTitle != '') {
        result += `<p class="title">${tooltipTitle}</p>`;
    }

    if (tooltip != null && tooltip != '') {
        result += `<p class="content">${tooltip}</p>`;
    }

    return result == '' ? undefined : result;
}

export function updateMainMenuControl<TControl extends IControlProps>(menu: IMenu, id: string, control: Partial<TControl>): IMenu {
    return {
        ...menu,
        controls: {
            ...menu.controls,
            [id]: {
                ...menu.controls[id],
                ...control
            }
        }
    };
}

export function registerMainMenuCustomStyles(shadowRootElement: ShadowRoot) {
    const styles: string[] = [
        `
            [data-control-id="cw-concrete-tab-cw-edge-distance-x-negative"],
            [data-control-id="cw-concrete-tab-cw-edge-distance-x-positive"],
            [data-control-id="cw-concrete-tab-cw-edge-distance-y-negative"],
            [data-control-id="cw-concrete-tab-cw-edge-distance-y-positive"],
            [data-control-id="cw-concrete-tab-cw-edge-distance-z-negative"] {
                justify-content: start !important;
            };
        `,
        `
            [data-control-id=cw-concrete-tab-cw-edge-distance-x-negative] [data-control-id=cw-concrete-tab-cw-geometry-edgedistancenegativexinfinity] .control-container,
            [data-control-id=cw-concrete-tab-cw-edge-distance-x-positive] [data-control-id=cw-concrete-tab-cw-geometry-edgedistancexinfinity] .control-container,
            [data-control-id=cw-concrete-tab-cw-edge-distance-y-negative] [data-control-id=cw-concrete-tab-cw-geometry-edgedistancenegativeyinfinity] .control-container,
            [data-control-id=cw-concrete-tab-cw-edge-distance-y-positive] [data-control-id=cw-concrete-tab-cw-geometry-edgedistanceyinfinity] .control-container,
            [data-control-id=cw-concrete-tab-cw-edge-distance-z-negative] [data-control-id=cw-concrete-tab-cw-geometry-edgedistancenegativezinfinity] .control-container {
                width: 100%;
            };
        `
    ];

    const styleSheets: CSSStyleSheet[] = [];

    for (const style of styles) {
        const sheet = new CSSStyleSheet;
        sheet.replaceSync(style);
        styleSheets.push(sheet);
    }

    shadowRootElement.adoptedStyleSheets = [...shadowRootElement.adoptedStyleSheets, ...styleSheets];
}