import { CodeList } from '../code-list';
import { HoleConditionEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class HoleConditions extends CodeList {
    constructor(codeList: HoleConditionEntity) {
        super(codeList);
    }

    public static fromService(codeList: HoleConditionEntity) {
        return new HoleConditions(CodeList.baseFromService('HoleConditionEntity', codeList));
    }

    public override cloneDeep(): HoleConditions {
        return super.cloneDeep() as HoleConditions;
    }
}
