import { CodeList } from '../code-list';
import { HoleTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class HoleTypes extends CodeList {
    constructor(codeList: HoleTypeEntity) {
        super(codeList);
    }

    public static fromService(codeList: HoleTypeEntity) {
        return new HoleTypes(CodeList.baseFromService('HoleTypeEntity', codeList));
    }

    public override cloneDeep(): HoleTypes {
        return super.cloneDeep() as HoleTypes;
    }
}
