import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CollapsingControls } from '../main/main.component';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UserService } from '../../services/user.service';
import { UnitService } from '../../services/unit.service';
import { Point3D } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design.Geometry';
import { PropertyMetaData } from '../../entities/properties';
import { UtilizationsHelper } from '../../helpers/utilizations-helper';

@Component({
  templateUrl: './tolerance-table.component.html',
  styleUrls: ['../utilizations/utilizations-base.component.scss', './tolerance-table.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ToleranceTableComponent implements OnInit {
  @Input()
  public title!: string;

  @Input()
  public tableIdTitle!: string;

  @Input()
  public collapsingControls!: CollapsingControls;

  @Input()
  public tolerance!: Point3D;


  public collapsed = false;

  constructor(
    public localizationService: LocalizationService,
    private userSettings: UserSettingsService,
    private user: UserService,
    private unitService: UnitService,
  ) { }

  ngOnInit(): void {
    this.collapsed = this.userSettings.isSectionCollapsed(this.collapsingControls);
  }

  public get displayTable(): boolean {
    return (this.tolerance != null && !this.user.design?.isPostInstallAnchorProduct() && UtilizationsHelper.getAllUtilizations(this.user.design, this.user.design?.selectedLoadCombinationId).length > 0) ?? false;
  }

  public translate(key: string) {
    return this.localizationService.getString(key);
  }

  public onCollapsedChanged(collapsed: boolean) {
    this.collapsed = collapsed;
    this.userSettings.setSectionCollapsed(this.collapsingControls, collapsed);
  }

  public get positionCaption(): string {
    return `${this.translate('Agito.Hilti.CW.ToleranceTable.Position')} [${this.unitService.formatUnit(this.defaultUnit)}]`;
  }

  public get defaultUnit(): UnitType {
    const unitGroup = UnitGroup.Length;
    return this.unitService.getDefaultUnit(unitGroup, this.user.design);
  }

  public formatPosition(value: number, direction: string) {
    if (!this.isApplicable(direction))
      return '-';

    const defaultUnit = this.defaultUnit;
    const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);
    const precision = this.unitService.getPrecision(defaultUnit);

    const defaultValue = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, this.defaultUnit, true);

    return this.unitService.formatNumber(defaultValue, precision);
  }

  public isApplicable(direction: string): boolean {
    const properties = {
      'x': PropertyMetaData.Loads_CW_Tolerance_Type.id,
      'y': PropertyMetaData.Loads_CW_ToleranceY_Type.id,
      'z': PropertyMetaData.Loads_CW_ToleranceZ_Type.id
    } as { [key: string]: number };

    const property = properties[direction];

    return property != undefined && !this.user.design.properties.get(property).hidden;
  }
}
