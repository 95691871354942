import { Subscription } from 'rxjs/internal/Subscription';
import { InitialDataService } from 'src/cw/services/initial-data.service';
import { LocalizationService } from 'src/cw/services/localization.service';

import {
    Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChange,
    SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { IUserMenuFavorites } from '@profis-engineering/pe-ui-common/entities/favorites';
import {
    IModuleInitialData, IModulePreInitialData
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    AuthenticationServiceBase
} from '@profis-engineering/pe-ui-common/services/authentication.common';
import { BrowserServiceBase } from '@profis-engineering/pe-ui-common/services/browser.common';
import { ChangesServiceBase } from '@profis-engineering/pe-ui-common/services/changes.common';
import {
    CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    CommonTrackingServiceBase
} from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { DateTimeServiceBase } from '@profis-engineering/pe-ui-common/services/date-time.common';
import {
    DesignTemplateServiceBase
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { FavoritesServiceBase } from '@profis-engineering/pe-ui-common/services/favorites.common';
import {
    FeaturesVisibilityInfoServiceBase
} from '@profis-engineering/pe-ui-common/services/features-visibility-info.common';
import { ImportServiceBase } from '@profis-engineering/pe-ui-common/services/import.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { LoggerServiceBase, LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { MathServiceBase } from '@profis-engineering/pe-ui-common/services/math.common';
import { MenuServiceBase } from '@profis-engineering/pe-ui-common/services/menu.common';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';
import { OfflineServiceBase } from '@profis-engineering/pe-ui-common/services/offline.common';
import {
    RegionOrderServiceBase
} from '@profis-engineering/pe-ui-common/services/region-order.common';
import {
    ReportTemplateServiceBase
} from '@profis-engineering/pe-ui-common/services/report-template.common';
import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';
import { TooltipServiceBase } from '@profis-engineering/pe-ui-common/services/tooltip.common';
import {
    TrimbleConnectServiceBase
} from '@profis-engineering/pe-ui-common/services/trimble-connect.common';
import { UnitServiceBase } from '@profis-engineering/pe-ui-common/services/unit.common';
import {
    UserSettingsServiceBase
} from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';

import { environment } from '../../../environments/environmentCW';
import { Design } from '../../entities/design';
import { SharedEnvironmentData } from '../../entities/sharedEnvironment';
import { UserSettings } from '../../entities/user-settings';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BrowserService } from '../../services/browser.service';
import { CalculationService } from '../../services/calculation.service';
import { ChangesService } from '../../services/changes.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { DateTimeService } from '../../services/date-time.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService, DocumentServiceBaseCW } from '../../services/document.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeatureVisibilityService } from '../../services/feature-visibility.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { GuidService } from '../../services/guid.service';
import { ImportService } from '../../services/import.service';
import { LoggerService } from '../../services/logger.service';
import { MathService } from '../../services/math.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { RegionOrderService } from '../../services/region-order.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { ReportService } from '../../services/report.service';
import { RoutingService } from '../../services/routing.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { TooltipService } from '../../services/tooltip.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { LicenseService } from '../../services/license.service';
import { LicenseServiceBase } from '@profis-engineering/pe-ui-common/services/license.common';

@Component({
    template: '',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UiCwInitComponent implements OnInit, OnChanges, OnDestroy {
    //#region Inputs
    @Input()
    public localizationService!: LocalizationServiceBase;

    @Input()
    public numberService!: NumberServiceBase;

    @Input()
    public apiService!: ApiServiceBase;

    @Input()
    public commonCodeListService!: CommonCodeListServiceBase;

    @Input()
    public unitService!: UnitServiceBase;

    @Input()
    public modalService!: ModalServiceBase;

    @Input()
    public userService!: UserServiceBase<Design>;

    @Input()
    public userSettingsService!: UserSettingsServiceBase<UserSettings>;

    @Input()
    public loggerService!: LoggerServiceBase;

    @Input()
    public guidService!: GuidService;

    @Input()
    public changesService!: ChangesServiceBase;

    @Input()
    public offlineService!: OfflineServiceBase;

    @Input()
    public featureVisibilityInfoService!: FeaturesVisibilityInfoServiceBase;

    @Input()
    public routingService!: RoutingServiceBase;

    @Input()
    public authenticationService!: AuthenticationServiceBase;

    @Input()
    public browserService!: BrowserServiceBase;

    @Input()
    public tooltipService!: TooltipServiceBase;

    @Input()
    public mathService!: MathServiceBase;

    @Input()
    public documentService!: DocumentServiceBaseCW;

    @Input()
    public dateTimeService!: DateTimeServiceBase;

    @Input()
    public reportService!: ReportService;

    @Input()
    public importService!: ImportServiceBase;

    @Input()
    public featureVisibilityService!: FeatureVisibilityService;

    @Input()
    public commonTrackingService!: CommonTrackingServiceBase;

    @Input()
    public sharedEnvironmentData!: SharedEnvironmentData;

    @Input()
    public menuService!: MenuServiceBase;

    @Input()
    public favoritesService!: FavoritesServiceBase;

    @Input()
    public regionOrderService!: RegionOrderServiceBase;

    @Input()
    public designTemplateService!: DesignTemplateServiceBase;

    @Input()
    public reportTemplateService!: ReportTemplateServiceBase;
    //#endregion

    @Input()
    public trimbleConnectService!: TrimbleConnectServiceBase;

    @Input()
    public licenseService!: LicenseServiceBase;

    @Output()
    public preInit = new EventEmitter<IModulePreInitialData>();

    @Output()
    public init = new EventEmitter<IModuleInitialData>();

    @Output()
    public update = new EventEmitter<IModuleInitialData>();

    private isInitLoaded = false;

    private featureVisibilityServiceSubscription?: Subscription;
    private favoritesServiceDataChangeSubscription?: Subscription;
    private localizationServiceSubscription?: Subscription;

    private initialDataLoadedCalled = false;
    private initFailed = false;

    constructor(
        private initialDataService: InitialDataService,
        private localizationServiceInternal: LocalizationService,
        private numberServiceInternal: NumberService,
        private commonCodeListServiceInternal: CommonCodeListService,
        private unitServiceInternal: UnitService,
        private modalServiceInternal: ModalService,
        private userServiceInternal: UserService,
        private userSettingsServiceInternal: UserSettingsService,
        private loggerServiceInternal: LoggerService,
        private changesServiceInternal: ChangesService,
        private offlineServiceInternal: OfflineService,
        private featureVisibilityInfoServiceInternal: FeaturesVisibilityInfoService,
        private routingServiceInternal: RoutingService,
        private authenticationServiceInternal: AuthenticationService,
        private browserServiceInternal: BrowserService,
        private apiServiceInternal: ApiService,
        private tooltipServiceInternal: TooltipService,
        private mathServiceInternal: MathService,
        private documentServiceInternal: DocumentService,
        private reportServiceInternal: ReportService,
        private importServiceInternal: ImportService,
        private dateTimeInternal: DateTimeService,
        private calculationService: CalculationService,
        private featureVisibilityServiceInternal: FeatureVisibilityService,
        private commonTrackingServiceInternal: CommonTrackingService,
        private sharedEnvironmentServiceInternal: SharedEnvironmentService,
        private menuServiceInternal: MenuService,
        private favoritesServiceInternal: FavoritesService,
        private regionOrderServiceInternal: RegionOrderService,
        private designTemplateServiceInternal: DesignTemplateService,
        private reportTemplateServiceInternal: ReportTemplateService,
        private trimbleConnectSvc: TrimbleConnectService,
        private licenseServiceInternal: LicenseService,
        private ngZone: NgZone
    ) { }

    /*
    This callback can be called on two different occasions:
    1. When the initial data is loaded - called from pe-ui
    2. When the feature visibility service is initialized - called from this component
    */
    initialDataLoadedEvent = async () => {
        this.initialDataLoadedCalled = true;

        if (!environment.cwEnabled) {
            return;
        }

        if (!this.localizationServiceInternal.loading && !this.localizationServiceInternal.initialized) {
            await this.safeInvokeAsync(async () => {
                await this.localizationServiceInternal.initTranslations();
                await this.initializeModule();
            });
        }
    };

    ngOnInit(): void {
        try {
            const preInitData: IModulePreInitialData = {} as IModulePreInitialData;
            preInitData.initialDataLoadedEvent = this.initialDataLoadedEvent;
            this.preInit.emit(preInitData);

            this.localizationServiceSubscription = this.localizationServiceInternal.localizationChange.subscribe({
                next: () => {
                    if (this.initFailed) {
                        return;
                    }

                    this.safeInvoke(() => {
                        this.update.emit(this.initialDataService.updateModuleData());
                    });
                },
                error: (err) => this.handleError(err)
            });
        }
        catch (error) {
            this.handleError(error);
        }
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (this.initFailed) {
            return;
        }

        try {
            this.initService(changes['commonCodeListService'], () => this.commonCodeListServiceInternal.setBaseService(this.commonCodeListService));
            this.initService(changes['unitService'], () => this.unitServiceInternal.setBaseService(this.unitService));
            this.initService(changes['modalService'], () => this.modalServiceInternal.setBaseService(this.modalService));
            this.initService(changes['numberService'], () => this.numberServiceInternal.setBaseService(this.numberService));
            this.initService(changes['userService'], () => this.userServiceInternal.setBaseService(this.userService));
            this.initService(changes['loggerService'], () => this.loggerServiceInternal.setBaseService(this.loggerService));
            this.initService(changes['changesService'], () => this.changesServiceInternal.setBaseService(this.changesService));
            this.initService(changes['offlineService'], () => this.offlineServiceInternal.setBaseService(this.offlineService));
            this.initService(changes['featureVisibilityInfoService'], () => this.featureVisibilityInfoServiceInternal.setBaseService(this.featureVisibilityInfoService));
            this.initService(changes['routingService'], () => this.routingServiceInternal.setBaseService(this.routingService));
            this.initService(changes['authenticationService'], () => this.authenticationServiceInternal.setBaseService(this.authenticationService));
            this.initService(changes['userSettingsService'], () => this.userSettingsServiceInternal.setBaseService(this.userSettingsService));
            this.initService(changes['browserService'], () => this.browserServiceInternal.setBaseService(this.browserService));
            this.initService(changes['tooltipService'], () => this.tooltipServiceInternal.setBaseService(this.tooltipService));
            this.initService(changes['mathService'], () => this.mathServiceInternal.setBaseService(this.mathService));
            this.initService(changes['documentService'], () => this.documentServiceInternal.setBaseService(this.documentService));
            this.initService(changes['importService'], () => this.importServiceInternal.setBaseService(this.importService));
            this.initService(changes['dateTimeService'], () => this.dateTimeInternal.setBaseService(this.dateTimeService));
            this.initService(changes['apiService'], () => this.apiServiceInternal.setBaseService(this.apiService));
            this.initService(changes['reportService'], () => this.reportServiceInternal.setBaseService(this.reportService));
            this.initService(changes['commonTrackingService'], () => this.commonTrackingServiceInternal.setBaseService(this.commonTrackingService));
            this.initService(changes['sharedEnvironmentData'], () => this.sharedEnvironmentServiceInternal.initialize(this.sharedEnvironmentData));
            this.initService(changes['menuService'], () => this.menuServiceInternal.setBaseService(this.menuService));
            this.initService(changes['regionOrderService'], () => this.regionOrderServiceInternal.setBaseService(this.regionOrderService));
            this.initService(changes['designTemplateService'], () => this.designTemplateServiceInternal.setBaseService(this.designTemplateService));
            this.initService(changes['reportTemplateService'], () => this.reportTemplateServiceInternal.setBaseService(this.reportTemplateService));
            this.initService(changes['localizationService'],  () => { this.localizationServiceInternal.setBaseService(this.localizationService); });
            this.initService(changes['favoritesService'], () => { this.initFavoritesService(); });
            this.initService(changes['featureVisibilityService'], () => { this.initFeatureVisibilityService(); });
            this.initService(changes['trimbleConnectService'], () => this.trimbleConnectSvc.setBaseService(this.trimbleConnectService));
            this.initService(changes['licenseService'], () => this.licenseServiceInternal.setBaseService(this.licenseService));
        }
        catch (error) {
            this.handleError(error);
        }
    }

    public ngOnDestroy(): void {
        if (this.featureVisibilityServiceSubscription != null) {
            this.featureVisibilityServiceSubscription.unsubscribe();
            this.featureVisibilityServiceSubscription = undefined;
        }

        if (this.favoritesServiceDataChangeSubscription != null) {
            this.favoritesServiceDataChangeSubscription.unsubscribe();
            this.favoritesServiceDataChangeSubscription = undefined;
        }

        if (this.localizationServiceSubscription != null) {
            this.localizationServiceSubscription.unsubscribe();
            this.localizationServiceSubscription = undefined;
        }
    }


    private initService(change: SimpleChange, initFn: () => void) {
        if (change != null) {
            initFn();
        }
    }

    private initFavoritesService() {
        this.favoritesServiceInternal.setBaseService(this.favoritesService);

        this.favoritesServiceDataChangeSubscription = this.favoritesServiceInternal.dataChange.subscribe({
            next: (data: object) => {
                if (this.initFailed) {
                    return;
                }

                this.safeInvoke(() => {
                    const cwData = data as IUserMenuFavorites;
                    this.favoritesServiceInternal.initFavorites(cwData);
                });
            },
            error: (err) => this.handleError(err)
        });
    }

    private initFeatureVisibilityService() {
        this.featureVisibilityServiceInternal.setBaseService(this.featureVisibilityService);
        this.featureVisibilityServiceSubscription = this.featureVisibilityServiceInternal.init.subscribe({
            next: (initialized) => {
                if (!initialized || this.initFailed) {
                    return;
                }

                (async () => {
                    await this.safeInvokeAsync(async () => {
                        environment.cwEnabled = environment.cwEnabled || this.featureVisibilityServiceInternal.isFeatureEnabled('CW_Module');

                        let preInitialData: IModulePreInitialData = {} as IModulePreInitialData;
                        if (environment.cwEnabled) {
                            preInitialData = this.initialDataService.getPreInitialData();
                        }

                        preInitialData.initialDataLoadedEvent = this.initialDataLoadedEvent;
                        if (this.initialDataLoadedCalled) {
                            await this.initialDataLoadedEvent();
                        }

                        this.preInit.emit(preInitialData);
                    });
                })();
            },
            error: (error) => this.handleError(error)
        });
    }

    private async initializeModule() {
        if (environment.cwEnabled && !this.isInitLoaded) {
            this.ngZone.run(async () => {
                await this.calculationService.loadInitialData();
                const initialData = this.initialDataService.getInitialData();
                this.init.emit(initialData);
                this.isInitLoaded = true;
            });
        }
    }

    private handleError(error: any) {
        this.initFailed = true;

        if (error instanceof Error) {
            this.loggerService.log(error.message, LogType.error);
        }
    }

    private safeInvoke(fn: () => void) {
        try {
            fn();
        }
        catch (error) {
            this.handleError(error);
        }
    }

    private async safeInvokeAsync(fn: () => Promise<void>) {
        try {
            await fn();
        }
        catch (error) {
            this.handleError(error);
        }
    }
}
