import { NgZone, Injectable } from '@angular/core';
import { IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import { IImportDesignProvider, IProjectDesignEntity } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { Constants } from '../entities/constants';
import { Design } from '../entities/design';
import { DesignEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { DesignTypes } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { CalculationService } from './calculation.service';
import { ProjectOpenType } from '../entities/tracking-entities';

@Injectable({
    providedIn: 'root'
})
export class ImportDesignProviderService implements IImportDesignProvider {
    public path = `/${Constants.DesignPath}/`;

    constructor(
        private calculationService: CalculationService,
        private ngZone: NgZone
    ) { }

    public static GetAllowedImportExtensions(): string[] {
        return ['.pe', '.pac'];
    }

    public getValidFileExtensions(): string[] {
        return ImportDesignProviderService.GetAllowedImportExtensions();
    }

    public checkFile(fileContent: string, fileName?: string): boolean {
        if (this.isOldPacFile(fileName)) {
            return true;
        }

        try {
            const designEntity = this.createDesignEntityFromFile(fileContent);
            return designEntity.designTypeId == DesignTypes.CurtainWall;
        }
        catch (err) {
            return false;
        }
    }

    public async createProjectDesignEntity(projectDesign: Blob | File, projectName: string, fileNameProposal: string, _designName: string, projectDesignContent?: string | undefined): Promise<IProjectDesignEntity> {
        if (projectDesignContent == null)
            throw new Error('Argument \'projectDesignContent\' can not be null.');

        let designEntity: DesignEntity;

        const fileName = projectDesign instanceof File ? projectDesign.name : undefined;
        if (this.isOldPacFile(fileName)) {
            try {
                designEntity = await this.ngZone.run(async () => await this.calculationService.convertDesign(projectDesignContent));
            }
            catch (err: any) {
                if (err?.cause == 'import_error') {
                    // In case cause of error is import_error, it means that the validation error occured and we throw 'closed' error so import.service will stop handling the exceptions
                    throw 'closed';
                }

                throw err;
            }
        }
        else {
            designEntity = this.createDesignEntityFromFile(projectDesignContent);
        }

        designEntity.designName = fileNameProposal;
        designEntity.projectName = projectName;
        return designEntity;
    }

    public async updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: IProjectDesignEntity): Promise<Design> {
        return this.ngZone.run(() => this.calculationService.updateDesignFromExternalFile(oldDesign, projectDesign as DesignEntity));
    }

    public async createDesign(importData: IImportData, projectId: string): Promise<IImportData> {
        const calculationResult = await this.ngZone.run(async () => await this.calculationService.createAndOpenFromProjectDesign(importData.projectDesign as DesignEntity, projectId));
        calculationResult.design.projectOpenType = ProjectOpenType.ImportExisting;

        importData.design = calculationResult.design;
        return importData;
    }

    public setDesignName(projectDesignEntity: IProjectDesignEntity, name: string): void {
        (projectDesignEntity as DesignEntity).designName = name;
    }

    private isOldPacFile(fileName?: string) {
        return fileName?.toLowerCase().endsWith('.pac');
    }

    private createDesignEntityFromFile(fileContent: string): DesignEntity {
        const designEntity: DesignEntity = JSON.parse(fileContent);
        return designEntity;
    }

}
