import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { IDesignListItem, DocumentServiceBase, DocumentAccessMode } from '@profis-engineering/pe-ui-common/services/document.common';

export abstract class DocumentServiceBaseCW extends DocumentServiceBase {
    public abstract createUniqueName(oldName: string, usedNames: string[]): string;
}

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends DocumentServiceBaseCW {
    private baseService!: DocumentServiceBaseCW;

    public setBaseService(baseService: DocumentServiceBaseCW) {
        this.baseService = baseService;

        this.findDesignById = baseService.findDesignById.bind(baseService);
        this.findProjectById = baseService.findProjectById.bind(baseService);
        this.openDesignExclusive = baseService.openDesignExclusive.bind(baseService);
        this.addDesignCommon = baseService.addDesignCommon.bind(baseService);
        this.updateDesignWithNewContentCommon = baseService.updateDesignWithNewContentCommon.bind(baseService);
        this.updateDesignThumbnailImage = baseService.updateDesignThumbnailImage.bind(baseService);
        this.getSessionKeyForDesign = baseService.getSessionKeyForDesign.bind(baseService);
        this.publish = baseService.publish.bind(baseService);
        this.createUniqueName = baseService.createUniqueName.bind(baseService);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    findDesignById(_designId: string): IDesignListItem {
        throw new Error('Method not implemented.');
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    public createUniqueName(_oldName: string, _usedNames: string[]): string {
        throw new Error('Method not implemented.');
    }

    findProjectById(_projectId: string): Project {
        throw new Error('Method not implemented.');
    }

    addDesignCommon(_projectId: string, _design: Design<unknown>, _canGenerateUniqueName: boolean, _ignoreConflict: boolean): Promise<IDesignListItem> {
        throw new Error('Method not implemented.');
    }

    updateDesignWithNewContentCommon(_design: Design<unknown>, _displayDesign: unknown, _unlock: boolean, _exclusiveLock: boolean, _documentAccessMode?: DocumentAccessMode): Promise<void> {
        throw new Error('Method not implemented.');
    }

    updateDesignThumbnailImage(_designId: string, _thumbnailContent: string, _respond: boolean): Promise<void> {
        throw new Error('Method not implemented.');
    }

    publish(_id: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    findProjectByDesignId(designId: string): Project {
        throw new Error('Method not implemented.');
    }

    uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void> {
        throw new Error('Method not implemented.');
    }

    get projects(): Record<string, Project> {
        return this.baseService.projects;
    }

    get draftsProject() {
        return this.baseService.draftsProject;
    }

    copyDesign(documentId: string, documentName: string, projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    moveDesign(documentId: string, projectId: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        throw new Error('Method not implemented.');
    }

    public getSessionKeyForDesign(_designId: string): string {
        throw new Error('Method not implemented.');
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
