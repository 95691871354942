import {
    Component, ElementRef, Input, OnInit, ViewEncapsulation
} from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { BrowserService } from '../../services/browser.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { Design } from '../../entities/design';
import { includeSprites } from '../../sprites';
import { CalculationStatus } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { CalculationService } from '../../services/calculation.service';
import { environment } from '../../../environments/environmentCW';
import { DesignHelper } from '../../helpers/design-helper';

enum Loading {
    pdf,
    localFile,
    trimbleConnect,
    cadFile,
    specificationText,
    IDEAFileDownload,
    integrationServicesExport,
}

export interface DesignSectionExportComponentInput {
    onSave?: () => void;
}

@Component({
    templateUrl: './design-section-export.component.html',
    styleUrls: ['./design-section-export.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DesignSectionExportComponent implements OnInit {

    public design?: Design;
    public loading!: Loading;
    public closing!: boolean;

    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        public offlineService: OfflineService,
        public modalService: ModalService,
        private userService: UserService,
        private browserService: BrowserService,
        private elementRef: ElementRef<HTMLElement>,
        private calculationService: CalculationService
    ) {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-profis-file',
            'sprite-specification-text',
            'sprite-trimble-file',
            'sprite-arrow-right');
    }

    public get title() {
        return this.translate('Agito.Hilti.CW.DesignSectionExport.Title');
    }

    public get isCalculationLogAvailable() {
        if (environment.production)
            return false;

        return environment.debugMode;
    }

    public get isExternalDesignAvailable() {
        return this.isCalculationLogAvailable && this.design?.isPostInstalledAnchorSelected;
    }

    public get canDownloadCalculationLog() {
        return this.design?.designData.reportData?.calculationStatus === CalculationStatus.OK ||
            this.design?.designData.reportData?.calculationStatus === CalculationStatus.UnhandledKernelError;
    }

    ngOnInit(): void {
        // don't close the modal if an action is pending
        this.design = this.userService.design;

        this.modalInstance?.setOnClosing(() => {
            if (!(this.loading == null || this.closing)) {
                return false;
            }

            return true;
        });
    }

    public async exportDesign() {
        if (this.design?.designData.projectDesign != null) {
            const simplifiedDesign = DesignHelper.simplifyDesign(this.design.designData.projectDesign);

            const blob = this.browserService.base64toBlob(this.browserService.toBase64(simplifiedDesign), 'application/json');
            const fileName = `${this.design.designData.projectDesign.designName}.pe`;

            await this.browserService.downloadBlob(blob, fileName, false, false, '');
            this.close();
        }
    }

    public async exportCalculationLog() {
        if (this.canDownloadCalculationLog && this.design?.designData.projectDesign != null && this.design?.designData.reportData != null) {
            // Run calculation
            this.calculationService.downloadCalculationLog(this.design);
            this.close();
        }
    }

    public async exportExternalDesign() {
        if (this.design == null)
            return;

        this.calculationService.downloadExternalDesign(this.design);
        this.close();
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
