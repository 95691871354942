
import { CodeList } from '../code-list';
import { SeismicDisplacementTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class SeismicDisplacementTypes extends CodeList {
    constructor(codeList: SeismicDisplacementTypeEntity) {
        super(codeList);
    }

    public static fromService(codeList: SeismicDisplacementTypeEntity) {
        return new SeismicDisplacementTypes(CodeList.baseFromService('SeismicDisplacementTypeEntity', codeList));
    }

    public override cloneDeep(): SeismicDisplacementTypes {
        return super.cloneDeep() as SeismicDisplacementTypes;
    }
}
