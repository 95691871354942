import { ProductFilterEntity as ProductFilter } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { AnchorFilter as ProductFilterEntity } from '../entities/code-lists/anchor-filter';
import { AnchorFilterGroup as ProductFilterGroupEntity } from '../entities/code-lists/anchor-filter-groups';

export function filterProductFilters(productFilters: ProductFilterEntity[], savedProductFilters: ProductFilter) {
    return productFilters.filter(productFilter => !savedProductFilters.checkboxFiltersRemoved.some(removed => removed.id == productFilter.id));
}

export function filterProductFilterGroups(productFilterGroups: ProductFilterGroupEntity[], productFilters: ProductFilterEntity[]) {
    return productFilterGroups.filter((productFilterGroup) => productFilters.some((productFilter) => productFilter.anchorFilterGroupId == productFilterGroup.id));
}