import { CodeList } from '../code-list';
import { EntityBase } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class StandOffDesignMethods extends CodeList {
    constructor(codeList: EntityBase) {
        super(codeList);
    }

    public static fromService(codeList: EntityBase) {
        return new StandOffDesignMethods(CodeList.baseFromService('StandOffDesignMethodsEntity', codeList));
    }

    public override cloneDeep(): StandOffDesignMethods {
        return super.cloneDeep() as StandOffDesignMethods;
    }
}
