import { MeshCache } from '@profis-engineering/gl-model/cache/mesh-cache';
import { MaterialCacheCW } from './material-cache';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { Mesh } from '@babylonjs/core/Meshes/mesh';

export class MeshCacheCW extends MeshCache<MaterialCacheCW>{

    public get cylinderMesh() {
        return this.create('CW.CylinderMesh', (name) => {
            return MeshBuilder.CreateCylinder(name, { height: CommonCache.boxSize, diameter: CommonCache.cylinderSize, sideOrientation: Mesh.DOUBLESIDE, updatable: true });
        });
    }
}