import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { Design } from '../../../entities/design';
import { IModelCW } from '../../../gl-model/base-component';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BoltEntity } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design.Product';
import { BaseUpdateCW } from '../../../gl-model/base-update';
import { IBolt } from '../components/bolt';
import { NumberedPoint2D } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design.Geometry';

export class BoltUpdater extends BaseUpdateCW {
    @propertyValueChanged()
    private BoltGeometry_Update(model: IModelCW, design: Design) {
        for (let i = 0; i < design.projectDesign.anchoringSystems.length; i++) {
            const acs = design.projectDesign.anchoringSystems[i];
            for (let j = 0; j < acs.basePlateSystems.length; j++) {
                this.updateBoltGeometry(model.anchoringSystems[i].basePlateSystems[j].bolt, design.projectDesign.anchoringSystems[i].basePlateSystems[j].basePlate.bolt);
            }
        }
    }

    private updateBoltGeometry(modelBolt: IBolt, designBolt: BoltEntity) {
        modelBolt.sideHeadLength = designBolt.geometry?.bs ?? 0;
        modelBolt.frontHeadLength = designBolt.geometry?.bl ?? 0;
        modelBolt.headHeight = designBolt.geometry?.hs ?? 0;
        modelBolt.diameter = designBolt.geometry?.d ?? 0;
        modelBolt.length = designBolt.geometry?.ls ?? 0;
        modelBolt.holeDiameter = designBolt.geometry?.df ?? 0;
        modelBolt.positions = designBolt.positions?.map((p: NumberedPoint2D) => { return { id: p.id, position: new Vector3(p.x, p.y, 0) }; }) ?? [];
    }
}