import { CodeList } from '../code-list';
import { BondConditionEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class BondConditions extends CodeList {
    constructor(codeList: BondConditionEntity) {
        super(codeList);
    }

    public static fromService(codeList: BondConditionEntity) {
        return new BondConditions(CodeList.baseFromService('BondConditionEntity', codeList));
    }

    public override cloneDeep(): BondConditions {
        return super.cloneDeep() as BondConditions;
    }
}
