<pe-modal-header
    id="select-anchor-channel-length-dialog"
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.CW.SelectAnchorChannelLength.Title')"
    [closeButtonDisabled]="false"
    [closeButtonId]="'select-anchor-channel-length-close-button'">
</pe-modal-header>

<form class="form" #ngForm="ngForm" (ngSubmit)="save()" [ngClass]="{ 'submitted': 'submitted' }" novalidate>
    <pe-modal-body id="select-anchor-channel-length-dialog" *ngIf="dataLoaded">
        <div class="component-section">
            <div class="control-row" id="select-anchor-channel-length-channel-length">
                <pe-dropdown
                    [id]="channelLength.id"
                    [title]="channelLength.title"
                    [items]="channelLength.items"
                    [selectedValue]="selectedChannelLengthId"
                    (selectedValueChange)="selectedChannelLengthId = $any($event).detail">
                </pe-dropdown>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'select-anchor-channel-length-cancel-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [l10n]="'Agito.Hilti.CW.SelectAnchorChannelLength.Cancel'"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            [id]="'select-anchor-channel-length-save-button'"
            [look]="'Cta'"
            class="modal-button"
            type="submit"
            [disabled]="submitted">
            <pe-loading-button
                [loading]="false"
                [l10n]="'Agito.Hilti.CW.SelectAnchorChannelLength.OK'">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
