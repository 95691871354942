import { CodeList } from '../code-list';
import {LongitudinalShearCalculationEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class LongitudinalShearCalculation extends CodeList {
    constructor(codeList: LongitudinalShearCalculationEntity) {
        super(codeList);
    }

    public static fromService(codeList: LongitudinalShearCalculationEntity) {
        return new LongitudinalShearCalculation(CodeList.baseFromService('LongitudinalShearCalculationEntity', codeList));
    }

    public override cloneDeep(): LongitudinalShearCalculation {
        return super.cloneDeep() as LongitudinalShearCalculation;
    }
}
