import { CodeList, ICodeListConstructor } from '../code-list';
import { ConcreteSafetyFactorGammaCEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class ConcreteSafetyFactorGammaC extends CodeList {

    constructor(codeList: ICodeListConstructor) {
        super(codeList);
    }

    public static fromService(codeList: ConcreteSafetyFactorGammaCEntity) {
        return new ConcreteSafetyFactorGammaC(CodeList.baseFromService('ConcreteSafetyFactorGammaCEntity', codeList));
    }

    public override cloneDeep(): ConcreteSafetyFactorGammaC {
        return super.cloneDeep() as ConcreteSafetyFactorGammaC;
    }
}
