import { CodeList } from '../code-list';
import { ShearConditionsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class ShearConditions extends CodeList {

    constructor(codeList: ShearConditionsEntity) {
        super(codeList);
    }

    public static fromService(codeList: ShearConditionsEntity) {
        return new ShearConditions(CodeList.baseFromService('ShearConditionsEntity', codeList));
    }

    public override cloneDeep(): ShearConditions {
        const codeList = super.cloneDeep() as ShearConditions;
        return codeList;
    }

}
