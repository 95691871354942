<pe-main-header class="main-header"
    [designName]="title"
    [designRegion]="design!.region"
    [beforeLogout]="beforeLogout"
    [openDesignSettings]="openDesignSettings"
    [openSaveAsTemplate]="openSaveAsTemplate"
    [useNewHomePage]="isNewHomePage"
    [openSupportOverride]="openSupportOverride">
</pe-main-header>

<div class="main-content">
    <!-- LEFT -->

    <div class="main-content-left" [class.width-extended]="hasExtendedWidth" [class.main-content-left-hidden]="hideLeftMenu">
        <div id="main-content-left-container" class="main-content-left-container">
            <div class="black-bar"></div>

            <pe-main-menu
                #mainMenuRef
                class="menu-container"
                [disableDragToMoveText]="true"
                [tabsHidden]="hideLeftMenu"
                [moduleLocalizationService]="localizationService"
                *ngIf="selectedMenu != null">
            </pe-main-menu>
        </div>
    </div>

    <div class="main-content-center-right" #mainContentCenterRightRef>
        <div class="main-content-center-right-top" #mainContentCenterRightTopRef>
            <!-- CENTER -->
            <div class="main-content-center">
                <div class="main-content-position">
                    <div class="model-view-options click-through">
                        <div class="options-left">
                            <div class="button-group undo-redo-section click-reset" role="group">
                                <div class="buttons join">
                                    <div class="tooltip-wrapper" [ngbTooltip]="undoTooltip" placement="auto top">
                                        <pe-button id="main-undo-button"
                                            [look]="'DefaultIcon'"
                                            class="button button-icon button-default" (buttonClick)="undo()"
                                            [disabled]="!canUndo()">
                                            <span class="pe-ui-cw-sprite pe-ui-cw-sprite-undo"></span>
                                        </pe-button>
                                    </div>
                                    <span>&nbsp;</span>
                                    <div class="tooltip-wrapper" [ngbTooltip]="redoTooltip" placement="auto top">
                                        <pe-button id="main-redo-button"
                                            [look]="'DefaultIcon'"
                                            class="button button-icon button-default" (buttonClick)="redo()"
                                            [disabled]="!canRedo()">
                                            <span class="pe-ui-cw-sprite pe-ui-cw-sprite-redo"></span>
                                        </pe-button>
                                    </div>
                                </div>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-numeric-text-box
                                    [value]="modelViewZoom"
                                    (valueChange)="zoomPercentageChange($any($event).detail)"
                                    [unit]="this.zoomUnit"
                                    [minValue]="0"
                                    [maxValue]="100"
                                    [updateOnBlur]="true"
                                    [required]="true"
                                    [width]="80"
                                    [height]="32">
                                </pe-numeric-text-box>
                            </div>

                            <div class="button-group click-reset" role="group" *ngIf="view == ViewType.View3d">
                                <pe-button id="main-zoom-to-fit-button"
                                    class="button-default button button-icon"
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.ZoomToFit')"
                                    (buttonClick)="zoomToFit()">
                                    <span class="pe-ui-cw-sprite pe-ui-cw-sprite-search"></span>
                                </pe-button>
                            </div>

                            <div class="button-group click-reset" role="group">
                                <pe-button id="main-reset-camera-button"
                                    class="button-default button button-icon"
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.ResetCamera')"
                                    (buttonClick)="resetCamera()">
                                    <span class="pe-ui-cw-sprite pe-ui-cw-sprite-center"></span>
                                </pe-button>
                            </div>

                            <div class="button-group click-reset" role="group" ngbDropdown [autoClose]="false" closeOutsideNgbDropdown>
                                <pe-button id="main-model-display-options-button" ngbDropdownToggle
                                    class="button-default button button-icon"
                                    [look]="'DefaultIcon'"
                                    [ngbTooltip]="displayOptionsTooltip">
                                    <span class="pe-ui-cw-sprite pe-ui-cw-sprite-view"></span>
                                </pe-button>

                                <div id="display-options-dropdown" ngbDropdownMenu>
                                    <cw-display-options [design]="design" (updateModel)="updateGlModel($event)">
                                    </cw-display-options>
                                </div>
                            </div>

                            <div class="flex-line-break"></div>

                            <div class="bottom">
                                <div class="options-left-bottom">
                                    <div class="flex-line-break"></div>
                                    <div class="loading-options">
                                        <div class="loading-container" [class.show-loading]="isLoading">
                                            <pe-loading [color]="'#524f53'"></pe-loading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="model-view-container">
                        <pe-button id="hide-left-menu" class="button button-icon button-default hide-menu-button hide-menu-button-left"
                            [look]="'DefaultIcon'"
                            (buttonClick)="toggleLeftMenu()"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.CollapseMenu')">
                            <span class="pe-ui-cw-sprite"
                                [class.pe-ui-cw-sprite-arrow-left-medium]="!hideLeftMenu"
                                [class.pe-ui-cw-sprite-arrow-right-medium]="hideLeftMenu"></span>
                        </pe-button>

                        <cw-gl-model #glModelRef *ngIf="glModel != null" [context3dKey]="Context3dKeyMain"
                            [continuousRender]="glModel.continuousRender" [model]="glModel.model"
                            (fontsLoaded)="glModel.onFontsLoaded()" (zoom)="glModel.onZoom($any($event).detail)"
                            (selectTab)="glModel.onSelectTab($any($event).detail)"
                            (positionsChanged)="glModel.onPositionsChanged($any($event).detail)"
                            (draggingSelectionChanged)="glModel.onDraggingSelectionChanged($any($event).detail)">
                        </cw-gl-model>
                    </div>

                    <pe-loads-legend class="model-view-legend"
                        [loadsLegendType]="loadsLegendType"
                        [hasLoadsInWorstPosition]="false">
                    </pe-loads-legend>

                    <pe-button id="hide-right-menu" class="button button-icon button-default hide-menu-button hide-menu-button-right"
                        [look]="'DefaultIcon'"
                        (buttonClick)="toggleRightMenu()"
                        [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.CollapseMenu')">
                        <span class="pe-ui-cw-sprite"
                            [class.pe-ui-cw-sprite-arrow-right-medium]="!hideRightMenu"
                            [class.pe-ui-cw-sprite-arrow-left-medium]="hideRightMenu"></span>
                    </pe-button>
                </div>

                <div id="floating-dropdowns" class="click-through">
                    <cw-product-drop-down
                        class="info-section-container click-reset"
                        [collapsed]="floatingInfo != FloatingInfo.ProductDropDown"
                        [parentElement]="mainContentCenterRightTopRef"
                        (collapsedChange)="floatingInfoChange(FloatingInfo.ProductDropDown, $event)">
                    </cw-product-drop-down>
                </div>
            </div>
        </div>

        <!-- LOADS component -->
        <cw-loads
            [resize3d]="resize3d"
            [parentElement]="mainContentCenterRightRef"
            [selectedLoadId]="design?.projectDesign?.loads?.selectedLoadCombinationId"
            [decisiveLoadId]="design?.projectDesign?.loads?.decisiveLoadCombinationId"
            class="loads-container-wrapper">
        </cw-loads>
    </div>

    <div class="main-content-right-sidebar" *ngIf="!hideRightMenu">
        <!-- RIGHT -->
        <div class="main-content-right" *ngIf="rightSideLoaded">
            <div class="main-content-right-container">
                <div class="main-content-right-scroll">
                    <div class="design-section-wrapper">
                        <cw-app-design-section #designSectionRef>
                        </cw-app-design-section>
                    </div>

                    <div class="main-content-right-util" sortablejs [sortablejsOptions]="sortableMenu3DRightOptions"
                    (sortablejsInit)="sortMenu3DRight($any($event))">

                        <div [attr.data-id]="CollapsingControls.ToleranceTable">
                            <cw-tolerance-table id="tolerance-table"
                                [title]="translate('Agito.Hilti.CW.ToleranceTable.Title')"
                                [collapsingControls]="CollapsingControls.ToleranceTable"
                                [tolerance]="mostUnfavorableTolerance">
                            </cw-tolerance-table>
                        </div>

                        <div [attr.data-id]="CollapsingControls.BoltLoads">
                            <cw-load-forces id="bolt-loads"
                                [title]="translate('Agito.Hilti.CW.LoadForces.Title.Bolt')"
                                [tableIdTitle]="translate('Agito.Hilti.CW.LoadForces.Table.Id.Bolt')"
                                [collapsingControls]="CollapsingControls.BoltLoads"
                                [forceList]="boltForceList">
                            </cw-load-forces>
                        </div>

                        <div [attr.data-id]="CollapsingControls.AnchorLoads">
                            <cw-load-forces id="anchor-loads"
                                [title]="translate('Agito.Hilti.CW.LoadForces.Title.Anchor')"
                                [tableIdTitle]="translate('Agito.Hilti.CW.LoadForces.Table.Id.Anchor')"
                                [collapsingControls]="CollapsingControls.AnchorLoads"
                                [forceList]="anchorForceList">
                            </cw-load-forces>
                        </div>

                        <div [attr.data-id]="CollapsingControls.ConcreteCompressionForce">
                            <cw-utilizations id="concrete-compression-force" [design]="design" [type]="utilizationTypeEnum.ConcreteCompressionForce"></cw-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.Utilizations">
                            <cw-utilizations id="anchor-design" [design]="design" [type]="utilizationTypeEnum.Default"></cw-utilizations>
                        </div>

                        <div [attr.data-id]="CollapsingControls.NotificationsDesign">
                            <pe-notifications
                                [isVisible]="notificationComponentInputs.isVisible()"
                                [inputs]="notificationComponentInputs">
                            </pe-notifications>
                        </div>

                        <div [attr.data-id]="CollapsingControls.ValidationError">
                            <pe-design-validation-error [validationError]="design?.validationError"
                            [menu]="selectedMenu">
                            </pe-design-validation-error>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
