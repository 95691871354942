import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import castinAnchorChannels from '../../../images/fastening-technology/CastinAnchorChannels.png';
import postInstalledAnchors from '../../../images/fastening-technology/PostInstalledAnchors.png';
import embeddedPlates from '../../../images/fastening-technology/EmbeddedPlates.png';

@Component({
    templateUrl: './select-fastening-technology.component.html',
    styleUrls: ['./select-fastening-technology.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectFasteningTechnologyComponent{
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;

    public images = {
        castinAnchorChannels,
        postInstalledAnchors,
        embeddedPlates
    };

    constructor(
        private localizationService: LocalizationService
    ) { }

    public get title() {
        return this.translate('Agito.Hilti.CW.Navigation.TabApplication.FasteningTechnologiesPopup');
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
