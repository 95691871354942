import { Injectable } from '@angular/core';
import { Properties } from '@profis-engineering/pe-ui-common/entities/design';
import { ChangesServiceInjected } from '@profis-engineering/pe-ui-common/services/changes.common';
import { LogMessage } from '@profis-engineering/pe-ui-common/services/logger.common';
import sortBy from 'lodash-es/sortBy';
import trim from 'lodash-es/trim';
import { LogChange, PropertyChangesHelper } from '../helpers/property-changes-helper';
import { LoggerService } from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class ChangesService extends ChangesServiceInjected {

    constructor(
        private logger: LoggerService
    ) {
        super();
    }

    public logUpdatesFromProperties(properties: Properties, modelChanges: any, propertyChanges: any) {
        const propChangesHelper = new PropertyChangesHelper(properties, modelChanges, propertyChanges);
        const changes = propChangesHelper.getChangesFromProperties();

        if (Object.keys(changes).length == 0)
            return;

        const logMessages = sortBy(
            Object.entries(changes).map(([propId, change]) => this.createLogMessageFromChange(propId, change)),
            log => log.message);

        this.logger.logGroup(new LogMessage({ message: 'Update' }), logMessages);
    }

    private createLogMessageFromChange(propertyId: string, change: LogChange): LogMessage {
        let message = propertyId + ':';
        const args: any[] = [];

        if (change.modelChange != null) {
            message += ' %o => %o |';
            args.push(trim(change.modelChange.oldValue), trim(change.modelChange.newValue));
        }

        if (change.isDisabled != null) {
            message += ' editable: %o |';
            args.push(!change.isDisabled);
        }

        if (change.isHidden != null) {
            message += ' visible: %o |';
            args.push(!change.isHidden);
        }

        if (change.min !== undefined) {
            message += ' min: %o |';
            args.push(change.min);
        }

        if (change.max !== undefined) {
            message += ' max: %o |';
            args.push(change.max);
        }

        if (change.displayKey !== undefined) {
            message += ' displayKey: %o |';
            args.push(change.displayKey);
        }

        if (change.title !== undefined) {
            message += ' title: %o |';
            args.push(change.title);
        }

        if (change.toolTip !== undefined) {
            message += ' toolTip: %o |';
            args.push(change.toolTip);
        }

        if (change.titleDisplayKey !== undefined) {
            message += ' titleDisplayKey: %o |';
            args.push(change.titleDisplayKey);
        }

        if (change.allowedValues != null) {
            message += ' allowedValues: %O |';
            args.push(change.allowedValues);
        }
        if (change.disabledValues != null) {
            message += ' disabledValues: %O |';
            args.push(change.disabledValues);
        }

        if (change.itemsTexts != null && Object.keys(change.itemsTexts).length > 0) {
            message += ' itemsTooltip: %O |';
            args.push(change.itemsTexts);
        }

        message = message.substring(0, message.length - 2);

        return new LogMessage({
            message,
            args
        });
    }
}
