import { CodeList, ICodeListTextDeps } from '../code-list';
import { AnchorFilterEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class AnchorFilter extends CodeList {
    public anchorFilterGroupId?: number;

    constructor(codeList: AnchorFilterEntity) {
        super(codeList);

        if (codeList != null) {
            this.anchorFilterGroupId = codeList.anchorFilterGroupId;
        }
    }

    public static fromService(codeList: AnchorFilterEntity) {
        const anchorFilter = new AnchorFilter(CodeList.baseFromService('AnchorFilterEntity', codeList, {
            anchorFilterGroupId: codeList.anchorFilterGroupId
        } as AnchorFilterEntity));

        anchorFilter.nameResourceKey = `Agito.Hilti.Profis3.CodeList.AnchorFilterEntity.${anchorFilter.displayKey}`;
        return anchorFilter;
    }

    public override cloneDeep(): AnchorFilter {
        const codeList = super.cloneDeep() as AnchorFilter;
        codeList.anchorFilterGroupId = this.anchorFilterGroupId;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
}
