import cloneDeep from 'lodash-es/cloneDeep';
import { CodeList, ICodeListConstructor } from '../code-list';
import { PropertyValueEntity, UIPropertyEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { UIPropertyTexts } from '../generated-modules/Hilti.PE.UserInterfaceProperties';

export interface IUIPropertyConstructor extends ICodeListConstructor {
    propertyValues: PropertyValueEntity[];
}

export interface UIPropertyConfig {
    DefaultValue: number;
    Editable: boolean;
    ItemsTexts: {[    key: string]:  UIPropertyTexts};
    MaxValue: number;
    MinValue: number;
    Property: UIProperty;
    Texts: UIPropertyTexts;
    Value: any;
    Visible: boolean;
    AllowedValues: number[];
    DisabledValues: number[];
}

export interface UIPropertyConfigExtended extends UIPropertyConfig {
    Size: number;
    AllowedValuesUniqueId: string;
}

export class UIProperty extends CodeList {
    public propertyValues?: PropertyValueEntity[];

    constructor(codeList: IUIPropertyConstructor) {
        super(codeList);

        if (codeList != null) {
            this.propertyValues = cloneDeep(codeList.propertyValues);
        }
    }

    public static fromService(codeList: UIPropertyEntity) {
        return new UIProperty(CodeList.baseFromService<IUIPropertyConstructor>('UIProperties', codeList, {
            propertyValues: codeList.propertyValues,
        }));
    }

    public override cloneDeep() {
        const codeList: UIProperty = super.cloneDeep() as UIProperty;
        codeList.propertyValues = cloneDeep(this.propertyValues);
        return codeList;
    }
}
