import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { GLEvent, GLModelBaseComponent } from './GLModelBase';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { NumberService } from '../../services/number.service';
import { UserService } from '../../services/user.service';
import { MathService } from '../../services/math.service';
import { TooltipService } from '../../services/tooltip.service';
import { ModalService } from '../../services/modal.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { CalculationService } from '../../services/calculation.service';
import { IGlModelComponent, GLModelCW, GLModelUpdateCW, IScreenShotSettingsCW } from '../../gl-model/gl-model';
import { Design } from '../../entities/design';
import { SceneCoordinateSystemUpdater } from './updaters/scene-coordinate-system.updater';
import { IModel, RenderType, View2dModeType } from '@profis-engineering/gl-model/gl-model';
import { Change, Update } from '@profis-engineering/gl-model/base-update';
import {
    getText2DFromGlobal, getText2DString, isText2DEnabled, updateText2D
} from '@profis-engineering/gl-model/consoleUpdatePropertyMode';
import { CanvasContext3d } from '@profis-engineering/gl-model/canvas-context-3d';
import { BaseMaterialUpdater } from './updaters/base-material-updater';
import { AnchorChannelUpdater } from './updaters/anchor-channel-updater';
import { PlateBracketUpdater } from './updaters/plate-bracket-updater';

import existingStructureConcrete from '@profis-engineering/gl-model/images/existing-concrete.jpg';
import fontTexture from '@profis-engineering/gl-model/text/default-font-texture';
import newStructureConcrete from '@profis-engineering/gl-model/images/new-concrete.jpg';
import reinforcement from '@profis-engineering/gl-model/images/rebar.jpg';
import { GLImages } from '@profis-engineering/gl-model/images';
import { BoltUpdater } from './updaters/bolt-updater';
import { environment } from '../../../environments/environmentCW';
import { LoadsUpdater } from './updaters/loads-updater';
import { RebarPlateUpdater } from './updaters/rebar-plate-updater';
import { AnchorChannelLipUpdater } from './updaters/anchor-channel-lip-updater';
import { MaterialCacheCW } from '../../gl-model/cache/material-cache';
import { MeshCacheCW } from '../../gl-model/cache/mesh-cache';
import { getOrCreateContext3d } from '../../gl-model/context-3d';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { CodelistHelper } from '../../helpers/codelist-helper';
import { DesignCodeList } from '../../entities/enums/design-code-list';
import { PropertyMetaData } from '../../entities/properties';
import { ComponentsHelper } from '../../helpers/components-helper';
import { LoggerService } from '../../services/logger.service';


export interface IPointsEditorPoint {
    id: number;
    x: number;
    y: number;
}

const enum Stepper {
    increment,
    decrement
}

@Component({
    templateUrl: './gl-model.component.html',
    styleUrls: ['./gl-model.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class GlModelComponent extends GLModelBaseComponent<GLModelCW> implements IGlModelComponent, OnDestroy {

    @Input()
    public zoomToFit = (extraInfo: IScreenShotSettingsCW) => this.zoomToFitInternal(extraInfo);
    @Input()
    public update = (model: IModel, replace?: boolean) => this.updateInternal(model, replace);
    @Input()
    public getModel = () => this.getModelInternal();
    @Input()
    public clearSelected = () => this.clearSelectedInternal();
    @Input()
    public resizeNextFrame = (which?: number, renderCount?: number) => this.resizeNextFrameInternal(which, renderCount);
    @Input()
    public resetCamera = () => this.resetCameraInternal();
    @Input()
    public createDesignScreenshot = (extraInfo: IScreenShotSettingsCW) => this.createDesignScreenshotInternal(extraInfo);
    @Input()
    public createDesignScreenshot2D = (extraInfo: IScreenShotSettingsCW, view2dMode: View2dModeType) => this.createDesignScreenshot2DInternal(extraInfo, view2dMode);
    @Input()
    public propertyValueChanged = (changes: Change[], design: Design, update: Update, model?: IModel) => this.propertyValueChangedInternal(changes, design, update, model);
    @Input()
    public resetHighlightedComponents = () => this.resetHighlightedComponentsInternal();
    @Input()
    public cameraZoomIn = () => this.cameraZoomInInternal();
    @Input()
    public cameraZoomOut = () => this.cameraZoomOutInternal();
    @Input()
    public cameraZoom = (percentage: number) => this.cameraZoomInternal(percentage);

    @Output()
    public fontsLoaded = new EventEmitter<void>();

    @Output()
    public zoom = new EventEmitter<number>();

    @Output()
    public selectTab = new EventEmitter<string>();

    @Output()
    public positionsChanged = new EventEmitter<Record<string, boolean>>();

    @Output()
    public draggingSelectionChanged = new EventEmitter<boolean>();

    public Stepper: Record<keyof typeof Stepper, Stepper> = {
        increment: Stepper.increment,
        decrement: Stepper.decrement
    };

    private textEditor!: HTMLInputElement | undefined;

    public propertyMetaData = PropertyMetaData;
    public dropdown: DropdownProps<number> = {};

    constructor(
        unitService: UnitService,
        numberService: NumberService,
        localizationService: LocalizationService,
        userService: UserService,
        mathService: MathService,
        tooltipService: TooltipService,
        modalService: ModalService,
        userSettingsService: UserSettingsService,
        calculationService: CalculationService,
        ngZone: NgZone,
        changeDetection: ChangeDetectorRef,
        private loggerService: LoggerService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        super(
            unitService,
            numberService,
            localizationService,
            userService,
            mathService,
            tooltipService,
            modalService,
            userSettingsService,
            calculationService,
            ngZone,
            changeDetection
        );

        if (typeof window == 'object') {
            (window as any).updateText2D = updateText2D;
            (window as any).getText2DFromGlobal = getText2DFromGlobal;
            (window as any).getText2DString = getText2DString;
            (window as any).isText2DEnabled = isText2DEnabled;
        }
    }

    public override ngOnDestroy(): void {
        this.textEditor?.remove();

        super.ngOnDestroy();
    }

    public clearSelectedInternal() {
        this.glModel3d.clearSelected();
    }

    public async createDesignScreenshotInternal(extraInfo: IScreenShotSettingsCW): Promise<string> {
        if (!extraInfo.loadsVisibilityInfo) {
            extraInfo.loadsVisibilityInfo = {
                modifyLoads: false,
                preview: false,
            };
        }

        return await this.glModel3d.createDesignScreenshot(extraInfo);
    }

    public async createDesignScreenshot2DInternal(extraInfo: IScreenShotSettingsCW, view2dMode: View2dModeType): Promise<string> {
        return await this.glModel3d.createDesignScreenshot2D(extraInfo, view2dMode);
    }

    public zoomToFitInternal(extraInfo: IScreenShotSettingsCW) {
        this.glModel3d.zoomToFit(extraInfo);
    }

    public resetHighlightedComponentsInternal() {
        this.glModel3d.resetHighlightedComponents();
    }

    public stepperClick(stepper: Stepper) {
        const unitValue = this.unitService.parseUnknownUnitValue(this.glModel3d.textEditorValue ?? '');

        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            const stepValue = this.unitService.incDecValueByUnit(unitValue.unit);
            unitValue.value = (stepper == Stepper.increment ? unitValue.value + stepValue : unitValue.value - stepValue);

            this.glModel3d.textEditorValue = this.unitService.formatUnitValue(unitValue);
            this.glModel3d.textEditorValueChange();
        }
    }

    protected createGLModelBase3D(): GLModelCW {
        const nativeElement = this.elementRef.nativeElement;
        this.textEditor = nativeElement.shadowRoot?.querySelector<HTMLInputElement>('.gl-model-text-editor') ?? undefined;

        setTimeout(() => {
            // canvas is not the correct size yet so we wait
            this.resizeInternal();

            // append the text editor to body
            if (this.textEditor) {
                document.body.appendChild(this.textEditor);
            }
        });

        const modelUpdate: GLModelUpdateCW = {
            sceneCoordinateSystemCtor: SceneCoordinateSystemUpdater,
            baseMaterialCtor: BaseMaterialUpdater,
            anchorChannelCtor: AnchorChannelUpdater,
            plateBracketCtor: PlateBracketUpdater,
            boltUpdaterCtor: BoltUpdater,
            loadsUpdaterCtor: LoadsUpdater,
            rebarPlateCtor: RebarPlateUpdater,
            anchorChannelLipCtor: AnchorChannelLipUpdater
        };

        const images: GLImages = {
            existingStructureConcrete,
            newStructureConcrete,
            reinforcement,
            fontTexture
        };

        const context3d = getOrCreateContext3d(this.context3dKey, () => new CanvasContext3d(this.context3dKey, MaterialCacheCW, MeshCacheCW));
        context3d.parentContainer = nativeElement;

        return this.ngZone.runOutsideAngular(() => new GLModelCW({
            showDebugLayer: environment.debugGlModel,
            context3d,
            images,
            renderType: this.continuousRender ? RenderType.Continuous : RenderType.Auto,
            textEditor: this.textEditor,
            // we bind glModel values in html (like glModel3d.textEditorVisible) so we need to call angular change detection to check for html changes
            textEditorChange: () => this.changeDetection.detectChanges(),
            textEditorOpen: (uiPropertyId: number) => this.refreshItems(uiPropertyId),
            localizationService: this.localizationService,
            unitConverter: this.glUnitConverter,
            propertyInfo: this.glPropertyInfo,
            mathCalculator: this.glMathCalculator,
            tooltip: this.glTooltip,
            eventNotifier: this.glEventNotifier,
            inputSettings: this.glInputSettings,
            model: this.model,
            logger: this.loggerService,
            modelUpdate
        }));
    }

    protected override onEvent(event: GLEvent, eventArgs?: unknown) {
        NgZone.assertInAngularZone();

        switch (event) {
            case 'Fonts_Loaded':
                this.fontsLoaded.next();
                break;
            case 'Zoom':
                this.zoom.next(eventArgs as number);
                break;
            case 'Select_Tab':
                this.selectTab.next(eventArgs as string);
                break;
            case 'positionsChanged':
                this.positionsChanged.next(eventArgs as Record<string, boolean>);
                break;
            case 'draggingSelectionChanged':
                this.draggingSelectionChanged.next(eventArgs as boolean);
                break;
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public refreshItems(uiPropertyId: number) {
        if (uiPropertyId == this.propertyMetaData.AnchorChannel_CW_Length.id) {
            this.loadDropDownItems(DesignCodeList.AnchorChannelLength, uiPropertyId);
        }
    }

    public loadDropDownItems(designCodeList: DesignCodeList, targetProperty: number) {
        this.dropdown = ComponentsHelper.createDropdownComponent(
            targetProperty.toString(),
            undefined,
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                CodelistHelper.getCodeListItems(this.design, designCodeList, targetProperty),
                designCodeList)
        );
    }

    public async setPropertyValue(value: number, propertyId: string | undefined) {
        if (this.design.isReadOnlyDesignMode || propertyId == undefined) {
            return;
        }

        const targetProperty = propertyId as unknown as number;

        await this.ngZone.run(async () => {
            await this.calculationService.calculateAsync(this.design, d => d.model[targetProperty] = value);
        });

        this.glModel3d.dropDownVisible = false;
        this.glModel3d.textEditorVisible = false;
        this.renderNextFrameInternal();
    }

    public getSelectedValue(propertyId: string | undefined) {
        if (propertyId == undefined) {
            return undefined;
        }

        const targetProperty = propertyId as unknown as number;
        return this.design.model[targetProperty];
    }
}
