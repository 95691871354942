import { CodeList, ICodeListConstructor } from '../code-list';
import { DesignMethodGroupsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export interface IDesignMethodGroupConstructor extends ICodeListConstructor {
    id: number;
    displayKey: string;
    groupId: number;
}

export class DesignMethodGroup extends CodeList {
    public groupId?: number;

    constructor(codeList: IDesignMethodGroupConstructor) {
        super(codeList);

        if (codeList != null) {
            this.id = codeList.id;
            this.displayKey = codeList.displayKey;
            this.groupId = codeList.groupId;
        }
    }

    public static fromService(codeList: DesignMethodGroupsEntity) {
        return new DesignMethodGroup(CodeList.baseFromService<IDesignMethodGroupConstructor>('DesignMethodGroupsEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
            groupId: codeList.groupId
        }));
    }

    public override cloneDeep() {
        const codeList: DesignMethodGroup = <DesignMethodGroup>super.cloneDeep();
        codeList.id = this.id;
        codeList.displayKey = this.displayKey;
        codeList.groupId = this.groupId;

        return codeList;
    }
}
