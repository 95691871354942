import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { DetailedScopeCheckInfoPopup } from '../../entities/generated-modules/Hilti.PE.ScopeChecks.CW.Enums';
import { getItemsForCommand, getItemsForScopeCheckInfoPopup, IcidLinks, IInfoDialogComponentInput, IInfoDialogItem, InfoDialogItemType } from '../../helpers/info-dialog-helper';
import { CalculationService } from '../../services/calculation.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { InfoDialogTable } from '../info-dialog-table/info-dialog-table.component';
import { InfoDialogUnorderedList } from '../info-dialog-unordered-list/info-dialog-unordered-list.component';

@Component({
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogComponent implements OnInit {

    @Input()
    public modalInstance!: ModalInstance<IInfoDialogComponentInput>;

    public items!: IInfoDialogItem[];

    constructor(
        private projectCodeList: CodeListService,
        private user: UserService,
        private userSettings: UserSettingsService,
        private localization: LocalizationService,
        private calculationService: CalculationService,
        private modalService: ModalService) {
    }

    ngOnInit(): void {
        this.items = this.modalInstance.input?.isScopeCheckInfoPopup
            ? this.getItemsForScopeCheck(this.modalInstance.input?.detailedScopeCheckInfoPopup)
            : getItemsForCommand(this.user.design, this.projectCodeList, this.modalInstance?.input?.forceLinkToOpen ?? false, this.modalInstance?.input?.command);

        if (this.items.some(x => x.type == InfoDialogItemType.InfoDirectLink)) {
            this.modalInstance.close();
            window.open(this.infoLinkValue, '_blank');
        }
    }

    private getItemsForScopeCheck(command: DetailedScopeCheckInfoPopup): IInfoDialogItem[] {
        let items: IInfoDialogItem[] = [];

        switch (command) {
            case DetailedScopeCheckInfoPopup.SelectVParaSolutions: {
                this.modalInstance.close();
                this.modalService.openSelectVParaSolution();
                break;
            }
            case DetailedScopeCheckInfoPopup.SendErrorInformation: {
                if (this.user.design?.projectDesign == null)
                    throw new Error('Project design is not set.');

                // Open support modal
                const appError = this.calculationService.getLatestAppErrorInfo();
                this.modalInstance.close();
                this.modalService.openSupport(appError, this.user.design.projectDesign);
                break;
            }
            case DetailedScopeCheckInfoPopup.SelectAnchorChannelLength: {
                this.modalInstance.close();
                this.modalService.openSelectAnchorChannelLength();
                break;
            }
            default: {
                items = getItemsForScopeCheckInfoPopup(command);
            }
        }

        return items;
    }

    public translate(key: string | undefined) {
        return this.localization.getString(key ?? '', { tags: { ...LocalizationService.SubSup, ...LocalizationService.SubSup, ...LocalizationService.PBrB } });
    }

    public get dialogInfoItems(): IInfoDialogItem[] {
        return this.items.filter(x => x.type != InfoDialogItemType.Title) ?? [];
    }

    public get title(): string {
        return this.items.find(x => x.type == InfoDialogItemType.Title)?.value?.toString() ?? '';
    }

    public get infoLinkTitle(): string {
        return this.items.find(x => x.type == InfoDialogItemType.InfoLinkTitle)?.value?.toString() ?? '';
    }

    public get infoLinkValue(): string {
        const icidLinks = (this.items.find(x => x.type == InfoDialogItemType.InfoLinkValue)?.value ?? []) as IcidLinks[];
        const icidValue = icidLinks.find(x => x.icid == this.userSettings.settings.application.general.languageId.value);

        return icidValue?.link ?? icidLinks.find(x => x.icid == 0)?.link ?? '';
    }

    public get table(): InfoDialogTable {
        return this.items.find(x => x.type == InfoDialogItemType.Table)?.value as InfoDialogTable;
    }

    public get unorderedList(): InfoDialogUnorderedList {
        return this.items.find(x => x.type == InfoDialogItemType.UnorderedList)?.value as InfoDialogUnorderedList;
    }

    public close() {
        this.modalInstance.close();
    }

    public isItemText(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.Text;
    }

    public isItemSubHeading(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.SubHeading;
    }

    public isItemListItem(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.ListItem;
    }

    public isItemImage(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.Image;
    }

    public isItemLinkTitle(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.InfoLinkTitle;
    }

    public isItemDirectLink(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.InfoDirectLink;
    }

    public isItemTable(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.Table;
    }

    public isItemLineBreak(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.LineBreak;
    }

    public isItemUnorderedList(item: IInfoDialogItem): boolean {
        return item.type == InfoDialogItemType.UnorderedList;
    }

    public objectContainsProperty(item: IInfoDialogItem, property: string): boolean {
        return property in item;
    }
}
