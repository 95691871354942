import { Component, Input, ViewEncapsulation } from '@angular/core';

export interface IInfoDialogUnorderedList {
    id: string;
    translationKeys: string[];
}

export class InfoDialogUnorderedList implements IInfoDialogUnorderedList {

    private _id: string;
    private _translationKeys: string[] = [];

    public get id(): string {
        return this._id;
    }

    public get translationKeys(): string[] {
        return this._translationKeys;
    }

    constructor(data: IInfoDialogUnorderedList) {
        this._id = data.id;
        this._translationKeys = data.translationKeys;
    }

    public addItem(key: string) {
        this._translationKeys.push(key);
    }
}

@Component({
    templateUrl: './info-dialog-unordered-list.component.html',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoDialogUnorderedListComponent {
    @Input()
    public unorderedList!: InfoDialogUnorderedList;
}
