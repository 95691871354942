import earcut from 'earcut';

import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { Scene } from '@babylonjs/core/scene';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { PolygonMeshBuilder } from '@babylonjs/core/Meshes/polygonMesh';
import { Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BaseComponentCW, IModelCW } from '../../../gl-model/base-component';
import { GlModelConstants } from '../../../gl-model/gl-model-constants';
import { Point2D } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design.Geometry';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { createArrowXYLine } from '@profis-engineering/gl-model/line-helper';
import { CreateIUnitText2DCreateOptions } from '@profis-engineering/gl-model/components/base-component';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder';
import { BaseMaterialHelper, IBaseMaterial } from './base-material';
import { BoltsHelper } from './bolt-manager';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import clamp from 'lodash-es/clamp';
import { BaseComponentHelper } from '../../../gl-model/base-component-helper';
import { AnchorChannelLipHelper } from './anchor-channel-lip';
import { UnitText2D } from '../../../gl-model/text/unit-text-2d';
import { Text2D } from '../../../gl-model/text/text-2d';
import { IAnchoringSystemBaseConstructor } from './anchoring-system';
import { INumberedPosition } from '../../../gl-model/entities/numbered-position';
import { GrahamScanVector2, grahamScanToGetConvexHull, isCounterClockwise, normalizePolygon } from '../helpers/polygon-helpers';
import { toVector3 } from '../helpers/geometry-mappers';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { CreateCylinderVertexData } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { CreateRibbonVertexData } from '@babylonjs/core/Meshes/Builders/ribbonBuilder';
import { cloneVertexData } from '@profis-engineering/gl-model/vertex-data-helper';
import { BasePlateSystemHelper } from '../helpers/base-plate-system-helper';

export interface IAnchorChannel {
    channelLength: number;
    h_ch: number;
    b_ch: number;
    h_ef: number;
    crossSectionNodes?: Point2D[];
    spacing: number;
    projection: number;
    l_a: number;
    t_h: number;
    d_1: number;
    d_2: number;
    numberOfAnchors: number;
    lipStrengthClip?: ILipStrengthClip;
    anchorPositions: INumberedPosition[];
    rebarChannel: IRebarChannel;
}

export interface IRebarChannel {
    d_s: number;
    l: number;
    b: number;
    d_b: number;
    k: number;
    kinkAngle: number;
    hasKink: boolean;
}

export interface ILipStrengthClip {
    length: number;
    thickness: number;
    flangeWidth: number;
}

export interface IAnchorChannelValues {
    size: Vector3;
    position: Vector3;
    dimensionLinesInfo: IAnchorChannelLinesInfo;
}

interface IAnchorChannelLinesMeshInfo {
    linesMesh: LinesMesh;
    linesInfo: IAnchorChannelLinesInfo;
}

interface IAnchorChannelLinesInfo {
    lines: Vector3[][];
    textLines: ILine[];
}

interface ILine {
    uiProperty: UIProperty;
    value: number;
    start: Vector3;
    end: Vector3;
    up: Vector3;
    rotationY: number;
}


interface IAnchor {
    anchorHeadDiameter: number;
    anchorDiameter: number;
    anchorHeight: number;
    anchorHeadHeight: number;
    anchorHeadBodyHeight: number;
    lengthToKink: number;
    kinkOffset: number;
    kinkAngle: number;
    kinkRadius: number;
}

export class AnchorChannel extends BaseComponentCW {
    public mesh?: Mesh;

    private readonly anchorChannelName = 'CW.AnchorChannel';
    private readonly meshName = 'CW.AnchorChannel.Mesh';
    private readonly lineMeshName = 'CW.AnchorChannel.LinesMesh';
    public static readonly NumberOfSamplesForOrientationDetection = 10;

    private anchorChannelValues = {} as IAnchorChannelValues;

    private unitTexts?: Map<UnitText2D, ILine>;
    private textCtor: CreateIUnitText2DCreateOptions;
    private structureLinesMeshInfo?: IAnchorChannelLinesMeshInfo;

    private anchorsText: Text2D[] = [];
    private transformNode!: TransformNode;

    constructor(ctor: IAnchoringSystemBaseConstructor) {
        super(ctor);

        this.textCtor = {
            unitGroup: UnitGroup.Length
        };

        this.id = ctor.id;
    }

    public override update(): void {
        const anchoringSystem = this.model.anchoringSystem(this.model, this.id);

        if (anchoringSystem?.anchorChannel.crossSectionNodes == undefined || anchoringSystem.anchorChannel.crossSectionNodes.length == 0) {
            this.hide();
            return;
        }

        this._dispose(true);
        this.disposeText();

        this.transformNode = this.getGlobalTransformNode(anchoringSystem.geometry.position, anchoringSystem.geometry.rotation);
        this.anchorChannelValues = AnchorChannelHelper.getAnchorChannelValues(this.model, this.id);

        this.createMainMesh();

        this.ensureDimensionsMesh();
    }

    public override getBoundingBoxes(): BoundingInfo[] {

        const boundingBoxes: BoundingInfo[] = [];

        if (this.mesh) {
            boundingBoxes.push(this.mesh.getBoundingInfo());
        }

        this.unitTexts?.forEach((textLine: ILine, unitText: UnitText2D) => {
            if (unitText.mesh != null) {
                boundingBoxes.push(unitText.mesh.getBoundingInfo());
            }
        });

        return boundingBoxes;
    }

    public override hide() {
        this.mesh?.setEnabled(false);
        this.anchorsText.forEach(x => x.setEnabled(false));

        this.structureLinesMeshInfo?.linesMesh?.setEnabled(false);
        this.unitTexts?.forEach((textLine: ILine, unitText: UnitText2D) => unitText.setEnabled(false));

        if (this.model.isAnchoringSystemSelected(this.id)) {
            const anchoringSystem = this.model.anchoringSystem(this.model, this.id);
            this.transformNode = this.getGlobalTransformNode(anchoringSystem.geometry.position, anchoringSystem.geometry.rotation);

            this.anchorChannelValues = AnchorChannelHelper.getAnchorChannelValues(this.model, this.id);
            this.ensureDimensionsMesh();
        }
    }

    public override dispose(): void {
        this._dispose(true);
        this.disposeText();
    }

    private _dispose(disposeMainMesh: boolean): void {
        if (disposeMainMesh && this.mesh) {
            this.scene.removeMesh(this.mesh);
            this.mesh.dispose();
        }

        this.disposeUnitTexts();
        this.disposeDimensionLines();
    }

    //#region Lines
    private ensureDimensionsMesh() {
        this.setUnitTexts();
        this.ensureDimensionLineMesh();
    }

    private setUnitTexts() {
        this.disposeUnitTexts();

        if (this.anchorChannelValues.dimensionLinesInfo == null)
            return;

        this.unitTexts = new Map();

        for (const textLineData of this.anchorChannelValues.dimensionLinesInfo.textLines) {
            const options = {
                unitGroup: this.textCtor.unitGroup,
                extraPrecision: this.textCtor.extraPrecision,
                isDropDown: textLineData.uiProperty == UIProperty.AnchorChannel_CW_Length
            };
            const unitText = this.createUnitText2DForProperty(options, textLineData.uiProperty as number, undefined, true);

            this.unitTexts.set(unitText, textLineData);
        }
    }

    private ensureDimensionLineMesh() {
        if (this.anchorChannelValues.dimensionLinesInfo == null)
            return;

        this.structureLinesMeshInfo = this.createDimensionLinesMesh();
        this.updateDimensionLinesMesh();
        this.sizeLinesText();

        this.structureLinesMeshInfo.linesMesh.setEnabled(true);

        if (this.transformNode != null)
            this.structureLinesMeshInfo.linesMesh.parent = this.transformNode;
    }

    private sizeLinesText() {
        this.unitTexts?.forEach((textLine: ILine, unitText: UnitText2D) => {
            if (unitText != null) {
                unitText.setValueOnLine(
                    textLine.value,
                    textLine.start,
                    textLine.end,
                    textLine.up,
                    {
                        rotationY: textLine.rotationY
                    }
                );

                if (this.transformNode != null && unitText.mesh != null)
                    unitText.mesh.parent = this.transformNode;

                unitText.setEnabled(true);
            }
        });
    }

    private updateDimensionLinesMesh() {
        if (this.structureLinesMeshInfo == null)
            return;

        CreateLineSystem(undefined as unknown as string, {
            lines: this.anchorChannelValues.dimensionLinesInfo.lines,
            instance: this.structureLinesMeshInfo.linesMesh,
            updatable: undefined
        }, undefined as unknown as Scene);

        this.structureLinesMeshInfo.linesInfo = this.anchorChannelValues.dimensionLinesInfo;
        this.structureLinesMeshInfo.linesMesh.refreshBoundingInfo();
    }

    private createDimensionLinesMesh() {
        if (this.structureLinesMeshInfo?.linesInfo.lines.flat().length != this.anchorChannelValues.dimensionLinesInfo?.lines.flat().length) {
            // Disposing dimension lines mesh if number of points is not the same as in previous rendering
            this.disposeDimensionLines();
        }

        return this.cache.meshCache.create(this.lineMeshName, (): IAnchorChannelLinesMeshInfo => {
            const linesMesh = this.createLineSystem(this.lineMeshName);

            return {
                linesMesh: linesMesh,
                linesInfo: this.anchorChannelValues.dimensionLinesInfo
            };
        });
    }

    private createLineSystem(name: string): LinesMesh {
        const linesMesh = CreateLineSystem(name, {
            lines: this.anchorChannelValues.dimensionLinesInfo.lines,
            updatable: true
        }, this.scene);

        linesMesh.isPickable = false;
        linesMesh.color = GlModelConstants.lineConstants.defaultLineColor;
        linesMesh.alphaIndex = 3000;

        return linesMesh;
    }

    private disposeDimensionLines() {
        this.cache.meshCache.clear(this.lineMeshName);

        if (this.structureLinesMeshInfo?.linesMesh != null) {
            this.structureLinesMeshInfo.linesMesh.setEnabled(false);
            this.structureLinesMeshInfo.linesMesh.dispose();
            this.structureLinesMeshInfo.linesMesh = undefined as unknown as LinesMesh;
        }
    }

    private disposeUnitTexts(): void {
        for (const unitText of this.unitTexts?.keys() ?? []) {
            if (unitText != null) {
                unitText.setEnabled(false);
                unitText.dispose();
            }
        }
        this.unitTexts = undefined;
    }

    private disposeText() {
        for (const text of this.anchorsText) {
            text?.mesh?.setEnabled(false);
            text.dispose();
        }
        this.anchorsText = [];
    }
    //#endregion

    //#region Creation of meshes
    private createMainMesh() {
        const channelVertexData = this.calculateAnchorChannelVertexData();
        const anchorVertexData = this.calculateAnchorVertexData();

        const anchors = BaseComponentHelper.replicateVertexDataToFullfilNumberOfInstances(
            anchorVertexData,
            AnchorChannelHelper.getAnchorsPositions(this.model, this.id),
            () => this.createText2D(),
            this.model.visibilityProperties.anchorNumberVisible ? this.anchorsText : undefined,
            this.transformNode,
            AnchorChannelHelper.getTextOffset(this.model, this.id));

        channelVertexData.merge(anchors);

        this.mesh = new Mesh(this.meshName, this.scene);
        channelVertexData.applyToMesh(this.mesh);

        this.mesh.material = this.cache.materialCache.steelMaterial;

        if (this.transformNode != null)
            this.mesh.parent = this.transformNode;

        this._dispose(false);
    }


    private calculateAnchorVertexData(): VertexData {
        /**
         *  | |
         *  | |   - anchor
         *  | |
         *  / \   - anchorHead
         * |   |  - anchorHeadBody
         */

        const anchorValues = AnchorChannelHelper.getAnchorValues(this.model, this.id);

        let anchor: VertexData;
        if (this.model.anchoringSystem(this.model, this.id).anchorChannel.rebarChannel.hasKink) {
            const circleShape = this.cache.commonCache.getCirclePoints(24, anchorValues.anchorDiameter).map(x => new Vector3(x.x, x.y, 0));
            circleShape.push(circleShape[0]);
            const path = this.getXTRebarPath(this.model, this.id);
            const mitre = this.cache.vertexDataCache.mitredExtrude(circleShape, path, false);
            anchor = CreateRibbonVertexData({ pathArray: mitre, closeArray: false, closePath: false, sideOrientation: Mesh.DOUBLESIDE });
            anchor.merge(this.getXTRebarCaps(circleShape, path[path.length - 1]));
            anchor.transform(Matrix.Translation(0, this.anchorChannelValues.position.y - this.anchorChannelValues.size.y / 2, AnchorChannelHelper.getzOffset(this.model.baseMaterial, false)));
            return anchor;
        } else {
            anchor = CreateCylinderVertexData({
                diameter: anchorValues.anchorDiameter,
                enclose: true,
                height: anchorValues.anchorHeight,
            });
        }

        let anchorHead: VertexData | undefined;
        let anchorHeadBody: VertexData | undefined;

        if (!AnchorChannelHelper.isAnchorRebar(this.model, this.id)) {
            anchorHead = CreateCylinderVertexData({
                diameterTop: anchorValues.anchorDiameter,
                diameterBottom: anchorValues.anchorHeadDiameter,
                enclose: true,
                height: anchorValues.anchorHeadHeight
            });

            anchorHeadBody = CreateCylinderVertexData({
                diameter: anchorValues.anchorHeadDiameter,
                enclose: true,
                height: anchorValues.anchorHeadBodyHeight
            });
        }

        this.positionAnchorParts(anchor, anchorHead, anchorHeadBody);

        if (anchorHead && anchorHeadBody) {
            anchor.merge(anchorHead);
            anchor.merge(anchorHeadBody);
        }

        return anchor;
    }

    private getXTRebarCaps(shape: Vector3[], lastPointOfPath: Vector3) {
        const capPolygonBuilder = new PolygonMeshBuilder('CW.XTRebar.Cap', shape.map(x => new Vector2(x.x, x.y)), undefined, earcut);
        const capVertexData = capPolygonBuilder.buildVertexData();
        const capEnd = cloneVertexData(capVertexData);
        capEnd.transform(Matrix.RotationZ(Math.PI).multiply(Matrix.Translation(0, lastPointOfPath.y, lastPointOfPath.z)));
        capVertexData.merge(capEnd);

        return capVertexData;
    }

    private getXTRebarPath(model: IModelCW, id: string): Vector3[] {
        const anchoringSystem = model.anchoringSystem(model, id);
        const data = AnchorChannelHelper.getAnchorValues(model, this.id);

        const path = [new Vector3(0, 0, 0), new Vector3(data.lengthToKink, 0, 0)];

        const firstArcCenter = new Vector2(path[1].x, path[1].z + data.kinkRadius);
        const roundness = 5;

        const startingAngle = Math.PI / 2;
        const finishAngle = Math.asin((firstArcCenter.y - (data.kinkOffset / 2)) / data.kinkRadius);

        const firstArc = AnchorChannelHelper.getCirclePath(roundness, Math.PI * 3 / 2, startingAngle - finishAngle, firstArcCenter.x, firstArcCenter.y, data.kinkRadius, false);

        const arcLength = firstArc[firstArc.length - 1].x - firstArcCenter.x;
        const secondArcCenter = new Vector2(firstArc[firstArc.length - 1].x + arcLength, firstArc[firstArc.length - 1].y - data.kinkRadius + data.kinkOffset / 2);
        const secondArc = AnchorChannelHelper.getCirclePath(roundness, Math.PI / 2, startingAngle - finishAngle, secondArcCenter.x, secondArcCenter.y, data.kinkRadius, false);

        firstArc.shift();
        secondArc.reverse();
        secondArc.shift();

        path.push(...firstArc.map(x => new Vector3(x.x, 0, x.y)));
        path.push(...secondArc.map(x => new Vector3(x.x, 0, x.y)));

        path.push(new Vector3(data.anchorHeight, 0, secondArc[secondArc.length - 1].y));

        return path.map(x => new Vector3(0, -x.x, anchoringSystem.isCorner ? -x.z : x.z));
    }

    /**
     * Applies polygon shape to mesh and creates caps for polygon it merges into mesh and returns reference
     * @param mesh
     * @param capFront
     * @param capBack
     */
    private calculateAnchorChannelVertexData(): VertexData {
        // Get anchorchannel polygon normalized into model size.
        const anchorChannelPolygon = AnchorChannelHelper.getAnchorChannelShape(this.model, this.id);

        // Close polygon if not closed
        const firstPoint = anchorChannelPolygon[0];
        const lastPoint = anchorChannelPolygon[anchorChannelPolygon.length - 1];
        if (!firstPoint.equals(lastPoint)) {
            anchorChannelPolygon.push(anchorChannelPolygon[0]);
        }

        // PolygonMeshBuilder requires clockwise orientation
        const anchorChannelCapPolygon = anchorChannelPolygon.map(x => new Vector2(x.x, x.y));
        if (!isCounterClockwise(anchorChannelCapPolygon, AnchorChannel.NumberOfSamplesForOrientationDetection)) {
            anchorChannelCapPolygon.reverse();
        }

        // Create polygon
        const polygonTriangulation = new PolygonMeshBuilder(this.anchorChannelName, anchorChannelCapPolygon, undefined, earcut);
        const anchorChannel = polygonTriangulation.buildVertexData(this.anchorChannelValues.size.x);

        anchorChannel.transform(Matrix.Translation(
            this.anchorChannelValues.position.z,
            this.anchorChannelValues.position.x,
            this.anchorChannelValues.position.y));
        anchorChannel.transform(Matrix.RotationX(-Math.PI / 2).multiply(Matrix.RotationY(-Math.PI / 2)));

        return anchorChannel;
    }
    //#endregion

    //#region Helpers
    private positionAnchorParts(anchor: VertexData, anchorHead?: VertexData, anchorHeadBody?: VertexData) {
        const isTopView = BaseComponentHelper.isTopView(this.model.applicationType);
        const zOffset = AnchorChannelHelper.getzOffset(this.model.baseMaterial, isTopView);

        const anchorChannelValues = AnchorChannelHelper.getAnchorChannelValues(this.model, this.id);
        const anchorValues = AnchorChannelHelper.getAnchorValues(this.model, this.id);

        const anchorPosition = new Vector3(0, anchorChannelValues.position.y - (anchorChannelValues.size.y / 2) - (anchorValues.anchorHeight / 2), zOffset);
        anchor.transform(Matrix.Translation(anchorPosition.x, anchorPosition.y, anchorPosition.z));

        if (anchorHead && anchorHeadBody) {
            const anchorHeadPosition = new Vector3(0, anchorPosition.y - (anchorValues.anchorHeight / 2) - (anchorValues.anchorHeadHeight / 2), zOffset);
            anchorHead.transform(Matrix.Translation(anchorHeadPosition.x, anchorHeadPosition.y, anchorHeadPosition.z));

            anchorHeadBody.transform(Matrix.Translation(0, anchorHeadPosition.y - (anchorValues.anchorHeadHeight / 2) - (anchorValues.anchorHeadBodyHeight / 2), zOffset));
        }
    }
    //#endregion
}

export class AnchorChannelHelper {
    public static getAnchorChannelValues(model: IModelCW, id: string): IAnchorChannelValues {
        const anchorChannel = model.anchoringSystem(model, id)?.anchorChannel;

        const size = new Vector3(anchorChannel.channelLength, anchorChannel.h_ch, anchorChannel.b_ch);
        const isTopView = BaseComponentHelper.isTopView(model.applicationType);

        const positionY = isTopView ?
            (model.baseMaterial.thickness / 2.0) - (size.y / 2.0) :
            -(size.y / 2.0);

        const positionZ = this.getzOffset(model.baseMaterial, isTopView);

        const position = new Vector3(size.x / 2, positionY, positionZ);

        return {
            size: size,
            position: position,
            dimensionLinesInfo: this.getDimensionLinesValues(model, id, size, position)
        };
    }

    public static getAnchorSpacing(model: IModelCW) {
        const basePlateSystem = BasePlateSystemHelper.getFirstBasePlateSystem(model);

        if (basePlateSystem == undefined)
            return {
                anchorsSpacing: 0,
                rightOffset: 0,
                firstAnchorPosition: 0,
                lastAnchorPosition: 0
            };

        const anchorChannel = model.anchoringSystem(model, '1')?.anchorChannel;
        const firstAnchorPosition = basePlateSystem.bolt.positions[0].position.x;
        const lastAnchorPosition = basePlateSystem.bolt.positions[basePlateSystem.bolt.positions.length - 1].position.x;
        const anchorsSpacing = lastAnchorPosition - firstAnchorPosition;

        let rightOffset = model.isPostInstallAnchorProduct ? anchorsSpacing : lastAnchorPosition - anchorChannel.channelLength / 2.0;

        // Coefficient has to increase with longer base material because of the infinity ribbon vertex stretching
        const rightOffsetCoefficient = model.baseMaterial.xPositiveInfinity ? 2 + (rightOffset / 1000) : 1;

        if (!model.isPostInstallAnchorProduct && !model.baseMaterial.xPositiveInfinity)
            rightOffset += (GlModelConstants.baseMaterialConstants.minEmbedment * rightOffsetCoefficient);

        return {
            anchorsSpacing,
            rightOffset,
            firstAnchorPosition,
            lastAnchorPosition
        };
    }

    private static getAnchorChannelLengthDimensionLinesValues(model: IModelCW, id: string, anchorChannelSize: Vector3, anchorChannelPosition: Vector3) {
        const lineTextsArrayAnchorChannel: ILine[] = [];
        const { lineDimensionOffsetXZ, anchorChannelOffsetZ } = GlModelConstants.lineConstants;
        const lineOffset = 2 * lineDimensionOffsetXZ;
        const { yPositive } = BaseMaterialHelper.getBaseMaterialEdgeDistances(model);


        const yOffset = BaseComponentHelper.isTopView(model.applicationType) ? yPositive : model.baseMaterial.thickness / 2.0;

        const defaultOffsetZ = yOffset + lineOffset;

        const anchoringSystem = model.anchoringSystem(model, id);
        const lineOffsetZ = Math.max(BoltsHelper.getSpacingLineOffset(model, id, anchoringSystem.basePlateSystems[0].id) + anchorChannelOffsetZ, defaultOffsetZ);

        const displayDimensions = model.visibilityProperties.anchorChannelLenVisible && model.isAnchoringSystemSelected(id);

        const widthLineCoords: Vector3[] = displayDimensions ? [
            new Vector3(anchorChannelPosition.x - anchorChannelSize.x, anchorChannelPosition.y + anchorChannelSize.y / 2, anchorChannelPosition.z),
            new Vector3(anchorChannelPosition.x - anchorChannelSize.x, anchorChannelPosition.y + anchorChannelSize.y / 2, lineOffsetZ),
            new Vector3(anchorChannelPosition.x, anchorChannelPosition.y + anchorChannelSize.y / 2, lineOffsetZ),
            new Vector3(anchorChannelPosition.x, anchorChannelPosition.y + anchorChannelSize.y / 2, anchorChannelPosition.z)
        ] : [];

        const widthLine: ILine = {
            uiProperty: UIProperty.AnchorChannel_CW_Length,
            value: anchorChannelSize.x,
            start: widthLineCoords[1],
            end: widthLineCoords[2],
            rotationY: Math.PI,
            up: new Vector3(0, 1, 1)
        };

        if (displayDimensions)
            lineTextsArrayAnchorChannel.push(widthLine);
        const widthArrowCoords: Vector3[][] = displayDimensions ? createArrowXYLine(widthLine.start, widthLine.end) : [];

        return {
            widthLineCoords,
            widthArrowCoords,
            lineTextsArrayAnchorChannel
        };
    }

    private static getBaseMaterialXDimensionLinesValues(model: IModelCW, id: string, anchorChannelSize: Vector3, anchorChannelPosition: Vector3) {
        const lineTextsArrayBaseMaterialX: ILine[] = [];
        const { lineDimensionOffsetXZ, anchorChannelOffsetZ } = GlModelConstants.lineConstants;
        const lineOffset = 2 * lineDimensionOffsetXZ;
        const { xNegative, xPositive, yPositive } = BaseMaterialHelper.getBaseMaterialEdgeDistances(model);

        // calculate offset based on rotation
        const yOffset = BaseComponentHelper.isTopView(model.applicationType) ? yPositive : model.baseMaterial.thickness / 2.0;

        const defaultOffsetZ = yOffset + lineOffset;
        const anchoringSystem = model.anchoringSystem(model, id);
        const lineOffsetZ = Math.max(BoltsHelper.getSpacingLineOffset(model, id, anchoringSystem.basePlateSystems[0].id) + anchorChannelOffsetZ, defaultOffsetZ);

        const displayDimensions = model.visibilityProperties.concreteDimensionVisible && model.isAnchoringSystemSelected(id);

        const { firstAnchorPosition, lastAnchorPosition } = AnchorChannelHelper.getAnchorSpacing(model);
        let x = model.isPostInstallAnchorProduct ? lastAnchorPosition : anchorChannelPosition.x;

        const baseMaterialPositiveXCoords: Vector3[] = displayDimensions ? [
            new Vector3(x, anchorChannelPosition.y + anchorChannelSize.y / 2, yOffset),
            new Vector3(x, anchorChannelPosition.y + anchorChannelSize.y / 2, lineOffsetZ),
            new Vector3(x + xPositive, anchorChannelPosition.y + anchorChannelSize.y / 2, lineOffsetZ),
            new Vector3(x + xPositive, anchorChannelPosition.y + anchorChannelSize.y / 2, yOffset)
        ] : [];

        const baseMaterialPositiveXWidthLine: ILine = {
            uiProperty: UIProperty.Geometry_CW_EdgeDistanceX,
            value: model.baseMaterial.xPositive,
            start: baseMaterialPositiveXCoords[1],
            end: baseMaterialPositiveXCoords[2],
            rotationY: Math.PI,
            up: new Vector3(0, 1, 1)
        };

        if (displayDimensions)
            lineTextsArrayBaseMaterialX.push(baseMaterialPositiveXWidthLine);
        const baseMaterialPositiveXCoordsArrow: Vector3[][] = displayDimensions ? createArrowXYLine(baseMaterialPositiveXWidthLine.start, baseMaterialPositiveXWidthLine.end) : [];

        x = model.isPostInstallAnchorProduct ? firstAnchorPosition : anchorChannelPosition.x - anchorChannelSize.x;

        const baseMaterialNegativeXCoords: Vector3[] = displayDimensions ? [
            new Vector3(x, anchorChannelPosition.y + anchorChannelSize.y / 2, yOffset),
            new Vector3(x, anchorChannelPosition.y + anchorChannelSize.y / 2, lineOffsetZ),
            new Vector3(x - xNegative, anchorChannelPosition.y + anchorChannelSize.y / 2, lineOffsetZ),
            new Vector3(x - xNegative, anchorChannelPosition.y + anchorChannelSize.y / 2, yOffset)
        ] : [];

        const baseMaterialNegativeXWidthLine: ILine = {
            uiProperty: UIProperty.Geometry_CW_EdgeDistanceNegativeX,
            value: model.baseMaterial.xNegative,
            start: baseMaterialNegativeXCoords[1],
            end: baseMaterialNegativeXCoords[2],
            rotationY: Math.PI,
            up: new Vector3(0, 1, 1)
        };

        if (displayDimensions)
            lineTextsArrayBaseMaterialX.push(baseMaterialNegativeXWidthLine);
        const baseMaterialNegativeXCoordsArrow: Vector3[][] = displayDimensions ? createArrowXYLine(baseMaterialNegativeXWidthLine.start, baseMaterialNegativeXWidthLine.end) : [];

        return {
            baseMaterialPositiveXCoords,
            baseMaterialNegativeXCoords,
            baseMaterialPositiveXCoordsArrow,
            baseMaterialNegativeXCoordsArrow,
            lineTextsArrayBaseMaterialX
        };
    }

    private static getBaseMaterialYDimensionLinesValuesTopView(model: IModelCW, id: string, anchorChannelSize: Vector3, anchorChannelPosition: Vector3) {
        const lineTextsArrayBaseMaterialY: ILine[] = [];
        const { lineDimensionOffsetXZ } = GlModelConstants.lineConstants;
        const lineOffset = 2 * lineDimensionOffsetXZ;
        const { xPositive, yNegative, yPositive } = BaseMaterialHelper.getBaseMaterialEdgeDistances(model);

        const displayDimensions = model.visibilityProperties.concreteDimensionVisible && model.isAnchoringSystemSelected(id);

        const { lastAnchorPosition } = AnchorChannelHelper.getAnchorSpacing(model);
        const x = model.isPostInstallAnchorProduct ? lastAnchorPosition : anchorChannelPosition.x;

        const baseMaterialNegativeYCoords: Vector3[] = displayDimensions ? [
            new Vector3(x + xPositive, anchorChannelPosition.y + anchorChannelSize.y / 2, -yNegative),
            new Vector3(x + xPositive + lineOffset, anchorChannelPosition.y + anchorChannelSize.y / 2, -yNegative),
            new Vector3(x + xPositive + lineOffset, anchorChannelPosition.y + anchorChannelSize.y / 2, 0.0),
            new Vector3(x + xPositive, anchorChannelPosition.y + anchorChannelSize.y / 2, 0.0),
        ] : [];

        const baseMaterialNegativeYLine: ILine = {
            uiProperty: UIProperty.Geometry_CW_EdgeDistanceNegativeY,
            value: model.baseMaterial.yNegative,
            start: baseMaterialNegativeYCoords[1],
            end: baseMaterialNegativeYCoords[2],
            rotationY: Math.PI,
            up: new Vector3(1, 0, 0)
        };

        if (displayDimensions)
            lineTextsArrayBaseMaterialY.push(baseMaterialNegativeYLine);
        const baseMaterialNegativeYCoordsArrow: Vector3[][] = displayDimensions ? createArrowXYLine(baseMaterialNegativeYLine.start, baseMaterialNegativeYLine.end) : [];

        const baseMaterialPositiveYCoords: Vector3[] = displayDimensions ? [
            new Vector3(x + xPositive, anchorChannelPosition.y + anchorChannelSize.y / 2, 0.0),
            new Vector3(x + xPositive + lineOffset, anchorChannelPosition.y + anchorChannelSize.y / 2, 0.0),
            new Vector3(x + xPositive + lineOffset, anchorChannelPosition.y + anchorChannelSize.y / 2, yPositive),
            new Vector3(x + xPositive, anchorChannelPosition.y + anchorChannelSize.y / 2, yPositive),
        ] : [];

        const baseMaterialPositiveYLine: ILine = {
            uiProperty: UIProperty.Geometry_CW_EdgeDistanceY,
            value: model.baseMaterial.yPositive,
            start: baseMaterialPositiveYCoords[1],
            end: baseMaterialPositiveYCoords[2],
            rotationY: Math.PI,
            up: new Vector3(1, 0, 0)
        };

        if (displayDimensions)
            lineTextsArrayBaseMaterialY.push(baseMaterialPositiveYLine);
        const baseMaterialPositiveYArrow: Vector3[][] = displayDimensions ? createArrowXYLine(baseMaterialPositiveYLine.start, baseMaterialPositiveYLine.end) : [];

        return {
            baseMaterialNegativeYCoords,
            baseMaterialPositiveYCoords,
            baseMaterialNegativeYCoordsArrow,
            baseMaterialPositiveYArrow,
            lineTextsArrayBaseMaterialY
        };
    }

    private static getEdgeDistanceYNegativeDimensionLinesValuesFrontView(model: IModelCW, id: string, anchorChannelSize: Vector3, anchorChannelPosition: Vector3) {
        const lineTextArrayBaseMatrialNegativeY: ILine[] = [];
        const { lineDimensionOffsetXZ } = GlModelConstants.lineConstants;
        const { xNegative, xPositive } = BaseMaterialHelper.getBaseMaterialEdgeDistances(model);
        const { firstAnchorPosition } = AnchorChannelHelper.getAnchorSpacing(model);

        const lineOffset = lineDimensionOffsetXZ / 2;
        const yOffset = model.baseMaterial.yNegative - (model.baseMaterial.thickness / 2);
        const angle = 4 * (lineOffset / Math.sqrt(2));

        let x = 0;
        let xWithOffset = 0;

        if (BaseComponentHelper.isFaceOfCorner(model.applicationType)) {
            x = anchorChannelPosition.x + xPositive;
            xWithOffset = x + lineOffset;
        }
        else {
            x = (model.isPostInstallAnchorProduct ? firstAnchorPosition : anchorChannelPosition.x - anchorChannelSize.x) - xNegative;
            xWithOffset = x - lineOffset;
        }

        const displayDimensions = model.visibilityProperties.concreteDimensionVisible && model.isAnchoringSystemSelected(id);

        const baseMaterialNegativeYCoords: Vector3[] = displayDimensions ? [
            new Vector3(x, anchorChannelPosition.y + anchorChannelSize.y / 2, -model.baseMaterial.thickness / 2),
            new Vector3(xWithOffset, (anchorChannelPosition.y + anchorChannelSize.y / 2) + angle, -model.baseMaterial.thickness / 2),
            new Vector3(xWithOffset, (anchorChannelPosition.y + anchorChannelSize.y / 2) + angle, yOffset),
            new Vector3(x, anchorChannelPosition.y + anchorChannelSize.y / 2, yOffset),
        ] : [];

        const baseMaterialNegativeYLine: ILine = {
            uiProperty: UIProperty.Geometry_CW_EdgeDistanceNegativeY,
            value: model.baseMaterial.yNegative,
            start: baseMaterialNegativeYCoords[1],
            end: baseMaterialNegativeYCoords[2],
            rotationY: Math.PI,
            up: new Vector3(0, 1, 1)
        };

        if (displayDimensions)
            lineTextArrayBaseMatrialNegativeY.push(baseMaterialNegativeYLine);

        const baseMaterialNegativeYArrow: Vector3[][] = displayDimensions ? createArrowXYLine(baseMaterialNegativeYLine.start, baseMaterialNegativeYLine.end) : [];

        return {
            baseMaterialNegativeYCoords,
            baseMaterialNegativeYArrow,
            lineTextArrayBaseMatrialNegativeY
        };
    }

    private static getBaseMaterialZNegativeDimensionLinesValuesFrontView(model: IModelCW, id: string, anchorChannelSize: Vector3, anchorChannelPosition: Vector3) {
        const lineTextsArrayBaseMaterialNegativeZ: ILine[] = [];
        const { lineDimensionOffsetXZ } = GlModelConstants.lineConstants;
        const lineOffset = 2 * lineDimensionOffsetXZ;
        const { zNegative, xNegative, xPositive } = BaseMaterialHelper.getBaseMaterialEdgeDistances(model);
        const { firstAnchorPosition } = AnchorChannelHelper.getAnchorSpacing(model);

        let x = 0;
        let xWithOffset = 0;

        if (BaseComponentHelper.isFaceOfCorner(model.applicationType)) {
            x = anchorChannelPosition.x + xPositive;
            xWithOffset = x + lineOffset;
        }
        else {
            x = (model.isPostInstallAnchorProduct ? firstAnchorPosition : anchorChannelPosition.x - anchorChannelSize.x) - xNegative;
            xWithOffset = x - lineOffset;
        }

        const displayDimensions = model.visibilityProperties.concreteDimensionVisible && model.isAnchoringSystemSelected(id);

        const baseMaterialNegativeZCoords: Vector3[] = displayDimensions ? [
            new Vector3(x, -zNegative, model.baseMaterial.thickness / 2),
            new Vector3(xWithOffset, -zNegative, model.baseMaterial.thickness / 2),
            new Vector3(xWithOffset, 0, model.baseMaterial.thickness / 2),
            new Vector3(x, 0, model.baseMaterial.thickness / 2)
        ] : [];

        const baseMaterialNegativeZLine: ILine = {
            uiProperty: UIProperty.Geometry_CW_EdgeDistanceNegativeZ,
            value: model.baseMaterial.zNegative,
            start: baseMaterialNegativeZCoords[1],
            end: baseMaterialNegativeZCoords[2],
            rotationY: Math.PI,
            up: new Vector3(0, 1, 1)
        };

        if (displayDimensions)
            lineTextsArrayBaseMaterialNegativeZ.push(baseMaterialNegativeZLine);

        const baseMaterialNegativeZArrow: Vector3[][] = displayDimensions ? createArrowXYLine(baseMaterialNegativeZLine.start, baseMaterialNegativeZLine.end) : [];

        return {
            baseMaterialNegativeZCoords,
            baseMaterialNegativeZArrow,
            lineTextsArrayBaseMaterialNegativeZ
        };
    }

    private static getDimensionLinesValues(model: IModelCW, id: string, anchorChannelSize: Vector3, anchorChannelPosition: Vector3): IAnchorChannelLinesInfo {
        let lineTextsArray: ILine[] = [];
        let linesCords: Vector3[][] = [];

        //Anchor Channel Length dimension lines values
        if (model.isAnchorChannelAvailable(model, id)) {
            const { widthLineCoords, widthArrowCoords, lineTextsArrayAnchorChannel } = this.getAnchorChannelLengthDimensionLinesValues(model, id, anchorChannelSize, anchorChannelPosition);
            linesCords = linesCords.concat([widthLineCoords, ...widthArrowCoords] as Vector3[][]);
            lineTextsArray = lineTextsArray.concat(lineTextsArrayAnchorChannel);
        }

        //BaseMaterial X dimension lines values
        const {
            baseMaterialPositiveXCoords,
            baseMaterialNegativeXCoords,
            baseMaterialPositiveXCoordsArrow,
            baseMaterialNegativeXCoordsArrow,
            lineTextsArrayBaseMaterialX
        } = this.getBaseMaterialXDimensionLinesValues(model, id, anchorChannelSize, anchorChannelPosition);

        linesCords = linesCords.concat([baseMaterialPositiveXCoords, baseMaterialNegativeXCoords, ...baseMaterialPositiveXCoordsArrow, ...baseMaterialNegativeXCoordsArrow] as Vector3[][]);
        lineTextsArray = lineTextsArray.concat(lineTextsArrayBaseMaterialX);

        if (BaseComponentHelper.isTopView(model.applicationType)) {
            //BaseMaterial Y dimension lines values
            const {
                baseMaterialNegativeYCoords,
                baseMaterialPositiveYCoords,
                baseMaterialNegativeYCoordsArrow,
                baseMaterialPositiveYArrow,
                lineTextsArrayBaseMaterialY
            } = this.getBaseMaterialYDimensionLinesValuesTopView(model, id, anchorChannelSize, anchorChannelPosition);

            linesCords = linesCords.concat([
                baseMaterialNegativeYCoords,
                baseMaterialPositiveYCoords,
                ...baseMaterialNegativeYCoordsArrow,
                ...baseMaterialPositiveYArrow
            ] as Vector3[][]);

            lineTextsArray = lineTextsArray.concat(lineTextsArrayBaseMaterialY);
        }
        else {
            // Edge distance negative y
            const {
                baseMaterialNegativeYCoords,
                baseMaterialNegativeYArrow,
                lineTextArrayBaseMatrialNegativeY
            } = this.getEdgeDistanceYNegativeDimensionLinesValuesFrontView(model, id, anchorChannelSize, anchorChannelPosition);

            linesCords = linesCords.concat([
                baseMaterialNegativeYCoords,
                baseMaterialNegativeYArrow
            ] as Vector3[][]);

            lineTextsArray = lineTextsArray.concat(lineTextArrayBaseMatrialNegativeY);

            // Z negative
            const {
                baseMaterialNegativeZCoords,
                baseMaterialNegativeZArrow,
                lineTextsArrayBaseMaterialNegativeZ
            } = this.getBaseMaterialZNegativeDimensionLinesValuesFrontView(model, id, anchorChannelSize, anchorChannelPosition);

            linesCords = linesCords.concat([
                baseMaterialNegativeZCoords,
                ...baseMaterialNegativeZArrow
            ] as Vector3[][]);

            lineTextsArray = lineTextsArray.concat(lineTextsArrayBaseMaterialNegativeZ);
        }

        return {
            lines: linesCords,
            textLines: lineTextsArray
        } as IAnchorChannelLinesInfo;
    }

    public static getAnchorChannelShape(model: IModelCW, id: string): Vector3[] {
        const anchorChannel = model.anchoringSystem(model, id).anchorChannel;
        const anchorChannelPolygon = anchorChannel.crossSectionNodes?.map(p => new Vector3(p.x, p.y, 0)) ?? [];
        const heightHalf = anchorChannel.h_ch / 2;
        const widthHalf = anchorChannel.b_ch / 2;

        // Normalize points according to the anchor channel size (width, height)
        return normalizePolygon(anchorChannelPolygon, -widthHalf, -heightHalf, 0, widthHalf, heightHalf, 0);
    }


    public static getAnchorValues(model: IModelCW, id: string): IAnchor {
        const anchorChannel = model.anchoringSystem(model, id).anchorChannel;
        const hnom = anchorChannel.h_ef + anchorChannel.t_h;
        const l_a = hnom - anchorChannel.h_ch;
        const overHeadHeight = l_a - anchorChannel.h_ef + anchorChannel.h_ch;

        let anchorHeight = hnom - anchorChannel.h_ch - overHeadHeight;
        let anchorDiameter = anchorChannel.d_2;

        if (this.isAnchorRebar(model, id)) {
            anchorHeight = anchorChannel.rebarChannel.l;
            anchorDiameter = anchorChannel.rebarChannel.d_s;
        }

        return {
            anchorHeadDiameter: anchorChannel.d_1,
            anchorDiameter: anchorDiameter,
            anchorHeight: anchorHeight,
            anchorHeadHeight: overHeadHeight,
            anchorHeadBodyHeight: anchorChannel.t_h,
            kinkOffset: anchorChannel.rebarChannel.k,
            lengthToKink: anchorChannel.rebarChannel.b,
            kinkAngle: anchorChannel.rebarChannel.kinkAngle,
            kinkRadius: anchorChannel.rebarChannel.d_b
        } as IAnchor;
    }

    private static getConcreteThickness(model: IModelCW) {
        return BaseComponentHelper.isTopView(model.applicationType) ? model.baseMaterial.thickness : 0;
    }

    public static getzOffset(baseMaterial: IBaseMaterial, isTopView: boolean): number {
        const maxChanelShift = (baseMaterial.thickness / 2);
        const yNegative = clamp(baseMaterial.yNegative - (baseMaterial.thickness / 2), -maxChanelShift, maxChanelShift);

        return isTopView ? 0 : yNegative;
    }

    public static getAnchorsPositions(model: IModelCW, id: string): INumberedPosition[] {
        const xPositions: INumberedPosition[] = [];

        const anchorChannel = model.anchoringSystem(model, id).anchorChannel;
        anchorChannel.anchorPositions.forEach(p => {
            xPositions.push({
                id: p.id,
                position: toVector3(p.position)
            });
        });

        return xPositions;
    }

    // For anchor rebars the parameters for anchor studs are undefined
    public static isAnchorRebar(model: IModelCW, id: string): boolean {
        const anchorChannel = model.anchoringSystem(model, id).anchorChannel;
        return anchorChannel.rebarChannel.l != undefined && anchorChannel.rebarChannel.l > 0;
    }


    public static getTextOffset(model: IModelCW, id: string) {
        const anchoringSystem = model.anchoringSystem(model, id);
        const anchorChannel = anchoringSystem.anchorChannel;
        const concreteThickness = this.getConcreteThickness(model);
        const embedment = this.isAnchorRebar(model, id) ? anchorChannel.rebarChannel.l + anchorChannel.h_ch : anchorChannel.h_ef;

        const offsetZ = this.getzOffset(model.baseMaterial, BaseComponentHelper.isTopView(model.applicationType));

        return new Vector3(0, (concreteThickness / 2.0) - embedment - GlModelConstants.baseMaterialConstants.cylinderNumberingOffset, offsetZ);
    }

    /**
     * It takes model calculate convex hull using Graham scan and then it applies
     * the shape to mesh you provided.
     * @param model
     * @param id
     * @returns
     */
    public static getAnchorChannelConvexHull(model: IModelCW, id: string): Vector2[] {
        const anchorChannelLipValues = AnchorChannelLipHelper.getAnchorChannelLipValues(model, id);
        const anchorChannelPolygon = AnchorChannelHelper.getAnchorChannelShape(model, id).map(x => new GrahamScanVector2(x.x, x.y));

        // Close polygon if not closed
        const firstPoint = anchorChannelPolygon[0];
        const lastPoint = anchorChannelPolygon[anchorChannelPolygon.length - 1];
        if (!firstPoint.equals(lastPoint)) {
            anchorChannelPolygon.push(anchorChannelPolygon[0]);
        }

        if (anchorChannelLipValues.lipCharacteristics) {
            anchorChannelPolygon.push(new GrahamScanVector2(anchorChannelLipValues.lipArcStart.x, anchorChannelLipValues.lipArcStart.y), new GrahamScanVector2(anchorChannelLipValues.lipArcEnd.x, anchorChannelLipValues.lipArcEnd.y));
        }

        const convexHull = grahamScanToGetConvexHull(anchorChannelPolygon);

        // Convex hull is not closed so we close it here
        const firstConvex = convexHull[0];
        const lastConvex = convexHull[convexHull.length - 1];
        if (!firstConvex.equals(lastConvex)) {
            convexHull.push(firstConvex);
        }

        return convexHull;
    }


    public static getCirclePath(numberOfPoints: number, startingAngle: number, rotationAngle: number, x: number, y: number, radius: number, clockWiseRotation: boolean) {
        let angle = rotationAngle / (numberOfPoints - 1);
        if (clockWiseRotation) {
            angle = -angle;
        }
        const arcPoints: Vector2[] = [];
        for (let i = 0; i < numberOfPoints; i++) {
            const angleForPoint = angle * i;
            const pointX = x + Math.cos(angleForPoint + startingAngle) * radius;
            const pointY = y + Math.sin(angleForPoint + startingAngle) * radius;
            arcPoints.push(new Vector2(pointX, pointY));
        }
        return arcPoints;
    }
}

