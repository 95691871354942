import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo';
import { Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { BaseComponentCW, IModelCW } from '../../../gl-model/base-component';
import { PropertyMetaData } from '../../../entities/properties';
import { PlateShapes, StandoffTypes } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { AnchorChannelHelper, IAnchorChannel, IAnchorChannelValues } from './anchor-channel';
import { PlateSpacingUpdate, UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { CreateIUnitText2DCreateOptions } from '@profis-engineering/gl-model/components/base-component';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder';
import { GlModelConstants } from '../../../gl-model/gl-model-constants';
import { createArrowXYLine, createArrowYZLine } from '@profis-engineering/gl-model/line-helper';
import { Scene } from '@babylonjs/core/scene';
import { CSG } from '@babylonjs/core/Meshes/csg.js';
import { BoltsHelper } from './bolt-manager';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { UnitText2D } from '../../../gl-model/text/unit-text-2d';
import { BaseComponentHelper } from '../../../gl-model/base-component-helper';
import { CreateCylinderVertexData } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { CreateBoxVertexData } from '@babylonjs/core/Meshes/Builders/boxBuilder';
import { createCSGFromVertexData } from '@profis-engineering/gl-model/csg';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh.js';
import { MaterialCacheCW } from '../../../gl-model/cache/material-cache';
import { ToolTipKeyCW } from '../../../gl-model/tooltip';
import { IBaseMaterial } from './base-material';
import { IBasePlateSystemBaseConstructor } from './base-plate-system';
import { BasePlateSystemHelper } from '../helpers/base-plate-system-helper';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Text2D } from '../../../gl-model/text/text-2d';
import { RomanNumberHelper } from '../../../helpers/roman-number-helper';
import { PolygonMeshBuilder } from '@babylonjs/core/Meshes/polygonMesh';
import earcut from 'earcut';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { cloneVertexData } from '@profis-engineering/gl-model/vertex-data-helper';

export interface IPlateBracket {
    shape: number;

    width: number;
    length: number;
    thickness: number;

    angleBracketHeight: number;

    offsetX: number;
    offsetY: number;
    offsetYNegative: number;

    piBracket: IPiBracket;

    standoff: IPlateStandoff;

    slottedHole: ISlottedHole;

    loadEccentricity: Vector2;
    isSunken: boolean;
}

export interface ISlottedHole {
    enabled: boolean;
    toleranceY: number;
    toleranceZ: number;
    fixedZ: boolean;
    washerHeight: number;
}

export interface IPiBracket {
    flangeOffset: number;
    flangeSpacing: number;
    holeDiameter: number;
    holeFromBoltOffset: number;
    thickness: number;
    widthFromHoleCenterToEdge: number;
    widthFromPlateToHoleCenter: number;
}

export interface IPlateStandoff {
    type: number;
    distance: number;
    width: number;
    offset: number;
}

export interface IPlateBracketValues {
    isPlateBracketVisible: boolean;
    isSelectable?: boolean;

    originPosition?: Vector3;

    plateSize?: Vector3;
    platePosition?: Vector3;

    isAngleBracketVisible?: boolean;
    angleBracketSize?: Vector3;
    angleBracketPosition?: Vector3;

    dimensionLinesInfo?: IPlateBracketLinesInfo;
    texts?: IPlateTexts[];

    standoff?: IStandoffValues;
    slottedHole?: ISlottedHole;
}

interface IStandoffValues {
    type: number;
    distance: number;
    offset: number;
    width: number;
    bracket: IStandoffBracketValues;
    grouting: IStandoffGeometry;
}

interface IStandoffBracketValues {
    anchorChannelSupport: IStandoffGeometry;
    bracketSupport: IStandoffGeometry;
}

interface IStandoffGeometry {
    position: Vector3;
    size: Vector3;
}

interface IPlateBracketDimensionValues {
    plateBracket: IPlateBracket;
    plateSize: Vector3;
    platePosition: Vector3;
    angleBracketSize: Vector3;
    angleBracketPosition: Vector3;
    standoff: IStandoffValues;
}

interface IPlateBracketLinesMeshInfo {
    linesMesh: LinesMesh;
    linesInfo: IPlateBracketLinesInfo;
}

interface IPlateBracketLinesInfo {
    lines: Vector3[][];
    textLines: ILine[];
}

interface ILine {
    uiProperty: UIProperty;
    value: number;
    start: Vector3;
    end: Vector3;
    up: Vector3;
    rotationY: number;
    isSpacing?: boolean;
}

interface IStandoffMeshes {
    slottedMesh?: Mesh;
    mesh?: Mesh;
}

interface IPlateTexts {
    color: Color3;
    position: Vector3;
    text: string;
}

export class PlateBracket extends BaseComponentCW {
    private readonly plateMeshName = 'PlateBracket.Plate';
    private readonly angleBracketMeshName = 'PlateBracket.AngleBracket';
    private readonly lineMeshName = 'PlateBracket.LinesMesh';
    private readonly piBracketName = 'CW.PiBracket';
    private readonly standoffBrackets = 'PlateBracket.Standoff.Brackets';
    private readonly standoffGrouting = 'PlateBracket.Standoff.Grouting';

    private tooltipSelectPlateKey: ToolTipKeyCW = 'SelectPlateSystem';

    private plateMesh?: Mesh;
    private angleBracketMesh?: Mesh;
    private piBrackets: AbstractMesh[] = [];
    private selectableMeshes: (AbstractMesh | undefined)[] = [];
    private standoffMeshes: IStandoffMeshes = {};

    private textCtor: CreateIUnitText2DCreateOptions;
    private unitTexts?: Map<UnitText2D, ILine>;
    private texts: Text2D[] = [];
    private structureLinesMeshInfo?: IPlateBracketLinesMeshInfo;
    private selectedBasePlateProperty?: UnitText2D;


    private transformNode!: TransformNode;

    constructor(ctor: IBasePlateSystemBaseConstructor) {
        super(ctor);

        this.texts = [];
        this.textCtor = {
            unitGroup: UnitGroup.Length
        };

        this.id = ctor.id;
        this.plateSystemId = ctor.plateSystemId;
    }

    public update(): void {
        this.dispose();

        const anchoringSystem = this.model.anchoringSystem(this.model, this.id);

        this.transformNode = this.getGlobalTransformNode(anchoringSystem.geometry.position, anchoringSystem.geometry.rotation);

        const values = PlateBracketHelper.getPlateBracketValues(this.model, this.id, this.plateSystemId);

        if (values == null || !values.isPlateBracketVisible) {
            return;
        }

        // Plate
        this.plateMesh = this.ensureMesh(this.plateMeshName, values.platePosition, values.plateSize, values.isPlateBracketVisible);

        this.standoffMeshes = this.createStandoff(values);

        const holeMesh = this.getBoltHole();

        this.plateMesh = values.slottedHole?.enabled ? this.subtractSlottedHoles(this.plateMesh, values.slottedHole) : this.subtractHoles(this.plateMesh, holeMesh);

        if (this.standoffMeshes.slottedMesh)
            this.standoffMeshes.slottedMesh = this.subtractHoles(this.standoffMeshes.slottedMesh, holeMesh);

        holeMesh.dispose(); // dispose it is clone of cache

        // AngleBracket
        this.angleBracketMesh = this.ensureMesh(this.angleBracketMeshName, values.angleBracketPosition, values.angleBracketSize, values.isAngleBracketVisible ?? false);

        if (this.transformNode != null) {
            this.plateMesh.parent = this.transformNode;
            this.angleBracketMesh.parent = this.transformNode;

            if (this.standoffMeshes.slottedMesh)
                this.standoffMeshes.slottedMesh.parent = this.transformNode;
            if (this.standoffMeshes.mesh)
                this.standoffMeshes.mesh.parent = this.transformNode;
        }

        // Dimensions and texts
        this.ensureDimensionsMesh(values);
        this.ensureTextsMesh(values);

        this.setTransparency(this.plateMesh);
        this.setTransparency(this.angleBracketMesh);
        this.setTransparency(this.standoffMeshes.slottedMesh);
        this.setTransparency(this.standoffMeshes.mesh);

        this.createPiBracket(values.plateSize, values.platePosition);

        this.setSelectableClickEvents(values);
    }

    private ensureMesh(name: string, position: Vector3 | undefined, size: Vector3 | undefined, visible: boolean) {
        const mesh = this.createMesh(name);

        if (position != null)
            mesh.position = position;

        if (size != null)
            this.scaleMesh(mesh, size);

        mesh.setEnabled(visible);
        return mesh;
    }

    private createMesh(meshName: string) {
        meshName += this.id + this.plateSystemId;
        return this.cache.meshCache.create(meshName, () => {
            const mesh = MeshBuilder.CreateBox(meshName, { size: CommonCache.boxSize, width: CommonCache.boxSize, depth: CommonCache.boxSize, updatable: true }, this.scene);
            mesh.material = this.cache.materialCache.steelMaterial;
            mesh.isPickable = true;

            return mesh;
        });
    }

    private subtractHoles(plate: Mesh, hole: Mesh): Mesh {
        const plateCSG = CSG.FromMesh(plate);

        const holeValues = BoltsHelper.getBoltValues(this.model, this.id, this.plateSystemId);

        // Scale cylinder to correct diameter and same height as plate to cut out
        hole.scaling = new Vector3(
            holeValues.holeDiameter / CommonCache.cylinderSize,
            plate.scaling.y,
            holeValues.holeDiameter / CommonCache.cylinderSize
        );

        const slottedCSG = plateCSG.clone();

        // Position the cylinder in the plate and cut it out
        for (const position of holeValues.positions) {
            hole.position = new Vector3(position.position.x, plate.position.y, position.position.z);
            const holeCSG = CSG.FromMesh(hole);
            slottedCSG.subtractInPlace(holeCSG);
        }

        // Recreate the mesh an remove the original from scene
        const slottedMesh = slottedCSG.toMesh(plate.name + 'Slotted', plate.material, this.scene, false);

        this.scene.removeMesh(plate);

        if (this.transformNode != null)
            slottedMesh.parent = this.transformNode;

        return slottedMesh;
    }

    private getSlottedHoleVertexData(length: number, diameter: number): VertexData {
        const deltaTheta = 0.1;
        const flatLength = Math.max(length - diameter, 0.1);
        const flatWidth = diameter;
        const radius = flatWidth / 2;

        const path = [];
        // create top line for the track
        path.push(new Vector3(flatLength / 2, flatWidth / 2), new Vector3(-flatLength / 2, flatWidth / 2));
        // add left side half-circle for the oval
        for (let theta = Math.PI / 2; theta < 3.0 / 2.0 * Math.PI; theta += deltaTheta ) {
            path.push(new Vector3(radius * Math.cos(theta) - flatLength / 2, radius * Math.sin(theta)));
        }
        // add bottom line for the track
        path.push(new Vector3(-flatLength / 2, -flatWidth / 2), new Vector3(flatLength / 2, -flatWidth / 2));
        // add right side half-circle for the oval
        for (let theta = -Math.PI / 2; theta < Math.PI / 2; theta += deltaTheta ) {
            path.push(new Vector3(radius * Math.cos(theta) + flatLength / 2, radius * Math.sin(theta)));
        }

        const vertexData = new PolygonMeshBuilder('CW.SlottedHoleOval', path, undefined, earcut).buildVertexData(CommonCache.boxSize);
        vertexData.transform(Matrix.Translation(
            0,
            CommonCache.boxSize / 2,
            0)
            .multiply(Matrix.RotationY(Math.PI / 2)));

        return vertexData;
    }

    private subtractSlottedHoles(plate: Mesh, values: ISlottedHole): Mesh {
        const plateCSG = CSG.FromMesh(plate);

        const holeValues = BoltsHelper.getBoltValues(this.model, this.id, this.plateSystemId);

        const slotVertexData = this.getSlottedHoleVertexData(Math.max(values.toleranceY, holeValues.holeDiameter), holeValues.holeDiameter);
        slotVertexData.transform(Matrix.Scaling(1, plate.scaling.y, 1));

        const slottedCSG = plateCSG.clone();

        // Position the cylinder in the plate and cut it out
        for (const position of holeValues.positions) {
            const holeVertexData = cloneVertexData(slotVertexData);
            holeVertexData.transform(Matrix.Translation(position.position.x, plate.position.y, position.position.z));

            const holeCSG = createCSGFromVertexData(holeVertexData);
            slottedCSG.subtractInPlace(holeCSG);
        }

        const slottedMesh = slottedCSG.toMesh(plate.name + 'Slotted', plate.material, this.scene, false);

        this.scene.removeMesh(plate);

        if (this.transformNode != null)
            slottedMesh.parent = this.transformNode;

        return slottedMesh;
    }

    private createPiBracket(plateSize?: Vector3, platePosition?: Vector3) {
        const basePlateSystem = this.model.basePlateSystem(this.model, this.plateSystemId, this.id);

        const plateBracket = basePlateSystem.plateBracket;
        if (!plateSize || !platePosition || !plateBracket.piBracket || plateBracket.shape != PlateShapes.PlateDesignRight)
            return;

        const flangeValues = PlateBracketHelper.getFlangeValues(this.model, this.plateSystemId, this.id);
        const holePosition = PlateBracketHelper.getHoleNormalPosition(plateSize, flangeValues);

        const slottedHoleTolerance = plateBracket.slottedHole.enabled ? plateBracket.slottedHole.toleranceZ : flangeValues.holeDiameter;

        const piBracket = createCSGFromVertexData(CreateBoxVertexData({ depth: plateSize.z, width: flangeValues.thickness, height: flangeValues.height + slottedHoleTolerance / 2 }));

        const holeData = plateBracket.slottedHole.enabled ? this.getSlottedHoleVertexData(slottedHoleTolerance, flangeValues.holeDiameter) : CreateCylinderVertexData({ diameter: flangeValues.holeDiameter, height: flangeValues.thickness });
        holeData.transform(Matrix.RotationZ(Math.PI / 2)
        .multiply(Matrix.RotationX(Math.PI / 2))
        .multiply(Matrix.Translation(holePosition.x, holePosition.y - slottedHoleTolerance / 4, holePosition.z)));

        const holeCSG = createCSGFromVertexData(holeData);
        piBracket.subtractInPlace(holeCSG);

        const bracket = piBracket.toMesh(this.piBracketName, this.cache.materialCache.steelMaterial, this.scene);
        bracket.setEnabled(false);
        this.setTransparency(bracket);
        this.piBrackets.push(bracket);

        const positions = PlateBracketHelper.getFlangePositions(platePosition, plateSize, flangeValues);

        for (let i = 0; i < positions.length; i++) {
            const instanced = bracket.createInstance(`${this.piBracketName}.${i}`);
            instanced.position = positions[i];
            instanced.position.y += slottedHoleTolerance / 4;
            instanced.setEnabled(true);
            instanced.parent = this.transformNode;
            this.piBrackets.push(instanced);
        }
    }

    private createStandoff(plateValues: IPlateBracketValues): IStandoffMeshes {
        if (plateValues.standoff == null)
            return {};

        switch (plateValues.standoff.type) {
            case StandoffTypes.Grouting: {
                const standoff = plateValues.standoff.grouting;

                const mesh = this.ensureMesh(this.standoffGrouting, standoff.position, standoff.size, true);
                mesh.material = this.cache.materialCache.standoffGroutingMaterial;

                return { slottedMesh: mesh };
            }
            case StandoffTypes.BracketSupport: {
                const standoff = plateValues.standoff.bracket;
                const acMesh = this.ensureMesh(this.standoffBrackets + 'Ac', standoff.anchorChannelSupport.position, standoff.anchorChannelSupport.size, true);

                const bracketMesh = this.ensureMesh(this.standoffBrackets + 'Bracket', standoff.bracketSupport.position, standoff.bracketSupport.size, true);

                return { slottedMesh: acMesh, mesh: bracketMesh };
            }
        }

        return {};
    }

    private setSelectableClickEvents(plateValues: IPlateBracketValues) {
        if (!plateValues.isSelectable)
            return;

        this.selectableMeshes = [this.plateMesh, this.angleBracketMesh, ...this.piBrackets];

        for (const mesh of this.selectableMeshes) {
            this.setClickableMesh(mesh);
        }

        const textCtor: CreateIUnitText2DCreateOptions = {
            unitGroup: UnitGroup.None
        };

        this.selectedBasePlateProperty ??= this.createUnitText2DForProperty(textCtor, PropertyMetaData.Plate_CW_SelectedSystemId.id);
        this.selectedBasePlateProperty.setValueOnLine(Number(this.plateSystemId), Vector3.Zero(), Vector3.Zero(), Vector3.Zero());
        this.selectedBasePlateProperty.setEnabled(false);
    }

    private setClickableMesh(mesh?: AbstractMesh) {
        if (mesh == null)
            return;

        mesh.onDoubleClick = () => this.onPlateSelected();
        mesh.onPickingInfosChanged = infos => this.onPickingInfosChanged(mesh, infos);
    }

    private onPlateSelected() {
        this.propertyInfo.setPropertyValue(PropertyMetaData.Plate_CW_SelectedSystemId.id, this.plateSystemId);
    }

    private onPickingInfosChanged(mesh: AbstractMesh | undefined, pickingInfos: PickingInfo[]) {
        let isPicked = false;

        for (const info of pickingInfos) {
            isPicked = info.pickedMesh === mesh;
            if (isPicked) break;
        }

        for (const selectableMesh of this.selectableMeshes) {
            if (selectableMesh instanceof Mesh) {
                const meshInstance = selectableMesh as Mesh;

                if (isPicked) {
                    this.setTransparency(meshInstance, true);
                    this.tooltip.showTranslation(this.tooltipSelectPlateKey);
                }
                else {
                    meshInstance.material = null;
                    this.setTransparency(meshInstance);
                    this.tooltip.hideTranslation(this.tooltipSelectPlateKey);
                }
            }
        }

        this.renderNextFrame();
    }

    private getBoltHole() {
        const name = 'CW_Plate_BoltHole';
        return this.cache.meshCache.cylinderMesh.clone(name);
    }

    private scaleMesh(mesh: Mesh, size: Vector3) {
        mesh.scaling = new Vector3(
            size.x / CommonCache.boxSize,
            size.y / CommonCache.boxSize,
            size.z / CommonCache.boxSize
        );
    }

    private setTransparency(mesh?: Mesh, isPicked?: boolean) {
        if (isPicked && mesh) {
            mesh.material = this.cache.materialCache.plateBracketHighlightedMaterial;
        }

        if (mesh?.material) {
            mesh.material.alpha = this.model.visibilityProperties.bracketTransparent ? MaterialCacheCW.transparency : 1;
        }
        else if (mesh) {
            mesh.material = this.model.visibilityProperties.bracketTransparent ? this.cache.materialCache.transparentSteelMaterial : this.cache.materialCache.steelMaterial;
        }
    }

    private ensureDimensionsMesh(values: IPlateBracketValues) {
        this.setUnitTexts(values);
        this.ensureDimensionLinesMesh(values.dimensionLinesInfo);
    }

    private setUnitTexts(values: IPlateBracketValues) {
        this.disposeUnitTexts();

        if (values.dimensionLinesInfo == null)
            return;

        this.unitTexts = new Map();

        for (const textLineData of values.dimensionLinesInfo.textLines) {
            const onValueUpdated: ((source: any, internalValue?: number) => number | void) | undefined = textLineData.isSpacing
                ? (_, value) => this.onPlateSpacingUpdate(value ?? 0, textLineData.uiProperty)
                : undefined;

            const unitText = this.createUnitText2DForProperty(this.textCtor, textLineData.uiProperty as number, onValueUpdated);
            this.unitTexts.set(unitText, textLineData);
        }
    }

    private ensureDimensionLinesMesh(linesInfo?: IPlateBracketLinesInfo) {
        if (linesInfo == null)
            return;

        this.structureLinesMeshInfo = this.createDimensionLinesMesh(linesInfo);
        this.updateDimensionLinesMesh(linesInfo);
        this.sizeLinesText();

        this.structureLinesMeshInfo.linesMesh.setEnabled(true);
    }

    private createDimensionLinesMesh(linesInfo: IPlateBracketLinesInfo) {
        if (this.structureLinesMeshInfo?.linesInfo.lines.flat().length != linesInfo.lines.flat().length) {
            // Disposing dimension lines mesh if number of points is not the same as in previous rendering
            this.disposeDimensionLines();
        }

        return this.cache.meshCache.create(this.lineMeshName, (): IPlateBracketLinesMeshInfo => {
            const linesMesh = this.createLineSystem(linesInfo, this.lineMeshName);

            linesMesh.parent = this.transformNode;

            return {
                linesMesh: linesMesh,
                linesInfo: linesInfo
            };
        });
    }

    private createLineSystem(linesInfo: IPlateBracketLinesInfo, name: string): LinesMesh {
        const linesMesh = CreateLineSystem(name, {
            lines: linesInfo.lines,
            updatable: true
        }, this.scene);

        linesMesh.isPickable = false;
        linesMesh.color = GlModelConstants.lineConstants.defaultLineColor;
        linesMesh.alphaIndex = 3000;

        return linesMesh;
    }

    private updateDimensionLinesMesh(linesInfo: IPlateBracketLinesInfo) {
        if (this.structureLinesMeshInfo == null)
            return;

        CreateLineSystem(undefined as unknown as string, {
            lines: linesInfo.lines,
            instance: this.structureLinesMeshInfo.linesMesh,
            updatable: undefined
        }, undefined as unknown as Scene);

        this.structureLinesMeshInfo.linesInfo = linesInfo;
        this.structureLinesMeshInfo.linesMesh.refreshBoundingInfo();
    }

    private sizeLinesText() {
        this.unitTexts?.forEach((textLine: ILine, unitText: UnitText2D) => {
            if (unitText != null) {
                unitText.setValueOnLine(
                    textLine.value,
                    textLine.start,
                    textLine.end,
                    textLine.up,
                    {
                        rotationY: textLine.rotationY
                    }
                );

                if (this.transformNode != null && unitText.mesh != null) {
                    unitText.mesh.parent = this.transformNode;
                }

                unitText.setEnabled(true);
            }
        });
    }

    private ensureTextsMesh(values: IPlateBracketValues) {
        for (const text of values.texts ?? []) {
            BaseComponentHelper.addNumberTextToMesh(() => this.createText2D(), text.position, text.text, this.texts, this.transformNode, text.color);
        }
    }

    public override getBoundingBoxes(): BoundingInfo[] {
        const boundingBoxes: BoundingInfo[] = [];

        if (this.plateMesh != null && this.plateMesh.isEnabled())
            boundingBoxes.push(this.plateMesh.getBoundingInfo());

        if (this.angleBracketMesh != null && this.angleBracketMesh.isEnabled())
            boundingBoxes.push(this.angleBracketMesh.getBoundingInfo());

        for (const unitText of this.unitTexts?.keys() ?? []) {
            if (unitText?.mesh != null && unitText?.mesh.isEnabled() && unitText?.mesh.isVisible)
                boundingBoxes.push(unitText.mesh.getBoundingInfo());
        }

        return boundingBoxes;
    }

    public override dispose(): void {
        this.hide();
    }

    public override hide() {
        this.plateMesh?.dispose();
        this.standoffMeshes.slottedMesh?.dispose();
        this.angleBracketMesh?.setEnabled(false);
        this.standoffMeshes.mesh?.setEnabled(false);

        for (const mesh of this.piBrackets) {
            mesh.setEnabled(false);
            mesh.dispose();
        }
        this.piBrackets = [];

        this.disposeUnitTexts();
        this.disposeDimensionLines();
        this.disposeTexts();
    }

    private disposeDimensionLines() {
        this.cache.meshCache.clear(this.lineMeshName);

        if (this.structureLinesMeshInfo?.linesMesh != null) {
            this.structureLinesMeshInfo.linesMesh.setEnabled(false);
            this.structureLinesMeshInfo.linesMesh.dispose();
            this.structureLinesMeshInfo.linesMesh = undefined as unknown as LinesMesh;
        }
    }

    public disposeUnitTexts(): void {
        for (const unitText of this.unitTexts?.keys() ?? []) {
            if (unitText != null) {
                unitText.setEnabled(false);
                unitText.dispose();
            }
        }

        this.unitTexts = undefined;

        this.selectedBasePlateProperty?.setEnabled(false);
    }

    private disposeTexts() {
        this.texts.forEach(x => {
            x.setEnabled(false);
            x.dispose();
        });

        this.texts = [];
    }

    private onPlateSpacingUpdate(value: number, uiProperty: UIProperty): number | void {
        const currentPlateSystem = this.model.basePlateSystem(this.model, this.plateSystemId, this.id);
        const spacingUpdate: PlateSpacingUpdate = {
            plateSystemId: this.plateSystemId,
            parentPlateSystemId: currentPlateSystem.parentId,
            value: value
        };

        this.propertyInfo.setPropertyValue(uiProperty, spacingUpdate);

        return undefined; // Returning undefined will not trigger default property updates which for spacing needs to be handled differently
    }
}

export class PlateBracketHelper {

    /**
     *
     *  Visual representation of plate bracket, anchor channel and origin position (marked with X):
     *
     *  |ˇ|
     *  | |
     *  | |
     *  --------------------------  <--- Plate bracket
     *  -------------------X------
     *                   |   |
     *                   |   | <--- Anchor channel
     *                    ˇˇˇ
     *
     * Character X defines origin position and based on this position all other
     * meshes (dimnesions, angle bracket,...) are calculated.
     *
     */
    public static getPlateBracketValues(model: IModelCW, anchoringSystemId: string, basePlateSystemId: string): IPlateBracketValues {
        const anchoringSystem = model.anchoringSystem(model, anchoringSystemId);
        const basePlateSystem = model.basePlateSystem(model, basePlateSystemId, anchoringSystemId);

        const plateBracket = basePlateSystem.plateBracket;
        const anchorChannel = anchoringSystem.anchorChannel;

        const isPlateBracketVisible = this.isPlateVisible(plateBracket.shape);
        const isTopView = BaseComponentHelper.isTopView(model.applicationType);

        if (!isPlateBracketVisible) {
            return {
                isPlateBracketVisible: false
            };
        }

        const isSelectable = this.arePlatesSelectable(model, anchoringSystemId);

        // Origin of plate bracket mesh is in top center of anchor channel and all other objects need to be recalculated by this position
        const anchorChannelValues = AnchorChannelHelper.getAnchorChannelValues(model, anchoringSystemId);
        const originPosition = new Vector3(0, anchorChannelValues.position.y + anchorChannelValues.size.y / 2.0, isTopView ? 0 : anchorChannelValues.position.z);

        // Plate
        const plateValues = this.getPlateValues(plateBracket, anchorChannel);
        const plateSize = plateValues.plateSize;
        const platePosition = originPosition.add(plateValues.platePosition);

        // Angle bracket
        const angleBracketValues = this.getAngleBracketValues(plateBracket, anchorChannel);
        const isAngleBracketVisible = angleBracketValues.isAngleBracketVisible;
        const angleBracketSize = angleBracketValues.angleBracketSize;
        const angleBracketPosition = originPosition.add(angleBracketValues.angleBracketPosition);

        // Standoff
        const standoffDistance = this.getStandoffDistance(plateBracket);
        const standoffBracket = this.getStandoffBracketValues(plateBracket.standoff, plateSize, platePosition, anchorChannelValues);
        const standoffGrouting = this.getStandoffGroutingValues(model.applicationType, plateBracket, model.baseMaterial, anchorChannelValues, plateBracket.standoff, plateSize, platePosition);

        const standoff: IStandoffValues = {
            type: plateBracket.standoff.type,
            distance: standoffDistance,
            offset: plateBracket.standoff.offset,
            width: plateBracket.standoff.width,
            bracket: standoffBracket,
            grouting: standoffGrouting
        };

        const slottedHole = plateBracket.slottedHole;

        // Dimensions
        const dimensionLinesInfo = this.getDimensionLinesValues({
            plateBracket,
            plateSize,
            platePosition,
            angleBracketSize,
            angleBracketPosition,
            standoff
        }, anchorChannelValues, model, anchoringSystemId, basePlateSystemId);

        // Texts
        const texts = this.getTexts(model, anchoringSystemId, basePlateSystemId, plateBracket, platePosition);

        return {
            originPosition,

            isPlateBracketVisible,
            isSelectable,

            plateSize,
            platePosition,

            isAngleBracketVisible,
            angleBracketSize,
            angleBracketPosition,

            dimensionLinesInfo,
            texts,

            standoff,
            slottedHole
        };
    }

    private static getOffsetX(plateBracket: IPlateBracket, anchorChannel: IAnchorChannel) {
        const halfWidth = plateBracket.width / 2.0;
        const halfChannelLength = anchorChannel.channelLength / 2.0;
        return plateBracket.offsetX + halfWidth + (halfChannelLength - halfWidth) - anchorChannel.channelLength;
    }

    private static getOffsetZ(plateBracket: IPlateBracket) {
        return -(plateBracket.length / 2.0) + plateBracket.offsetY;
    }

    public static getPlateValues(plateBracket: IPlateBracket, anchorChannel: IAnchorChannel) {
        const plateSize = new Vector3(plateBracket.width, plateBracket.thickness, plateBracket.length);
        const plateOffsetX = this.getOffsetX(plateBracket, anchorChannel);
        const plateOffsetZ = this.getOffsetZ(plateBracket);

        const standoffDistance = this.getStandoffDistance(plateBracket);

        const positionY = plateBracket.isSunken ? -(plateSize.y / 2.0) + 2 : (plateSize.y / 2.0);

        const platePosition = new Vector3(plateOffsetX, positionY + standoffDistance, plateOffsetZ);
        return {
            plateSize,
            platePosition
        };
    }

    public static getStandoffDistance(plateBracket: IPlateBracket): number {
        if (plateBracket.standoff.type == null || plateBracket.standoff.type == StandoffTypes.None)
            return 0;
        return plateBracket.standoff.distance;
    }

    public static getStandoffGroutingValues(applicationType: number, plateBracket: IPlateBracket, baseMaterial: IBaseMaterial, anchorChannel: IAnchorChannelValues, standoff: IPlateStandoff, plateSize: Vector3, platePosition: Vector3): IStandoffGeometry {
        const negativeOverlap = Math.min(plateBracket.offsetYNegative, baseMaterial.yNegative);
        const positiveOverlap = Math.min(plateBracket.offsetY, BaseComponentHelper.isTopView(applicationType) ? baseMaterial.yPositive : baseMaterial.thickness - baseMaterial.yNegative);

        const size = plateSize.clone();
        size.y = standoff.distance;
        size.z = negativeOverlap + positiveOverlap;

        const position = platePosition.clone();
        position.y -= (standoff.distance / 2) + (plateSize.y / 2);
        position.z = anchorChannel.position.z - (negativeOverlap - positiveOverlap) / 2;

        return {
            position,
            size
        };
    }

    public static getStandoffBracketValues(standoff: IPlateStandoff, plateSize: Vector3, platePosition: Vector3, anchorChannel: IAnchorChannelValues): IStandoffBracketValues {
        const acSupportSize = plateSize.clone();
        acSupportSize.y = standoff.distance;
        acSupportSize.z = anchorChannel.size.z;

        const acSupportPosition = platePosition.clone();
        acSupportPosition.y -= (standoff.distance / 2) + (plateSize.y / 2);
        acSupportPosition.z = anchorChannel.position.z;

        const bracketSupportSize = acSupportSize.clone();
        bracketSupportSize.z = standoff.width;

        const bracketSupportPosition = acSupportPosition.clone();
        bracketSupportPosition.z -= standoff.offset + bracketSupportSize.z / 2;

        return {
            anchorChannelSupport: {
                position: acSupportPosition,
                size: acSupportSize
            },
            bracketSupport: {
                position: bracketSupportPosition,
                size: bracketSupportSize
            }
        };
    }

    public static getAngleBracketValues(plateBracket: IPlateBracket, anchorChannel: IAnchorChannel) {
        const shapeType = plateBracket.shape;
        const plateThickness = plateBracket.thickness;

        let angleBracketSize = new Vector3();
        let angleBracketPosition = new Vector3();

        const isAngleBracketVisible = this.isAngleBracketVisible(shapeType);

        if (isAngleBracketVisible) {
            angleBracketSize = new Vector3(plateBracket.width, plateBracket.angleBracketHeight, plateThickness);
            const { plateSize, platePosition } = this.getPlateValues(plateBracket, anchorChannel);
            const angleBracketOffset = plateBracket.angleBracketHeight / 2 + plateThickness / 2;

            const x = platePosition.x;
            const y = platePosition.y + (shapeType == PlateShapes.AngleBracketTop ? angleBracketOffset : -angleBracketOffset);
            const z = -plateSize.z + plateBracket.offsetY + (plateThickness / 2.0);

            angleBracketPosition = new Vector3(x, y, z);
        }

        return {
            isAngleBracketVisible,
            angleBracketSize,
            angleBracketPosition
        };
    }

    private static getDimensionLinesValues(
        plateBracketDimensions: IPlateBracketDimensionValues,
        anchorChannelValues: IAnchorChannelValues,
        model: IModelCW,
        anchoringSystemId: string,
        basePlateSystemId: string
    ): IPlateBracketLinesInfo {
        const lineTextsArray: ILine[] = [];
        const plateBracketLinesInfo = {
            lines: [],
            textLines: lineTextsArray
        } as IPlateBracketLinesInfo;

        const { angleBracketPosition, angleBracketSize, plateBracket, platePosition, plateSize, standoff } = plateBracketDimensions;
        const { lineDimensionOffsetXZ } = GlModelConstants.lineConstants;
        const lineOffset = 2 * lineDimensionOffsetXZ;
        const angle = lineOffset / Math.sqrt(2);

        const isPlateVisible = this.isPlateVisible(plateBracket.shape);
        const isAnchoringSystemSelected = model.isAnchoringSystemSelected(anchoringSystemId);
        const isBasePlateSystemSelected = BasePlateSystemHelper.isBasePlateSystemWithAnchoringSystemSelected(model, basePlateSystemId, anchoringSystemId);

        // Add plate specific lines (dimension, offset-y, standoffs,...)
        const displayDimensions = model.visibilityProperties.bracketDimensionsVisible && isBasePlateSystemSelected;
        PlateBracketHelper.addWidthLine(isPlateVisible, displayDimensions, platePosition, plateSize, lineDimensionOffsetXZ, plateBracket, plateBracketLinesInfo);
        PlateBracketHelper.addLengthLine(isPlateVisible, displayDimensions, platePosition, plateSize, lineOffset, plateBracket, plateBracketLinesInfo);
        PlateBracketHelper.addThicknessLine(isPlateVisible, displayDimensions, platePosition, plateSize, angle, plateBracket, plateBracketLinesInfo);
        PlateBracketHelper.addHeightLine(plateBracket, displayDimensions, angleBracketPosition, angleBracketSize, angle, plateBracketLinesInfo);

        const displayOffsetYDimensions = model.visibilityProperties.bracketOffsetVisible && isBasePlateSystemSelected;
        PlateBracketHelper.addOffsetYLine(plateBracket, displayOffsetYDimensions, platePosition, plateSize, lineOffset, plateBracketLinesInfo);
        PlateBracketHelper.addOffsetYNegativeLine(plateBracket, displayOffsetYDimensions, platePosition, plateSize, lineOffset, plateBracketLinesInfo);

        PlateBracketHelper.addPiBracketLines(displayDimensions, model, anchoringSystemId, basePlateSystemId, plateBracketDimensions, lineOffset, plateBracketLinesInfo);

        PlateBracketHelper.addStandoffBracketSupportLines(displayDimensions, standoff, lineOffset / 2, plateBracketLinesInfo);

        // Add offset-x line for first plate
        const currentPlateSystem = model.basePlateSystem(model, basePlateSystemId, anchoringSystemId);
        const displayOffsetX = !model.isPostInstallAnchorProduct && model.visibilityProperties.bracketDimensionsVisible && currentPlateSystem.parentId == null && isAnchoringSystemSelected;
        PlateBracketHelper.addOffsetXLine(isPlateVisible, displayOffsetX, anchorChannelValues, plateBracketDimensions, plateBracketLinesInfo);

        // Add spacing lines for other plates
        const displaySpacingDimensions = isPlateVisible && (model.visibilityProperties.bracketDimensionsVisible ?? false) && currentPlateSystem.parentId != null && isAnchoringSystemSelected;
        const parentPlate = displaySpacingDimensions
            ? model.basePlateSystem(model, currentPlateSystem.parentId ?? '', anchoringSystemId)?.plateBracket
            : undefined;
        PlateBracketHelper.addSpacingLine(displaySpacingDimensions, plateBracketDimensions, parentPlate, anchorChannelValues, plateBracketLinesInfo);

        return plateBracketLinesInfo;
    }

    private static addStandoffBracketSupportLines(displayDimensions: boolean | undefined, standoff: IStandoffValues, lineOffset: number, platebracketLinesInfo: IPlateBracketLinesInfo) {
        if (!this.areStandoffDimensionsVisible(standoff.type) || !displayDimensions) {
            return;
        }

        if (standoff.type == StandoffTypes.BracketSupport) {
            const acSupport = standoff.bracket.anchorChannelSupport;
            const bracketSupport = standoff.bracket.bracketSupport;

            const acSupportOrigin = acSupport.position.add(new Vector3(acSupport.size.x / 2, acSupport.size.y / 2));
            const bracketSupportOrigin = bracketSupport.position.add(new Vector3(bracketSupport.size.x / 2, bracketSupport.size.y / 2, bracketSupport.size.z / 2));

            const offsetLineCoords = [
                acSupportOrigin,
                acSupportOrigin.add(new Vector3(lineOffset)),
                bracketSupportOrigin.add(new Vector3(lineOffset)),
                bracketSupportOrigin
            ];

            const offsetLine: ILine = {
                uiProperty: UIProperty.Plate_CW_Standoff_Offset,
                value: standoff.offset,
                start: offsetLineCoords[1],
                end: offsetLineCoords[2],
                rotationY: Math.PI,
                up: new Vector3(1, 0, 0)
            };

            platebracketLinesInfo.lines.push(offsetLineCoords, ...createArrowYZLine(offsetLine.start, offsetLine.end));
            platebracketLinesInfo.textLines.push(offsetLine);

            const widthLineCoords = [
                bracketSupportOrigin,
                bracketSupportOrigin.add(new Vector3(lineOffset)),
                bracketSupportOrigin.add(new Vector3(lineOffset, 0, -bracketSupport.size.z)),
                bracketSupportOrigin.add(new Vector3(0, 0, -bracketSupport.size.z)),
            ];

            const widthLine: ILine = {
                uiProperty: UIProperty.Plate_CW_Standoff_Width,
                value: standoff.width,
                start: widthLineCoords[1],
                end: widthLineCoords[2],
                rotationY: Math.PI,
                up: new Vector3(1, 0, 0)
            };

            platebracketLinesInfo.lines.push(widthLineCoords, ...createArrowYZLine(widthLine.start, widthLine.end));
            platebracketLinesInfo.textLines.push(widthLine);
        }

        const supportGeometry = standoff.type == StandoffTypes.Grouting ? standoff.grouting : standoff.bracket.anchorChannelSupport;

        const heightLineCoords = [
            supportGeometry.position.add(new Vector3(supportGeometry.size.x / 2, -supportGeometry.size.y / 2, supportGeometry.size.z / 2)),
            supportGeometry.position.add(new Vector3(supportGeometry.size.x / 2 + lineOffset, -supportGeometry.size.y / 2, supportGeometry.size.z / 2)),
            supportGeometry.position.add(new Vector3(supportGeometry.size.x / 2 + lineOffset, supportGeometry.size.y / 2, supportGeometry.size.z / 2)),
            supportGeometry.position.add(new Vector3(supportGeometry.size.x / 2, supportGeometry.size.y / 2, supportGeometry.size.z / 2))
        ];

        const heightLine: ILine = {
            uiProperty: UIProperty.Plate_CW_Standoff_Distance,
            value: standoff.distance,
            start: heightLineCoords[1],
            end: heightLineCoords[2],
            rotationY: Math.PI,
            up: new Vector3(1, 0, 0)
        };

        platebracketLinesInfo.lines.push(heightLineCoords, ...createArrowYZLine(heightLine.start, heightLine.end));
        platebracketLinesInfo.textLines.push(heightLine);
    }

    private static addPiBracketLines(displayDimensions: boolean | undefined, model: IModelCW, anchoringSystemId: string, basePlateSystemId: string, plateBracketDimensions: IPlateBracketDimensionValues, lineOffset: number, plateBracketLinesInfo: IPlateBracketLinesInfo) {
        const { plateBracket, platePosition, plateSize } = plateBracketDimensions;
        if (this.isPiBracketVisible(plateBracket.shape) && displayDimensions) {
            const boltValues = BoltsHelper.getBoltValues(model, anchoringSystemId, basePlateSystemId);
            const boltPosition = boltValues.positions[0].position;
            const holeOffset = plateBracket.piBracket?.holeFromBoltOffset ?? 0;
            const holeHeightOffset = plateBracket.piBracket?.widthFromPlateToHoleCenter ?? 0;
            boltPosition.y += boltValues.length;
            const boltOffset = 5;
            boltPosition.y -= boltOffset;
            const flangeValues = this.getFlangeValues(model, basePlateSystemId, anchoringSystemId);
            const flangeOffset = this.getFlangeOffset(platePosition, plateSize, flangeValues.offset);
            const halfLineOffset = lineOffset / 2;

            const holeHeightPosition = platePosition.y + plateSize.y / 2 + holeHeightOffset;

            const holeLineCoords = [
                new Vector3(flangeOffset, holeHeightPosition, boltPosition.z),
                new Vector3(flangeOffset - halfLineOffset, holeHeightPosition, boltPosition.z),
                new Vector3(flangeOffset - halfLineOffset, holeHeightPosition, boltPosition.z - holeOffset),
                new Vector3(flangeOffset, holeHeightPosition, boltPosition.z - holeOffset)
            ];

            // This is needed because line arrows would turn wrong way if start > end
            let start: Vector3, end: Vector3;
            if (holeLineCoords[1] >= holeLineCoords[2]) {
                start = holeLineCoords[1];
                end = holeLineCoords[2];
            } else {
                start = holeLineCoords[2];
                end = holeLineCoords[1];
            }

            const holeLine: ILine = {
                uiProperty: UIProperty.Plate_CW_PiBracket_HoleFromBoltOffset,
                value: plateBracket.piBracket.holeFromBoltOffset ?? 0,
                start,
                end,
                rotationY: Math.PI,
                up: new Vector3(-1, 1, 0)
            };

            plateBracketLinesInfo.textLines.push(holeLine);
            plateBracketLinesInfo.lines.push(holeLineCoords, ...createArrowYZLine(holeLine.start, holeLine.end));

            const plateTop = platePosition.y + plateSize.y / 2;
            const plateFront = platePosition.z - plateSize.z / 2;
            const plateLeft = platePosition.x - plateSize.x / 2;
            const flangeOffsetLineCoords = [
                new Vector3(plateLeft, plateTop, plateFront),
                new Vector3(plateLeft, plateTop, plateFront - halfLineOffset),
                new Vector3(flangeOffset, plateTop, plateFront - halfLineOffset),
                new Vector3(flangeOffset, plateTop, plateFront),
            ];

            const flangeOffsetLine: ILine = {
                uiProperty: UIProperty.Plate_CW_PiBracket_FlangeOffset,
                value: plateBracket.piBracket?.flangeOffset ?? 0,
                start: flangeOffsetLineCoords[1],
                end: flangeOffsetLineCoords[2],
                rotationY: Math.PI,
                up: new Vector3(-1, 1, 0)
            };

            plateBracketLinesInfo.textLines.push(flangeOffsetLine);
            plateBracketLinesInfo.lines.push(flangeOffsetLineCoords, ...createArrowYZLine(flangeOffsetLine.start, flangeOffsetLine.end));

            const flangeSpacing = plateBracket.piBracket?.flangeSpacing ?? 0;
            const flangeSpacingCoords = [
                new Vector3(flangeOffset, plateTop, plateFront),
                new Vector3(flangeOffset, plateTop, plateFront - halfLineOffset),
                new Vector3(flangeOffset + flangeSpacing, plateTop, plateFront - halfLineOffset),
                new Vector3(flangeOffset + flangeSpacing, plateTop, plateFront)
            ];

            const flangeSpacingLine: ILine = {
                uiProperty: UIProperty.Plate_CW_PiBracket_FlangeSpacing,
                value: plateBracket.piBracket?.flangeSpacing ?? 0,
                start: flangeSpacingCoords[1],
                end: flangeSpacingCoords[2],
                rotationY: Math.PI,
                up: new Vector3(-1, 1, 0)
            };

            plateBracketLinesInfo.textLines.push(flangeSpacingLine);
            plateBracketLinesInfo.lines.push(flangeSpacingCoords, ...createArrowYZLine(flangeSpacingLine.start, flangeSpacingLine.end));

            const piBracketThickness = (plateBracket.piBracket?.thickness ?? 0) / 2;
            const holeHeightCoords = [
                new Vector3(plateLeft, plateTop, plateFront),
                new Vector3(plateLeft, holeHeightPosition, plateFront),
                new Vector3(flangeOffset - piBracketThickness, holeHeightPosition, plateFront),
            ];

            const holeHeightLine: ILine = {
                uiProperty: UIProperty.Plate_CW_PiBracket_WidthFromPlateToHoleCenter,
                value: plateBracket.piBracket?.widthFromPlateToHoleCenter ?? 0,
                start: holeHeightCoords[0],
                end: holeHeightCoords[1],
                rotationY: Math.PI,
                up: new Vector3(-1, 1, 0)
            };

            plateBracketLinesInfo.textLines.push(holeHeightLine);
            plateBracketLinesInfo.lines.push(holeHeightCoords, ...createArrowYZLine(holeHeightLine.start, holeHeightLine.end));
        }
    }

    private static addOffsetYNegativeLine(plateBracket: IPlateBracket, displayOffsetDimensions: boolean | undefined, platePosition: Vector3, plateSize: Vector3, lineOffset: number, plateBracketLinesInfo: IPlateBracketLinesInfo) {
        if (this.isOffsetYVisible(plateBracket.shape) && displayOffsetDimensions) {
            const position = platePosition;
            const size = plateSize;
            const yLineOffset = lineOffset / 2.0;

            const yOffsetLineCoordsNegative = [
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z + size.z / 2 - plateBracket.offsetY),
                new Vector3(position.x - size.x / 2 - yLineOffset, position.y + size.y / 2, position.z + size.z / 2 - plateBracket.offsetY),
                new Vector3(position.x - size.x / 2 - yLineOffset, position.y + size.y / 2, position.z + size.z / 2 - plateBracket.length),
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z + size.z / 2 - plateBracket.length)
            ];

            const yOffsetLine: ILine = {
                uiProperty: UIProperty.Plate_CW_OffsetYNegative,
                value: plateBracket.length - plateBracket.offsetY,
                start: yOffsetLineCoordsNegative[1],
                end: yOffsetLineCoordsNegative[2],
                rotationY: Math.PI,
                up: new Vector3(-1, 1, 0)
            };

            plateBracketLinesInfo.textLines.push(yOffsetLine);
            plateBracketLinesInfo.lines.push(yOffsetLineCoordsNegative, ...createArrowYZLine(yOffsetLine.start, yOffsetLine.end));
        }
    }

    private static addOffsetYLine(plateBracket: IPlateBracket, displayOffsetDimensions: boolean | undefined, platePosition: Vector3, plateSize: Vector3, lineOffset: number, plateBracketLinesInfo: IPlateBracketLinesInfo) {
        if (this.isOffsetYVisible(plateBracket.shape) && displayOffsetDimensions) {
            const position = platePosition;
            const size = plateSize;
            const yLineOffset = lineOffset / 2.0;

            const yOffsetLineCoords = [
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z + size.z / 2),
                new Vector3(position.x - size.x / 2 - yLineOffset, position.y + size.y / 2, position.z + size.z / 2),
                new Vector3(position.x - size.x / 2 - yLineOffset, position.y + size.y / 2, position.z + size.z / 2 - plateBracket.offsetY),
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z + size.z / 2 - plateBracket.offsetY)
            ];

            const yOffsetLine: ILine = {
                uiProperty: UIProperty.Plate_CW_OffsetY,
                value: plateBracket.offsetY,
                start: yOffsetLineCoords[1],
                end: yOffsetLineCoords[2],
                rotationY: Math.PI,
                up: new Vector3(-1, 1, 0)
            };

            plateBracketLinesInfo.textLines.push(yOffsetLine);
            plateBracketLinesInfo.lines.push(yOffsetLineCoords, ...createArrowYZLine(yOffsetLine.start, yOffsetLine.end));
        }
    }

    private static addOffsetXLine(isPlateVisible: boolean, displayDimensions: boolean | undefined, anchorChannelValues: IAnchorChannelValues, plateBracketDimensions: IPlateBracketDimensionValues, plateBracketLinesInfo: IPlateBracketLinesInfo) {
        const { plateBracket, platePosition } = plateBracketDimensions;
        if (isPlateVisible && displayDimensions) {
            const { anchorChannelOffsetZ } = GlModelConstants.lineConstants;
            const lineOffsetX = anchorChannelOffsetZ;

            const xOffsetLineCoords = [
                new Vector3(anchorChannelValues.position.x - anchorChannelValues.size.x, anchorChannelValues.position.y + anchorChannelValues.size.y / 2, anchorChannelValues.position.z - anchorChannelValues.size.z / 2),
                new Vector3(anchorChannelValues.position.x - anchorChannelValues.size.x, anchorChannelValues.position.y + anchorChannelValues.size.y / 2, platePosition.z - plateBracket.length / 2 - lineOffsetX),
                new Vector3(platePosition.x, anchorChannelValues.position.y + anchorChannelValues.size.y / 2, platePosition.z - plateBracket.length / 2 - lineOffsetX),
                new Vector3(platePosition.x, anchorChannelValues.position.y + anchorChannelValues.size.y / 2, anchorChannelValues.position.z - anchorChannelValues.size.z / 2)
            ];

            const widthLine: ILine = {
                uiProperty: UIProperty.Plate_CW_Spacing,
                value: plateBracket.offsetX,
                start: xOffsetLineCoords[1],
                end: xOffsetLineCoords[2],
                rotationY: Math.PI,
                up: new Vector3(0, 1, 1),
                isSpacing: true
            };

            plateBracketLinesInfo.textLines.push(widthLine);
            plateBracketLinesInfo.lines.push(xOffsetLineCoords, ...createArrowXYLine(widthLine.start, widthLine.end));
        }
    }

    private static addSpacingLine(
        isSpacingLineVisible: boolean,
        currentPlateBracketValues: IPlateBracketDimensionValues,
        parentPlateBracket: IPlateBracket | undefined,
        anchorChannelValues: IAnchorChannelValues,
        plateBracketLinesInfo: IPlateBracketLinesInfo
    ): void {
        if (!isSpacingLineVisible || parentPlateBracket == null)
            return;

        const currentPlateBracket = currentPlateBracketValues.plateBracket;
        const currentPlatePosition = currentPlateBracketValues.platePosition;

        const spacing = PlateBracketHelper.calculateSpacingBetweenPlates(currentPlateBracket, parentPlateBracket);

        // Y coordinate is always the same
        const elevation = anchorChannelValues.position.y + anchorChannelValues.size.y / 2;

        // calculate left and right X coordinates which should be on the end of previous plate and on the start on current plate
        const rightX = currentPlatePosition.x - currentPlateBracket.width / 2;
        const leftX = rightX - spacing;

        // Z coordinates always starts on the edge of the plates
        const parentPlateOffsetZ = this.getOffsetZ(parentPlateBracket);
        const parentPlateZ = anchorChannelValues.position.z + parentPlateOffsetZ;
        const startZLeft = parentPlateZ - parentPlateBracket.length / 2;
        const startZRight = currentPlatePosition.z - currentPlateBracket.length / 2;
        const zMin = Math.min(startZLeft, startZRight);
        const endZ = zMin - GlModelConstants.lineConstants.anchorChannelOffsetZ;

        const spacingLineCoords = [
            new Vector3(leftX, elevation, startZLeft),
            new Vector3(leftX, elevation, endZ),
            new Vector3(rightX, elevation, endZ),
            new Vector3(rightX, elevation, startZRight)
        ];

        const widthLine: ILine = {
            uiProperty: UIProperty.Plate_CW_Spacing,
            value: spacing,
            start: spacingLineCoords[1],
            end: spacingLineCoords[2],
            rotationY: Math.PI,
            up: new Vector3(0, 1, 1),
            isSpacing: true
        };

        plateBracketLinesInfo.textLines.push(widthLine);
        plateBracketLinesInfo.lines.push(spacingLineCoords, ...createArrowXYLine(widthLine.start, widthLine.end));
    }

    private static addHeightLine(plateBracket: IPlateBracket, displayDimensions: boolean | undefined, position: Vector3, size: Vector3, angle: number, plateBracketLinesInfo: IPlateBracketLinesInfo) {
        if (this.isAngleBracketVisible(plateBracket.shape) && displayDimensions) {
            const heightLineCoords = [
                new Vector3(position.x - size.x / 2, position.y - size.y / 2, position.z - size.z / 2),
                new Vector3(position.x - size.x / 2 - angle, position.y - size.y / 2, position.z - size.z / 2 - angle),
                new Vector3(position.x - size.x / 2 - angle, position.y + size.y / 2, position.z - size.z / 2 - angle),
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z - size.z / 2)
            ];

            const heightLine: ILine = {
                uiProperty: UIProperty.Plate_CW_AngleBracketHeight,
                value: plateBracket.angleBracketHeight,
                start: heightLineCoords[1],
                end: heightLineCoords[2],
                rotationY: Math.PI,
                up: new Vector3(-1, 0, -1)
            };

            plateBracketLinesInfo.textLines.push(heightLine);
            plateBracketLinesInfo.lines.push(heightLineCoords, ...createArrowXYLine(heightLine.start, heightLine.end));
        }
    }

    private static addThicknessLine(isPlateVisible: boolean, displayDimensions: boolean | undefined, position: Vector3, size: Vector3, angle: number, plateBracket: IPlateBracket, plateBracketLinesInfo: IPlateBracketLinesInfo) {
        if (isPlateVisible && displayDimensions) {
            const thicknessLineCoords = [
                new Vector3(position.x + size.x / 2, position.y - size.y / 2, position.z + size.z / 2),
                new Vector3(position.x + size.x / 2 + angle, position.y - size.y / 2, position.z + size.z / 2 + angle),
                new Vector3(position.x + size.x / 2 + angle, position.y + size.y / 2, position.z + size.z / 2 + angle),
                new Vector3(position.x + size.x / 2, position.y + size.y / 2, position.z + size.z / 2)
            ];

            const thicknessLine: ILine = {
                uiProperty: UIProperty.Plate_CW_Thickness,
                value: plateBracket.thickness,
                start: thicknessLineCoords[1],
                end: thicknessLineCoords[2],
                rotationY: -Math.PI,
                up: new Vector3(1, 0.2, 1)
            };

            plateBracketLinesInfo.textLines.push(thicknessLine);
            plateBracketLinesInfo.lines.push(thicknessLineCoords, ...createArrowXYLine(thicknessLine.start, thicknessLine.end));
        }
    }

    private static addLengthLine(isPlateVisible: boolean, displayDimensions: boolean | undefined, position: Vector3, size: Vector3, lineOffset: number, plateBracket: IPlateBracket, plateBracketLinesInfo: IPlateBracketLinesInfo) {
        if (isPlateVisible && displayDimensions) {
            const lengthLineCoords = [
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z + size.z / 2),
                new Vector3(position.x - size.x / 2 - lineOffset, position.y + size.y / 2, position.z + size.z / 2),
                new Vector3(position.x - size.x / 2 - lineOffset, position.y + size.y / 2, position.z - size.z / 2),
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z - size.z / 2)
            ];

            const lengthLine: ILine = {
                uiProperty: UIProperty.Plate_CW_Length,
                value: plateBracket.length,
                start: lengthLineCoords[1],
                end: lengthLineCoords[2],
                rotationY: Math.PI,
                up: new Vector3(-1, 1, 0)
            };

            plateBracketLinesInfo.textLines.push(lengthLine);
            plateBracketLinesInfo.lines.push(lengthLineCoords, ...createArrowYZLine(lengthLine.start, lengthLine.end));
        }
    }

    private static addWidthLine(isPlateVisible: boolean, displayDimensions: boolean | undefined, position: Vector3, size: Vector3, lineDimensionOffsetXZ: number, plateBracket: IPlateBracket, plateBracketLinesInfo: IPlateBracketLinesInfo) {
        if (isPlateVisible && displayDimensions) {
            const widthLineCoords = [
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z + size.z / 2),
                new Vector3(position.x - size.x / 2, position.y + size.y / 2, position.z + size.z / 2 + 3 * lineDimensionOffsetXZ),
                new Vector3(position.x + size.x / 2, position.y + size.y / 2, position.z + size.z / 2 + 3 * lineDimensionOffsetXZ),
                new Vector3(position.x + size.x / 2, position.y + size.y / 2, position.z + size.z / 2),
            ];

            const widthLine: ILine = {
                uiProperty: UIProperty.Plate_CW_Width,
                value: plateBracket.width,
                start: widthLineCoords[1],
                end: widthLineCoords[2],
                rotationY: Math.PI,
                up: new Vector3(0, 1, 1)
            };

            plateBracketLinesInfo.textLines.push(widthLine);
            plateBracketLinesInfo.lines.push(widthLineCoords, ...createArrowXYLine(widthLine.start, widthLine.end));
        }
    }

    private static getTexts(model: IModelCW, anchoringSystemId: string, basePlateSystemId: string, plateBracket: IPlateBracket, platePosition: Vector3): IPlateTexts[] {
        const texts: IPlateTexts[] = [];

        const plateNumberText = this.createPlateNumberText(model, anchoringSystemId, basePlateSystemId, plateBracket, platePosition);
        if (plateNumberText != null)
            texts.push(plateNumberText);

        return texts;
    }

    private static createPlateNumberText(model: IModelCW, anchoringSystemId: string, basePlateSystemId: string, plateBracket: IPlateBracket, platePosition: Vector3): IPlateTexts | undefined {
        if (!this.arePlatesSelectable(model, anchoringSystemId))
            return undefined;

        const plateNumberColor = model.isBasePlateSystemSelected(basePlateSystemId, anchoringSystemId)
            ? MaterialCacheCW.boltColor
            : Color3.Black();

        const plateNumberOffset = new Vector3(0, plateBracket.thickness + 10, -plateBracket.length / 4);

        let plateText = basePlateSystemId;
        const parsedId = parseInt(basePlateSystemId);
        if (!isNaN(parsedId)) {
            plateText = RomanNumberHelper.arabicToRoman(parsedId);
        }

        return {
            color: plateNumberColor,
            position: platePosition.add(plateNumberOffset),
            text: plateText
        };
    }

    public static arePlatesSelectable(model: IModelCW, anchoringSystemId: string) {
        return model.isAnchoringSystemSelected(anchoringSystemId) && model.anchoringSystem(model, anchoringSystemId).basePlateSystems.length > 1;
    }

    private static areStandoffDimensionsVisible(standoffType: StandoffTypes) {
        return standoffType != StandoffTypes.None;
    }

    private static isPlateVisible(shape: PlateShapes): boolean {
        return shape != PlateShapes.NoPlate && shape != PlateShapes.None;
    }

    private static isAngleBracketVisible(shape: PlateShapes): boolean {
        return shape == PlateShapes.AngleBracketTop || shape == PlateShapes.AngleBracketBottom;
    }

    private static isOffsetYVisible(shape: PlateShapes): boolean {
        return this.isPlateVisible(shape);
    }

    private static isPiBracketVisible(shape: PlateShapes): boolean {
        return shape == PlateShapes.PlateDesignRight;
    }

    public static getFlangeOffset(platePosition: Vector3, plateSize: Vector3, flangeOffset: number): number {
        return platePosition.x - plateSize.x / 2 + flangeOffset;
    }

    public static getHoleNormalPosition(plateSize: Vector3, flangeValues: IFlangeValues): Vector3 {
        const zPos = plateSize.z / 2 - flangeValues.plateBracketOffsetY - flangeValues.holeBoltOffset;
        return new Vector3(0, flangeValues.plateToHoleOffset - flangeValues.height / 2, zPos);
    }

    public static getFlangePositions(platePosition: Vector3, plateSize: Vector3, flangeValues: IFlangeValues): Vector3[] {
        const flangeOffset = this.getFlangeOffset(platePosition, plateSize, flangeValues.offset);
        return [
            new Vector3(flangeOffset, platePosition.y + plateSize.y / 2 + flangeValues.size.y / 2, platePosition.z),
            new Vector3(flangeOffset + flangeValues.spacing, platePosition.y + plateSize.y / 2 + flangeValues.size.y / 2, platePosition.z)
        ];
    }

    public static getHolePosition(platePosition: Vector3, plateSize: Vector3, flangeValues: IFlangeValues): Vector3 {
        const flangePosition = this.getFlangePositions(platePosition, plateSize, flangeValues)[0] ?? new Vector3();
        const holeNormalPosition = this.getHoleNormalPosition(plateSize, flangeValues);

        return new Vector3(flangePosition.x + holeNormalPosition.x, flangePosition.y + holeNormalPosition.y, flangePosition.z + holeNormalPosition.z);
    }

    public static getFlangeValues(model: IModelCW, basePlateSytemId: string, anchoringSystemId: string): IFlangeValues {
        const basePlateSystem = model.basePlateSystem(model, basePlateSytemId, anchoringSystemId);

        const plateBracket = basePlateSystem.plateBracket;

        const plateToHoleOffset = plateBracket.piBracket?.widthFromPlateToHoleCenter ?? 0;
        const holeToEdgeConstant = plateBracket.piBracket?.widthFromHoleCenterToEdge ?? 0;
        const height = plateToHoleOffset + holeToEdgeConstant;
        const thickness = plateBracket.piBracket?.thickness ?? 0;
        const holeDiameter = plateBracket.piBracket?.holeDiameter ?? 0;
        const offset = plateBracket.piBracket?.flangeOffset ?? 0;
        const spacing = plateBracket.piBracket?.flangeSpacing ?? 0;
        const plateSize = new Vector3(plateBracket.width, plateBracket.thickness, plateBracket.length);
        const size = new Vector3(plateSize.z, height, thickness);
        const holeBoltOffset = plateBracket.piBracket?.holeFromBoltOffset ?? 0;
        const plateBracketOffsetY = plateBracket.offsetY;

        return {
            height,
            thickness,
            size,
            offset,
            spacing,
            plateToHoleOffset,
            holeToEdgeConstant,
            holeDiameter,
            holeBoltOffset,
            plateBracketOffsetY
        } as IFlangeValues;
    }

    public static calculateSpacingBetweenPlates(plateBracket: IPlateBracket, parentPlateBracket: IPlateBracket): number {
        return plateBracket.offsetX - parentPlateBracket.offsetX - plateBracket.width / 2.0 - parentPlateBracket.width / 2.0;
    }
}

export interface IFlangeValues {
    height: number;
    thickness: number;
    size: Vector3;
    offset: number;
    spacing: number;
    plateToHoleOffset: number;
    holeToEdgeConstant: number;
    holeDiameter: number;
    holeBoltOffset: number;
    plateBracketOffsetY: number;
}
