import { CodeList, ICodeListTextDeps } from '../code-list';
import { EmbedmentOptimizationTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class EmbedmentOptimizationType extends CodeList {
    constructor(codeList: EmbedmentOptimizationTypeEntity) {
        super(codeList);
    }

    public static fromService(codeList: EmbedmentOptimizationTypeEntity) {
        return new EmbedmentOptimizationType(CodeList.baseFromService('EmbedmentOptimizationTypeEntity', codeList));
    }

    public override cloneDeep(): EmbedmentOptimizationType {
        const codeList = super.cloneDeep() as EmbedmentOptimizationType;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
}
