import { CodeList, ICodeListTextDeps } from '../code-list';
import { AnchorSizeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class AnchorSize extends CodeList {
    public fastenerFamilyId?: number;
    public diameter?: number;

    constructor(codeList: AnchorSizeEntity) {
        super(codeList);

        if (codeList != null) {
            this.fastenerFamilyId = codeList.fastenerFamilyId;
            this.diameter = codeList.diameter;
        }
    }

    public static fromService(codeList: AnchorSizeEntity) {
        return new AnchorSize(CodeList.baseFromService('AnchorSizeEntity', codeList, {
            fastenerFamilyId: codeList.fastenerFamilyId,
            diameter: codeList.diameter
        } as AnchorSizeEntity));
    }

    public override cloneDeep(): AnchorSize {
        const codeList = super.cloneDeep() as AnchorSize;
        codeList.fastenerFamilyId = this.fastenerFamilyId;
        codeList.diameter = this.diameter;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
}
