import { Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UtilizationLoadForceEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.DesignReportData';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import { CollapsingControls } from '../main/main.component';

interface IForceLoad {
    id: string;
    N: string;
    Qx: string;
    Qy: string;
}

@Component({
    templateUrl: './load-forces.component.html',
    styleUrls: ['../utilizations/utilizations-base.component.scss', './load-forces.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadForcesComponent implements OnInit {

    @Input()
    public title!: string;

    @Input()
    public tableIdTitle!: string;

    @Input()
    public collapsingControls!: CollapsingControls;

    private _forceList!: UtilizationLoadForceEntity[];
    public get forceList() { return this._forceList; }
    @Input() set forceList(value: UtilizationLoadForceEntity[]) {
        this._forceList = value;
        this.fillBoltArray();
    }

    public collapsed = false;
    public tableElement!: string;
    public loadForcesElement!: string;
    public forces: IForceLoad[] = [];

    private fullHeight = 0;
    private maxHeightFactor = 30;
    private parentElement!: string;
    private resizerTriggerPosition!: { y: number };
    private resizerOverlayElement!: HTMLElement;
    private continueResizingFn!: (event: MouseEvent) => void;
    private endResizingFn!: (event: MouseEvent) => void;
    private resizeBoltLoadsElement!: string;

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private user: UserService,
        private unitService: UnitService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot, 'sprite-anchor-loads');

        this.resizeBoltLoadsElement = '#resize-load-forces';
        this.loadForcesElement = '.load-forces';
        this.tableElement = '.load-forces-table';
        this.parentElement = '.main-content-right-util';

        this.continueResizingFn = this.continueResizing.bind(this);
        this.endResizingFn = this.endResizing.bind(this);

        this.resizerOverlayElement = document.createElement('div');
        this.resizerOverlayElement.setAttribute('id', 'resize-drag-handle-horizontal');

        this.collapsed = this.userSettings.isSectionCollapsed(this.collapsingControls);
        setTimeout(() => this.setMinimumHeight());
    }

    //#region GET

    public get showLoadForces() {
        return this.forces.length > 0;
    }

    public get defaultUnit() {
        const unitGroup = UnitGroup.Force;
        return this.unitService.getDefaultUnit(unitGroup, this.user.design);
    }

    public get showResizer() {
        const numOfForces = this.forces.length;
        if (numOfForces <= 1) {
            return false;
        }
        else if (this.collapsed) {
            return false;
        }
        else {
            return true;
        }
    }

    private get maxHeight() {
        const numOfForces = this.forces.length;
        const maxHeightOffset = 34;

        return numOfForces * this.maxHeightFactor + maxHeightOffset;
    }

    private get minHeight() {
        const minHeight = 61;

        return minHeight;
    }

    //#endregion

    //#region PUBLIC

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public getLocalizedTextAndForceUnit(key: string) {
        const unitGroup = UnitGroup.Force;
        return `${this.localizationService.getString(key)} [${this.unitService.formatUnit(this.unitService.getDefaultUnit(unitGroup))}]`;
    }

    public trackLoadForceById: TrackByFunction<IForceLoad> = (_: number, loadForce: IForceLoad) => loadForce.id;

    public setMinimumHeight() {
        let minimumHeight = 0;
        if (!this.collapsed) {
            minimumHeight = this.minHeight;
        }

        // if boltLoadsElement is even shown
        if (this.showLoadForces) {
            const loadForcesElement = this.elementRef.nativeElement.shadowRoot?.querySelector<HTMLElement>(this.loadForcesElement);
            if (loadForcesElement != null) {
                loadForcesElement.style.minHeight = minimumHeight + 'px';
            }
        }
    }

    public beginResizing(event: MouseEvent) {
        event.preventDefault();
        const mouse = this.calculateMouseInsideElement(event, document.querySelector(this.parentElement) as HTMLElement);
        const position = document.querySelector(this.parentElement)?.getBoundingClientRect();
        this.resizerTriggerPosition = mouse;

        this.resizerOverlayElement.style.top = '-1px';
        this.resizerOverlayElement.style.left = 0 + 'px';
        this.resizerOverlayElement.style.width = position?.width + 'px';

        document.querySelectorAll(this.parentElement).forEach(e => {
            e.append(this.resizerOverlayElement);
        });

        document.addEventListener('mousemove', this.continueResizingFn, false);
        document.addEventListener('mouseup', this.endResizingFn, false);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(this.collapsingControls, collapsed);

        setTimeout(() => {
            this.setHeight();
            this.setMinimumHeight();
        });
    }

    //#endregion

    //#region PRIVATE

    private fillBoltArray(): IForceLoad[] {
        const unitGroup = UnitGroup.Force;
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup as number);
        const precision = this.unitService.getPrecision(defaultUnit);

        if (this._forceList != null) {
            this.forces = this._forceList.map((force: UtilizationLoadForceEntity) => {
                return {
                    id: force.id,
                    N: this.convertForveValue(force.n_a_Ed, unitGroup, precision),
                    Qx: this.convertForveValue(force.v_a_Ed_x, unitGroup, precision),
                    Qy: this.convertForveValue(force.v_a_Ed_y, unitGroup, precision),
                };
            });
        }

        this.setHeight();
        return this.forces;
    }

    private setHeight() {
        const boltLoadsElt = this.elementRef.nativeElement.shadowRoot?.querySelector(this.loadForcesElement) as HTMLElement;
        const heightOffset = 64;
        const adjustOffset = 1;

        if (this.collapsed && boltLoadsElt != null) {
            boltLoadsElt.style.height = '';
            boltLoadsElt.style.overflowY = '';
        }
        else {
            const numOfBolts = this.forces.length;
            const tableElt = this.elementRef.nativeElement.shadowRoot?.querySelector(this.tableElement) as HTMLElement;

            if (numOfBolts == 1) {
                this.fullHeight = numOfBolts * this.maxHeightFactor + heightOffset;
                this.setHeightLessThenOrFourItems(tableElt, boltLoadsElt);
            }

            else if (numOfBolts <= 4) {
                this.fullHeight = numOfBolts * this.maxHeightFactor + heightOffset + adjustOffset;
                this.setHeightLessThenOrFourItems(tableElt, boltLoadsElt);
            }

            else {
                this.fullHeight = 4 * this.maxHeightFactor + heightOffset + adjustOffset;
                this.setHeightMoreThenFourItems(tableElt, boltLoadsElt);
            }

            if (boltLoadsElt != null) {
                boltLoadsElt.style.height = this.fullHeight + 'px';
            }
        }
    }

    private continueResizing(event: MouseEvent) {
        event.preventDefault();

        const tableElt = this.elementRef.nativeElement.shadowRoot?.querySelector(this.tableElement) as HTMLElement;
        if (tableElt && tableElt.offsetParent) {
            this.resizerOverlayElement.style.top = event.clientY + (tableElt.offsetParent.scrollTop - 50) + 'px'; // -50 used to adjust the overlay element
        }
    }

    private endResizing(event: MouseEvent) {
        event.preventDefault();

        // adjust the overlay element
        const overlayElAdjustment = 31;
        const mouse = this.calculateMouseInsideElement(event, document.querySelector(this.parentElement) as HTMLElement);

        const diffMouse = {
            y: mouse.y - this.resizerTriggerPosition.y - overlayElAdjustment
        };

        this.resizeDiv(diffMouse.y);

        this.resizerOverlayElement.remove();

        document.removeEventListener('mousemove', this.continueResizingFn, false);
        document.removeEventListener('mouseup', this.endResizingFn, false);
    }

    private resizeDiv(sizeDifference: number) {
        if (sizeDifference == 0) {
            return;
        }

        const heightOffset = 32;
        const resizeElement = this.elementRef.nativeElement.shadowRoot?.querySelector(this.resizeBoltLoadsElement) as HTMLElement;
        const maxHeight = this.maxHeight;
        const minHeight = this.minHeight;
        const elementHeight = resizeElement.offsetHeight;
        const height = elementHeight + sizeDifference;

        const tableElement = this.elementRef.nativeElement.shadowRoot?.querySelector(this.tableElement) as HTMLElement;
        if (height > maxHeight) {
            tableElement.style.height = maxHeight + 'px';
            resizeElement.style.overflowY = '';
            tableElement.style.overflowY = '';
            resizeElement.style.height = maxHeight + heightOffset + 'px';
        }
        else if (height < minHeight) {
            tableElement.style.height = minHeight + 'px';
            resizeElement.style.height = minHeight + heightOffset + 'px';
            tableElement.style.overflowY = 'auto';
        }
        else {
            tableElement.style.height = height + 'px';
            resizeElement.style.height = height + heightOffset + 'px';
            tableElement.style.overflowY = 'auto';
        }
    }

    private calculateMouseInsideElement(event: MouseEvent, element: HTMLElement) {
        return {
            y: event.pageY - (element.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop))
        };
    }

    private setHeightLessThenOrFourItems(tableElt: HTMLElement, boltLoadsElt: HTMLElement) {
        const tableHeightAdjustment = 33;

        if (tableElt != null) {
            tableElt.style.height = this.fullHeight - tableHeightAdjustment + 'px';
        }

        if (boltLoadsElt != null) {
            boltLoadsElt.style.overflowY = '';
        }
    }

    private setHeightMoreThenFourItems(tableElt: HTMLElement, boltLoadsElt: HTMLElement) {
        const tableHeightOffset = 31;

        const tableHeight = 4 * this.maxHeightFactor + tableHeightOffset;  // default height with 4 bolts
        if (tableElt != null) {
            tableElt.style.height = tableHeight + 'px';
            tableElt.style.overflowY = 'scroll';
        }

        if (boltLoadsElt != null) {
            boltLoadsElt.style.width = 'auto';
        }
    }

    private convertForveValue(forceValue: number, unitGroup: UnitGroup, precision: number): string {
        const defaultValue = this.unitService.convertInternalValueToDefaultUnitValue(forceValue, unitGroup as number);
        return this.unitService.formatNumber(defaultValue.value, precision);
    }

    //#endregion

}
