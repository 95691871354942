import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { IBasePlateConstructor, BasePlateSystem } from './base-plate-system';
import { SystemComponentCW } from './system-component';

export type IBasePlateSystemManagerConstructor = IBasePlateConstructor;

export class BasePlateSystemManager extends SystemComponentCW {
    private components: BasePlateSystem[] = [];

    private ctor: IBasePlateConstructor;

    constructor(ctor: IBasePlateSystemManagerConstructor) {
        super(ctor);

        this.ctor = ctor;
        this.id = ctor.id;

        this.update();
    }

    override update(): void {
        const anchoringSystem = this.model.anchoringSystem(this.model, this.id);
        const basePlateSystmes = anchoringSystem.basePlateSystems;

        for (let i = this.components.length; i < basePlateSystmes.length; i++) {
            this.components.push(new BasePlateSystem({ ...this.ctor, plateSystemId: basePlateSystmes[i].id, id: anchoringSystem.id }));
        }

        for (let i = 0; i < basePlateSystmes.length; i++) {
            this.components[i].id = anchoringSystem.id;
            this.components[i].plateSystemId = basePlateSystmes[i].id;
        }

        this.components.forEach(c => c.update());
    }

    public override getBoundingBoxes(): BoundingInfo[] {
        this.ensureNotDisposed();

        const boundingInfo: BoundingInfo[] = [];

        for (const component of this.components) {
            boundingInfo.push(...component.getBoundingBoxes());
        }

        return boundingInfo;
    }

    override hide(): void {
        this.components.forEach(x => x.hide());
    }

    public getComponents() {
        return this.components;
    }
}