import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import { NgForm, Validators } from '@angular/forms';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { UserService } from '../../services/user.service';
import { CodeListService } from '../../services/code-list.service';
import { CalculationService } from '../../services/calculation.service';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { PropertyMetaData } from '../../entities/properties';
import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import tension1Image from '../../../images/anchor-reinforcement/AnchorReinforcementForTension.png';
import tension2Image from '../../../images/anchor-reinforcement/AnchorReinforcementForTension2.png';
import shearImage from '../../../images/anchor-reinforcement/AnchorReinforcementForPerpendicularShear.png';
import longitudinalImage from '../../../images/anchor-reinforcement/AnchorReinforcementForParallelShear.png';
import { CodelistHelper } from '../../helpers/codelist-helper';
import { UnitService } from '../../services/unit.service';
import { NumberService } from '../../services/number.service';
import { DesignCodeList } from '../../entities/enums/design-code-list';
import { DesignStandardHelper } from '../../helpers/design-standard-helper';
import { ComponentsHelper } from '../../helpers/components-helper';
import { LoggerService } from '../../services/logger.service';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

@Component({
    templateUrl: './select-anchor-reinforcement.component.html',
    styleUrls: ['./select-anchor-reinforcement.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectAnchorReinforcementComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;
    public pendingSave = false;

    public yieldStrength!: NumericTextBoxProps;
    public concreteCover!: NumericTextBoxProps;

    public tensionCheckbox!: CheckboxButtonProps<boolean>;
    public tensionDiameter!: DropdownProps;
    public tensionBondCondition!: DropdownProps<number>;
    public tensionConcreteCover!: NumericTextBoxProps;

    public shearCheckbox!: CheckboxButtonProps<boolean>;
    public shearDiameter!: DropdownProps;
    public shearBondCondition!: DropdownProps<number>;
    public shearConcreteCover!: NumericTextBoxProps;

    public longitudinalCheckbox!: CheckboxButtonProps<boolean>;
    public longitudinalDiameter!: DropdownProps<number>;
    public longitudinalBondCondition!: DropdownProps<number>;
    public longitudinalConcreteCover!: NumericTextBoxProps;

    public propertyMetaData = PropertyMetaData;

    public images = {
        tension1Image,
        tension2Image,
        shearImage,
        longitudinalImage
    };

    public requiredValidator = Validators.required;

    constructor(
        private localizationService: LocalizationService,
        private userService: UserService,
        private codeList: CodeListService,
        private unitService: UnitService,
        private logger: LoggerService,
        private numberService: NumberService,

        private calculationService: CalculationService
    ) { }

    public get isEnStandard(): boolean {
        return DesignStandardHelper.isEnBasedDesignStandard(this.design?.designStandard?.id);
    }

    public get designStandardTranslationPostfix(): string {
        if (this.isEnStandard)
            return 'ETAG.';

        return '';
    }

    public isPropertyVisible(uiProperty: number): boolean {
        return !this.design.properties.get(uiProperty).hidden;
    }

    public isPropertyDisabled(uiProperty: number): boolean {
        return this.design.properties.get(uiProperty).disabled;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance?.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        let valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorReinforcement_CW_YieldStrength.id);
        this.yieldStrength = {
            id: 'cw-anchor-reinforcement-yield-strength',
            title: this.translate('Agito.Hilti.CW.AnchorReinforcement.YieldStrength'),
            unit: this.design.unitStress,
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design?.anchorReinforcementYieldStrength
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorReinforcement_CW_ConcreteCover.id);
        this.concreteCover = {
            id: 'cw-anchor-reinforcement-concrete-cover',
            title: this.translate('Agito.Hilti.CW.AnchorReinforcement.ConcreteCover'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design?.anchorReinforcementConcreteCover
        };

        this.tensionCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.design?.anchorReinforcementTensionEnabled,
            itemId: 'cw-anchor-reinforcement-tension-checkbox',
            itemText: this.translate(`Agito.Hilti.CW.AnchorReinforcement.${this.designStandardTranslationPostfix}Tension.Title`),
            itemDescription: this.translate('Agito.Hilti.CW.AnchorReinforcement.Tension.Description'),
            disabled: this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Tension_IsEnabled.id)
        });

        this.tensionDiameter = ComponentsHelper.createDropdownComponent(
            'cw-anchor-reinforcement-tension-diameter-dropdown',
            this.translate(`Agito.Hilti.CW.AnchorReinforcement.${this.designStandardTranslationPostfix}Tension.Diameter`),
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                CodelistHelper.getCodeListItems(this.design, DesignCodeList.GenericRebars, PropertyMetaData.AnchorReinforcement_CW_Tension_RebarId.id),
                this.isEnStandard ? DesignCodeList.GenericRebars : undefined),
            this.design?.anchorReinforcementTensionDiameter?.id,
            this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Tension_RebarId.id)
        );

        this.tensionBondCondition = ComponentsHelper.createDropdownComponent(
            'cw-anchor-reinforcement-tension-bond-condition-dropdown',
            this.translate('Agito.Hilti.CW.AnchorReinforcement.Tension.BondCondition'),
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                CodelistHelper.getCodeListItems(this.design, DesignCodeList.BondConditions, PropertyMetaData.AnchorReinforcement_CW_Tension_BondCondition.id),
                DesignCodeList.BondConditions),
            this.design?.anchorReinforcementTensionBondCondition?.id,
            this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Tension_BondCondition.id)
        );

        valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorReinforcement_CW_Tension_ConcreteCover.id);
        this.tensionConcreteCover = {
            id: 'cw-anchor-reinforcement-tension-concrete-cover',
            title: this.translate('Agito.Hilti.CW.AnchorReinforcement.Tension.ConcreteCover'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design?.anchorReinforcementTensionConcreteCover
        };

        this.shearCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.design?.anchorReinforcementShearEnabled,
            itemId: 'cw-anchor-reinforcement-shear-checkbox',
            itemText: this.translate(`Agito.Hilti.CW.AnchorReinforcement.${this.designStandardTranslationPostfix}Shear.Title`),
            itemDescription: this.translate('Agito.Hilti.CW.AnchorReinforcement.Shear.Description'),
            disabled: this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Shear_IsEnabled.id)
        });

        this.shearDiameter = ComponentsHelper.createDropdownComponent(
            'cw-anchor-reinforcement-shear-diameter-dropdown',
            this.translate(`Agito.Hilti.CW.AnchorReinforcement.${this.designStandardTranslationPostfix}Shear.Diameter`),
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                CodelistHelper.getCodeListItems(this.design, DesignCodeList.GenericRebars, PropertyMetaData.AnchorReinforcement_CW_Shear_RebarId.id),
                this.isEnStandard ? DesignCodeList.GenericRebars : undefined),
            this.design?.anchorReinforcementShearDiameter?.id,
            this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Shear_RebarId.id)
        );

        this.shearBondCondition = ComponentsHelper.createDropdownComponent(
            'cw-anchor-reinforcement-shear-bond-condition-dropdown',
            this.translate('Agito.Hilti.CW.AnchorReinforcement.Shear.BondCondition'),
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                CodelistHelper.getCodeListItems(this.design, DesignCodeList.BondConditions, PropertyMetaData.AnchorReinforcement_CW_Shear_BondCondition.id),
                DesignCodeList.BondConditions),
            this.design?.anchorReinforcementShearBondCondition?.id,
            this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Shear_BondCondition.id)
        );

        valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorReinforcement_CW_Shear_ConcreteCover.id);
        this.shearConcreteCover = {
            id: 'cw-anchor-reinforcement-shear-concrete-cover',
            title: this.translate('Agito.Hilti.CW.AnchorReinforcement.Shear.ConcreteCover'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design?.anchorReinforcementShearConcreteCover
        };

        this.longitudinalCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.design?.anchorReinforcementLongitudinalEnabled,
            itemId: 'cw-anchor-reinforcement-longitudinal-checkbox',
            itemText: this.translate(`Agito.Hilti.CW.AnchorReinforcement.${this.designStandardTranslationPostfix}Longitudinal.Title`),
            itemDescription: this.translate('Agito.Hilti.CW.AnchorReinforcement.Longitudinal.Description'),
            disabled: this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Longitudinal_IsEnabled.id)
        });

        this.longitudinalDiameter = ComponentsHelper.createDropdownComponent(
            'cw-anchor-reinforcement-longitudinal-diameter-dropdown',
            this.translate(`Agito.Hilti.CW.AnchorReinforcement.${this.designStandardTranslationPostfix}Longitudinal.Diameter`),
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                CodelistHelper.getCodeListItems(this.design, DesignCodeList.GenericRebars, PropertyMetaData.AnchorReinforcement_CW_Longitudinal_RebarId.id),
                this.isEnStandard ? DesignCodeList.GenericRebars : undefined),
            this.design?.anchorReinforcementLongitudinalDiameter?.id,
            this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Longitudinal_RebarId.id)
        );

        this.longitudinalBondCondition = ComponentsHelper.createDropdownComponent(
            'cw-anchor-reinforcement-longitudinal-bond-condition-dropdown',
            this.translate('Agito.Hilti.CW.AnchorReinforcement.Longitudinal.BondCondition'),
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                CodelistHelper.getCodeListItems(this.design, DesignCodeList.BondConditions, PropertyMetaData.AnchorReinforcement_CW_Longitudinal_BondCondition.id),
                DesignCodeList.BondConditions),
            this.design?.anchorReinforcementLongitudinalBondCondition?.id,
            this.isPropertyDisabled(PropertyMetaData.AnchorReinforcement_CW_Longitudinal_BondCondition.id)
        );

        valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorReinforcement_CW_Longitudinal_ConcreteCover.id);
        this.longitudinalConcreteCover = {
            id: 'cw-anchor-reinforcement-longitudinal-concrete-cover',
            title: this.translate('Agito.Hilti.CW.AnchorReinforcement.Longitudinal.ConcreteCover'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design?.anchorReinforcementLongitudinalConcreteCover
        };
    }

    public get title() {
        let regionTitleKey = 'Agito.Hilti.CW.Navigation.TabReinforcement.AnchorReinforcement';
        if (DesignStandardHelper.isEnBasedDesignStandard(this.design?.designStandard?.id))
            regionTitleKey = 'Agito.Hilti.CW.Navigation.TabReinforcement.SupplementaryReinforcement';

        return this.translate(regionTitleKey);
    }

    public get formValid() {
        return true;
    }

    public async save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        await this.calculationService.calculateAsync(this.design, d => {
            d.model[PropertyMetaData.AnchorReinforcement_CW_YieldStrength.id] = this.yieldStrength.value as number;
            d.model[PropertyMetaData.AnchorReinforcement_CW_ConcreteCover.id] = this.concreteCover.value as number;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Tension_IsEnabled.id] = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.tensionCheckbox) ?? false;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Tension_RebarId.id] = this.tensionDiameter.selectedValue as number;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Tension_BondCondition.id] = this.tensionBondCondition.selectedValue as number;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Tension_ConcreteCover.id] = this.tensionConcreteCover.value as number;

            d.model[PropertyMetaData.AnchorReinforcement_CW_Shear_IsEnabled.id] = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.shearCheckbox) ?? false;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Shear_RebarId.id] = this.shearDiameter.selectedValue as number;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Shear_BondCondition.id] = this.shearBondCondition.selectedValue as number;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Shear_ConcreteCover.id] = this.shearConcreteCover.value as number;

            d.model[PropertyMetaData.AnchorReinforcement_CW_Longitudinal_IsEnabled.id] = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.longitudinalCheckbox) ?? false;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Longitudinal_RebarId.id] = this.longitudinalDiameter.selectedValue as number;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Longitudinal_BondCondition.id] = this.longitudinalBondCondition.selectedValue as number;
            d.model[PropertyMetaData.AnchorReinforcement_CW_Longitudinal_ConcreteCover.id] = this.longitudinalConcreteCover.value as number;
        })
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err: any) => {
                if (err instanceof Error) {
                    this.logger.log(err.message, LogType.error);
                }

                this.submitted = false;
            });

        this.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    private get design() {
        return this.userService.design;
    }

    private getPropertyValueRange(propertyId: number) {
        const baseplateSize = this.codeList.getPropertyValue(propertyId, this.design.region?.id ?? SpecialRegion.Default);

        const retVal: IValueRange = {
            min: baseplateSize.minValue,
            max: baseplateSize.maxValue
        };
        return retVal;
    }
}
