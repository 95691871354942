import { IModelCW } from '../../../gl-model/base-component';

export class BasePlateSystemHelper {

    /**
     * Returns if base plate system and anchoring system are currently selected systems in design
     * @param model The model
     * @param basePlateSystemId The base plate system id
     * @param anchoringSystemId The anchoring system id
     */
    public static isBasePlateSystemWithAnchoringSystemSelected(model: IModelCW, basePlateSystemId: string, anchoringSystemId: string) {
        return model.isAnchoringSystemSelected(anchoringSystemId) && model.isBasePlateSystemSelected(basePlateSystemId, anchoringSystemId);
    }

    public static getFirstBasePlateSystem(model: IModelCW) {
        return model.basePlateSystem(model, '1', '1');
    }
}
