import { CodeList, ICodeListConstructor } from '../code-list';
import { DesignStandardMethodRegionEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export interface IDesignStandardMethodRegionCodeList extends ICodeListConstructor {
    designStandardId: number;
    designMethodGroupId: number;
    newStatus: boolean;
    regionId: number;
}

export class DesignStandardMethodRegionCodeList extends CodeList {
    public designStandardId!: number;
    public designMethodGroupId!: number;
    public newStatus!: boolean;
    public regionId!: number;

    constructor(codeList: IDesignStandardMethodRegionCodeList) {
        super(codeList);

        if (codeList != null) {
            this.designStandardId = codeList.designStandardId;
            this.designMethodGroupId = codeList.designMethodGroupId;
            this.newStatus = codeList.newStatus;
            this.regionId = codeList.regionId;
        }
    }

    public static fromService(codeList: DesignStandardMethodRegionEntity): DesignStandardMethodRegionCodeList {
        return new DesignStandardMethodRegionCodeList(CodeList.baseFromService<IDesignStandardMethodRegionCodeList>('DesignStandardMethodRegionEntity', codeList, {
            designStandardId: codeList.designStandardId,
            regionId: codeList.regionId,
            designMethodGroupId: codeList.designMethodGroupId,
            newStatus: codeList.newStatus
        }));
    }

    public override cloneDeep() {
        const codeList = super.cloneDeep() as DesignStandardMethodRegionCodeList;
        codeList.designStandardId = this.designStandardId;
        codeList.regionId = this.regionId;
        codeList.designMethodGroupId = this.designMethodGroupId;
        codeList.newStatus = this.newStatus;

        return codeList;
    }
}
