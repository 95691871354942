import { CodeList } from '../code-list';
import { ConcreteMixesEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class ConcreteMixes extends CodeList {

    constructor(codeList: any) {
        super(codeList);
    }

    public static fromService(codeList: ConcreteMixesEntity) {
        return new ConcreteMixes(CodeList.baseFromService('ConcreteMixesEntity', codeList));
    }

    public override cloneDeep(): ConcreteMixes {
        return super.cloneDeep() as ConcreteMixes;
    }
}
