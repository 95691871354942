import { Injectable } from '@angular/core';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { CommonCodeList, CommonCodeListServiceInjected } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { Unit } from '../entities/code-lists/unit';

@Injectable({
    providedIn: 'root'
})
export class CommonCodeListService extends CommonCodeListServiceInjected {

    public getRegionById(regionId: number): CommonRegion | undefined {
        const regionCodeList = this.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(r => r.id == regionId);
    }

    public getUnitFromCodeList(codeListType: CommonCodeList, val: string | number | undefined | null): Unit | undefined {
        let valNum: number;

        if (typeof val === 'number') {
            if (val == null || val == 0) {
                return undefined;
            }
            valNum = val;
        }
        else if (typeof val === 'string') {
            if (val == null || val == '' || val == '0') {
                return undefined;
            }
            valNum = parseInt(val, 10);
        }

        const unitsCodeList = this.commonCodeLists[codeListType] as Unit[];
        return unitsCodeList.find((unit) => unit.id == valNum);
    }
}