<div class="section data"
    [class.no-margin]="collapseRegion.MethodAndApprovals">
    <p class="title-strike title" [class.no-margin]="collapseRegion.MethodAndApprovals">
        {{translate("Agito.Hilti.CW.CalculationAndApprovals")}}
        <pe-collapse
            class="section-collapse"
            [container]="'section-methods-approvals'"
            [collapsed]="collapseRegion.MethodAndApprovals"
            (collapsedChange)="collapseRegion.MethodAndApprovals = $any($event).detail">
        </pe-collapse>
    </p>

    <cw-method-inputs
        [collapsed]="collapseRegion.MethodAndApprovals"
        [data]="methodInputsData"
        [selectedRegionId]="selectedRegionId"
        [disabled]="submitted">
    </cw-method-inputs>
</div>

<div class="section units"
    [class.no-margin]="collapseRegion.UnitsAndParams">
    <p class="title-strike title" [class.no-margin]="collapseRegion.UnitsAndParams">
        {{translate("Agito.Hilti.CW.ApplicationSettings.Units")}}

        <pe-collapse
            class="section-collapse"
            [container]="'section-units-params'"
            [collapsed]="collapseRegion.UnitsAndParams"
            (collapsedChange)="collapseRegion.UnitsAndParams = $any($event).detail">
        </pe-collapse>
    </p>

    <cw-unit-inputs
        [collapsed]="collapseRegion.UnitsAndParams"
        [data]="unitInputsData"
        [selectedRegionId]="selectedRegionId"
        [selectedDesignStandardId]="methodInputsData.designStandard ?? 0"
        [disabled]="submitted">
    </cw-unit-inputs>
</div>
