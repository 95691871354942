import earcut from 'earcut';
import clamp from 'lodash-es/clamp';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { Vector3, Matrix } from '@babylonjs/core/Maths/math.vector';
import { CreateLineSystem } from '@babylonjs/core/Meshes/Builders/linesBuilder';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';
import { Scene } from '@babylonjs/core/scene';
import { CSG } from '@babylonjs/core/Meshes/csg';
import { PolygonMeshBuilder } from '@babylonjs/core/Meshes/polygonMesh';
import { Path2 } from '@babylonjs/core/Maths/math.path.js';
import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { createArrowXYLine } from '@profis-engineering/gl-model/line-helper';
import { CreateIUnitText2DCreateOptions } from '@profis-engineering/gl-model/components/base-component';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { PickingInfo } from '@babylonjs/core/Collisions/pickingInfo.js';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { BaseComponentCW, IBaseComponentConstructorCW, IModelCW } from '../../../gl-model/base-component';
import { GlModelConstants } from '../../../gl-model/gl-model-constants';
import { AnchorChannelHelper, IAnchorChannel } from './anchor-channel';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { cloneVertexData, flipFaces } from '@profis-engineering/gl-model/vertex-data-helper';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { CreateRibbonVertexData } from '@babylonjs/core/Meshes/Builders/ribbonBuilder';
import { AnchorChannelLipHelper } from './anchor-channel-lip';
import isEqual from 'lodash-es/isEqual';
import { UnitText2D } from '../../../gl-model/text/unit-text-2d';
import { Text2D } from '../../../gl-model/text/text-2d.js';
import { ToolTipKeyCW } from '../../../gl-model/tooltip';
import { BaseComponentHelper } from '../../../gl-model/base-component-helper';
import { ApplicationTypes, StandoffTypes } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { IPlateBracket } from './plate-bracket';
import { BasePlateSystemHelper } from '../helpers/base-plate-system-helper';
import { IRebarPlate } from './rebar-plate';
import { Constants } from '../../../entities/constants';

export interface IBaseMaterial {
    thickness: number;

    xNegative: number;
    yNegative: number;
    xPositive: number;
    yPositive: number;

    zNegative: number;

    xNegativeInfinity: boolean;
    yNegativeInfinity: boolean;
    xPositiveInfinity: boolean;
    yPositiveInfinity: boolean;

    zNegativeInfinity: boolean;
}

export interface IInfinityEdges {
    xPositive: boolean;
    xNegative: boolean;
    yPositive: boolean;
    yNegative: boolean;
    zNegative: boolean;
}

export interface BaseMaterialEdgeDistances {
    xNegative: number;
    xPositive: number;
    yNegative: number;
    yPositive: number;

    zNegative: number;
}

export interface IBaseMaterialValues {
    size: Vector3;
    position: Vector3;
    uiProperties: IMemberUiProperties;
    dimensionLinesInfo: IRectangularLinesInfo;
    infinityEdges: IInfinityEdges;
    displaySymmetricCorner: boolean;
}

interface IEdgeMeshes {
    leftEdge: Mesh;
    rightEdge: Mesh;
    frontEdge: Mesh;
    backEdge: Mesh;
}

interface IMemberUiProperties {
    y?: UIProperty;
}

interface IRectangularLinesMeshInfo {
    linesMesh: LinesMesh;
    x: number;
    y: number;
    z: number;
    linesInfo: IRectangularLinesInfo;
}

interface IRectangularLinesInfo {
    lines: Vector3[][];
    yTextLine: ILine;
    symmetricCornerTextLine: ILine;
}

interface ILine {
    start: Vector3;
    end: Vector3;
}

interface ICubeFaces {
    rightVertexData: VertexData;
    leftVertexData: VertexData;
    backVertexData: VertexData;
    frontVertexData: VertexData;
    topVertexData: VertexData;
    bottomVertexData: VertexData;
}

interface IEdgeValues {
    xPositive: number;
    xNegative: number;
    yPositive: number;
    yNegative: number;
    zNegative: number;
}

export type IBaseMaterialConstructor = IBaseComponentConstructorCW;

export class BaseMaterial extends BaseComponentCW {

    private size!: Vector3;
    private position!: Vector3;

    private mesh: Mesh;
    private symmetricCornerMesh: Mesh;
    private edgeMeshes!: IEdgeMeshes;
    private structureLinesMeshInfo!: IRectangularLinesMeshInfo;

    private unitTextY?: UnitText2D;
    private symmetricCornerText?: Text2D;
    private transformNode?: TransformNode;

    private textCtor: CreateIUnitText2DCreateOptions;
    private tooltipEdgeKey: ToolTipKeyCW = 'ConcreteBaseMaterialEdge';
    private anchorChannelName = 'CW.AnchorChannel.Cut';

    constructor(ctor: IBaseMaterialConstructor) {
        super(ctor);

        this.textCtor = {
            unitGroup: UnitGroup.Length
        };

        this.mesh = this.createCubeMesh();
        this.symmetricCornerMesh = this.createSymmetricCornerMesh();

        this.onLeftEdgeClick = this.onLeftEdgeClick.bind(this);
        this.onRightEdgeClick = this.onRightEdgeClick.bind(this);
        this.onBackEdgeClick = this.onBackEdgeClick.bind(this);
        this.onFrontEdgeClick = this.onFrontEdgeClick.bind(this);
        this.onEdgePickingInfosChanged = this.onEdgePickingInfosChanged.bind(this);
    }

    private get isTopView() {
        return BaseComponentHelper.isTopView(this.model.applicationType);
    }

    public update(): void {
        this.mesh.dispose();
        this.symmetricCornerMesh.dispose();

        this.transformNode = this.calculateTransformNode();

        const values = BaseMaterialHelper.getBaseMaterialValues(this.model, this.model.anchoringSystems[0].id);

        this.position = values.position;
        this.size = values.size;

        const baseMesh = this.ensureCubeMesh(values.infinityEdges);
        this.setTexts(values.uiProperties, values.displaySymmetricCorner);
        this.ensureDimensionLinesMesh(values.dimensionLinesInfo, values.displaySymmetricCorner);

        this.ensureEdgeMeshes();

        this.mesh = this.subtractAnchorChannel(baseMesh);
        this.setTransparency(this.mesh);

        if (values.displaySymmetricCorner) {
            this.symmetricCornerMesh = this.ensureSymmetricCornerMesh();
        }
    }

    public override getBoundingBoxes(): BoundingInfo[] {
        const boundingBoxes: BoundingInfo[] = [this.getBoundingBox()];

        if (this.unitTextY?.mesh != null && this.unitTextY.mesh.isEnabled() && this.unitTextY.mesh.isVisible) {
            boundingBoxes.push(this.unitTextY.mesh.getBoundingInfo());
        }

        if (this.symmetricCornerText?.mesh != null && this.symmetricCornerText.mesh.isEnabled() && this.symmetricCornerText.mesh.isVisible) {
            boundingBoxes.push(this.symmetricCornerText.mesh.getBoundingInfo());
        }

        return boundingBoxes;
    }

    private ensureCubeMesh(infinityEdges: IInfinityEdges): Mesh {
        const baseMesh = this.createMesh(infinityEdges);
        baseMesh.position = this.position;

        this.scaleMesh(baseMesh);

        return baseMesh;
    }

    private ensureSymmetricCornerMesh(): Mesh {
        const symmetricCornerMesh = this.createSymmetricCornerMesh();
        symmetricCornerMesh.position = this.position;
        symmetricCornerMesh.addRotation(0, -Math.PI / 4, 0);

        this.scaleMesh(symmetricCornerMesh);

        return symmetricCornerMesh;
    }

    private subtractAnchorChannel(mesh: Mesh): Mesh {
        const meshToSlice = CSG.FromMesh(mesh);

        if (this.model.isAnchorChannelAvailable(this.model, this.id)) {
            for (const anchoringSystem of this.model.anchoringSystems) {

                const anchorChannel = anchoringSystem.anchorChannel;
                if (!anchorChannel.crossSectionNodes || anchorChannel.crossSectionNodes.length == 0) {
                    return mesh.clone();
                }

                // Get convex hull of anchor channel and get it into vertex data
                const values = AnchorChannelHelper.getAnchorChannelValues(this.model, anchoringSystem.id);
                const convexHullPolygon = AnchorChannelHelper.getAnchorChannelConvexHull(this.model, anchoringSystem.id);
                const anchorChannelPolygonTriangulation = new PolygonMeshBuilder(this.anchorChannelName, convexHullPolygon, undefined, earcut);
                const convexHullVertexData = anchorChannelPolygonTriangulation.buildVertexData(anchorChannel.channelLength);
                convexHullVertexData.transform(Matrix.RotationX(-Math.PI / 2));
                convexHullVertexData.transform(Matrix.RotationY(-Math.PI / 2));
                convexHullVertexData.transform(Matrix.Translation(values.position.x, values.position.y, values.position.z));
                const anchorChannelMesh = new Mesh(this.anchorChannelName);
                convexHullVertexData.applyToMesh(anchorChannelMesh, true);

                const transform = this.getGlobalTransformNode(anchoringSystem.geometry.position, anchoringSystem.geometry.rotation);
                anchorChannelMesh.parent = transform;

                // Subtract convex hull and dispose it
                const anchorChannelSubtractor = CSG.FromMesh(anchorChannelMesh);

                anchorChannelMesh.dispose();
                meshToSlice.subtractInPlace(anchorChannelSubtractor);

                // Get lip plate and arc into vertex data
                const lipValues = AnchorChannelLipHelper.getAnchorChannelLipValues(this.model, anchoringSystem.id);
                if (lipValues.lipCharacteristics) {
                    let lipPolygon = [lipValues.lipArcStart, ...lipValues.lipArc, lipValues.lipPlate.start, lipValues.lipPlate.end];
                    lipPolygon = lipPolygon.concat(AnchorChannelLipHelper.replicatePerpendicular(lipPolygon, lipValues.lipCharacteristics.thickness).reverse());
                    const lipTriangulation = new PolygonMeshBuilder(`${this.anchorChannelName}.Lip`, lipPolygon, undefined, earcut);
                    const lipVertexData = lipTriangulation.buildVertexData(anchorChannel.channelLength);
                    lipVertexData.transform(Matrix.RotationX(-Math.PI / 2));
                    lipVertexData.transform(Matrix.RotationY(-Math.PI / 2));
                    lipVertexData.transform(Matrix.Translation(values.position.x, values.position.y, values.position.z));
                    const lipMesh = new Mesh(`${this.anchorChannelName}.LipMesh`);
                    lipVertexData.applyToMesh(lipMesh);

                    lipMesh.parent = transform;

                    // Subtract lip and dispose
                    const lipSubstractor = CSG.FromMesh(lipMesh);
                    lipMesh.dispose();
                    meshToSlice.subtractInPlace(lipSubstractor);
                }
            }
        }

        // Bake final mesh
        return meshToSlice.toMesh(mesh.name, this.cache.materialCache.transparentConcreteMaterial, this.scene);
    }

    private setTexts(uiProperties: IMemberUiProperties, displaySymmetricCornerText: boolean) {
        this.disposeTexts();

        if (this.unitTextY != null && this.unitTextY?.property == uiProperties.y) {
            return;
        }

        this.unitTextY = uiProperties.y != null ? this.createUnitText2DForProperty(this.textCtor, uiProperties.y as number) : undefined;

        if (displaySymmetricCornerText) {
            this.symmetricCornerText = this.createText2D({ zoomTextScale: () => 0.25 });
        }
    }

    private ensureDimensionLinesMesh(linesInfo: IRectangularLinesInfo, displaySymmetricCorner: boolean) {
        this.structureLinesMeshInfo = this.createDimensionLinesMesh(linesInfo);
        this.updateDimensionLinesMesh(linesInfo);
        this.sizeLinesText(this.structureLinesMeshInfo, displaySymmetricCorner);

        this.structureLinesMeshInfo.linesMesh.setEnabled(true);
    }

    public override getBoundingBox(): BoundingInfo {
        return this.mesh.getBoundingInfo();
    }

    public override dispose(): void {
        this.disposeDimensionLines();
        this.disposeTexts();
    }

    private disposeDimensionLines() {
        this.cache.meshCache.clear(`BaseMaterial.LinesMesh`);

        if (this.structureLinesMeshInfo?.linesMesh != null) {
            this.structureLinesMeshInfo.linesMesh.setEnabled(false);
            this.structureLinesMeshInfo.linesMesh.dispose();
            this.structureLinesMeshInfo.linesMesh = undefined as unknown as LinesMesh;
        }
    }

    public disposeTexts(): void {
        if (this.unitTextY != null) {
            this.unitTextY.setEnabled(false);
            this.unitTextY.dispose();
            this.unitTextY = undefined;
        }

        if (this.symmetricCornerText != null) {
            this.symmetricCornerText.setEnabled(false);
            this.symmetricCornerText.dispose();
            this.symmetricCornerText = undefined;
        }
    }

    private createCubeMesh() {
        const name = 'CW_BaseMaterial';

        const mesh = MeshBuilder.CreateBox(name, { size: CommonCache.boxSize, width: CommonCache.boxSize, depth: CommonCache.boxSize, updatable: true });
        mesh.material = this.cache.materialCache.transparentConcreteMaterial;
        mesh.isPickable = true;
        mesh.alphaIndex = 1;

        return mesh;
    }

    public createMesh(infinityEdges: IInfinityEdges): Mesh {
        const meshName = `CW_BaseMaterialMesh:${infinityEdges.xNegative}:${infinityEdges.xPositive}:${infinityEdges.yNegative}:${infinityEdges.yPositive}:${infinityEdges.zNegative}`;
        const mesh = this.cache.meshCache.create(meshName, (): Mesh => {
            const infinityPoints = this.getInfinityPoints();
            const topBottomPath = new Path2(-CommonCache.boxSizeHalf, CommonCache.boxSizeHalf);

            // back
            const cubeFaces = this.getCubeFacesVertexData(infinityEdges, infinityPoints, topBottomPath);

            const meshVertexData = new VertexData();
            meshVertexData.positions = [];
            meshVertexData.indices = [];
            meshVertexData.normals = [];
            meshVertexData.uvs = [];

            meshVertexData.merge(cubeFaces.backVertexData);
            meshVertexData.merge(cubeFaces.rightVertexData);
            meshVertexData.merge(cubeFaces.frontVertexData);
            meshVertexData.merge(cubeFaces.leftVertexData);
            meshVertexData.merge(cubeFaces.topVertexData);
            meshVertexData.merge(cubeFaces.bottomVertexData);

            const mesh = new Mesh(meshName);
            meshVertexData.applyToMesh(mesh, true);

            mesh.material = this.cache.materialCache.transparentConcreteMaterial;
            mesh.isPickable = false;
            mesh.alphaIndex = 1000;

            return mesh;
        });

        return mesh;
    }

    private getCubeFacesVertexData(infinityEdges: IInfinityEdges, infinityPoints: { top: Vector3[]; bottom: Vector3[] }, topBottomPath: Path2): ICubeFaces {
        const yPositiveInfEdge = this.isTopView ? infinityEdges.yPositive : infinityEdges.zNegative;
        let backVertexData: VertexData;
        let rightVertexData: VertexData;
        let frontVertexData: VertexData;
        let leftVertexData: VertexData;

        if (yPositiveInfEdge) {
            backVertexData = this.getInfinityBackVertexData();
            this.addFaceToPath(infinityPoints.top.map(x => ({ x: x.x, y: -x.z })), topBottomPath);
        }
        else {
            backVertexData = this.getBoxSideBackVertexData();
        }

        // right
        if (infinityEdges.xPositive) {
            rightVertexData = this.getInfinityRightVertexData();
            this.addFaceToPath(infinityPoints.top.map(x => ({ x: -x.z, y: -x.x })), topBottomPath);
        }
        else {
            rightVertexData = this.getBoxSideRightVertexData();
            topBottomPath.addLineTo(CommonCache.boxSizeHalf, CommonCache.boxSizeHalf);
        }

        // front
        const yNegativeInfEdge = this.isTopView ? infinityEdges.yNegative : false; // in face of slabe front can't be infinity
        if (yNegativeInfEdge) {
            frontVertexData = this.getInfinityVertexData();
            this.addFaceToPath(infinityPoints.top.map(x => ({ x: -x.x, y: x.z })), topBottomPath);
        }
        else {
            frontVertexData = this.cache.vertexDataCache.boxSide;
            topBottomPath.addLineTo(CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf);
        }

        // left
        if (infinityEdges.xNegative) {
            leftVertexData = this.getInfinityLeftVertexData();
            this.addFaceToPath(infinityPoints.top.map(x => ({ x: x.z, y: x.x })), topBottomPath);
        }
        else {
            leftVertexData = this.getBoxSideLeftVertexData();
            topBottomPath.addLineTo(-CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf);
        }

        const topVertexData = new PolygonMeshBuilder('CW_BaseMaterial_TopVertex', topBottomPath, undefined, earcut).buildVertexData();
        const bottomVertexData = cloneVertexData(topVertexData);

        topVertexData.transform(this.getTranslationYBoxHalfMatrix());

        flipFaces(bottomVertexData, true);
        bottomVertexData.transform(this.getTranslationMinusYBoxHalfMatrix());

        return { backVertexData, rightVertexData, frontVertexData, leftVertexData, topVertexData, bottomVertexData } as ICubeFaces;
    }

    public createSymmetricCornerMesh(): Mesh {
        const { symmetricCornerOffset } = GlModelConstants.baseMaterialConstants;
        const meshName = `CW_SymmetricCornerHighlightMesh`;

        const mesh = MeshBuilder.CreateBox(
            meshName,
            {
                size: CommonCache.boxSize + symmetricCornerOffset * 2,
                width: 2 * ((CommonCache.boxSize + symmetricCornerOffset / 2) / Math.sqrt(2)),
                depth: 1,
                updatable: true
            },
            this.scene);

        mesh.material = this.cache.materialCache.symmetricCornerHighlightedMaterial;
        mesh.isPickable = false;
        mesh.alphaIndex = 1;

        return mesh;
    }

    private addFaceToPath(face: { x: number; y: number }[], path: Path2) {
        for (const element of face) {
            path.addLineTo(element.x, element.y);
        }
    }

    private scaleMesh(mesh: Mesh) {
        mesh.scaling = new Vector3(
            this.size.x / CommonCache.boxSize,
            this.size.y / CommonCache.boxSize,
            this.size.z / CommonCache.boxSize
        );
    }

    private setTransparency(mesh: Mesh) {
        mesh.material = this.model.visibilityProperties.baseMaterialTransparent ? this.cache.materialCache.transparentConcreteMaterial : this.cache.materialCache.concreteMaterial;
        mesh.isPickable = false;
    }

    private ensureEdgeMeshes() {
        this.edgeMeshes = {} as IEdgeMeshes;

        const { maxBoundingBoxSize } = GlModelConstants.baseMaterialConstants;
        const edgeoffset = (maxBoundingBoxSize / 2) - 1;

        const edgeYPosition = this.size.y / 2 - edgeoffset + 1;

        this.edgeMeshes.frontEdge = this.createEdgeMesh('Front');
        this.edgeMeshes.frontEdge.onDoubleClick = this.onFrontEdgeClick;
        this.edgeMeshes.frontEdge.onPickingInfosChanged = this.onEdgePickingInfosChanged;
        this.edgeMeshes.frontEdge.position = this.position.clone().add(new Vector3(0, edgeYPosition, -this.size.z / 2 + edgeoffset));
        this.edgeMeshes.frontEdge.scaling.x = this.size.x / CommonCache.boxSize;
        this.edgeMeshes.frontEdge.setEnabled(!this.propertyInfo.isPropertyDisabled(UIProperty.Geometry_CW_EdgeDistanceNegativeYInfinity));

        this.edgeMeshes.backEdge = this.createEdgeMesh('Back');
        this.edgeMeshes.backEdge.onDoubleClick = this.onBackEdgeClick;
        this.edgeMeshes.backEdge.onPickingInfosChanged = this.onEdgePickingInfosChanged;
        this.edgeMeshes.backEdge.position = this.position.clone().add(new Vector3(0, edgeYPosition, this.size.z / 2 - edgeoffset));
        this.edgeMeshes.backEdge.scaling.x = this.size.x / CommonCache.boxSize;
        const backUiProperty = this.isTopView ? UIProperty.Geometry_CW_EdgeDistanceYInfinity : UIProperty.Geometry_CW_EdgeDistanceNegativeZInfinity;
        this.edgeMeshes.backEdge.setEnabled(!this.propertyInfo.isPropertyDisabled(backUiProperty));

        this.edgeMeshes.leftEdge = this.createEdgeMesh('Left');
        this.edgeMeshes.leftEdge.onDoubleClick = this.onLeftEdgeClick;
        this.edgeMeshes.leftEdge.onPickingInfosChanged = this.onEdgePickingInfosChanged;
        this.edgeMeshes.leftEdge.position = this.position.clone().add(new Vector3(-this.size.x / 2 + edgeoffset, edgeYPosition, 0));
        this.edgeMeshes.leftEdge.scaling.z = this.size.z / CommonCache.boxSize;
        this.edgeMeshes.leftEdge.setEnabled(!this.propertyInfo.isPropertyDisabled(UIProperty.Geometry_CW_EdgeDistanceNegativeXInfinity));

        this.edgeMeshes.rightEdge = this.createEdgeMesh('Right');
        this.edgeMeshes.rightEdge.onDoubleClick = this.onRightEdgeClick;
        this.edgeMeshes.rightEdge.onPickingInfosChanged = this.onEdgePickingInfosChanged;
        this.edgeMeshes.rightEdge.position = this.position.clone().add(new Vector3(this.size.x / 2 - edgeoffset, edgeYPosition, 0));
        this.edgeMeshes.rightEdge.scaling.z = this.size.z / CommonCache.boxSize;
        this.edgeMeshes.rightEdge.setEnabled(!this.propertyInfo.isPropertyDisabled(UIProperty.Geometry_CW_EdgeDistanceXInfinity));
    }

    private onEdgePickingInfosChanged(pickingInfos: PickingInfo[]) {

        // tooltip
        const edges = {
            leftEdge: false,
            rightEdge: false,
            backEdge: false,
            frontEdge: false
        };

        for (let i = 0; i < pickingInfos.length && i < 3; i++) {
            edges.leftEdge = edges.leftEdge || this.edgeMeshes.leftEdge === pickingInfos[i].pickedMesh;
            edges.rightEdge = edges.rightEdge || this.edgeMeshes.rightEdge === pickingInfos[i].pickedMesh;
            edges.backEdge = edges.backEdge || this.edgeMeshes.backEdge === pickingInfos[i].pickedMesh;
            edges.frontEdge = this.isTopView && (edges.frontEdge || this.edgeMeshes.frontEdge === pickingInfos[i].pickedMesh);
        }

        (Object.keys(edges) as (keyof IEdgeMeshes)[]).forEach(key => {
            this.edgeMeshes[key].material = edges[key] ? this.cache.materialCache.concreteEdgeHighlightedMaterial : this.cache.materialCache.concreteEdgeMaterial;
        });

        if ((edges.leftEdge === true || edges.rightEdge === true || edges.backEdge === true || edges.frontEdge === true)) {
            this.tooltip.showTranslation(this.tooltipEdgeKey);
        }
        else {
            this.tooltip.hideTranslation(this.tooltipEdgeKey);
        }

        if (!isEqual(edges, this.edgeMeshes)) {
            this.renderNextFrame();
        }
    }

    private onLeftEdgeClick(pickingInfos: PickingInfo[]) {
        for (let i = 0; i < pickingInfos.length && i < 3; i++) {
            if (pickingInfos[i].pickedMesh === this.edgeMeshes.leftEdge) {
                this.propertyInfo.setPropertyValue(
                    UIProperty.Geometry_CW_EdgeDistanceNegativeXInfinity as number,
                    !BaseMaterialHelper.getInfinityEdges(this.model).xNegative
                );
                break;
            }
        }
    }

    private onRightEdgeClick(pickingInfos: PickingInfo[]) {
        for (let i = 0; i < pickingInfos.length && i < 3; i++) {
            if (pickingInfos[i].pickedMesh === this.edgeMeshes.rightEdge) {
                this.propertyInfo.setPropertyValue(
                    UIProperty.Geometry_CW_EdgeDistanceXInfinity as number,
                    !BaseMaterialHelper.getInfinityEdges(this.model).xPositive
                );
                break;
            }
        }
    }

    private onBackEdgeClick(pickingInfos: PickingInfo[]) {
        if (this.isTopView) {
            for (let i = 0; i < pickingInfos.length && i < 3; i++) {
                if (pickingInfos[i].pickedMesh === this.edgeMeshes.backEdge) {
                    this.propertyInfo.setPropertyValue(
                        UIProperty.Geometry_CW_EdgeDistanceYInfinity as number,
                        !BaseMaterialHelper.getInfinityEdges(this.model).yPositive
                    );
                    break;
                }
            }
        }
        else {
            for (let i = 0; i < pickingInfos.length && i < 3; i++) {
                if (pickingInfos[i].pickedMesh === this.edgeMeshes.backEdge) {
                    this.propertyInfo.setPropertyValue(
                        UIProperty.Geometry_CW_EdgeDistanceNegativeZInfinity as number,
                        !BaseMaterialHelper.getInfinityEdges(this.model).zNegative
                    );
                    break;
                }
            }
        }
    }

    private onFrontEdgeClick(pickingInfos: PickingInfo[]) {
        // in face of slabe front can't be infinity
        if (this.isTopView) {
            for (let i = 0; i < pickingInfos.length && i < 3; i++) {
                if (pickingInfos[i].pickedMesh === this.edgeMeshes.frontEdge) {
                    this.propertyInfo.setPropertyValue(
                        UIProperty.Geometry_CW_EdgeDistanceNegativeYInfinity as number,
                        !BaseMaterialHelper.getInfinityEdges(this.model).yNegative
                    );
                    break;
                }
            }
        }
    }

    private createEdgeMesh(name: string) {
        const meshName = `CW_BaseMaterial_Edge_${name}`;
        return this.cache.meshCache.create(meshName, () => {
            const mesh = new Mesh(meshName, this.scene);
            this.cache.vertexDataCache.box.applyToMesh(mesh, true);

            const { maxBoundingBoxSize } = GlModelConstants.baseMaterialConstants;
            const size = maxBoundingBoxSize / CommonCache.boxSize;

            mesh.scaling = new Vector3(size, size, size);
            mesh.material = this.cache.materialCache.concreteEdgeMaterial;
            mesh.isPickable = true;

            return mesh;
        });
    }

    private createDimensionLinesMesh(linesInfo: IRectangularLinesInfo) {
        if (this.structureLinesMeshInfo?.linesInfo.lines.flat().length != linesInfo.lines.flat().length) {
            // Disposing dimension lines mesh if number of points is not the same as in previous rendering
            this.disposeDimensionLines();
        }

        return this.cache.meshCache.create(`BaseMaterial.LinesMesh`, (): IRectangularLinesMeshInfo => {

            const linesMesh = this.createLineSystem(linesInfo, `BaseMaterial.LinesMesh`);

            return {
                linesMesh: linesMesh,
                x: this.size.x,
                y: this.size.y,
                z: this.size.z,
                linesInfo: linesInfo
            };
        });
    }

    private createLineSystem(linesInfo: IRectangularLinesInfo, name: string): LinesMesh {
        const linesMesh = CreateLineSystem(name, {
            lines: linesInfo.lines,
            updatable: true
        }, this.scene);

        linesMesh.isPickable = false;
        linesMesh.color = GlModelConstants.lineConstants.defaultLineColor;
        linesMesh.alphaIndex = 3000;

        return linesMesh;
    }

    private updateDimensionLinesMesh(linesInfo: IRectangularLinesInfo) {
        CreateLineSystem(undefined as unknown as string, {
            lines: linesInfo.lines,
            instance: this.structureLinesMeshInfo.linesMesh,
            updatable: undefined
        }, undefined as unknown as Scene);

        this.structureLinesMeshInfo.x = this.size.x;
        this.structureLinesMeshInfo.y = this.size.y;
        this.structureLinesMeshInfo.z = this.size.z;
        this.structureLinesMeshInfo.linesInfo = linesInfo;
        this.structureLinesMeshInfo.linesMesh.refreshBoundingInfo();
    }

    private sizeLinesText(structureLinesMeshInfo: IRectangularLinesMeshInfo, displaySymmetricCorner: boolean) {
        // y text
        if (this.unitTextY != null) {
            this.unitTextY.setValueOnLine(structureLinesMeshInfo.y,
                structureLinesMeshInfo.linesInfo.yTextLine.start,
                structureLinesMeshInfo.linesInfo.yTextLine.end,
                new Vector3(-1, 0, -1),
                {
                    rotationY: Math.PI
                });
        }

        // Symmetric corner text
        if (displaySymmetricCorner && this.symmetricCornerText != null) {
            // We set the text to get the calculated width from gl-model
            // and use that width to position the text.
            const symmetricCornerText = Constants.SymmetricCornerText;

            this.symmetricCornerText.setText(symmetricCornerText);
            this.symmetricCornerText.setTextOnLine(symmetricCornerText,
                structureLinesMeshInfo.linesInfo.symmetricCornerTextLine.start.addInPlace(new Vector3(this.symmetricCornerText.width / 2 - 25, 0, 0)),
                structureLinesMeshInfo.linesInfo.symmetricCornerTextLine.end.addInPlace(new Vector3(this.symmetricCornerText.width, 0, 0)),
                new Vector3(-1, 0, -1),
                Math.PI);
        }
    }

    private getInfinityPoints() {
        const { infinityPointsNumber, infinitySize, infinityOffset } = GlModelConstants.baseMaterialConstants;

        return this.cache.commonCache.create(`CW_InfinityPoints:${infinityOffset}`, () => {

            const top: Vector3[] = [];
            const bottom: Vector3[] = [];

            for (let point = 0; point < infinityPointsNumber * infinitySize; point++) {
                const x = point / infinityPointsNumber;
                const z = Math.cos(x * Math.PI * 2);

                const boxX = x / infinitySize * CommonCache.boxSize - CommonCache.boxSizeHalf;
                const boxZ = -z * infinityOffset - CommonCache.boxSizeHalf + infinityOffset;

                top.push(new Vector3(boxX, CommonCache.boxSizeHalf, boxZ));
                bottom.push(new Vector3(boxX, -CommonCache.boxSizeHalf, boxZ));
            }

            return { top, bottom };
        });
    }

    private getInfinityVertexData() {
        const { infinityOffset } = GlModelConstants.baseMaterialConstants;

        return this.cache.vertexDataCache.create(`CW_Infinity:${infinityOffset}`, () => {
            const infinityPoints = this.getInfinityPoints();

            const infinityPointsTop = infinityPoints.top.slice();
            infinityPointsTop.push(new Vector3(CommonCache.boxSizeHalf, CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf));

            const infinityPointsBottom = infinityPoints.bottom.slice();
            infinityPointsBottom.push(new Vector3(CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf, -CommonCache.boxSizeHalf));

            return CreateRibbonVertexData({
                pathArray: [
                    infinityPointsTop,
                    infinityPointsBottom
                ]
            });
        });
    }

    private getTranslationYBoxHalfMatrix() {
        return this.cache.matrixCache.create('CW_TranslationYBoxHalf', () => Matrix.Translation(0, CommonCache.boxSizeHalf, 0));
    }

    private getTranslationMinusYBoxHalfMatrix() {
        return this.cache.matrixCache.create('CW_TranslationMinusYBoxHalf', () => Matrix.Translation(0, -CommonCache.boxSizeHalf, 0));
    }


    private getInfinityLeftVertexData() {
        const { infinityOffset } = GlModelConstants.baseMaterialConstants;
        return this.cache.vertexDataCache.create(`CW_InfinityLeft:${infinityOffset}`, () => {
            const vertexData = cloneVertexData(this.getInfinityVertexData());
            vertexData.transform(this.cache.matrixCache.rotationY90);

            return vertexData;
        });
    }

    private getInfinityRightVertexData() {
        const { infinityOffset } = GlModelConstants.baseMaterialConstants;
        return this.cache.vertexDataCache.create(`CW_InfinityRight:${infinityOffset}`, () => {
            const vertexData = cloneVertexData(this.getInfinityVertexData());
            vertexData.transform(this.cache.matrixCache.rotationY270);

            return vertexData;
        });
    }

    private getInfinityBackVertexData() {
        const { infinityOffset } = GlModelConstants.baseMaterialConstants;
        return this.cache.vertexDataCache.create(`CW_InfinityBack:${infinityOffset}`, () => {
            const vertexData = cloneVertexData(this.getInfinityVertexData());
            vertexData.transform(this.cache.matrixCache.rotationY180);

            return vertexData;
        });
    }

    private getBoxSideBackVertexData() {
        return this.cache.vertexDataCache.create('CW_BoxSideBack', () => {
            const vertexData = cloneVertexData(this.cache.vertexDataCache.boxSide);
            vertexData.transform(this.cache.matrixCache.rotationY180);

            return vertexData;
        });
    }

    private getBoxSideRightVertexData() {
        return this.cache.vertexDataCache.create('CWBoxSideRight', () => {
            const vertexData = cloneVertexData(this.cache.vertexDataCache.boxSide);
            vertexData.transform(this.cache.matrixCache.rotationY270);

            return vertexData;
        });
    }

    private getBoxSideLeftVertexData() {
        return this.cache.vertexDataCache.create('CW_BoxSideLeft', () => {
            const vertexData = cloneVertexData(this.cache.vertexDataCache.boxSide);
            vertexData.transform(this.cache.matrixCache.rotationY90);

            return vertexData;
        });
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}

/**
 * Class which map model data to base material component values such as size, position, dimension lines, etc,...
 */
export class BaseMaterialHelper {

    public static getBaseMaterialValues(model: IModelCW, id: string): IBaseMaterialValues {
        const anchoringSystem = model.anchoringSystem(model, id);
        const { anchorsSpacing, firstAnchorPosition } = AnchorChannelHelper.getAnchorSpacing(model);
        const anchorChannelLength = anchoringSystem.anchorChannel.channelLength;
        const isTopView = BaseComponentHelper.isTopView(model.applicationType);

        // Size of cube is calculated based of edge distances
        const edgeDistances = this.getBaseMaterialEdgeDistances(model);

        const xValue = edgeDistances.xNegative + edgeDistances.xPositive + (model.isPostInstallAnchorProduct ? anchorsSpacing : anchorChannelLength);
        const yValue = isTopView ? edgeDistances.yNegative + edgeDistances.yPositive : edgeDistances.zNegative;

        // 'z' is in fact thickness (y coordinate in general coord system)
        const zValue = model.baseMaterial.thickness;

        const size = new Vector3(xValue, zValue, yValue);

        const positionX = model.isPostInstallAnchorProduct ? firstAnchorPosition + anchorsSpacing / 2.0 - (edgeDistances.xNegative - edgeDistances.xPositive) / 2.0 : (edgeDistances.xPositive - edgeDistances.xNegative) / 2.0;
        const positionZ = isTopView ? (edgeDistances.yPositive - edgeDistances.yNegative) / 2.0 : edgeDistances.zNegative / 2.0;

        // Position of cube is calculated by x and y clamped edge distances of cube
        const position = new Vector3(
            positionX, // Position of cube must be moved for offsets (edge distances for x)
            0.0,
            positionZ // Position of cube must be moved for offsets (edge distances for y)
        );

        const uiProperties: IMemberUiProperties = {
            y: model.visibilityProperties.concreteDimensionVisible ? UIProperty.Geometry_CW_Thickness : undefined,
        };

        const displaySymmetricCorner = (model.visibilityProperties.symmetricCornerVisible ?? false) && BaseComponentHelper.isCorner(model.applicationType) && xValue == yValue;
        const dimensionLinesInfo = BaseMaterialHelper.calculateDimensionLines(position, size, uiProperties, model, displaySymmetricCorner);
        const infinityEdges = BaseMaterialHelper.getInfinityEdges(model);

        return {
            size: size,
            position: position,
            uiProperties: uiProperties,
            dimensionLinesInfo: dimensionLinesInfo,
            infinityEdges: infinityEdges,
            displaySymmetricCorner: displaySymmetricCorner
        };
    }

    public static getInfinityEdges(model: IModelCW): IInfinityEdges {
        return {
            xNegative: model.baseMaterial.xNegativeInfinity,
            xPositive: model.baseMaterial.xPositiveInfinity,
            yNegative: model.baseMaterial.yNegativeInfinity,
            yPositive: model.baseMaterial.yPositiveInfinity,
            zNegative: model.baseMaterial.zNegativeInfinity
        } as IInfinityEdges;
    }

    /**
     * Gets edge distances.
     * @param model ¸¸
     * @returns
     */
    public static getBaseMaterialEdgeDistances(model: IModelCW): BaseMaterialEdgeDistances {
        const baseMaterial = model.baseMaterial;
        const maxConcreteLength = GlModelConstants.baseMaterialConstants.maxConcreteLength;

        const frontAnchoringSystem = model.anchoringSystems[0];
        const { anchorsSpacing, rightOffset, firstAnchorPosition, lastAnchorPosition } = AnchorChannelHelper.getAnchorSpacing(model);
        const frontAnchorChannel = frontAnchoringSystem.anchorChannel;
        const frontAnchorChannelLength = model.isPostInstallAnchorProduct ? anchorsSpacing : frontAnchorChannel.channelLength;
        const frontRebarPlate = frontAnchoringSystem.rebarPlate;
        const anchorLength = BasePlateSystemHelper.getFirstBasePlateSystem(model)?.bolt?.length ?? 0;

        const edges = {
            xPositive: clamp(baseMaterial.xPositive, baseMaterial.xPositiveInfinity ? maxConcreteLength : 0, maxConcreteLength),
            xNegative: clamp(baseMaterial.xNegative, baseMaterial.xNegativeInfinity ? maxConcreteLength : 0, maxConcreteLength),
            yPositive: clamp(baseMaterial.yPositive, baseMaterial.yPositiveInfinity ? maxConcreteLength : 0, maxConcreteLength),
            yNegative: clamp(baseMaterial.yNegative, baseMaterial.yNegativeInfinity ? maxConcreteLength : 0, maxConcreteLength),
            zNegative: clamp(baseMaterial.zNegative, baseMaterial.zNegativeInfinity ? maxConcreteLength : 0, maxConcreteLength)
        } as IEdgeValues;

        // Adjust
        for (const plateSystem of  frontAnchoringSystem.basePlateSystems) {
            const plateBracket = plateSystem.plateBracket;

            // Adjust yPositive based on baseplate offset
            BaseMaterialHelper.adjustYPositive(plateBracket, baseMaterial, edges);
            BaseMaterialHelper.adjustYNegative(plateBracket, baseMaterial, edges);

            if (model.isPostInstallAnchorProduct) {
                BaseMaterialHelper.adjustAnchorsXPositiveAndNegative(baseMaterial, plateBracket.width, firstAnchorPosition, lastAnchorPosition, edges);
            }
            else {
                const plateWidth = plateBracket.width;
                const plateOffset = plateBracket.offsetX;

                const plateLeftOverhang = (plateWidth / 2) - plateOffset + GlModelConstants.baseMaterialConstants.minEmbedment;
                const plateRightOverhang = (plateWidth / 2) + plateOffset - frontAnchorChannelLength + GlModelConstants.baseMaterialConstants.minEmbedment;

                BaseMaterialHelper.adjustXPositiveAndNegative(baseMaterial, plateLeftOverhang, plateRightOverhang, edges);
            }
        }

        // Enlarge infinity yPositive if rebar plate is present
        BaseMaterialHelper.adjustNoRebar(frontRebarPlate, baseMaterial, edges);

        // Enlarge infinity zNegative if anchor channel rebar or PIA FoS product
        let maxZNegative = maxConcreteLength * 2;
        if (AnchorChannelHelper.isAnchorRebar(model, frontAnchoringSystem.id) || (model.isPostInstallAnchorProduct && !BaseComponentHelper.isTopView(model.applicationType))) {
            const productLength = model.isPostInstallAnchorProduct ? anchorLength : frontAnchorChannel.h_ch + frontAnchorChannel.rebarChannel.l;
            maxZNegative = Math.max(maxConcreteLength * 2, productLength + GlModelConstants.baseMaterialConstants.minEmbedment);
            edges.zNegative = clamp(baseMaterial.zNegative, baseMaterial.zNegativeInfinity ? maxZNegative : 0, maxZNegative);
        }

        // Adjust offsets for bolts/anchors sticking out of infinity length concrete
        if (model.isPostInstallAnchorProduct && edges.xPositive < 0 ) {
            edges.xPositive = Math.min(baseMaterial.xPositive, GlModelConstants.baseMaterialConstants.minEmbedment);
        }
        else if (!model.isPostInstallAnchorProduct && edges.xPositive < rightOffset) {
            edges.xPositive = (baseMaterial.xPositiveInfinity ? rightOffset : Math.min(rightOffset, baseMaterial.xPositive));
        }

        // Corner edges must be realistic, adjust y positive with channel length
        BaseMaterialHelper.adjustCorner(model, edges, baseMaterial, frontAnchorChannel);

        return {
            xNegative: edges.xNegative,
            xPositive: edges.xPositive,
            yNegative: edges.yNegative,
            yPositive: edges.yPositive,
            zNegative: edges.zNegative
        } as BaseMaterialEdgeDistances;
    }

    private static adjustCorner(model: IModelCW, edges: IEdgeValues, baseMaterial: IBaseMaterial, frontAnchorChannel: IAnchorChannel) {
        if ([ApplicationTypes.FaceOfCorner, ApplicationTypes.TopOfSlabCorner].includes(model.applicationType)) {
            edges.xNegative = baseMaterial.xNegative;
            edges.yNegative = baseMaterial.yNegative;

            const desiredYLength = edges.xNegative + edges.xPositive + frontAnchorChannel.channelLength;

            let adjustment = desiredYLength - (edges.yNegative + edges.yPositive);
            if (adjustment > 0) {
                edges.yPositive += adjustment;
            }

            if (!baseMaterial.yPositiveInfinity) {
                edges.yPositive = Math.min(baseMaterial.yPositive, edges.yPositive);
            }

            const desiredZLength = edges.xNegative + edges.xPositive + frontAnchorChannel.channelLength;

            adjustment = desiredZLength - edges.zNegative;
            if (adjustment > 0) {
                edges.zNegative += adjustment;
            }

            if (!baseMaterial.zNegativeInfinity) {
                edges.zNegative = Math.min(baseMaterial.zNegative, edges.zNegative);
            }

            // adjust xPositive for yPositive plate overhang
            const desiredXLength = BaseComponentHelper.isFaceOfCorner(model.applicationType) ? edges.zNegative : edges.yNegative + edges.yPositive;
            const adjustmentX = desiredXLength - (edges.xNegative + edges.xPositive + frontAnchorChannel.channelLength);

            edges.xPositive = Math.min(baseMaterial.xPositive, edges.xPositive + adjustmentX);
        }
    }

    private static adjustNoRebar(frontRebarPlate: IRebarPlate, baseMaterial: IBaseMaterial, edges: IEdgeValues) {
        if (frontRebarPlate.noOfRebars) {
            edges.yNegative = baseMaterial.yNegativeInfinity ? (frontRebarPlate.rebarLength ?? 0) + GlModelConstants.baseMaterialConstants.minEmbedment : baseMaterial.yNegative;

            const desiredYLength = (frontRebarPlate.rebarLength ?? 0) + GlModelConstants.baseMaterialConstants.minEmbedment;
            const adjustment = desiredYLength - (edges.yNegative + edges.yPositive);

            if (adjustment > 0) {
                edges.yPositive += desiredYLength - (edges.yNegative + edges.yPositive);
            }

            if (!baseMaterial.yPositiveInfinity) {
                edges.yPositive = Math.min(baseMaterial.yPositive, edges.yPositive);
            }
        }
    }

    private static adjustXPositiveAndNegative(baseMaterial: IBaseMaterial, plateLeftOverhang: number, plateRightOverhang: number, edges: IEdgeValues) {
        if (baseMaterial.xNegativeInfinity || baseMaterial.xNegative > plateLeftOverhang) {
            edges.xNegative = Math.max(edges.xNegative, plateLeftOverhang);
        }

        if (baseMaterial.xPositiveInfinity || baseMaterial.xPositive > plateRightOverhang) {
            edges.xPositive = Math.max(edges.xPositive, plateRightOverhang);
        }
    }

    private static adjustAnchorsXPositiveAndNegative(baseMaterial: IBaseMaterial, plateWidth: number, firstAnchorPosition: number, lastAnchorPosition: number, edges: IEdgeValues) {
        const plateLeftOverhang = plateWidth / 2 + firstAnchorPosition;
        const plateLeftInfinityOverhang = plateLeftOverhang + edges.xNegative + GlModelConstants.baseMaterialConstants.minEmbedment;

        if (baseMaterial.xNegativeInfinity || (baseMaterial.xNegative > plateLeftInfinityOverhang)) {
            edges.xNegative = plateLeftInfinityOverhang;
        }
        else if (baseMaterial.xNegative < plateLeftInfinityOverhang) {
            edges.xNegative = baseMaterial.xNegative;
        }

        const plateRightOverhang = plateWidth / 2 - lastAnchorPosition;
        const plateRightInfinityOverhang = plateRightOverhang + edges.xPositive + GlModelConstants.baseMaterialConstants.minEmbedment;

        if (baseMaterial.xPositiveInfinity || (baseMaterial.xPositive > plateRightInfinityOverhang)) {
            edges.xPositive = plateRightInfinityOverhang;
        }
        else if (baseMaterial.xPositive < plateRightInfinityOverhang) {
            edges.xPositive = baseMaterial.xPositive;
        }
    }

    private static adjustYPositive(frontPlateBracket: IPlateBracket, baseMaterial: IBaseMaterial, edges: IEdgeValues) {
        const plateBackOverhang = frontPlateBracket.offsetY + GlModelConstants.baseMaterialConstants.minEmbedment;
        if (baseMaterial.yPositive > edges.yPositive && baseMaterial.yPositive < plateBackOverhang) {
            edges.yPositive = baseMaterial.yPositive;
        }

        if (baseMaterial.yPositiveInfinity || baseMaterial.yPositive > plateBackOverhang) {
            edges.yPositive = Math.max(edges.yPositive, plateBackOverhang);
        }
    }

    private static adjustYNegative(frontPlateBracket: IPlateBracket, baseMaterial: IBaseMaterial, edges: IEdgeValues) {
        const plateBackOverhang = frontPlateBracket.offsetYNegative;
        const bracketOverhang = frontPlateBracket.standoff.type == StandoffTypes.BracketSupport ? (frontPlateBracket.standoff.offset + frontPlateBracket.standoff.width) : 0;

        const overhang = Math.max(plateBackOverhang, bracketOverhang) + GlModelConstants.baseMaterialConstants.minEmbedment;

        if (baseMaterial.yNegative > edges.yNegative && baseMaterial.yNegative <= overhang) {
            edges.yNegative = baseMaterial.yNegative;
        }

        if (baseMaterial.yNegativeInfinity || baseMaterial.yNegative > overhang) {
            edges.yNegative = Math.max(edges.yNegative, overhang);
        }
    }

    private static calculateDimensionLines(position: Vector3, size: Vector3, uiProperties: IMemberUiProperties, model: IModelCW, displaySymmetricCorner: boolean): IRectangularLinesInfo {
        const { lineDimensionOffsetXZ } = GlModelConstants.lineConstants;
        const { symmetricCornerOffset } = GlModelConstants.baseMaterialConstants;
        let angle = 2 * (lineDimensionOffsetXZ / Math.sqrt(2));

        let x = BaseComponentHelper.isFaceOfCorner(model.applicationType) ? position.x + size.x / 2 : position.x - size.x / 2;
        let xWithAngle = BaseComponentHelper.isFaceOfCorner(model.applicationType) ? x + angle : x - angle;

        let yLine: Vector3[] = [];
        let yTextLine: ILine = { start: new Vector3(), end: new Vector3() };
        let yArrows: Vector3[][] = [];

        if (uiProperties.y != null && model.visibilityProperties.concreteDimensionVisible) {
            yLine = [
                new Vector3(x, position.y - size.y / 2, position.z - size.z / 2),
                new Vector3(xWithAngle, position.y - size.y / 2, position.z - size.z / 2 - angle),
                new Vector3(xWithAngle, position.y + size.y / 2, position.z - size.z / 2 - angle),
                new Vector3(x, position.y + size.y / 2, position.z - size.z / 2)
            ];

            yTextLine = {
                start: yLine[1],
                end: yLine[2]
            };

            yArrows = createArrowXYLine(yTextLine.start, yTextLine.end);
        }

        let symmetricCornerLine: Vector3[] = [];
        let symmetricCornerTextLine: ILine = { start: new Vector3(), end: new Vector3() };

        if (displaySymmetricCorner) {
            angle = 2 * ((lineDimensionOffsetXZ + symmetricCornerOffset) / Math.sqrt(2));
            x = position.x + (size.x / 2) + symmetricCornerOffset;
            xWithAngle = x + angle;
            const y = position.y + size.y / 2 + symmetricCornerOffset;
            const z = position.z + (size.z / 2) + symmetricCornerOffset;

            symmetricCornerLine = [
                new Vector3(x, y, z),
                new Vector3(xWithAngle, y, z + angle),
                new Vector3(xWithAngle + lineDimensionOffsetXZ, y, z + angle)
            ];

            symmetricCornerTextLine = {
                start: symmetricCornerLine[2].clone(),
                end: symmetricCornerLine[2].clone()
            };
        }

        return {
            lines: [
                yLine,
                symmetricCornerLine,
                ...yArrows,
            ],
            yTextLine,
            symmetricCornerTextLine: symmetricCornerTextLine
        };
    }
}
