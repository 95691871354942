import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';
import firestopImage from '../../../images/firestop/Firestop.png';
import { Constants } from '../../entities/constants';

@Component({
    templateUrl: './firestop-dialog.component.html',
    styleUrls: ['./firestop-dialog.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class FirestopDialogComponent {

    @Input()
    public modalInstance!: ModalInstance;

    public images = {
        firestopImage
    };

    constructor(
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        public offlineService: OfflineService,
        public modalService: ModalService,
    ) {}

    public get title() {
        return this.translate('Agito.Hilti.CW.TabFirestop');
    }

    public openLink() {
        window.open(Constants.FirestopLink, '_blank');
        this.close();
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
