import { Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector';

export function normalizePolygon(points: Vector3[], minX: number, minY: number, minZ: number, maxX: number, maxY: number, maxZ: number): Vector3[] {
    const setCurrentValues = (value: number, currentValues: { min: number; max: number }) => {
        if (value < currentValues.min) {
            currentValues.min = value;
        }

        if (value > currentValues.max) {
            currentValues.max = value;
        }
    };

    // Find the current minimum and maximum x and y values
    const xCurrentValues = { min: Number.MAX_VALUE, max: -Number.MAX_VALUE };
    const yCurrentValues = { min: Number.MAX_VALUE, max: -Number.MAX_VALUE };
    const zCurrentValues = { min: Number.MAX_VALUE, max: -Number.MAX_VALUE };

    for (const point of points) {
        setCurrentValues(point.x, xCurrentValues);
        setCurrentValues(point.y, yCurrentValues);
        setCurrentValues(point.z, zCurrentValues);
    }

    const currentMinX = xCurrentValues.min, currentMaxX = xCurrentValues.max;
    const currentMinY = yCurrentValues.min, currentMaxY = yCurrentValues.max;
    const currentMinZ = zCurrentValues.min, currentMaxZ = zCurrentValues.max;

    // Calculate the scaling factors for x and y
    const scaleX = (currentMaxX - currentMinX) !== 0 ? (maxX - minX) / (currentMaxX - currentMinX) : 0;
    const scaleY = (currentMaxY - currentMinY) !== 0 ? (maxY - minY) / (currentMaxY - currentMinY) : 0;
    const scaleZ = (currentMaxZ - currentMinZ) !== 0 ? (maxZ - minZ) / (currentMaxZ - currentMinZ) : 0;

    // Create a new array of normalized points
    const normalizedPoints: Vector3[] = [];

    for (const point of points) {
        const normalizedX = (point.x - currentMinX) * scaleX + minX;
        const normalizedY = (point.y - currentMinY) * scaleY + minY;
        const normalizedZ = (point.z - currentMinZ) * scaleZ + minZ;
        normalizedPoints.push(new Vector3(normalizedX, normalizedY, normalizedZ));
    }

    return normalizedPoints;
}

/**
    * It generates convex shape out of given points in 2D space. You can google it
    * if you want to learn more how it works
    * @param points Takes ExtendedVector[] to store angle inside
    * @returns Vector2[] of points of convex hull polygon
    */
export function grahamScanToGetConvexHull(points: GrahamScanVector2[]): Vector2[] {
    // The enveloppe is the points themselves
    if (points.length <= 3) return points;

    // Find the pivot
    let pivot = points[0];
    for (const point of points) {
        if (point.y < pivot.y || (point.y === pivot.y && point.x < pivot.x))
            pivot = point;
    }

    // Attribute an angle to the points
    for (const point of points) {
        point.grahamAngle = Math.atan2(point.y - pivot.y, point.x - pivot.x);
    }

    points.sort((a, b) => {
        return a.grahamAngle === b.grahamAngle
            ? a.x - b.x
            : a.grahamAngle - b.grahamAngle;
    });

    // Adding points to the result if they "turn left"
    const result = [points[0]];
    let len = 1;
    for (let i = 1; i < points.length; i++) {
        let a = result[len - 2],
            b = result[len - 1];
        const c = points[i];
        while (
            (len === 1 && b.x === c.x && b.y === c.y) ||
            (len > 1 && (b.x - a.x) * (c.y - a.y) <= (b.y - a.y) * (c.x - a.x))) {
            len--;
            b = a;
            a = result[len - 2];
        }
        result[len++] = c;
    }
    result.length = len;
    return result.map(x => new Vector2(x.x, x.y));
}

/**
 * Calculate if polygon is clockwise or anticlockwise.
 * It only takes a sample out of given array as we dont need to check every point.
 * @param points Polygon
 * @param numSamples Size of samples that are going to be checked
 * @returns
 */
export function isCounterClockwise(points: Vector2[], numSamples: number): boolean {
    if (numSamples >= points.length) {
        // if numSamples is greater than or equal to the number of points,
        // use all the points to determine the orientation
        numSamples = points.length - 1;
    }

    let sum = 0;
    for (let i = 0; i < numSamples; i++) {
        const p1 = points[i];
        const p2 = points[(i + 1) % points.length];
        sum += (p2.x - p1.x) * (p2.y + p1.y);
    }

    return sum < 0;
}

/**
 * Class used for graham scan as we need to store angles.
 */
export class GrahamScanVector2 extends Vector2 {
    public grahamAngle: number;
    constructor(x: number, y: number) {
        super(x, y);
        this.grahamAngle = 0;
    }
}