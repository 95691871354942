import { Injectable } from '@angular/core';
import { FeatureFlagFromPeUi, FeaturesVisibilityServiceInjected } from '@profis-engineering/pe-ui-common/services/feature-visibility.common';

export type FeatureFlag =
    FeatureFlagFromPeUi |
    'CW_Module' |
    'CW_MultiLoadCombination';

@Injectable({
    providedIn: 'root'
  })
  export class FeatureVisibilityService extends FeaturesVisibilityServiceInjected<FeatureFlag> {
  }