import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './select-inspection-type-popup.component.html',
    styleUrls: ['./select-inspection-type-popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectInspectionTypePopupComponent{
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;

    constructor(
        private localizationService: LocalizationService,
    ) { }

    public get title() {
        return this.translate('Agito.Hilti.CW.Navigation.TabProduct.InspectionTypePopupTitle');
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
