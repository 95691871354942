import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import { CodeList } from '../code-list';
import { SeismicStressTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export interface ISeismicStressTypeConstructor extends ICodeListConstructor {
    designStandardId?: number;
    designMethodGroupId?: number;
    descriptionResourceKey?: string;
    image?: string;
}

export class SeismicStressType extends CodeList {
    designStandardId?: number;
    designMethodGroupId?: number;
    public descriptionResourceKey?: string;

    constructor(codeList: ISeismicStressTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.designStandardId  = codeList.designStandardId;
            this.designMethodGroupId  = codeList.designMethodGroupId;
            this.descriptionResourceKey = codeList.descriptionResourceKey;
            this.image = codeList.image;
            this.kbTooltip = codeList.kbTooltip;
            this.kbNumberRegion = codeList.kbNumberRegion;
        }
    }

    public static fromService(codeList: SeismicStressTypeEntity, displayKeyPrefix: string) {
        const seismicStressType = new SeismicStressType(CodeList.baseFromService<ICodeListConstructor>(displayKeyPrefix, codeList, {
            designStandardId: codeList.designStandardId,
            designMethodGroupId: codeList.designMethodGroupId,
            image: codeList.image != null && codeList.image != '' ? codeList.image : 'seismic-type-no-photo-available',
            kbTooltip: codeList.kbTooltip,
            kbNumberRegion: Object.fromEntries(codeList.kbNumbers.map(item => [item.regionId, item.kbNumber]))
        } as ICodeListConstructor));

        // set description resource key
        seismicStressType.descriptionResourceKey = seismicStressType.nameResourceKey != null && seismicStressType.nameResourceKey != '' ? seismicStressType.nameResourceKey + '.Description' : undefined;

        return seismicStressType;
    }

    public override getTranslatedDescriptionText(codeListDeps: ICodeListTextDeps) {
        if (this.descriptionResourceKey == null || this.descriptionResourceKey == '') {
            return undefined;
        }

        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        return codeListDeps.localizationService.getString(this.descriptionResourceKey);
    }

    public override cloneDeep(): SeismicStressType {
        const codeList = super.cloneDeep() as SeismicStressType;

        codeList.designStandardId = this.designStandardId;
        codeList.designMethodGroupId = this.designMethodGroupId;
        codeList.descriptionResourceKey = this.descriptionResourceKey;
        codeList.image = this.image;
        codeList.kbTooltip = this.kbTooltip;
        codeList.kbNumberRegion = this.kbNumberRegion;

        return codeList;
    }
}
