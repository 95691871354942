import { CodeList, ICodeListConstructor } from '../code-list';
import { LoadTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class LoadType extends CodeList {

    public static fromService(codeList: LoadTypeEntity) {
        return new LoadType(CodeList.baseFromService<ICodeListConstructor>('LoadTypeEntity', codeList, {
            image: codeList.image
        } as ICodeListConstructor));
    }

    public override cloneDeep(): LoadType {
        return super.cloneDeep() as LoadType;
    }
}
