import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { BaseComponentCW } from '../../../gl-model/base-component';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { Vector3, Matrix } from '@babylonjs/core/Maths/math.vector';
import { AnchorChannelHelper } from './anchor-channel';
import { cloneVertexData, extrudeShape } from '@profis-engineering/gl-model/vertex-data-helper.js';
import { BoltsHelper } from './bolt-manager';
import { PolygonMeshBuilder } from '@babylonjs/core/Meshes/polygonMesh';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import earcut from 'earcut';
import { INumberedPosition } from '../../../gl-model/entities/numbered-position';
import { IBasePlateSystemBaseConstructor } from './base-plate-system';
import { GlModelConstants } from '../../../gl-model/gl-model-constants';
import { BasePlateSystemHelper } from '../helpers/base-plate-system-helper';
import { StandoffTypes } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';

export interface IBolt {
    sideHeadLength: number;
    frontHeadLength: number;
    headHeight: number;
    diameter: number;
    length: number;
    holeDiameter: number;
    positions: INumberedPosition[];
    washerHeight: number;
}

export class Bolt extends BaseComponentCW {
    private mesh: Mesh;
    private washerMesh: Mesh;

    private transformNode!: TransformNode;

    private values: IBolt = {
        sideHeadLength: 0,
        frontHeadLength: 0,
        headHeight: 0,
        diameter: 0,
        length: 0,
        holeDiameter: 0,
        washerHeight: 0,
        positions: []
    };

    constructor(ctor: IBasePlateSystemBaseConstructor) {
        super(ctor);

        this.id = ctor.id;
        this.plateSystemId = ctor.plateSystemId;

        this.mesh = this.getBoltMesh();
        this.washerMesh = this.getWasher();
    }

    public getMesh() {
        return this.mesh;
    }

    public getWasherMesh() {
        return this.washerMesh;
    }

    public update(): void {
        this.transformNode = this.calculateTransformNode();

        this.values = this.model.basePlateSystem(this.model, this.plateSystemId, this.id).bolt;

        this.ensureMesh();
    }

    private ensureMesh(): void {
        this.mesh.dispose();
        this.mesh = this.getBoltMesh();
        this.washerMesh = this.getWasher();

        // Hide original mesh, as instances will be shown
        this.mesh.setEnabled(false);
        this.washerMesh.setEnabled(false);
    }

    private getBoltMesh(): Mesh {
        const boltCylinder = this.getCylinderMesh();
        const boltMeshArray = [boltCylinder];

        if (!this.model.isPostInstallAnchorProduct) {
            boltMeshArray.push(this.getBoltHeadMesh());
        }

        // Construct the final mesh and apply material
        const mesh = new Mesh('Bolt_FullBolt', this.scene);
        Mesh.MergeMeshes(boltMeshArray, false, true, mesh, true, false);
        mesh.material = this.material;

        boltCylinder.dispose(); // dispose it is clone of cache

        if (this.transformNode != null)
            mesh.parent = this.transformNode;

        return mesh;
    }

    private getWasher(): Mesh {
        const washer = this.cache.meshCache.create('Bolt.CW.Washer', () => {
            const mesh = MeshBuilder.CreateBox('Bolt.CW.Washer', { size: CommonCache.boxSize, width: CommonCache.boxSize, depth: CommonCache.boxSize, updatable: true }, this.scene);
            mesh.material = this.cache.materialCache.steelMaterial.clone('Bolt.CW.Washer.Material');
            mesh.material.alpha = 1;
            return mesh;
        });

        washer.scaling = new Vector3(
            2 * this.values.diameter / CommonCache.boxSize,
            this.values.washerHeight / CommonCache.boxSize,
            2 * this.values.diameter / CommonCache.boxSize,
        );

        return washer;
    }

    private get material() {
        return this.cache.materialCache.boltMaterial;
    }

    /**
     * Constructs the bolt head
     * @returns Bolt head mesh
     */
    private getBoltHeadMesh() {
        const anchoringSystem = this.model.anchoringSystem(this.model, this.id);
        // If anchor channel cross section nodes are provided, construct an accurate shape
        if (anchoringSystem.anchorChannel.crossSectionNodes && anchoringSystem.anchorChannel.crossSectionNodes.length > 0) {
            // Calculate the bolt head shape
            const anchorChannelPolygon = AnchorChannelHelper.getAnchorChannelShape(this.model, this.id);
            const boltHeadPolygon = BoltsHelper.getBoltHeadShape(anchorChannelPolygon);

            // Extrude the shape for a length of bolt side length
            const mesh = new Mesh('Bolt_ActualHeadShape');

            const extrudedShape = extrudeShape(boltHeadPolygon, [new Vector3(-this.values.sideHeadLength / 2, 0, 0), new Vector3(this.values.sideHeadLength / 2, 0, 0)], 1, undefined, Mesh.NO_CAP, Mesh.DOUBLESIDE);
            extrudedShape.applyToMesh(mesh);

            // Create a mesh cap that spans whole bolt side length
            const capMesh = new Mesh('Bolt_ActualHeadShape_Cap');

            const capShape = new PolygonMeshBuilder('Bolt_ActualHeadShape_Cap', boltHeadPolygon, undefined, earcut).buildVertexData();

            capShape.transform(Matrix.RotationX(-Math.PI / 2).multiply(Matrix.RotationY(Math.PI / 2)).multiply(Matrix.Translation(-this.values.sideHeadLength / 2, 0, 0)));

            const backCap = cloneVertexData(capShape);
            backCap.transform(Matrix.RotationY(Math.PI));

            capShape.merge(backCap);
            capShape.applyToMesh(capMesh);

            // return merged mesh for easier consumption
            Mesh.MergeMeshes([mesh, capMesh], false, true, mesh, true, false);

            return mesh;
        }

        // If there is no info on bolt shape, construct a simple cuboid
        const name = 'Bolt_ApproximateHeadShape';
        const headMesh = this.cache.meshCache.create(name, () => {
            const mesh = MeshBuilder.CreateBox(name, { size: CommonCache.boxSize, updatable: true });
            return mesh;
        });

        headMesh.scaling = new Vector3(
            this.values.sideHeadLength / CommonCache.boxSize,
            this.values.headHeight / CommonCache.boxSize,
            this.values.frontHeadLength / CommonCache.boxSize
        );

        return headMesh;
    }

    /**
     * Constructs, scales and positions the bolt screw
     * @returns Bolt screw mesh
     */
    private getCylinderMesh(): Mesh {
        const name = 'CW_Bolt_Cylinder';
        const mesh = this.cache.meshCache.cylinderMesh.clone(name);

        mesh.scaling = new Vector3(
            this.values.diameter / CommonCache.cylinderSize,
            this.values.length / CommonCache.boxSize,
            this.values.diameter / CommonCache.cylinderSize
        );

        mesh.position.y = this.model.isPostInstallAnchorProduct ? this.getAnchorPositionY() : this.getBoltPositionY();

        return mesh;
    }

    private getAnchorPositionY() {
        const originPosition = GlModelConstants.baseMaterialConstants.anchorProtrudingHeight - this.values.length / 2;
        const piProtrudingLength = this.getPiProtrudingLength();
        return originPosition + piProtrudingLength;
    }

    private getPiProtrudingLength() {
        const bps = BasePlateSystemHelper.getFirstBasePlateSystem(this.model);
        if (bps == null || bps.plateBracket == null)
            return 0;

        const addWasher = bps.plateBracket.slottedHole.enabled === true ? this.values.washerHeight : 0;

        const plateThickness = bps.plateBracket.thickness;
        const standoffHeight = bps.plateBracket.standoff.type != StandoffTypes.None ? bps.plateBracket.standoff.distance : 0;
        const sunkenOffset = bps.plateBracket.isSunken ? -plateThickness : 0;
        return plateThickness + standoffHeight + sunkenOffset + addWasher;
    }

    private getBoltPositionY() {
        return this.values.length / 2;
    }
}
