import { CodeList } from '../code-list';
import { EdgeReinforcementsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class EdgeReinforcements extends CodeList {

    constructor(codeList: EdgeReinforcementsEntity) {
        super(codeList);
    }

    public static fromService(codeList: EdgeReinforcementsEntity) {
        return new EdgeReinforcements(CodeList.baseFromService('EdgeReinforcementsEntity', codeList));
    }

    public override cloneDeep(): EdgeReinforcements {
        const codeList = super.cloneDeep() as EdgeReinforcements;
        return codeList;
    }

}
