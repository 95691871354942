<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.CW.SelectBolt.Title')" closeButtonId="select-bolt-close-button">
</pe-modal-header>

<pe-modal-body  id="bolt-tab-select-bolt-dialog" *ngIf="dataLoaded">
    <div class="modal-body-content">
        <div class="left-side">
        </div>

        <div class="right-side">
            <div class="bolts"
                data-control-id='select-bolt-dialog-bolt-list-section'
                id="selectboltdialogboltlistsection">

                <virtual-scroller #scrollBolts
                    [items]="bolts" [parentScroll]="scrollElement" [scrollAnimationTime]="0"
                    [ngStyle]="{ 'height': (bolts.length * boltRowHeight) + 'px' }">
                    <div id="select-bolt-{{bolt.id}}-row"
                        *ngFor="let bolt of $any(scrollBolts).viewPortItems; trackBy:identifyBolt"
                        class="bolt modal-grid-button"
                        [class.selected]="selectedBoltId == bolt.id"
                        (click)="selectBolt(bolt)">
                        <div class="image-container">
                            <div class="no-image-container">
                                <span
                                    class="no-image sprite sprite-responsive pe-ui-cw-sprite-no-photo-available"></span>
                            </div>
                            <span class="image sprite-responsive pe-ui-cw-sprite" [ngClass]="'pe-ui-cw-' + bolt.image"></span>
                        </div>
                        <div class="text-container">
                            <div class="text-wrapper">
                                <p class="bolt-name">{{bolt.name}}</p>
                            </div>
                        </div>
                    </div>
                </virtual-scroller>
            </div>
        </div>
    </div>
</pe-modal-body>
