
import { Properties, UIPropertyTexts } from '@profis-engineering/pe-ui-common/entities/design';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';

export type LogChange = {
    modelChange: Change;
    isHidden?: boolean;
    isDisabled?: boolean;
    allowedValues?: number[];
    disabledValues?: number[];
    min?: number;
    max?: number;
    displayKey?: string;
    title?: string;
    toolTip?: string;
    titleDisplayKey?: string;
    itemsTexts: { [id: number]: UIPropertyTexts };
};

export class PropertyChangesHelper {

    private changes: { [propertyId: string]: LogChange } = {};

    constructor(
        private properties: Properties,
        private modelChanges: any,
        private propertyChanges: any
    ) { }

    public getChangesFromProperties(): { [propertyId: string]: LogChange } {
        this.detectModelChanges();
        this.detectAllowedValuesChanges();
        this.detectDisabledValuesChanges();
        this.detectDisabledChanges();
        this.detectHiddenChanges();
        this.detectMinChanges();
        this.detectMaxChanges();
        this.detectDisplayKeyChanges();
        this.detectTitleChanges();
        this.detectTooltipChanges();
        this.detectUpdateTitleKeyChanges();
        this.detectItemsTooltipChanges();

        // Remove empty changes
        this.changes = Object.fromEntries(Object.entries(this.changes).filter(([, change]) => !this.isEmptyLogChange(change)));

        return this.changes;
    }

    private detectModelChanges() {
        // model changes
        for (const modelChangeKey in this.modelChanges) {
            const propertyId = modelChangeKey as any as number;
            this.ensureLogChangeExists(propertyId);

            const modelChange = this.modelChanges[modelChangeKey];
            this.changes[propertyId].modelChange = modelChange;
        }
    }

    private detectAllowedValuesChanges() {
        // allowed values changes
        for (const propertyId of this.propertyChanges.updatedAllowedValues) {
            this.ensureLogChangeExists(propertyId);

            this.changes[propertyId].allowedValues = this.properties.get(propertyId).allowedValues;
        }
    }

    private detectDisabledValuesChanges() {
        // disabled values changes
        for (const propertyId of this.propertyChanges.updatedDisabledValues) {
            this.ensureLogChangeExists(propertyId);

            this.changes[propertyId].disabledValues = this.properties.get(propertyId).disabledValues;
        }
    }

    private detectDisabledChanges() {
        // disabled changes
        for (const propertyId of this.propertyChanges.updatedDisabled) {
            this.ensureLogChangeExists(propertyId);

            const isDisabled = this.properties.get(propertyId).disabled;
            this.changes[propertyId].isDisabled = isDisabled;
        }
    }

    private detectHiddenChanges() {
        // hidden changes
        for (const propertyId of this.propertyChanges.updatedHidden) {
            this.ensureLogChangeExists(propertyId);

            const isHidden = this.properties.get(propertyId).hidden;
            this.changes[propertyId].isHidden = isHidden;
        }
    }

    private detectMinChanges() {
        // min changes
        for (const propertyId of this.propertyChanges.updatedMin) {
            this.ensureLogChangeExists(propertyId);

            const min = this.properties.get(propertyId).min;
            this.changes[propertyId].min = min;
        }
    }

    private detectMaxChanges() {
        // max changes
        for (const propertyId of this.propertyChanges.updatedMin) {
            this.ensureLogChangeExists(propertyId);

            const max = this.properties.get(propertyId).max;
            this.changes[propertyId].max = max;
        }
    }

    private detectDisplayKeyChanges() {
        // display key
        for (const propertyId of this.propertyChanges.updatedDisplayKey) {
            this.ensureLogChangeExists(propertyId);

            const displayKey = this.properties.get(propertyId).displayKey;
            this.changes[propertyId].displayKey = displayKey;
        }
    }

    private detectTitleChanges() {
        // title
        for (const propertyId of this.propertyChanges.updatedTooltipTitle) {
            this.ensureLogChangeExists(propertyId);

            const title = this.properties.get(propertyId).tooltipTitle;
            this.changes[propertyId].title = title;
        }
    }

    private detectTooltipChanges() {
        // toolTip
        for (const propertyId of this.propertyChanges.updatedTooltip) {
            this.ensureLogChangeExists(propertyId);

            const toolTip = this.properties.get(propertyId).tooltip;
            this.changes[propertyId].toolTip = toolTip;
        }
    }

    private detectUpdateTitleKeyChanges() {
        // updatedTitleDisplayKey
        for (const propertyId of this.propertyChanges.updatedTitleDisplayKey) {
            this.ensureLogChangeExists(propertyId);

            const titleDisplayKey = this.properties.get(propertyId).titleDisplayKey;
            this.changes[propertyId].titleDisplayKey = titleDisplayKey;
        }
    }

    private detectItemsTooltipChanges() {
        // updated ItemsTooltip
        for (const propertyId of this.propertyChanges.updatedItemsTooltip) {
            this.ensureLogChangeExists(propertyId);

            const itemsTexts = this.properties.get(propertyId).itemsTexts;
            this.changes[propertyId].itemsTexts = itemsTexts;
        }
    }

    private ensureLogChangeExists(propertyId: number) {
        if (this.changes[propertyId] == null) {
            this.changes[propertyId] = this.createEmptyLogChange();
        }
    }

    private createEmptyLogChange(): LogChange {
        return {
            modelChange: null as unknown as Change,
            isHidden: undefined,
            isDisabled: undefined,
            allowedValues: undefined,
            disabledValues: undefined,
            min: undefined,
            max: undefined,
            displayKey: undefined,
            title: undefined,
            toolTip: undefined,
            titleDisplayKey: undefined,
            itemsTexts: {}
        };
    }

    private isEmptyLogChange(logChange: LogChange) {
        return logChange == null || (
            logChange.modelChange == undefined &&
            logChange.isHidden == undefined &&
            logChange.isDisabled == undefined &&
            logChange.allowedValues == undefined &&
            logChange.disabledValues == undefined &&
            logChange.min === undefined &&
            logChange.max === undefined &&
            logChange.displayKey === undefined &&
            logChange.title === undefined &&
            logChange.toolTip === undefined &&
            logChange.titleDisplayKey === undefined &&
            !logChange.itemsTexts != null && Object.keys(logChange.itemsTexts).length > 0);
    }
}