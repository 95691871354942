import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

import fillHolesImage from '../../../images/installation/fill-holes.png';

@Component({
    templateUrl: './installation-fill-holes-popup.component.html',
    styleUrls: ['./installation-fill-holes-popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})


export class InstallationFillHolesPopupComponent implements OnInit {
    public hiltiFillHoles!: string;

    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;

    constructor(
        private localizationService: LocalizationService,
    ) { }

    ngOnInit(): void {
        this.hiltiFillHoles = fillHolesImage;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
