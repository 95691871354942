import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { includeSprites, Sprite } from '../../sprites';

@Component({
    selector: 'cw-product-drop-down-item',
    templateUrl: './product-drop-down-item.component.html',
    styleUrls: ['./product-drop-down-item.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ProductDropDownItemComponent implements OnChanges {

    @Input()
    public id?: number;

    @Input()
    public name?: string;

    @Input()
    public image?: string;

    @Input()
    public isSelected?: boolean;

    @Input()
    public isReadOnly?: boolean;

    @Input()
    public isLastElement?: boolean;

    @Input()
    public isPostInstalledAnchor?: boolean;

    @Output()
    public productChange = new EventEmitter<number>();

    private initializedSpriteImages: string[] = [];

    constructor(private elementRef: ElementRef<HTMLElement>) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['image'] != null && changes['image'].previousValue != changes['image'].currentValue) {
            this.initProductImage();
        }
    }

    public selectProduct() {
        this.productChange.emit(this.id);
    }

    private initProductImage() {
        if (this.image != null && !this.initializedSpriteImages.includes(this.image)) {
            includeSprites(this.elementRef.nativeElement.shadowRoot, this.image as Sprite);
        }
    }
}
