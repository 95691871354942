import cloneDeep from 'lodash-es/cloneDeep';
import { Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ToggleButtonGroupItem } from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { LoadsComponentHelper } from '../../helpers/loads-component-helper';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle } from '../../sprites';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Unit as UnitCodeListItem } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { replace } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { NumberType } from '@profis-engineering/pe-ui-common/services/number.common';
import { GuidService } from '../../services/guid.service';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ImportLoadsNumberTypeBase } from '@profis-engineering/pe-ui-common/entities/import-loads-number-type-base';
import { LoadCombinationEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { CalculationService } from '../../services/calculation.service';
import { ImportLoadsNumberType } from '../../entities/enums/import-loads-number-type';
import { LoadTypes } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { Constants } from '../../entities/constants';
import { CodeListService } from '../../services/code-list.service';
import { ModalService } from '../../services/modal.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import clamp from 'lodash-es/clamp';
import { LoggerService } from '../../services/logger.service';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

const keyTab = 'Tab';

interface IParseResponse {
    loads: LoadCombinationEntity[];
    limitReached: boolean;
}

export enum ImportLoadsView {
    copyPaste,
    import
}

interface ILoadsDropdownHeader {
    index: number;
    class: string;
    dropdown: DropdownProps<ImportLoadsNumberTypeBase>;
}

@Component({
    templateUrl: './loads-import.component.html',
    styleUrls: ['./loads-import.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsImportComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;
    public hasSustainedLoads!: boolean;
    public view = ImportLoadsView.copyPaste;
    public importData!: string | null;
    public importedLoads: LoadCombinationEntity[] = [];
    public limitReached!: boolean;

    public forceUnitDropdown!: DropdownProps<Unit>;
    public momentUnitDropdown!: DropdownProps<Unit>;
    public loadsDropdownHeaders: ILoadsDropdownHeader[] = [];
    public loadTypeToggleItems!: ToggleButtonGroupItem<LoadTypes>[];

    public importedLoadRowHeight = 31;
    public scrollElement!: Element;

    public helper: LoadsComponentHelper;

    // constants
    public viewEnum = ImportLoadsView;

    private isPasted = false;
    private copyPasteInput!: HTMLInputElement;
    private pendingSave = false;
    public requiredValidator = Validators.required;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private commonCodeList: CommonCodeListService,
        private unitService: UnitService,
        private numberService: NumberService,
        private guidService: GuidService,
        private userSettings: UserSettingsService,
        private calculationService: CalculationService,
        private logger: LoggerService,
        private modalService: ModalService,
        private translationFormatService: TranslationFormatService,
        private codeListService: CodeListService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.hasSustainedLoads = this.projectDesign.loads.loadCombinations.some(load => load.hasSustainedLoads);
        this.helper = new LoadsComponentHelper(
            localizationService,
            codeListService,
            unitService,
            modalService,
            translationFormatService,
            this.userService.design
        );
    }

    public get design() {
        return this.userService.design;
    }

    public get projectDesign() {
        return this.userService.design.designData.projectDesign;
    }

    public get importLoadsSettings() {
        return this.userSettings.settings.application.curtainWall.applicationImportLoads;
    }

    public get hasImportedLoads() {
        return this.importedLoads.length > 0;
    }

    public get maxLoads() {
        return Constants.MaxLoadCombinations;
    }

    public get designStandardName() {
        return this.design.designStandard?.displayKey ?? '';
    }

    public get staticTooltipTitle() {
        return this.translate('Agito.Hilti.CW.LoadType.Static.Tooltip.Title');
    }

    public get staticTooltip() {
        return this.translate(this.helper.loadTypeToolTip(LoadTypes.Static, this.designStandardName));
    }

    public get seismicTooltipTitle() {
        return this.translate('Agito.Hilti.CW.LoadType.Seismic.Tooltip.Title');
    }

    public get seismicTooltip() {
        return this.translate(this.helper.loadTypeToolTip(LoadTypes.Seismic, this.designStandardName));
    }

    public get dynamicTooltipTitle() {
        return this.translate('Agito.Hilti.CW.LoadType.Fatigue.Tooltip.Title');
    }

    public get dynamicTooltip() {
        return this.translate(this.helper.loadTypeToolTip(LoadTypes.Dynamic, this.designStandardName));
    }

    public get fireTooltipTitle() {
        return this.translate('Agito.Hilti.CW.LoadType.Fire.Tooltip.Title');
    }

    public get fireTooltip() {
        return this.translate(this.helper.loadTypeToolTip(LoadTypes.Fire, this.designStandardName));
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return !this.pendingSave;
        });

        this.helper.initialize();
        this.scrollElement = document.querySelector('.modal') as Element;

        const forceUnit = this.importLoadsSettings?.forceUnit.value ?? this.unitService.getDefaultUnit(UnitGroup.Force);
        const momentUnit = this.importLoadsSettings?.momentUnit.value ?? this.unitService.getDefaultUnit(UnitGroup.Moment);

        // unit dropdowns
        this.forceUnitDropdown = this.createUnitDropdown('force-dropdown', 'ForceUnit', this.commonCodeList.commonCodeLists[CommonCodeList.UnitForce], forceUnit);
        this.momentUnitDropdown = this.createUnitDropdown('moment-dropdown', 'MomentUnit', this.commonCodeList.commonCodeLists[CommonCodeList.UnitMoment], momentUnit);

        // loads headers
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'one',
            dropdown: this.createNumberTypeDropdown('number-type-one', this.importLoadsSettings?.numberTypeOne.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'two',
            dropdown: this.createNumberTypeDropdown('number-type-two', this.importLoadsSettings?.numberTypeTwo.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'three',
            dropdown: this.createNumberTypeDropdown('number-type-three', this.importLoadsSettings?.numberTypeThree.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'four',
            dropdown: this.createNumberTypeDropdown('number-type-four', this.importLoadsSettings?.numberTypeFour.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'five',
            dropdown: this.createNumberTypeDropdown('number-type-five', this.importLoadsSettings?.numberTypeFive?.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'six',
            dropdown: this.createNumberTypeDropdown('number-type-six', this.importLoadsSettings?.numberTypeSix.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'seven',
            dropdown: this.createNumberTypeDropdown('number-type-seven', this.importLoadsSettings?.numberTypeSeven.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'eight',
            dropdown: this.createNumberTypeDropdown('number-type-eight', this.importLoadsSettings?.numberTypeEight.value as ImportLoadsNumberType)
        });
        this.loadsDropdownHeaders.push({
            index: this.loadsDropdownHeaders.length,
            class: 'nine',
            dropdown: this.createNumberTypeDropdown('number-type-nine', this.importLoadsSettings?.numberTypeNine.value as ImportLoadsNumberType)
        });

        this.loadTypeToggleItems = [];

        const loadTypes = [
            {
                type: LoadTypes.Static,
                image: 'sprite-anchor-shock',
                class: 'sprite-anchor-shock',
                title: this.staticTooltipTitle,
                content: this.staticTooltip
            },
            {
                type: LoadTypes.Seismic,
                image: 'sprite-anchor-seismic',
                class: 'sprite-anchor-seismic',
                title: this.seismicTooltipTitle,
                content: this.seismicTooltip
            },
            {
                type: LoadTypes.Dynamic,
                image: 'sprite-anchor-fatigue',
                class: 'sprite-anchor-fatigue',
                title: this.dynamicTooltipTitle,
                content: this.dynamicTooltip
            },
            {
                type: LoadTypes.Fire,
                image: 'sprite-anchor-fire-resistant',
                class: 'sprite-anchor-fire-resistant',
                title: this.fireTooltipTitle,
                content: this.fireTooltip
            }
        ];

        loadTypes.forEach(loadType => {
            if (this.helper.isLoadTypeAllowed(loadType.type)) {
                this.loadTypeToggleItems.push({
                    value: loadType.type,
                    disabled: this.helper.isLoadTypeDisabled(loadType.type),
                    image: getSpriteAsIconStyle(loadType.image),
                    class: loadType.class,
                    tooltip: {
                        title: loadType.title,
                        content: loadType.content
                    }
                });
            }
        });

        setTimeout(() => {
            const element = this.elementRef.nativeElement.shadowRoot?.getElementById('import-loads-dialog') as HTMLInputElement;
            this.copyPasteInput = element.querySelector('.copy-paste-input') as HTMLInputElement;
            this.copyPasteInput.focus();
        });
    }

    public close() {
        this.modalInstance.close();
    }

    public cancel() {
        this.close();
    }

    public onImportPaste() {
        this.isPasted = true;
    }

    public onImportChange() {
        if (!this.isPasted) {
            this.importData = null;
            return;
        }

        const existingLoads = this.projectDesign.loads.loadCombinations.filter(lc => this.loadCombinationHasValue(lc, true));
        const maxLoads = Math.max(0, this.maxLoads - existingLoads.length);
        const parsed = this.parsePastedData(this.importData as string, maxLoads);

        this.limitReached = parsed.limitReached;
        this.importData = null;
        this.importedLoads = parsed.loads;
        this.view = ImportLoadsView.import;
    }

    public onImportKeyDown(event: KeyboardEvent) {
        // prevent tab navigation
        if (event.key == keyTab) {
            event.preventDefault();
        }
    }

    public onImportBlur() {
        this.copyPasteInput.focus();
    }

    public virtualScrollChange() {
        // If input element had focus we need to blur it on virtual scroll change
        // (viewport end/start index changed)
        const activeElement = document.activeElement as HTMLElement;
        if (activeElement != null && activeElement.tagName.toLowerCase() === 'input') {
            activeElement.blur();
        }
    }

    public identifyField: TrackByFunction<ILoadsDropdownHeader> = (_: number, field: ILoadsDropdownHeader) => field.index;

    public identifyLoad: TrackByFunction<LoadCombinationEntity> = (index: number) => index;

    public getLoadValue(load: LoadCombinationEntity, numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        return this.getLoadNumber(load, numberType);
    }

    public updateLoadValue(value: number, load: LoadCombinationEntity, numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        if (value != null && !Number.isNaN(value)) {
            this.setLoadNumber(load, numberType, value);
        }
    }

    public isColumnVisible(numberValue: number) {
        const numberType = this.loadsDropdownHeaders[numberValue].dropdown.selectedValue as ImportLoadsNumberType;
        return this.isLoadAvailable(numberType);
    }

    public isLoadAvailable(numberType: ImportLoadsNumberType) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZMinus:
                return true;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                return this.hasSustainedLoads;
            default:
                return false;
        }
    }

    public saveLoadCombinations(form: NgForm) {
        return this.save(form);
    }

    public numberTypeDropdownSelectedValueChange(selectedValue: ImportLoadsNumberType, dropdown: DropdownProps<ImportLoadsNumberType>) {
        const oldSelectedValue = dropdown.selectedValue as number;
        dropdown.selectedValue = selectedValue;

        // swap numbers in load
        for (const load of this.importedLoads || []) {
            const loadNumber = this.getLoadNumber(load, oldSelectedValue);

            this.setLoadNumber(load, oldSelectedValue, this.getLoadNumber(load, selectedValue));
            this.setLoadNumber(load, selectedValue, loadNumber);
        }

        // swap dropdown values
        for (const header of this.loadsDropdownHeaders) {
            if (header.dropdown !== dropdown && this.isSameNumberType(header.dropdown.selectedValue as number, selectedValue as number)) {
                header.dropdown.selectedValue = oldSelectedValue - oldSelectedValue % 2;
            }
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public concatFilterLoadCombinations(existingCombinations: LoadCombinationEntity[], newCombinations: LoadCombinationEntity[], maxLoadCombinations: number, checkDynamic: boolean) {
        // Filter existing Load Combinations (also, check dynamic loads!)
        let existingFiltered = cloneDeep(existingCombinations || [])
            .filter((lc) => this.loadCombinationHasValue(lc, checkDynamic));

        // Filter new Load Combinations
        const newFiltered = newCombinations
            .filter((lc) => this.loadCombinationHasValue(lc, checkDynamic));

        // Filter out wizard generated Load Combinations
        existingFiltered = existingFiltered.filter((lc) => !lc.isWizardGenerated);
        this.design.designData.projectDesign.loads.loadCombinationWizard.loadCombinationWizardEquations = [];
        this.design.designData.projectDesign.loads.loadCombinationWizard.loadCombinationWizardFactors = [];

        // Concatenate the two and consider the max Load Combinations limitation
        const retVal = [...existingFiltered, ...newFiltered]
            .slice(0, maxLoadCombinations);

        // Ensure at least one Load Combination is left
        if (retVal.length < 1) {
            if (existingCombinations.length > 0) {
                retVal.push(existingCombinations[0]);
            }
            else if (newCombinations.length > 0) {
                retVal.push(newCombinations[0]);
            }
        }
        return retVal;
    }

    public loadCombinationHasValue(loadCombination: LoadCombinationEntity, checkDynamic: boolean) {
        let retVal =
            this.isValueNotEmpty(loadCombination.forceX) ||
            this.isValueNotEmpty(loadCombination.forceY) ||
            this.isValueNotEmpty(loadCombination.forceZ) ||
            this.isValueNotEmpty(loadCombination.momentX) ||
            this.isValueNotEmpty(loadCombination.momentY) ||
            this.isValueNotEmpty(loadCombination.momentZ);

        if (!retVal && checkDynamic) {
            retVal = retVal ||
                this.isValueNotEmpty(loadCombination.dynamicForceZ) ||
                this.isValueNotEmpty(loadCombination.dynamicMomentX) ||
                this.isValueNotEmpty(loadCombination.dynamicMomentY);
        }

        return retVal;
    }

    public isValueNotEmpty(value: number | null | undefined) {
        return value != null && value != 0;
    }

    public setLoadNumber(load: LoadCombinationEntity, numberType: ImportLoadsNumberType, value: number) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
                load.forceX = value;
                break;
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
                load.forceY = value;
                break;
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
                load.forceZ = value;
                break;
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
                load.momentX = value;
                break;
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
                load.momentY = value;
                break;
            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZMinus:
                load.momentZ = value;
                break;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
                load.dynamicForceZ = value;
                break;
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
                load.dynamicMomentX = value;
                break;
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                load.dynamicMomentY = value;
                break;
        }
    }

    public isSameNumberType(numberTypeOne: number, numberTypeTwo: number) {
        return Math.floor(numberTypeOne / 2) == Math.floor(numberTypeTwo / 2);
    }

    private processLine(line: string, i: number, newLInes: number) {
        if ((i < newLInes - 1) && (line.startsWith('"') || line.includes('\t"'))) {
            return this.processQuotedLine(line);
        } else {
            return this.processUnquotedLine(line);
        }
    }

    private processQuotedLine(line: string) {
        if ((line.indexOf('\t"') != -1 && (line.match(/\t"/g) || []).length != (line.match(/"\t/g) || []).length)) {
            return { line, row: null };
        } else if ((line.indexOf('\t') != -1 || (line.match(/"/g)?.length ?? 0) % 2 == 0)) {
            if (line.startsWith('"') && !line.startsWith('\n', 1) && line.indexOf('\n') < line.length - 1) {
                line = line.slice(1).replace('"\t', '\t');
            }
            const row = line.replace(/"\n/g, '').replace(/\n"/g, '').replace(/\n/g, ' ').replace(/""/g, '"');
            return { line: '', row };
        }
        return { line, row: null };
    }

    private processUnquotedLine(line: string) {
        if (line.indexOf('\n') != -1 && line.startsWith('"')) {
            line = line.slice(1).replace(/\n"/g, '').replace('"\t', '\t');
        }
        if (line.trim() != '') {
            const row = line.replace(/"\n/g, '').replace(/\n"/g, '').replace(/\n/g, ' ').replace(/""/g, '"');
            return { line: '', row };
        }
        return { line, row: null };
    }

    public CSVToRows(csvString: string) {
        const rows: string[] = [];
        const newLInes = csvString.split('\n').length;
        let line = '';
        for (let i = 0; i <= newLInes - 1; i++) {
            line += (i == newLInes - 1) ? csvString : csvString.substring(0, csvString.indexOf('\n') + 1);
            csvString = csvString.slice(csvString.indexOf('\n') + 1, csvString.length);

            const { line: newLine, row } = this.processLine(line.replace('\n',''), i, newLInes);
            line = newLine;
            if (row !== null) {
                rows.push(row);
            }
        }
        return rows;
    }

    public parsePastedData(data: string, maxLoads: number): IParseResponse {
        const loads: LoadCombinationEntity[] = [];

        if (data == null || data == '') {
            return { loads, limitReached: false };
        }

        data = replace(data, '\r\n', '\n');
        const rows = this.CSVToRows(data);

        let limitReached = false;

        for (const row of rows) {
            if (loads.length >= maxLoads) {
                limitReached = true;
                break;
            }

            const load = this.parseRow(row);
            if (load) {
                loads.push(load);
            }
        }

        return { loads, limitReached };
    }

    private parseRow(row: string): LoadCombinationEntity | null {
        const columns = row.split('\t');
        const hasName = this.hasName(columns);
        const columnOffset = hasName ? 1 : 0;

        const visibleLoads = this.loadsDropdownHeaders.filter(x => this.isLoadAvailable(x.dropdown.selectedValue as ImportLoadsNumberType));

        const loadValues = this.getLoadValues(visibleLoads, columns, columnOffset);
        const name = hasName ? columns[0].trim() : '';

        if (this.shouldAddLoad(loadValues, name)) {
            return this.createLoad(loadValues, name);
        }

        return null;
    }

    private hasName(columns: string[]): boolean {
        return columns.length >= (this.hasSustainedLoads ? 10 : 7) || this.getNumber(columns, 0) == null;
    }

    private getLoadValues(visibleLoads: any[], columns: string[], columnOffset: number): any {
        const loadValues: any = {};

        visibleLoads.forEach((item, key) => {
            const numberType = item.dropdown.selectedValue;
            loadValues[numberType] = this.getValidatedNumber(columns, key + columnOffset, numberType);
        });

        return loadValues;
    }

    private getValidatedNumber(columns: string[], columnIndex: number, numberType: ImportLoadsNumberType): number | null {
        return !this.isLoadAvailable(numberType) ? 0 : this.getNumber(columns, columnIndex);
    }

    private shouldAddLoad(loadValues: any, name: string): boolean {
        return name != '' || Object.values(loadValues).some(value => value != null);
    }

    private createLoad(loadValues: any, name: string): LoadCombinationEntity {
        return {
            id: this.guidService.new(),
            name: name,
            description: '',
            loadType: LoadTypes.Static,
            forceX: (loadValues[ImportLoadsNumberType.ForceX] ?? loadValues[ImportLoadsNumberType.ForceXMinus]) ?? 0,
            forceY: (loadValues[ImportLoadsNumberType.ForceY] ?? loadValues[ImportLoadsNumberType.ForceYMinus]) ?? 0,
            forceZ: (loadValues[ImportLoadsNumberType.ForceZ] ?? loadValues[ImportLoadsNumberType.ForceZMinus]) ?? 0,
            momentX: (loadValues[ImportLoadsNumberType.MomentX] ?? loadValues[ImportLoadsNumberType.MomentXMinus]) ?? 0,
            momentY: (loadValues[ImportLoadsNumberType.MomentY] ?? loadValues[ImportLoadsNumberType.MomentYMinus]) ?? 0,
            momentZ: (loadValues[ImportLoadsNumberType.MomentZ] ?? loadValues[ImportLoadsNumberType.MomentZMinus]) ?? 0,
            dynamicForceZ: this.hasSustainedLoads ? ((loadValues[ImportLoadsNumberType.ForceZSustained] ?? loadValues[ImportLoadsNumberType.ForceZSustainedMinus]) ?? 0) : 0,
            dynamicMomentX: this.hasSustainedLoads ? ((loadValues[ImportLoadsNumberType.MomentXSustained] ?? loadValues[ImportLoadsNumberType.MomentXSustainedMinus]) ?? 0) : 0,
            dynamicMomentY: this.hasSustainedLoads ? ((loadValues[ImportLoadsNumberType.MomentYSustained] ?? loadValues[ImportLoadsNumberType.MomentYSustainedMinus]) ?? 0) : 0,
            isWizardGenerated: false,
            hasSustainedLoads: this.hasSustainedLoads,
            hasPostCalculationScopeChecksError: false,
            hasPreCalculationScopeChecksError: false,
            hasScopeChecksError: false,
            resultMessages: []
        };
    }

    public getNumber(columns: string[], index: number) {
        if (index < columns.length) {
            const value = columns[index];
            const num = this.numberService.parse(value, NumberType.real);

            if (num != null && !Number.isNaN(num)) {
                return num;
            }
        }

        return null;
    }

    private createUnitDropdown(id: string, titleKey: string, codeList: UnitCodeListItem[], defaultUnit: Unit) {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const dropdown: DropdownProps<Unit> = {
            id: 'import-loads-' + id,
            title: this.translate(`Agito.Hilti.CW.LoadsImport.${titleKey}`),
            items: codeList.map((unit) => {
                return {
                    id: `import-loads-${id}-${unit.id}`,
                    value: unit.id,
                    text: unit.getTranslatedNameText(codeListDeps)
                } as DropdownItem<Unit>;
            }),
            selectedValue: defaultUnit
        };

        return dropdown;
    }

    private createNumberTypeDropdown(id: string, selected: ImportLoadsNumberType) {
        const dropdown: DropdownProps<ImportLoadsNumberType> = {
            id: 'import-loads-' + id,
            selectedValue: selected
        };

        dropdown.items = [
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceX),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceXMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceY),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceYMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZ),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentX),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentY),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYMinus),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentZ),
            this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentZMinus)
        ];

        if (this.hasSustainedLoads) {
            dropdown.items = [
                ...dropdown.items,
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZSustained),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.ForceZSustainedMinus),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXSustained),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentXSustainedMinus),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYSustained),
                this.createNumberDropdownItem(dropdown, ImportLoadsNumberType.MomentYSustainedMinus)
            ];
        }

        return dropdown;
    }

    private createNumberDropdownItem(dropdown: DropdownProps<ImportLoadsNumberType>, numberType: ImportLoadsNumberType) {
        return {
            id: `import-loads-${dropdown.id}-${JSON.stringify(numberType)}`,
            value: numberType,
            text: this.getNumberTypeString(numberType),
            disabled: false
        } as DropdownItem<ImportLoadsNumberType>;
    }

    private getNumberTypeString(numberType: ImportLoadsNumberType) {
        let key = 'Agito.Hilti.CW.LoadsImport.';
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
                key += 'VxPlus';
                break;

            case ImportLoadsNumberType.ForceXMinus:
                key += 'VxMinus';
                break;

            case ImportLoadsNumberType.ForceY:
                key += 'VyPlus';
                break;

            case ImportLoadsNumberType.ForceYMinus:
                key += 'VyMinus';
                break;

            case ImportLoadsNumberType.ForceZ:
                key += 'NPlus';
                break;

            case ImportLoadsNumberType.ForceZMinus:
                key += 'NMinus';
                break;

            case ImportLoadsNumberType.MomentX:
                key += 'MxPlus';
                break;

            case ImportLoadsNumberType.MomentXMinus:
                key += 'MxMinus';
                break;

            case ImportLoadsNumberType.MomentY:
                key += 'MyPlus';
                break;

            case ImportLoadsNumberType.MomentYMinus:
                key += 'MyMinus';
                break;

            case ImportLoadsNumberType.MomentZ:
                key += 'MzPlus';
                break;

            case ImportLoadsNumberType.MomentZMinus:
                key += 'MzMinus';
                break;

            case ImportLoadsNumberType.ForceZSustained:
                key += 'NSustainedPlus';
                break;

            case ImportLoadsNumberType.ForceZSustainedMinus:
                key += 'NSustainedMinus';
                break;

            case ImportLoadsNumberType.MomentXSustained:
                key += 'MxSustainedPlus';
                break;

            case ImportLoadsNumberType.MomentXSustainedMinus:
                key += 'MxSustainedMinus';
                break;

            case ImportLoadsNumberType.MomentYSustained:
                key += 'MySustainedPlus';
                break;

            case ImportLoadsNumberType.MomentYSustainedMinus:
                key += 'MySustainedMinus';
                break;

            default:
                throw new Error('Unknown number type.');
        }

        return this.translate(key);
    }

    private getLoadNumber(load: LoadCombinationEntity, numberType: ImportLoadsNumberType) {
        switch (numberType) {
            case ImportLoadsNumberType.ForceX:
            case ImportLoadsNumberType.ForceXMinus:
                return load.forceX;
            case ImportLoadsNumberType.ForceY:
            case ImportLoadsNumberType.ForceYMinus:
                return load.forceY;
            case ImportLoadsNumberType.ForceZ:
            case ImportLoadsNumberType.ForceZMinus:
                return load.forceZ;
            case ImportLoadsNumberType.MomentX:
            case ImportLoadsNumberType.MomentXMinus:
                return load.momentX;
            case ImportLoadsNumberType.MomentY:
            case ImportLoadsNumberType.MomentYMinus:
                return load.momentY;
            case ImportLoadsNumberType.MomentZ:
            case ImportLoadsNumberType.MomentZMinus:
                return load.momentZ;
            case ImportLoadsNumberType.ForceZSustained:
            case ImportLoadsNumberType.ForceZSustainedMinus:
                return load.dynamicForceZ;
            case ImportLoadsNumberType.MomentXSustained:
            case ImportLoadsNumberType.MomentXSustainedMinus:
                return load.dynamicMomentX;
            case ImportLoadsNumberType.MomentYSustained:
            case ImportLoadsNumberType.MomentYSustainedMinus:
                return load.dynamicMomentY;
            default:
                return 0;
        }
    }

    private prepareLoadCombinations() {
        const importedLoads = cloneDeep(this.importedLoads);
        const forceUnit = this.forceUnitDropdown.selectedValue as Unit;
        const momentUnit = this.momentUnitDropdown.selectedValue as Unit;
        const internalForceUnit = this.unitService.getInternalUnit(UnitGroup.Force);
        const internalMomentUnit = this.unitService.getInternalUnit(UnitGroup.Moment);

        for (const load of importedLoads) {
            // convert to selected unit
            load.forceX = this.unitService.convertUnitValueArgsToUnit(load.forceX, forceUnit, internalForceUnit);
            load.forceY = this.unitService.convertUnitValueArgsToUnit(load.forceY, forceUnit, internalForceUnit);
            load.forceZ = this.unitService.convertUnitValueArgsToUnit(load.forceZ, forceUnit, internalForceUnit);

            load.momentX = this.unitService.convertUnitValueArgsToUnit(load.momentX, momentUnit, internalMomentUnit);
            load.momentY = this.unitService.convertUnitValueArgsToUnit(load.momentY, momentUnit, internalMomentUnit);
            load.momentZ = this.unitService.convertUnitValueArgsToUnit(load.momentZ, momentUnit, internalMomentUnit);

            load.dynamicForceZ = this.unitService.convertUnitValueArgsToUnit(load.dynamicForceZ ?? 0, forceUnit, internalForceUnit);
            load.dynamicMomentX = this.unitService.convertUnitValueArgsToUnit(load.dynamicMomentX ?? 0, momentUnit, internalMomentUnit);
            load.dynamicMomentY = this.unitService.convertUnitValueArgsToUnit(load.dynamicMomentY ?? 0, momentUnit, internalMomentUnit);

            // negate if needed
            for (const header of this.loadsDropdownHeaders) {
                const numberType = header.dropdown.selectedValue as number;
                const loadNumber = this.getLoadNumber(load, numberType);

                if (numberType % 2 != 0 && loadNumber != null) {
                    this.setLoadNumber(load, numberType, -loadNumber);
                }
            }

            // clamp values, because they are not sent to backend updaters
            load.forceX = clamp(load.forceX, this.helper.forceXRange?.min ?? load.forceX, this.helper.forceXRange?.max ?? load.forceX);
            load.forceY = clamp(load.forceY, this.helper.forceYRange?.min ?? load.forceY, this.helper.forceYRange?.max ?? load.forceY);
            load.forceZ = clamp(load.forceZ, this.helper.forceZRange?.min ?? load.forceZ, this.helper.forceZRange?.max ?? load.forceZ);

            load.momentX = clamp(load.momentX, this.helper.momentXRange?.min ?? load.momentX, this.helper.momentXRange?.max ?? load.momentX);
            load.momentY = clamp(load.momentY, this.helper.momentYRange?.min ?? load.momentY, this.helper.momentYRange?.max ?? load.momentY);
            load.momentZ = clamp(load.momentZ, this.helper.momentZRange?.min ?? load.momentZ, this.helper.momentZRange?.max ?? load.momentZ);

            load.dynamicForceZ = clamp(load.dynamicForceZ, this.helper.dynamicForceZRange?.min ?? load.dynamicForceZ, this.helper.dynamicForceZRange?.max ?? load.dynamicForceZ);
            load.dynamicMomentX = clamp(load.dynamicMomentX, this.helper.dynamicMomentXRange?.min ?? load.dynamicMomentX, this.helper.dynamicMomentXRange?.max ?? load.dynamicMomentX);
            load.dynamicMomentY = clamp(load.dynamicMomentY, this.helper.dynamicMomentYRange?.min ?? load.dynamicMomentY, this.helper.dynamicMomentYRange?.max ?? load.dynamicMomentY);
        }

        // Filter out empty load combinations and consider max number of combinations
        this.design.designData.projectDesign.loads.loadCombinations = this.concatFilterLoadCombinations(
            this.projectDesign.loads.loadCombinations,
            importedLoads,
            this.maxLoads,
            true
        );

        this.design.designData.projectDesign.loads.selectedLoadCombinationId = this.design.designData.projectDesign.loads.loadCombinations[0].id;
    }

    private saveUserSettings() {
        if (this.userSettings.settings.application.curtainWall.applicationImportLoads == undefined) {
            throw new Error('CurtainWall application loads import settings are not defined.');
        }

        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeOne.value = this.loadsDropdownHeaders[0].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeTwo.value = this.loadsDropdownHeaders[1].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeThree.value = this.loadsDropdownHeaders[2].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeFour.value = this.loadsDropdownHeaders[3].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeFive.value = this.loadsDropdownHeaders[4].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeSix.value = this.loadsDropdownHeaders[5].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeSeven.value = this.loadsDropdownHeaders[6].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeEight.value = this.loadsDropdownHeaders[7].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.numberTypeNine.value = this.loadsDropdownHeaders[8].dropdown.selectedValue as ImportLoadsNumberType;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.forceUnit.value = this.forceUnitDropdown.selectedValue as Unit;
        this.userSettings.settings.application.curtainWall.applicationImportLoads.momentUnit.value = this.momentUnitDropdown.selectedValue as Unit;

        this.userSettings.save();
    }

    private save(form: NgForm) {
        if (this.submitted || (form.enabled && !form.valid) || this.importedLoads.length == 0) {
            return;
        }

        this.design.usageCounter.ExcelImportLoad++;

        this.submitted = true;
        this.pendingSave = true;

        // prepare importned loads
        this.prepareLoadCombinations();

        // save columns to user settings
        this.saveUserSettings();

        // run calculation
        this.calculationService.calculateAsync(
            this.design,
            undefined,
            { forceCalculation: true }
        )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    this.logger.log(err.message, LogType.error);
                }

                this.submitted = false;
            });
    }
}
