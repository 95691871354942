<pe-modal-header [title]="title" [modalInstance]="modalInstance">
</pe-modal-header>

<form>
    <pe-modal-body>
        <div class="section-row-margin">
            <span l10n="Agito.Hilti.CW.Navigation.TabProduct.SpecialInspectionType.Text"></span>
        </div>

        <div class=" section-row">
            <span l10n="Agito.Hilti.CW.Navigation.TabProduct.SpecialInspectionType.Bold.Text"></span>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button id="cw-inspection-type-popup-ok-button" look="Primary" class="modal-button" (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.Ok')" [disabled]="submitted">
        </pe-button>
    </pe-modal-footer>
</form>