import { Injectable } from '@angular/core';
import { CommonRegion as Region, ICommonRegionConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { SpecialRegion, SpecialRegionName } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { UserSettingsServiceInjected } from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { ConcreteSafetyFactorGammaC, DesignStandards as DesignStandardEnum } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { PropertyMetaData } from '../entities/properties';
import { ICwQuickStartSettingEntity, UserSettings } from '../entities/user-settings';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { LocalizationService } from './localization.service';
import { AppSettingsHelper } from '../helpers/app-settings-helper';
import { NumberService } from './number.service';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService extends UserSettingsServiceInjected<UserSettings> {
    private appSettingsHelper: AppSettingsHelper;

    constructor(
        private localization: LocalizationService,
        private commonCodeListService: CommonCodeListService,
        private codeListService: CodeListService,
        private numberService: NumberService
    ) {
        super();

        this.appSettingsHelper = new AppSettingsHelper(this.localization, this, this.codeListService, this.commonCodeListService, this.numberService);
    }

    public getNumberSeparators() {
        const numberDecimalSeparator = this.getDecimalSeparator()?.character || this.localization.numberFormat().NumberDecimalSeparator;
        const numberThousandsSeparator = this.getThousandsSeparator()?.character || this.localization.numberFormat().NumberGroupSeparator;

        return { numberDecimalSeparator, numberThousandsSeparator };
    }

    public getRegionById(regionId: number) {
        return this.commonCodeListService.getRegionById(regionId) ?? this.getGlobalOrDefaultRegion();
    }

    public getQuickStartSettingsData(): ICwQuickStartSettingEntity {
        const quickStartSettingsCw = this.settings.quickStart.curtainWall;
        const generalRegionField = this.getRegionById(quickStartSettingsCw.generalRegionId.value ?? KnownRegion.Unknown) ?? this.getGlobalOrDefaultRegion();
        const region = this.getSelectedOrGlobalAllowedRegion(generalRegionField.id);
        const regionId = region?.id ?? KnownRegion.Unknown;

        const designStandardPropVal = this.codeListService.getPropertyValue(PropertyMetaData.Option_CW_DesignStandard.id, regionId, 0);
        const designStandard = this.getDesignStandardById(quickStartSettingsCw.calculationDesignStandardId.value)
            ?? this.appSettingsHelper.getDefaultDesignStandard(regionId);
        const designStandardId = designStandard?.id ?? 0;

        const designMethodGroupPropVal = this.codeListService.getPropertyValue(PropertyMetaData.Option_CW_DesignMethodGroup.id, regionId, designStandardId);
        const defaultMethod = designStandardPropVal.designMethodGroupId != 0 ? designStandardPropVal.designMethodGroupId : designMethodGroupPropVal.defaultValue;
        const designMethodGroupId = this.appSettingsHelper.getDesignMethodIdOrDefault(regionId, designStandardId, quickStartSettingsCw.calculationDesignMethodGroupId.value ?? defaultMethod ?? 0);
        const designMethodGroup = this.appSettingsHelper.getDesignMethodById(designMethodGroupId);

        return {
            general_region: generalRegionField,
            calculation_designStandard: designStandard,
            calculation_designMethod: designMethodGroup,
            basePlateFactor: quickStartSettingsCw.basePlateFactor.value ?? undefined,
            safetyFactorPermLoad: quickStartSettingsCw.safetyFactorPermLoad.value ?? undefined,
            safetyFactorVarLoad: quickStartSettingsCw.safetyFactorVarLoad.value ?? undefined,
            minConcreteCover: quickStartSettingsCw.minConcreteCover.value ?? undefined,
            minAnchorProfileDist: quickStartSettingsCw.minAnchorProfileDist.value ?? undefined,
            concreteSafetyFactorGammaC: quickStartSettingsCw.concreteSafetyFactorGammaC.value ?? ConcreteSafetyFactorGammaC.GeneralUse,

            units_area: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitArea, quickStartSettingsCw.unitsAreaId.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitArea, region?.defaultUnitArea),

            units_force: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitForce, quickStartSettingsCw.unitsForceId.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitForce, region?.defaultUnitForce),

            units_length: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitLength, quickStartSettingsCw.unitsLengthId.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLength),

            units_moment: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitMoment, quickStartSettingsCw.unitsMomentId.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitMoment, region?.defaultUnitMoment),

            units_stress: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitStress, quickStartSettingsCw.unitsStressId.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStress),

            units_temperature: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitTemperature, quickStartSettingsCw.unitsTemperatureId.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitTemperature, region?.defaultUnitTemperature),

            units_force_per_length: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, quickStartSettingsCw.unitsForcePerLengthId.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, region?.defaultUnitForcePerLength),

            units_moment_per_length: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, quickStartSettingsCw.unitsMomentPerLengthId.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, region?.defaultUnitMomentPerLength),

            units_density: this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitDensity, quickStartSettingsCw.unitsDensity.value)
                ?? this.commonCodeListService.getUnitFromCodeList(CommonCodeList.UnitDensity, region?.defaultUnitDensity)
        };
    }

    private getSelectedOrGlobalAllowedRegion(id: number | undefined): Region | undefined {
        if (id == null) {
            return undefined;
        }

        if (id == SpecialRegion.Default && this.settings.application.general.regionId.value != null) {
            id = this.settings.application.general.regionId.value;
        }

        if (this.codeListService.designType?.regions.some(r => r == id)) {
            return this.getRegionById(id);
        }

        return this.getGlobalOrDefaultRegion();
    }

    private getGlobalOrDefaultRegion(): Region {
        return new Region({
            id: SpecialRegion.Default,
            countryCode: SpecialRegionName.Default
        } as ICommonRegionConstructor);
    }

    public getDesignStandardById(designStandardId: DesignStandardEnum | undefined | null) {
        return this.codeListService.getDesignStandards().find(designStandard => designStandard.id == designStandardId);
    }
}
