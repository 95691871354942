import { AnchoringSystem, IAnchoringSystemConstructor } from './anchoring-system';
import { SystemComponentCW } from './system-component';

export type IAnchoringSystemManagerConstructor = IAnchoringSystemConstructor;

export class AnchoringSystemManager extends SystemComponentCW {

    private components: AnchoringSystem[] = [];

    private ctor: IAnchoringSystemConstructor;

    constructor(ctor: IAnchoringSystemManagerConstructor) {
        super(ctor);

        this.ctor = ctor;

        this.update();
    }

    override update(): void {

        for (let i = this.components.length; i < this.model.anchoringSystems.length; i++) {
            this.components.push(new AnchoringSystem({ ...this.ctor, id: this.model.anchoringSystems[i].id }));
        }

        for (let i = 0; i < this.model.anchoringSystems.length; i++) {
            this.components[i].id = this.model.anchoringSystems[i].id;
        }

        this.components.forEach(c => {
            if (this.model.isPostInstallAnchorProduct && c.id != '1') {
                c.hide();
            }
            else {
                c.update();
            }
        });
    }

    public getComponents() {
        return this.components;
    }

}
