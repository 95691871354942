import { Subscription } from 'rxjs';

import { ChangeDetectorRef, Directive, Input, NgZone, OnChanges, OnDestroy } from '@angular/core';
import { DesignEvent } from '@profis-engineering/pe-ui-common/entities/design';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { Context3dKey } from '@profis-engineering/pe-ui-common/entities/context-3d';
import { CalculationService } from '../../services/calculation.service';

import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { TooltipService } from '../../services/tooltip.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { MathService } from '../../services/math.service';
import { Design } from '../../entities/design';
import { GLModelBase, IModel, Mode2d } from '@profis-engineering/gl-model/gl-model';
import { Update } from '@profis-engineering/gl-model/base-update';
import { UnitConverter, UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { PropertyInfo } from '@profis-engineering/gl-model/external/property-info';
import { EventNotifier, RemoveEvent, UndoRedoAction } from '@profis-engineering/gl-model/external/event-notifier';
import { MathCalculator } from '@profis-engineering/gl-model/external/math-calculator';
import { Tooltip, TooltipKey } from '@profis-engineering/gl-model/external/tooltip';
import { InputSettings, MouseClickType } from '@profis-engineering/gl-model/external/input-settings';
import { IModelCW } from '../../gl-model/base-component';
import { MaterialCacheCW } from '../../gl-model/cache/material-cache';
import { MeshCacheCW } from '../../gl-model/cache/mesh-cache';
import { UIProperty } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { ToolTipKeyCW } from '../../gl-model/tooltip';

export type GLEvent =
    'Zoom' |
    'Model_Loaded' |
    'Fonts_Loaded' |
    'Select_Tab' |
    'positionsChanged' |
    'draggingSelectionChanged';

@Directive()
export abstract class GLModelBaseComponent<TGLModelBase3D extends GLModelBase<IModelCW, UIProperty, EventNotifier, MaterialCacheCW, MeshCacheCW, ToolTipKeyCW, Mode2d>> implements OnDestroy, OnChanges {

    @Input()
    public continuousRender!: boolean;

    @Input()
    public context3dKey!: Context3dKey;

    @Input()
    public model!: IModelCW;

    protected glModel3d!: TGLModelBase3D;
    protected design: Design;

    protected glUnitConverter!: GLUnitConverter;
    protected glPropertyInfo!: GLPropertyInfo;
    protected glMathCalculator!: GLMathCalculator;
    protected glTooltip!: GLTooltip;
    protected glEventNotifier!: GLEventNotifier;
    protected glInputSettings!: GLInputSettings;

    private initialized = false;

    constructor(
        protected unitService: UnitService,
        protected numberService: NumberService,
        protected localizationService: LocalizationService,
        protected userService: UserService,
        protected mathService: MathService,
        protected tooltipService: TooltipService,
        protected modalService: ModalService,
        protected userSettingsService: UserSettingsService,
        protected calculationService: CalculationService,
        protected ngZone: NgZone,
        protected changeDetection: ChangeDetectorRef
    ) {
        this.design = userService.design;
    }

    public ngOnChanges(): void {
        if (this.model != null && !this.initialized) {
            const ensureInZone = createEnsureInZoneFunction(this.ngZone);

            this.glUnitConverter = new GLUnitConverter(this.unitService, this.numberService, this.localizationService, this.design);
            this.glPropertyInfo = new GLPropertyInfo(this.calculationService, this.design, ensureInZone);
            this.glMathCalculator = new GLMathCalculator(this.mathService);
            this.glTooltip = new GLTooltip(this.tooltipService, this.localizationService, ensureInZone);
            this.glEventNotifier = new GLEventNotifier(this.modalService, this.onEvent.bind(this), this.design, ensureInZone);
            this.glInputSettings = new GLInputSettings(this.userSettingsService);

            this.glModel3d = this.createGLModelBase3D();

            this.initialized = true;
        }
    }

    public ngOnDestroy(): void {
        this.glUnitConverter?.dispose();

        this.glModel3d?.dispose();
    }

    public resizeInternal() {
        this.glModel3d.resize();
    }

    public renderNextFrameInternal(count?: number) {
        this.glModel3d.renderNextFrame(count);
    }

    public resizeNextFrameInternal(which?: number, renderCount?: number) {
        this.glModel3d.resizeNextFrame(which, renderCount);
    }

    public updateInternal(model: IModel, replace?: boolean, changeDetection?: boolean): Promise<void> {
        return this.glModel3d.update(model, replace).finally(() => {
            if (changeDetection) {
                this.changeDetection.detectChanges();
            }
        });
    }

    public propertyValueChangedInternal(changes: Change[], design: Design, update: Update, model?: IModel): void {
        this.glModel3d.propertyValueChanged(changes, [], update, model, design);
    }

    public resetCameraInternal() {
        this.glModel3d.resetCamera();
    }

    public cameraZoomInternal(percentage: number) {
        this.glModel3d.cameraZoom(percentage);
    }

    public cameraZoomInInternal() {
        this.glModel3d.cameraZoomIn();
    }

    public cameraZoomOutInternal() {
        this.glModel3d.cameraZoomOut();
    }

    public getModelInternal(): IModel {
        return this.glModel3d.getModel();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onEvent(_event: GLEvent, _eventArgs?: unknown) { return; }

    protected abstract createGLModelBase3D(): TGLModelBase3D;
}

class GLUnitConverter implements UnitConverter<UIProperty> {
    private separatorChangeSubscription: Subscription;
    private separatorChangeCallbacks: Set<() => void> = new Set();

    constructor(
        private unitService: UnitService,
        private numberService: NumberService,
        private localizationService: LocalizationService,
        private design: Design
    ) {
        this.separatorChangeSubscription = this.localizationService.separatorChange.subscribe(() => {
            for (const callback of this.separatorChangeCallbacks) {
                callback();
            }
        });
    }

    public getNumberWithUnit(internalValue: number, unitGroup = UnitGroup.None): string {
        const defaultValue = this.unitService.convertInternalValueToDefaultUnitValue(internalValue, unitGroup as number);
        return this.unitService.formatUnitValue(defaultValue, Number.MAX_SAFE_INTEGER);
    }

    public format(internalValue: number, unitGroup = UnitGroup.None, property?: UIProperty): string {
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup as number);
        const precision = this.unitService.getPrecision(defaultUnit, property as number);
        const defaultValue = this.unitService.convertInternalValueToDefaultUnitValue(internalValue, unitGroup as number);

        return this.unitService.formatNumber(defaultValue.value, precision);
    }

    public formatWithUnit(internalValue: number, unitGroup = UnitGroup.None, property?: UIProperty): string {
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup as number);
        const precision = this.unitService.getPrecision(defaultUnit, property as number);
        const defaultValue = this.unitService.convertInternalValueToDefaultUnitValue(internalValue, unitGroup as number);

        return this.unitService.formatUnitValue(defaultValue, precision);
    }

    public parse(value: string, unitGroup: UnitGroup): number {
        const unitValue = this.unitService.parseUnitValue(value, unitGroup as number);

        return this.unitService.convertUnitValueToInternalUnitValue(unitValue)?.value ?? 0;
    }

    public round(value: number, decimals?: number): number {
        return this.numberService.round(value, decimals);
    }

    public formatChanged(fn: () => void): () => void {
        this.separatorChangeCallbacks.add(fn);
        this.design.onStateChanged(fn);

        return () => {
            this.separatorChangeCallbacks.delete(fn);
            this.design.off(DesignEvent.stateChanged, fn);
        };
    }

    public equals(firstValue: number, secondValue: number, precision: number): boolean {
        return this.numberService.equals(firstValue, secondValue, precision);
    }

    public dispose() {
        this.separatorChangeSubscription.unsubscribe();
        this.separatorChangeCallbacks = new Set();
    }
}

class GLPropertyInfo implements PropertyInfo<UIProperty> {
    constructor(
        private calculationService: CalculationService,
        private design: Design,
        private ensureInZone: EnsureInZone
    ) { }

    public isPropertyDisabled(property: UIProperty): boolean {
        return this.design.properties.get(property).disabled;
    }

    public isPropertyHidden(property: UIProperty): boolean {
        return this.design.properties.get(property).hidden;
    }

    public propertyDisabledChanged(_property: UIProperty, fn: () => void): RemoveEvent {
        this.design.onDisabledChanged(fn);

        return () => {
            this.design.off(DesignEvent.disabledChanged, fn);
        };
    }

    public setPropertyValue(property: UIProperty, value: unknown): void {
        this.ensureInZone(() => {
            // Update model and run calculation
            this.calculationService.calculateAsync(this.design,
                (design) => {
                    design.model[property] = value;
                }
            );
        });
    }

    public getPropertyValue(property: UIProperty): unknown {
        return this.design.model[property];
    }
}

class GLMathCalculator implements MathCalculator {
    private mathService: MathService;

    constructor(mathService: MathService) {
        this.mathService = mathService;
    }

    public tryComputeExactUnitValue(value: string, unitGroup?: UnitGroup, precision?: number): string {
        return this.mathService.tryComputeUnitValue(value, unitGroup as number, undefined, undefined, precision);
    }
}

class GLTooltip implements Tooltip<ToolTipKeyCW> {
    constructor(
        private tooltipService: TooltipService,
        private localizationService: LocalizationService,
        private ensureInZone: EnsureInZone
    ) { }

    public getTranslation(key: TooltipKey): string {
        return this.localizationService.getString(this.getTranslationKey(key));
    }

    public showTranslation(value: TooltipKey): void {
        this.ensureInZone(() => {
            this.tooltipService.show(this.localizationService.getString(this.getTranslationKey(value)));
        });
    }

    public hideTranslation(value: TooltipKey): void {
        this.ensureInZone(() => {
            this.tooltipService.hide(this.localizationService.getString(this.getTranslationKey(value)));
        });
    }

    public showText(value: string): void {
        this.ensureInZone(() => {
            this.tooltipService.show(value);
        });
    }

    public hideText(value: string): void {
        this.ensureInZone(() => {
            this.tooltipService.hide(value);
        });
    }

    private getTranslationKey(tooltipKey: ToolTipKeyCW) {
        switch (tooltipKey) {
            case 'Arrow':
                return 'Agito.Hilti.Profis3.GL.Arrow.Tooltip';
            case 'ConcreteBaseMaterialEdge':
                return 'Agito.Hilti.CW.Geometry.InfinityEdgeTooltip';
            case 'SelectPlateSystem':
                return 'Agito.Hilti.CW.Plate.SelectedSystemId.Tooltip';
            default:
                throw new Error('unknown TooltipKey');
        }
    }
}

type TriggerEvent = (event: GLEvent, eventArgs?: unknown) => void;

class GLEventNotifier implements EventNotifier {
    private alert3dErrorPopupOpend?: IModalOpened;

    constructor(
        private modalService: ModalService,
        private triggerEvent: TriggerEvent,
        private design: Design,
        private ensureInZone: EnsureInZone
    ) { }

    public webGLContextLost(): void {
        this.ensureInZone(() => {
            this.alert3dErrorPopupOpend = this.modalService.openAlertGLError();
        });
    }

    public webGLContextRestored(): void {
        this.ensureInZone(() => {
            if (this.alert3dErrorPopupOpend != null) {
                this.alert3dErrorPopupOpend.close();
                this.alert3dErrorPopupOpend = undefined;
            }
        });
    }

    public zoomChange(zoom: number): void {
        this.ensureInZone(() => {
            this.triggerEvent?.('Zoom', zoom);
        });
    }

    public fontsLoaded(): void {
        this.ensureInZone(() => {
            this.triggerEvent?.('Fonts_Loaded');
        });
    }


    public registerCurrentStateUndoRedoAction(action: UndoRedoAction): void {
        if (this.design.currentState != null) {
            this.design.currentState.undoRedoActions = this.design.currentState.undoRedoActions.filter(pendingAction => pendingAction.name != action.name);
            this.design.currentState.undoRedoActions.push(action);
        }
    }

    public positionsChanged2d(components: Record<string, boolean>): void {
        this.ensureInZone(() => {
            this.triggerEvent?.('positionsChanged', components);
        });
    }

    public draggingSelectionChanged2d(dragging: boolean): void {
        this.ensureInZone(() => {
            this.triggerEvent?.('draggingSelectionChanged', dragging);
        });
    }
}

/**
 * This should all be in IModel. Do NOT add new things here. Add them to IModel as an input to GLModel.
 */
class GLInputSettings implements InputSettings {
    private userSettingsService: UserSettingsService;

    constructor(userSettingsService: UserSettingsService) {
        this.userSettingsService = userSettingsService;
    }

    public get rotateMouseButton(): MouseClickType {
        return this.userSettingsService.controls3dSettings.rotate as number;
    }

    public get panMouseButton(): MouseClickType {
        return this.userSettingsService.controls3dSettings.pan as number;
    }
}

// gl-model events might be triggered outside angular zone (we don't want gl-model requestAnimationFrame to run inside angular)
// this function ensures that all "events" outside gl-model run in an angular zone
type EnsureInZone = <T>(fn: () => T) => T;
function createEnsureInZoneFunction(ngZone: NgZone): EnsureInZone {
    return <T>(fn: () => T): T => {
        if (NgZone.isInAngularZone()) {
            return fn();
        }
        else {
            return ngZone.run(fn);
        }
    };
}
