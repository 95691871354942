import { CodeList } from '../code-list';
import { EntityBase } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class NumberOfPlates extends CodeList {

    constructor(codeList: EntityBase) {
        super(codeList);
    }

    public static fromService(codeList: EntityBase) {
        return new NumberOfPlates(CodeList.baseFromService('NumberOfPlatesEntity', codeList));
    }

    public override cloneDeep(): NumberOfPlates {
        const codeList = super.cloneDeep() as NumberOfPlates;
        return codeList;
    }

}
