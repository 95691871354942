import { CodeList, ICodeListTextDeps } from '../code-list';
import { AnchorChannelFamilyEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class AnchorChannelFamily extends CodeList {
    public imageFoS?: string;

    constructor(codeList: AnchorChannelFamilyEntity) {
        super(codeList);

        if (codeList != null) {
            this.imageFoS = codeList.imageFoS;
        }
    }

    public static fromService(codeList: AnchorChannelFamilyEntity) {
        return new AnchorChannelFamily(CodeList.baseFromService('AnchorChannelFamilyEntity', codeList, {
            image: AnchorChannelFamily.getProductImageOrDefault(codeList.image),
            imageFoS: AnchorChannelFamily.getProductImageOrDefault(codeList.imageFoS)
        } as AnchorChannelFamilyEntity));
    }

    public override cloneDeep(): AnchorChannelFamily {
        const codeList = super.cloneDeep() as AnchorChannelFamily;
        codeList.imageFoS = this.imageFoS;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }

    private static getProductImageOrDefault(image: string | null | undefined): string {
        return image != null && image != '' ? `anchor-channel-type-${image}` : 'no-photo-available';
    }
}
