import { CodeList, ICodeListTextDeps } from '../code-list';
import { AnchorChannelFamilyGroupsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class AnchorChannelFamilyGroup extends CodeList {
    public sortOrder?: number;

    constructor(codeList: AnchorChannelFamilyGroupsEntity) {
        super(codeList);

        if (codeList != null) {
            this.sortOrder = codeList.sortOrder;
        }
    }

    public static fromService(codeList: AnchorChannelFamilyGroupsEntity) {
        return new AnchorChannelFamilyGroup(CodeList.baseFromService('AnchorChannelFamilyGroupEntity', codeList, {
            image: codeList.image != null && codeList.image != '' ? `anchor-channel-family-${codeList.image}` : 'no-photo-available',
            sortOrder: codeList.sortOrder
        } as AnchorChannelFamilyGroupsEntity));
    }

    public override cloneDeep(): AnchorChannelFamilyGroup {
        const codeList = super.cloneDeep() as AnchorChannelFamilyGroup;
        codeList.sortOrder = this.sortOrder;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
}
