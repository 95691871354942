import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { ApplicationTypes } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { Text2D } from './text/text-2d';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { cloneVertexData } from '@profis-engineering/gl-model/vertex-data-helper';
import { INumberedPosition } from './entities/numbered-position';
import { Color3 } from '@babylonjs/core/Maths/math.color';

export class BaseComponentHelper {
    public static isTopView(applicationType: ApplicationTypes): boolean {
        switch (applicationType) {
            case ApplicationTypes.TopOfSlab:
            case ApplicationTypes.TopOfSlabCorner:
                return true;
            case ApplicationTypes.FaceOfSlab:
            case ApplicationTypes.FaceOfCorner:
                return false;
            default:
                throw new Error('Unsupported application type.');
        }
    }

    public static isFaceOfCorner(applicationType: ApplicationTypes) {
        return applicationType == ApplicationTypes.FaceOfCorner;
    }

    public static isCorner(applicationType: ApplicationTypes): boolean {
        switch (applicationType) {
            case ApplicationTypes.TopOfSlab:
            case ApplicationTypes.FaceOfSlab:
                return false;
            case ApplicationTypes.TopOfSlabCorner:
            case ApplicationTypes.FaceOfCorner:
                    return true;
            default:
                throw new Error('Unsupported application type.');
        }
    }

    public static addNumberTextToMesh(textFn: () => Text2D, position: Vector3, textToDisplay: number | string, texts: Text2D[], transformNode?: TransformNode, color?: Color3): void {
        const text = textFn();
        text.setMaterial(color);
        text.setText(textToDisplay.toString());
        text.setEnabled(true);

        if (text.mesh == null)
            return;

        text.mesh.setEnabled(true);

        const mesh = text.mesh as Mesh;
        mesh.position = position.clone();

        if (transformNode != null)
            mesh.parent = transformNode;

        texts.push(text);
    }

    /**
     * Clones mesh and positions it along with projection/spacing logic
     * @returns doesnt return but sets meshes so it fits number of meshes and projection/spacing
     */
    public static replicateMeshToFullfilNumberOfInstances(meshToReplicate: Mesh | undefined, positions: INumberedPosition[], meshInstances: Mesh[], textFn: () => Text2D, texts: Text2D[] | undefined, transformNode?: TransformNode, textPositionsOffset?: Vector3): void {
        if (!meshToReplicate)
            return;

        let number = 1;
        for (const numberedPosition of positions) {
            const position = numberedPosition.position;

            const replicatedMesh = meshToReplicate.clone();
            replicatedMesh.position.x = position.x;
            meshInstances.push(replicatedMesh);

            if (texts) {
                const numberToRender = numberedPosition.id ?? number;
                BaseComponentHelper.addNumberTextToMesh(textFn, textPositionsOffset ? position.clone().add(textPositionsOffset) : position, numberToRender, texts, transformNode);
                number++;
            }
        }

        meshToReplicate.dispose();
    }

    public static replicateVertexDataToFullfilNumberOfInstances(vertexData: VertexData, positions: INumberedPosition[], textFn: () => Text2D, texts: Text2D[] | undefined, transformNode?: TransformNode, textPositionsOffset?: Vector3): VertexData[] {
        const vds = [];
        let number = 1;
        for (const numberedPosition of positions) {
            const position = numberedPosition.position;

            const vdClone = cloneVertexData(vertexData);
            vdClone.transform(Matrix.Translation(position.x, position.y, position.z));

            vds.push(vdClone);

            if (texts) {
                const numberToRender = numberedPosition.id ?? number;
                BaseComponentHelper.addNumberTextToMesh(textFn, textPositionsOffset ? position.add(textPositionsOffset) : position, numberToRender, texts, transformNode);
                number++;
            }
        }

        return vds;
    }
}
