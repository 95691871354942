// screenshot.service.ts
import { Injectable } from '@angular/core';
import { IGlModelComponent, IScreenShotSettingsCW } from '../gl-model/gl-model';
import { LoadsVisibilityInfo } from '@profis-engineering/gl-model/gl-model';
import { Color4 } from '@babylonjs/core/Maths/math.color';
import { UserService } from './user.service';
import { ApplicationTypes } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';

@Injectable({
  providedIn: 'root',
})
export class ScreenshotService {

  private glModelComponentRef!: IGlModelComponent;
  constructor(
    private userService: UserService
  ) { }

  public setGlModelInstance(instance: IGlModelComponent): void {
    this.glModelComponentRef = instance;
  }

  public async create3dPrintPreview(): Promise<string> {
    const screenShotSettings = this.getDefaultScreenShotSettings({
      imgHeight: 500,
      imgWidth: 500,
      preview: true
    });

    return this.createDesignScreenshot(screenShotSettings);
  }

  public async create3dGeometry(): Promise<string> {
    const screenShotSettings = this.getDefaultScreenShotSettings({
      cameraRotation: {
        x: 5500,
        y: 10000,
        z: -12200,
      }
    });

    return this.createDesignScreenshot(screenShotSettings);
  }

  private getFixturePosition(applicationType: ApplicationTypes) {
    switch (applicationType) {
      case ApplicationTypes.TopOfSlabCorner:
        return { x: -2800, y: 2000, z: -500 };
      case ApplicationTypes.FaceOfSlab:
        return { x: 250, y: 350, z: -700 };
      default:
        return { x: 120, y: 350, z: -220 };
    }
  }

  private async generate3dFixture(applicationType: ApplicationTypes): Promise<string> {
    const screenShotSettings = this.getDefaultScreenShotSettings({
      cameraRotation: this.getFixturePosition(applicationType),
      visibilityProperties: {
        baseMaterialTransparent: true,
        bracketTransparent: false,
        anchorChannelLenVisible: true,
        concreteDimensionVisible: false,
        boltSpacingVisible: true,
        bracketDimensionsVisible: true,
        bracketOffsetVisible: true,
        anchorNumberVisible: true,
        boltNumberVisible: true,
      },
    });

    return this.createDesignScreenshot(screenShotSettings);
  }

  public async create3dFixtureGroup(groupId: string, applicationType: ApplicationTypes): Promise<string> {
    if (this.userService.design == null) {
      return Promise.resolve('');
    }

    const selectedAnchoringSystemId = this.userService.design.selectedAnchoringSystemId;
    const selectedBasePlateSystemId = this.userService.design.getSelectedBasePlateSystemId(selectedAnchoringSystemId);

    try {
      if (groupId != selectedBasePlateSystemId) {
        await this.preparePlateSystemForScreenshot(groupId);
      }

      return await this.generate3dFixture(applicationType);
    }
    finally {
      if (groupId != selectedBasePlateSystemId) {
        // Set the original plate system back
        await this.preparePlateSystemForScreenshot(selectedBasePlateSystemId);
      }
    }
  }

  private async preparePlateSystemForScreenshot(plateSystemId: string) {
    this.userService.design.setSelectedBasePlateSystemId(plateSystemId);
    await this.glModelComponentRef.update(this.glModelComponentRef.getModel());
  }

  public getDefaultScreenShotSettings(overrides: Partial<IScreenShotSettingsCW> = {}): IScreenShotSettingsCW {
    const defaultSettings: IScreenShotSettingsCW = {
      isThumbnail: false,
      imgWidth: 1400,
      imgHeight: 1000,
      zoomed: false,
      preview: false,
      loadsVisibilityInfo: {
        preview: false,
        modifyLoads: false,
      } as LoadsVisibilityInfo,
      backgroundColor: new Color4(0, 0, 0, 0),
      visibilityProperties: {
        baseMaterialTransparent: true,
        bracketTransparent: false,
        anchorChannelLenVisible: false,
        concreteDimensionVisible: true,
        boltSpacingVisible: false,
        bracketDimensionsVisible: false,
        bracketOffsetVisible: false,
        anchorNumberVisible: true,
        boltNumberVisible: true,
        symmetricCornerVisible: false
      }
    };

    return { ...defaultSettings, ...overrides };
  }

  private async createDesignScreenshot(settings: IScreenShotSettingsCW): Promise<string> {
    if (this.userService.design == null) {
      return Promise.resolve('');
    }

    return this.glModelComponentRef.createDesignScreenshot(settings);
  }

}
