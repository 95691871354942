import cloneDeep from 'lodash-es/cloneDeep';
import { IDesignState } from '../entities/design';
import { DesignEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { ProductEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design.Product';
import { ApplicationTypes } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { PropertyMetaData } from '../entities/properties';

export class DesignHelper {

    public static simplifyDesign(projectDesign: DesignEntity): DesignEntity {
        const simplifiedDesign = cloneDeep(projectDesign);

        this.pruneProduct(simplifiedDesign.product);

        simplifiedDesign.anchoringSystems.forEach(anchoringSystem => {
            this.pruneProduct(anchoringSystem.product);
        });

        return simplifiedDesign;
    }

    private static pruneProduct(product: ProductEntity) {
        product.anchorChannel.crossSectionNodes = [];
    }

    public static isTopView(applicationType: ApplicationTypes | undefined): boolean {
        switch (applicationType) {
            case ApplicationTypes.TopOfSlab:
            case ApplicationTypes.TopOfSlabCorner:
                return true;
            default:
                return false;
        }
    }

    private static isPostInstallAnchorProduct(uiPropertyId: number): boolean {
        return uiPropertyId < 0;
    }

    public static hasProductFasteningTechologyChanged(oldFamilyGroupId: number, newFamilyGroupId:number): boolean {
        return this.isPostInstallAnchorProduct(oldFamilyGroupId) !== this.isPostInstallAnchorProduct(newFamilyGroupId);
    }

    public static hasProductFasteningTechologyStateChanged(state: IDesignState, oldState: IDesignState): boolean {
        return this.hasProductFasteningTechologyChanged(oldState.model[PropertyMetaData.AnchorChannel_CW_Family.id] as number, state.model[PropertyMetaData.AnchorChannel_CW_Family.id] as number);
    }

}