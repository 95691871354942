import { BaseComponent, IBaseComponentConstructor } from '@profis-engineering/gl-model/components/base-component';
import { IModel, Mode2d } from '@profis-engineering/gl-model/gl-model';
import { IAnchorChannel } from '../components/gl-model/components/anchor-channel';
import { IBaseMaterial } from '../components/gl-model/components/base-material';
import { IPlateBracket } from '../components/gl-model/components/plate-bracket';
import { IBolt } from '../components/gl-model/components/bolt';
import { ApplicationTypes } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Scene } from '@babylonjs/core/scene';
import { Cache } from '../gl-model/cache/cache';
import { BaseComponentHelper } from './base-component-helper';
import { ILoadCombinationValues } from '../components/gl-model/components/loads-manager';
import { IRebarPlate } from '../components/gl-model/components/rebar-plate';
import { UIProperty } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { EventNotifier } from '@profis-engineering/gl-model/external/event-notifier';
import { MaterialCacheCW } from './cache/material-cache';
import { MeshCacheCW } from './cache/mesh-cache';
import { ToolTipKeyCW } from './tooltip';
import { PlatePosition } from './gl-model';
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh.js';
import { Vector3 } from '@babylonjs/core/Maths/math';
import { LocalizationService } from '../services/localization.service';

export interface IBaseComponentConstructorCW extends IBaseComponentConstructor<IModelCW, UIProperty, EventNotifier, MaterialCacheCW, MeshCacheCW, ToolTipKeyCW, Mode2d> {
    setPlateLights: (platePosition: PlatePosition) => void;
    plateFrontLight: DirectionalLight;
    plateBackLight: DirectionalLight;
    localizationService: LocalizationService;
}

export abstract class BaseComponentCW extends BaseComponent<IModelCW, UIProperty, EventNotifier, MaterialCacheCW, MeshCacheCW, ToolTipKeyCW, Mode2d> {
    protected _id: string;
    protected _plateSystemId: string;
    protected plateFrontLight: DirectionalLight;
    protected plateBackLight: DirectionalLight;
    protected setPlateLights: (platePosition: PlatePosition) => void;
    protected localizationService: LocalizationService;

    constructor(ctor: IBaseComponentConstructorCW) {
        super(ctor);

        this._id = '';
        this._plateSystemId = '';
        this.model = ctor.model;
        this.plateFrontLight = ctor.plateFrontLight;
        this.plateBackLight = ctor.plateBackLight;
        this.setPlateLights = ctor.setPlateLights;
        this.localizationService = ctor.localizationService;
    }

    protected calculateTransformNode(): TransformNode {
        return BaseComponentCW.calculateTransformNode(this.model, this.cache, this.scene);
    }

    protected getGlobalTransformNode(localPosition: Vector3 = Vector3.Zero(), localRotation: Vector3 = Vector3.Zero()): TransformNode {
        const name = `TransformNode_CW_${localPosition.toString()}.${localRotation.toString()}`;

        const localTransform = this.cache.meshCache.create(name, name => new TransformNode(name, this.scene));
        localTransform.rotation = Vector3.Zero();

        // Apply rotation for corner anchor system
        localTransform.addRotation(0, localRotation.y, 0);

        // Apply rotation for front of slab
        localTransform.addRotation(-localRotation.z, 0, 0);

        localTransform.setPositionWithLocalVector(BaseComponentHelper.isFaceOfCorner(this.model.applicationType) ? new Vector3(localPosition.x, localPosition.z, 0) : new Vector3(localPosition.x, localPosition.y, -localPosition.z));

        const globalTransform = this.cache.meshCache.create('TransformNode_CW', name => new TransformNode(name, this.scene));

        localTransform.parent = globalTransform;

        return localTransform;
    }

    protected setPlateLightsForMesh(...meshes: AbstractMesh[]): void {
        for (const mesh of meshes) {
            this.frontLight.excludedMeshes.push(mesh);
            this.backLight.excludedMeshes.push(mesh);

            this.plateFrontLight.includedOnlyMeshes.push(mesh);
            this.plateBackLight.includedOnlyMeshes.push(mesh);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public hide(): void {
        // Nothing to do
    }

    public get id() {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get plateSystemId() {
        return this._plateSystemId;
    }

    public set plateSystemId(value: string) {
        this._plateSystemId = value;
    }

    private static calculateTransformNode(model: IModelCW, cache: Cache, scene: Scene): TransformNode {
        return cache.meshCache.create('TransformNode_CW', name => new TransformNode(name, scene));
    }
}

export interface IVisibilityProperties {
    baseMaterialTransparent?: boolean;
    bracketTransparent?: boolean;
    anchorChannelLenVisible?: boolean;
    concreteDimensionVisible?: boolean;
    boltSpacingVisible?: boolean;
    bracketDimensionsVisible?: boolean;
    bracketOffsetVisible?: boolean;
    anchorNumberVisible?: boolean;
    boltNumberVisible?: boolean;
    symmetricCornerVisible?: boolean;
}

export interface IAnchoringSystem {
    id: string;
    geometry: IAnchoringSystemGeometry;
    anchorChannel: IAnchorChannel;
    rebarPlate: IRebarPlate;
    basePlateSystems: IBasePlateSystem[];
    isCorner: boolean;
}

export interface IAnchoringSystemGeometry {
    position: Vector3;
    rotation: Vector3;
}

export interface IBasePlateSystem {
    id: string;
    parentId?: string;
    plateBracket: IPlateBracket;
    bolt: IBolt;
}

export interface IModelCW extends IModel {
    applicationType: ApplicationTypes;
    baseMaterial: IBaseMaterial;
    anchoringSystems: IAnchoringSystem[];
    visibilityProperties: IVisibilityProperties;
    loadCombinations: ILoadCombinationValues[];
    isPostInstallAnchorProduct: boolean;

    anchoringSystem: (model: IModelCW, anchoringSystemId: string) => IAnchoringSystem;
    isAnchoringSystemSelected: (anchoringSystemId: string) => boolean;
    isAnchorChannelAvailable: (model: IModelCW, id: string) => boolean;

    basePlateSystem: (model: IModelCW, basePlateSystemId: string, anchoringSystemId: string) => IBasePlateSystem;
    isBasePlateSystemSelected: (basePlateSystemId: string, anchoringSystemId: string) => boolean;
}
