import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { DesignMethodGroups } from './generated-modules/Hilti.CW.CalculationService.Shared.Enums';

export class Constants {
    static readonly DemoQuery = 'cwDemo';
    static readonly ModuleIndex = 2;
    static readonly DesignPath = 'design';
    static readonly CurtainWallDesignTypeTranslationKey = 'CurtainWall';
    static readonly DebugQuery = 'debug';
    static readonly DebugMode = 'debugmode';
    static readonly UserDefinedId = -1;
    static readonly IsEdgeDistanceLockingEnabled = false;
    static readonly FirestopRegionAllowed = [
        KnownRegion.UnitedStates,
        KnownRegion.Canada,
        KnownRegion.Mexico
    ];
    static readonly FirestopDesignMethodAllowed = [
        DesignMethodGroups.ACI31811,
        DesignMethodGroups.ACI31814,
        DesignMethodGroups.ACI31819,
        DesignMethodGroups.CSAA23314,
        DesignMethodGroups.CSAA23319
    ];
    static readonly FirestopLink = 'https://constructionplatform.hilti.com/microapp/firestop/search-type';
    static readonly FirestopTabName = 'cw-firestop-tab';
    static readonly MaxLoadCombinations = 20;
    static readonly SymmetricCornerText = 'Symmetric corner';
}
