import { CodeList } from '../code-list';
import { SeismicDesignTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';
import { ICodeListConstructor, ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

export interface ISeismicDesignTypeConstructor extends ICodeListConstructor {
    descriptionResourceKey?: string;
}

export class SeismicDesignTypes extends CodeList {

    public descriptionResourceKey?: string;

    constructor(codeList: SeismicDesignTypeEntity) {
        super(codeList);
    }

    public static fromService(codeList: SeismicDesignTypeEntity) {
        const seismicDesignType = new SeismicDesignTypes(CodeList.baseFromService('SeismicDesignTypeEntity', codeList, {
            image: codeList.image != null && codeList.image != '' ? `${codeList.image}` : 'no-photo-available'
        } as SeismicDesignTypeEntity));

        seismicDesignType.descriptionResourceKey = seismicDesignType.nameResourceKey != null && seismicDesignType.nameResourceKey != '' ? seismicDesignType.nameResourceKey + '.Description' : undefined;

        return seismicDesignType;
    }

    public override getTranslatedDescriptionText(codeListDeps: ICodeListTextDeps) {
        if (this.descriptionResourceKey == null || this.descriptionResourceKey == '') {
            return undefined;
        }

        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        return codeListDeps.localizationService.getString(this.descriptionResourceKey);
    }

    public override cloneDeep(): SeismicDesignTypes {
        const codeList = super.cloneDeep() as SeismicDesignTypes;

        codeList.descriptionResourceKey = this.descriptionResourceKey;

        return codeList;
    }
}
