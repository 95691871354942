import { CodeList, ICodeListConstructor } from '../code-list';
import { DesignStandardsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export interface IDesignStandardConstructor extends ICodeListConstructor {
    id: number;
    displayKey: string;
    disabled?: boolean;
    descriptionResourceKey?: string;
}

export class DesignStandard extends CodeList {
    public disabled?: boolean;
    public descriptionResourceKey?: string | null;

    constructor(codeList: IDesignStandardConstructor) {
        super(codeList);

        if (codeList != null) {
            this.id = codeList.id;
            this.displayKey = codeList.displayKey;
            this.disabled = codeList.disabled;
            this.descriptionResourceKey = codeList.descriptionResourceKey;
        }
    }

    public static fromService(codeList: DesignStandardsEntity) {
        const designStandard = new DesignStandard(CodeList.baseFromService<IDesignStandardConstructor>('DesignStandardsEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
        }));

        designStandard.descriptionResourceKey = designStandard.nameResourceKey != null && designStandard.nameResourceKey != '' ? designStandard.nameResourceKey + '.Description' : null;
        return designStandard;
    }

    public override cloneDeep() {
        const codeList: DesignStandard = super.cloneDeep() as DesignStandard;

        codeList.id = this.id;
        codeList.displayKey = this.displayKey;
        codeList.disabled = this.disabled;
        codeList.descriptionResourceKey = this.descriptionResourceKey;

        return codeList;
    }
}
