<div class="design-section box-section box-section-white">
    <div class="box-section-content">
        <div class="design-section-row">
            <button id="export-button" type="button" class="generate-report-button button button-primary"
                (click)="openExportReport()"
                [disabled]="generateReportDisabled || loading">
                <pe-loading-button
                    [color]="'#ffffff'">
                    <div class="generate-report-button-container">
                        <span class="text" l10n="Agito.Hilti.CW.Main.GenerateReport"></span>
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-long-arrow-right-white"></span>
                    </div>
                </pe-loading-button>
            </button>
        </div>

        <div class="design-section-row">
            <button id="export-button" type="button" class="export-button button button-design-section" (click)="openExport()"
                [ngbTooltip]="translate('Agito.Hilti.CW.ExportDesign')">
                <div class="button-sprite-wrapper">
                    <span class="pe-ui-cw-sprite pe-ui-cw-sprite-export-design"></span>
                </div>
            </button>

            <button id="duplicate-button" type="button" class="duplicate-button button button-design-section"
                (click)="duplicateDesign()"[disabled]="duplicating || isDesignInReadOnlyMode"
                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.DuplicateDesign')" placement="top">
                <div class="button-sprite-wrapper">
                    <pe-loading-button
                        [loading]="duplicating">
                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-duplicate-design"></span>
                    </pe-loading-button>
                </div>
            </button>

            <button id="open-button" type="button" class="open-button button button-design-section"
            (click)="selectOpenFile()" [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.OpenDesign')">
                <div class="button-sprite-wrapper">
                    <pe-loading-button [loading]="openDesign">
                        <span *ngIf="!offlineService.isOffline" class="pe-ui-cw-sprite pe-ui-cw-sprite-openfile-d-light"></span>
                    </pe-loading-button>
                </div>
            </button>

            <input class="open-design-input" #openDesignInput (change)="importFileSelected()" name="project" type="file"
                [accept]="allowedExtensions" (click)="$event.stopPropagation();" />
        </div>
    </div>
</div>
