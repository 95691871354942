import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { IModelCW } from '../../../gl-model/base-component';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { UnitGroup } from '@profis-engineering/gl-model/external/unit-converter';
import { BaseUpdateCW } from '../../../gl-model/base-update';

export class BaseMaterialUpdater extends BaseUpdateCW {

    @propertyValueChanged(UIProperty.Geometry_CW_Thickness)
    private Geometry_CW_Thickness(value: number, model: IModelCW) {
        model.baseMaterial.thickness = value;
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceNegativeXInfinity)
    private Geometry_CW_EdgeDistanceNegativeXInfinity(value: boolean, model: IModelCW) {
        model.baseMaterial.xNegativeInfinity = value;
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceNegativeX)
    private Geometry_CW_EdgeDistanceNegativeX(value: number | string, model: IModelCW) {
        model.baseMaterial.xNegative = this.convert(value);
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceXInfinity)
    private Geometry_CW_EdgeDistanceXInfinity(value: boolean, model: IModelCW) {
        model.baseMaterial.xPositiveInfinity = value;
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceX)
    private Geometry_CW_EdgeDistanceX(value: number | string, model: IModelCW) {
        model.baseMaterial.xPositive = this.convert(value);
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceNegativeYInfinity)
    private Geometry_CW_EdgeDistanceNegativeYInfinity(value: boolean, model: IModelCW) {
        model.baseMaterial.yNegativeInfinity = value;
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceNegativeY)
    private Geometry_CW_EdgeDistanceNegativeY(value: number | string, model: IModelCW) {
        model.baseMaterial.yNegative = this.convert(value);
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceYInfinity)
    private Geometry_CW_EdgeDistanceYInfinity(value: boolean, model: IModelCW) {
        model.baseMaterial.yPositiveInfinity = value;
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceY)
    private Geometry_CW_EdgeDistanceY(value: number | string, model: IModelCW) {
        model.baseMaterial.yPositive = this.convert(value);
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceNegativeZ)
    private Geometry_CW_EdgeDistanceNegativeZ(value: number | string, model: IModelCW) {
        model.baseMaterial.zNegative = this.convert(value);
    }

    @propertyValueChanged(UIProperty.Geometry_CW_EdgeDistanceNegativeZInfinity)
    private Geometry_CW_EdgeDistanceNegativeZInfinity(value: boolean, model: IModelCW) {
        model.baseMaterial.zNegativeInfinity = value;
    }

    @propertyValueChanged(UIProperty.Option_CW_ApplicationType)
    private Option_CW_ApplicationType(value: number, model: IModelCW) {
        model.applicationType = value;
    }

    private convert(value: number | string): number {
        if (typeof value === 'number') {
            return value;
        }

        return this.unitConverter.parse(value, UnitGroup.Length);
    }
}
