import { Design } from '../entities/design';
import { UIProperty } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';

export class PropertiesHelper {
    public static IsLongitudinalShearCalculationShown(design: Design) {
        const longitudinalShearCalculationProperty = design.properties.get(UIProperty.Loads_CW_LongitudinalShearCalculation);
        return !longitudinalShearCalculationProperty.hidden && !longitudinalShearCalculationProperty.disabled;
    }

    public static IsLongitudinalShearCalculationApplicableInDesignMethod(design: Design) {
        return design.hasUtilizations && this.IsLongitudinalShearCalculationShown(design);
    }
}
