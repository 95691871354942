import { CodeList } from '../code-list';
import { ApplicationTypesEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class ApplicationTypes extends CodeList {
    public allowed?: boolean;
    public disabled?: boolean;

    constructor(codeList: ApplicationTypesEntity) {
        super(codeList);

        if (codeList != null) {
            this.image = codeList.image;
            this.allowed = codeList.allowed;
            this.disabled = codeList.disabled;
        }
    }

    public static fromService(codeList: ApplicationTypesEntity) {
        return new ApplicationTypes(CodeList.baseFromService('ApplicationTypesEntity', codeList, {
            image: codeList.image,
            allowed: codeList.allowed,
            disabled: codeList.disabled
        } as ApplicationTypesEntity));
    }

    public override cloneDeep(): ApplicationTypes {
        const codeList = super.cloneDeep() as ApplicationTypes;
        codeList.image = this.image;
        codeList.allowed = this.allowed;
        codeList.disabled = this.disabled;

        return codeList;
    }
}
