
<pe-modal-header [modalInstance]="modalInstance" [title]="title">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="section">
            <p class="title-strike title">{{subtitle}}</p>

            <div class="section-row">
                <div class="section-column loads-input-column">
                    <div class="control-row" [hidden]="design.properties.get(propertyMetaData.LoadCombination_CW_ForceZ.id).hidden">
                        <pe-numeric-text-box
                            [id]="forceZTextBox.id"
                            [title]="forceZTextBox.title"
                            [value]="forceZTextBox.value"
                            (valueChange)="forceZTextBox.value = $any($event).detail"
                            [unit]="forceZTextBox.unit"
                            [minValue]="forceZTextBox.minValue"
                            [maxValue]="forceZTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || design.properties.get(propertyMetaData.LoadCombination_CW_ForceZ.id).disabled">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row" [hidden]="design.properties.get(propertyMetaData.LoadCombination_CW_ForceX.id).hidden">
                        <pe-numeric-text-box
                            [id]="forceXTextBox.id"
                            [title]="forceXTextBox.title"
                            [value]="forceXTextBox.value"
                            (valueChange)="forceXTextBox.value = $any($event).detail"
                            [unit]="forceXTextBox.unit"
                            [minValue]="forceXTextBox.minValue"
                            [maxValue]="forceXTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || design.properties.get(propertyMetaData.LoadCombination_CW_ForceX.id).disabled">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row" [hidden]="design.properties.get(propertyMetaData.LoadCombination_CW_ForceY.id).hidden">
                        <pe-numeric-text-box
                            [id]="forceYTextBox.id"
                            [title]="forceYTextBox.title"
                            [value]="forceYTextBox.value"
                            (valueChange)="forceYTextBox.value = $any($event).detail"
                            [unit]="forceYTextBox.unit"
                            [minValue]="forceYTextBox.minValue"
                            [maxValue]="forceYTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || design.properties.get(propertyMetaData.LoadCombination_CW_ForceY.id).disabled">
                        </pe-numeric-text-box>
                    </div>
                </div>

                <div class="section-column loads-input-column">
                    <div class="control-row" [hidden]="design.properties.get(propertyMetaData.LoadCombination_CW_MomentZ.id).hidden">
                        <pe-numeric-text-box
                            [id]="momentZTextBox.id"
                            [title]="momentZTextBox.title"
                            [value]="momentZTextBox.value"
                            (valueChange)="momentZTextBox.value = $any($event).detail"
                            [unit]="momentZTextBox.unit"
                            [minValue]="momentZTextBox.minValue"
                            [maxValue]="momentZTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || design.properties.get(propertyMetaData.LoadCombination_CW_MomentZ.id).disabled">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row" [hidden]="design.properties.get(propertyMetaData.LoadCombination_CW_MomentX.id).hidden">
                        <pe-numeric-text-box
                            [id]="momentXTextBox.id"
                            [title]="momentXTextBox.title"
                            [value]="momentXTextBox.value"
                            (valueChange)="momentXTextBox.value = $any($event).detail"
                            [unit]="momentXTextBox.unit"
                            [minValue]="momentXTextBox.minValue"
                            [maxValue]="momentXTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || design.properties.get(propertyMetaData.LoadCombination_CW_MomentX.id).disabled">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row" [hidden]="design.properties.get(propertyMetaData.LoadCombination_CW_MomentY.id).hidden">
                        <pe-numeric-text-box
                            [id]="momentYTextBox.id"
                            [title]="momentYTextBox.title"
                            [value]="momentYTextBox.value"
                            (valueChange)="momentYTextBox.value = $any($event).detail"
                            [unit]="momentYTextBox.unit"
                            [minValue]="momentYTextBox.minValue"
                            [maxValue]="momentYTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || design.properties.get(propertyMetaData.LoadCombination_CW_MomentY.id).disabled">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button id="input-loads-cancel-button" type="button" class="modal-button"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button id="input-loads-save-button" class="modal-button" type="submit"
            [look]="'Cta'"
            [text]="translate('Agito.Hilti.Profis3.LoadsInput.Save')"
            [loading]="submitted"
            [disabled]="(ngForm.enabled && !ngForm.valid) || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="translate('Agito.Hilti.Profis3.LoadsInput.Save')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>

</form>
