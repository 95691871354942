import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { CodeList } from '../code-list';
import { LoadTolerancesEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

export class LoadTolerances extends CodeList {
    public prefix?: string;
    public value?: number;
    public valueImperial?: number;

    constructor(codeList: any) {
        super(codeList);

        if (codeList != null) {
            this.prefix = codeList.prefix;
            this.value = codeList.value;
            this.valueImperial = codeList.valueImperial;
        }
    }

    public static formatItemText(userService: UserService, unitService: UnitService, localizationService: LocalizationService, codeListItem: LoadTolerances, unit?: Unit) {
        const selectedUnit = unit ?? userService.design.unitLength;
        const defaultUnit: Unit = selectedUnit;

        const value = unitService.isMetric(defaultUnit) ? codeListItem.value : codeListItem.valueImperial;

        if (value != null) {
            const internalUnit = unitService.getInternalUnit(UnitGroup.Length);
            const defaultPrecision = unitService.getPrecision(defaultUnit);
            const lengthUnitUnit = unitService.formatUnitValueArgs(unitService.convertUnitValueArgsToUnit(value ?? 0, internalUnit, defaultUnit), defaultUnit, defaultPrecision);

            return `${codeListItem.prefix} ${lengthUnitUnit}`;
        }

        return localizationService.getString(codeListItem.nameResourceKey ?? '');
    }

    public static fromService(codeList: LoadTolerancesEntity) {
        return new LoadTolerances(CodeList.baseFromService('LoadTolerancesEntity', codeList, {
            prefix: codeList.prefix,
            value: codeList.value,
            valueImperial: codeList.valueImperial
        } as LoadTolerancesEntity));
    }

    public override cloneDeep(): LoadTolerances {
        const codeList = super.cloneDeep() as LoadTolerances;
        codeList.prefix = this.prefix;
        codeList.value = this.value;
        codeList.valueImperial = this.valueImperial;

        return codeList;
    }
}