import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { MaterialCache } from '@profis-engineering/gl-model/cache/material-cache';

export class MaterialCacheCW extends MaterialCache {
    public static readonly steelColor = Color3.FromHexString('#a0a0a0');
    public static readonly boltColor = Color3.FromHexString('#ee0011');
    public static readonly concreteEdgeColor = Color3.FromHexString('#FF0000');
    public static readonly symmetricCornerColor = Color3.FromHexString('#6e30a6');
    public static readonly transparentConcreteColor = Color3.FromHexString('#b6b4a6');
    public static readonly standoffGroutingMaterial = Color3.FromHexString('#e3e3c7');
    public static readonly plateBracketHighlightedĆColor = Color3.FromHexString('#c87878');

    public static readonly transparency = 0.5;

    public get steelMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.Steel', {
            diffuseColor: MaterialCacheCW.steelColor
        });
    }

    public get transparentSteelMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.Steel.Transparent', {
            diffuseColor: MaterialCacheCW.steelColor,
            alpha: MaterialCacheCW.transparency
        });
    }

    public get boltMaterial(): StandardMaterial {
        return this.createStandardMaterial('Bolt.Material', {
            diffuseColor: MaterialCacheCW.boltColor
        });
    }

    public get concreteEdgeMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.ConcreteEdge', {
            diffuseColor: MaterialCacheCW.concreteEdgeColor,
            alpha: 0
        });
    }

    public get concreteEdgeHighlightedMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.ConcreteEdgeHighlighted', {
            diffuseColor: MaterialCacheCW.concreteEdgeColor,
            alpha: 0.1
        });
    }

    public get transparentConcreteMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.TransparentConcrete', {
            diffuseColor: MaterialCacheCW.transparentConcreteColor,
            alpha: MaterialCacheCW.transparency
        });
    }

    public get concreteMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.Concrete', {
            diffuseColor: MaterialCacheCW.transparentConcreteColor
        });
    }

    public get standoffGroutingMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.StandoffGroutingMaterial', {
            diffuseColor: MaterialCacheCW.standoffGroutingMaterial
        });
    }

    public get plateBracketHighlightedMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.PlateBracketHighlighted', {
            diffuseColor: MaterialCacheCW.plateBracketHighlightedĆColor,
            alpha: MaterialCacheCW.transparency
        });
    }

    public get symmetricCornerHighlightedMaterial(): StandardMaterial {
        return this.createStandardMaterial('Material.SymmetricCornerHighlightedMaterial', {
            diffuseColor: MaterialCacheCW.symmetricCornerColor,
            alpha: 0.2
        });
    }

}
