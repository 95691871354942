import { ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CodeList } from '../code-list';
import { BaseMaterialCodeListEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { Constants } from '../constants';

export class BaseMaterials extends CodeList {

    constructor(codeList: any) {
        super(codeList);
    }

    public static fromService(codeList: BaseMaterialCodeListEntity) {
        return new BaseMaterials(CodeList.baseFromService('BaseMaterialCodeListEntity', codeList));
    }

    public override cloneDeep(): BaseMaterials {
        return super.cloneDeep() as BaseMaterials;
    }

    public override getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        return this.id == Constants.UserDefinedId && codeListDeps.localizationService
            ? codeListDeps.localizationService.getString(this.displayKey ?? '')
            : this.displayKey;
    }
}
