import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { Design } from '../../../entities/design';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { IModelCW } from '../../../gl-model/base-component';
import { BaseUpdateCW } from '../../../gl-model/base-update';
import { toVector3 } from '../helpers/geometry-mappers';

export class RebarPlateUpdater extends BaseUpdateCW {

    @propertyValueChanged(UIProperty.AnchorChannel_CW_RebarPlate)
    private AnchorChannel_CW_RebarPlate(_value: number, model: IModelCW, design: Design) {
        if (design.rebarPlate) {
            model.anchoringSystems.forEach((acs, i) => {
                const designAcs = design.projectDesign.anchoringSystems[i];
                const rebarPlateGeometry = designAcs.product.anchorChannel.rebarPlate.geometry;

                acs.rebarPlate.length = rebarPlateGeometry.length;
                acs.rebarPlate.height = rebarPlateGeometry.height;
                acs.rebarPlate.thickness = rebarPlateGeometry.thickness;
                acs.rebarPlate.x_pl = rebarPlateGeometry.xPl;
                acs.rebarPlate.noOfRebars = rebarPlateGeometry.noOfRebars;
                acs.rebarPlate.rebarDiameter = rebarPlateGeometry.rebarDiameter;
                acs.rebarPlate.rebarSpacing = rebarPlateGeometry.rebarSpacing;
                acs.rebarPlate.rebarLength = rebarPlateGeometry.rebarLength;
                acs.rebarPlate.x_r = rebarPlateGeometry.xr;
                acs.rebarPlate.e_r = rebarPlateGeometry.er;
                acs.rebarPlate.position = toVector3(designAcs.product.anchorChannel.rebarPlate.platePosition);
                acs.rebarPlate.rebarPositions = designAcs.product.anchorChannel.rebarPlate.rebarPositions.map(point => {
                    return {
                        id: point.id,
                        position: toVector3(point)
                    };
                }) ?? [];
            });
        }
        else {
            model.anchoringSystems.forEach(acs => {
                acs.rebarPlate.noOfRebars = undefined;
            });
        }
    }

}
