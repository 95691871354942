import { BaseUpdate as BaseUpdateCommon, BaseUpdateCtor as BaseUpdateCtorCommon } from '@profis-engineering/gl-model/base-update';
import { UIProperty } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { MaterialCacheCW } from './cache/material-cache';
import { MeshCacheCW } from './cache/mesh-cache';
import { Mode2d } from '@profis-engineering/gl-model/gl-model';

export abstract class BaseUpdateCW extends BaseUpdateCommon<UIProperty, MaterialCacheCW, MeshCacheCW, Mode2d>{
}

export type BaseUpdateCtor = BaseUpdateCtorCommon<UIProperty, MaterialCacheCW, MeshCacheCW, Mode2d>;
