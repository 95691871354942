import { CodeList } from '../code-list';
import { LipStrengthClipsEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class LipStrengthClips extends CodeList {
    public flangeWidth?: number;
    public length?: number;
    public thickness?: number;

    constructor(codeList: LipStrengthClipsEntity) {
        super(codeList);

        if (codeList != null) {
            this.flangeWidth = codeList.flangeWidth;
            this.length = codeList.length;
            this.thickness = codeList.thickness;
        }
    }

    public static fromService(codeList: LipStrengthClipsEntity) {
        return new LipStrengthClips(CodeList.baseFromService('LipStrengthClipsEntity', codeList, {
            thickness: codeList.thickness,
            flangeWidth: codeList.flangeWidth,
            length: codeList.length
        } as LipStrengthClipsEntity));
    }

    public override cloneDeep(): LipStrengthClips {
        const codeList = super.cloneDeep() as LipStrengthClips;
        codeList.flangeWidth = this.flangeWidth;
        codeList.length = this.length;
        codeList.thickness = this.thickness;

        return codeList;
    }
}
