import { Injectable } from '@angular/core';
import {
    IModalGridComponentInput
} from '@profis-engineering/pe-ui-common/entities/modal-grid';
import {
    ModalOptions, ModalSize
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    ModalServiceInjected
} from '@profis-engineering/pe-ui-common/services/modal.common';
import { ApprovalsEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { DetailedScopeCheckInfoPopup } from '../entities/generated-modules/Hilti.PE.ScopeChecks.CW.Enums';
import { IInfoDialogComponentInput, MainMenuCommand } from '../helpers/info-dialog-helper';

@Injectable({
    providedIn: 'root'
})
export class ModalService extends ModalServiceInjected {

    public openModalGridInternal(props?: IModalGridComponentInput<any>, opts?: ModalOptions) {
        return this.openWebComponentModal('cw-modal-grid', opts, props);
    }

    public openAnchorReinforcement(props?: object) {
        return this.openWebComponentModal('cw-select-anchor-reinforcement', { size: 'lg' }, props);
    }

    public openFasteningTechnology(props?: object) {
        return this.openWebComponentModal('cw-select-fastening-technology', { size: 'lg' }, props);
    }

    public openPlateStandoffPopup(props?: object) {
        return this.openWebComponentModal('cw-select-plate-standoff-popup', { size: 'lg' }, props);
    }

    public openInspectionTypePopup(props?: object) {
        return this.openWebComponentModal('cw-select-inspection-type-popup', { size: 'lg' }, props);
    }

    public openTorquingTypePopup(props?: object) {
        return this.openWebComponentModal('cw-automatic-torque-popup', { size: 'lg' }, props);
    }

    public openInstallationFillHolesPopup(props?: object) {
        return this.openWebComponentModal('cw-installation-fill-holes-popup', { size: 'md' }, props);
    }

    public openBasePlateSize(props?: object) {
        return this.openWebComponentModal('cw-baseplate-size', { size: 'sm' }, props);
    }

    public openExportDesignDialog() {
        return this.openWebComponentModal('cw-design-section-export', { size: 'md' });
    }

    public openLoadsHNAWizard(props?: object) {
        this.openWebComponentModal('cw-loads-wizard', { size: 'lg' }, props);
    }

    public openExportReportDialog(props?: object) {
        return this.openWebComponentModal('cw-report-export', { size: 'lg' }, props);
    }

    public openImportLoads(props?: object) {
        return this.openWebComponentModal('cw-loads-import', { size: 'xl' }, props);
    }

    public openLoadsInput() {
        this.openWebComponentModal('cw-loads-input', { size: 'md' });
    }

    public openInfoDialog(command: MainMenuCommand, size: ModalSize = 'md', forceLinkToOpen = false) {
        const inputValues: IInfoDialogComponentInput = {
            detailedScopeCheckInfoPopup: undefined as unknown as DetailedScopeCheckInfoPopup,
            isScopeCheckInfoPopup: false,
            command,
            forceLinkToOpen
        };

        this.openWebComponentModal('cw-info-dialog', { size }, inputValues);
    }

    public openInfoScopeCheckDialog(detailedScopeCheckInfoPopup: DetailedScopeCheckInfoPopup, size: ModalSize = 'md') {
        const inputValues: IInfoDialogComponentInput = {
            detailedScopeCheckInfoPopup,
            isScopeCheckInfoPopup: true,
            command: undefined as unknown as MainMenuCommand,
            forceLinkToOpen: false
        };

        this.openWebComponentModal('cw-info-dialog', { size }, inputValues);
    }

    public openSelectVParaSolution(props?: object) {
        return this.openWebComponentModal('cw-select-v-para-solution', { size: 'sm' }, props);
    }

    public openSelectAnchorChannelLength(props?: object) {
        return this.openWebComponentModal('cw-select-anchor-channel-length', { size: 'sm' }, props);
    }

    public openMultipleApprovals(approvals: ApprovalsEntity[]) {
        const inputValues = {
            approvals
        };
        this.openWebComponentModal('cw-multiple-approvals', { size: 'sm' }, inputValues);
    }

    public openFirestopDialog(props?: object) {
        return this.openWebComponentModal('cw-firestop-dialog', { size: 'md' }, props);
    }
}
