import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { BaseComponentCW, IModelCW } from '../../../gl-model/base-component';
import { GlModelConstants } from '../../../gl-model/gl-model-constants';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { BaseComponentHelper } from '../../../gl-model/base-component-helper';
import { Text2D } from '../../../gl-model/text/text-2d';
import { IAnchoringSystemBaseConstructor } from './anchoring-system';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { cloneVertexData } from '@profis-engineering/gl-model/vertex-data-helper';
import { INumberedPosition } from '../../../gl-model/entities/numbered-position';

export interface IRebarPlate {
    length?: number;
    height?: number;
    thickness?: number;
    x_pl?: number;
    noOfRebars?: number;
    rebarDiameter?: number;
    rebarSpacing?: number;
    rebarLength?: number;
    x_r?: number;
    e_r?: number;
    position?: Vector3;
    rebarPositions: INumberedPosition[];
}

export class RebarPlate extends BaseComponentCW {
    private readonly meshName = 'CW.RebarPlate.Mesh';

    public mesh?: Mesh;
    private rebarsText: Text2D[] = [];
    private transformNode!: TransformNode;

    constructor(ctor: IAnchoringSystemBaseConstructor) {
        super(ctor);

        this.id = ctor.id;
    }

    public override update(): void {
        // Dispose all possible locally or in scene stored meshes
        this.dispose();

        const anchoringSystem = this.model.anchoringSystem(this.model, this.id);
        this.transformNode = this.getGlobalTransformNode(anchoringSystem.geometry.position, anchoringSystem.geometry.rotation);

        const values = RebarPlateHelper.getRebarPlateValues(this.model, this.id);

        // No data no calculations
        if (!values.noOfRebars) {
            return;
        }

        this.createMainMesh(values);
    }

    public override getBoundingBoxes(): BoundingInfo[] {
        if (this.mesh)
            return [this.mesh.getBoundingInfo()];

        return [];
    }

    public override hide(): void {
        this.mesh?.setEnabled(false);
        this.rebarsText?.forEach(x => {
            x.setEnabled(false);
        });
    }

    public override dispose(): void {
        this.mesh?.dispose();
        this.disposeText();
    }

    //#region Lines
    private disposeText() {
        this.rebarsText?.forEach(x => {
            x.setEnabled(false);
            x.dispose();
        });
        this.rebarsText = [];
    }
    //#endregion

    //#region Creation of meshes
    private createMainMesh(values: IRebarPlate) {
        const rebarPlate = this.createRebarPlate(values);
        const rebar = this.createRebar(values);

        const textOffset = new Vector3(0, 0, ((values.rebarLength ?? 0) / 2) + GlModelConstants.baseMaterialConstants.cylinderNumberingOffset);
        const bars = BaseComponentHelper.replicateVertexDataToFullfilNumberOfInstances(
            rebar,
            values.rebarPositions,
            () => this.createText2D(),
            this.model.visibilityProperties.anchorNumberVisible ? this.rebarsText : undefined,
            this.transformNode,
            textOffset);

        rebarPlate.merge(bars);

        this.mesh = new Mesh(this.meshName, this.scene);
        rebarPlate.applyToMesh(this.mesh);

        this.mesh.material = this.cache.materialCache.steelMaterial;

        if (this.transformNode != null)
            this.mesh.parent = this.transformNode;
    }

    private createRebarPlate(values: IRebarPlate): VertexData {
        const vertexData = cloneVertexData(this.cache.vertexDataCache.box);

        vertexData.transform(
            Matrix.Scaling(
                (values.length ?? 0) / CommonCache.boxSize,
                (values.height ?? 0) / CommonCache.boxSize,
                (values.thickness ?? 0) / CommonCache.boxSize));

        const position = values.position ?? Vector3.Zero();
        const protrudingOffset = GlModelConstants.baseMaterialConstants.protrudingOffset;
        vertexData.transform(Matrix.Translation(position.x, position.y + protrudingOffset , position.z - protrudingOffset));

        return vertexData;
    }

    private createRebar(values: IRebarPlate) {
        const vertexData = cloneVertexData(this.cache.vertexDataCache.cylinder(8).vertexData);

        vertexData.transform(Matrix.Scaling(
            (values.rebarDiameter ?? 0) / CommonCache.cylinderSize,
            (values.rebarLength ?? 0) / CommonCache.cylinderSize,
            (values.rebarDiameter ?? 0) / CommonCache.cylinderSize));

        vertexData.transform(Matrix.RotationX(Math.PI / 2));

        return vertexData;
    }
    //#endregion
}

export class RebarPlateHelper {
    public static getRebarPlateValues(model: IModelCW, id: string): IRebarPlate {
        const anchoringSystem = model.anchoringSystem(model, id);
        return anchoringSystem.rebarPlate;
    }
}
