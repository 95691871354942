import {
    ICodeListConstructor
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import { CodeList } from '../code-list';
import { DesignTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export interface IDesignTypeConstructor extends ICodeListConstructor {
    id: number;
    displayKey: string;
    regions: number[];
}

export class DesignType extends CodeList {
    public regions: number[];

    constructor(codeList: IDesignTypeConstructor) {
        super(codeList);

        this.id = codeList.id;
        this.displayKey = codeList.displayKey;
        this.regions = codeList.regions;
    }

    public static fromService(codeList: DesignTypeEntity) {
        return new DesignType(CodeList.baseFromService<IDesignTypeConstructor>('DesignTypeEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
            regions: codeList.regions
        }));
    }

    public override cloneDeep() {
        const codeList: DesignType = <DesignType>super.cloneDeep();
        codeList.id = this.id;
        codeList.displayKey = this.displayKey;
        codeList.regions = this.regions;

        return codeList;
    }
}
