import {
    TrackingUsageCounter as TrackingUsageCounterBase
} from '@profis-engineering/pe-ui-common/entities/tracking-usage-counter';
import { LoggerServiceBase } from '@profis-engineering/pe-ui-common/services/logger.common';
import { UsageCounter } from './tracking-entities';

export class TrackingUsageCounter extends TrackingUsageCounterBase {

    constructor(logger: LoggerServiceBase) {
        super(logger);
        this.resetCounter();
    }

    toTrackingEntity(): UsageCounter {
        return {
            approval: this.Approval,
            dateAccessed: this.DateAccessed,
            dateClosed: this.DateClosed,
            designExportedToDevice: this.DesignExportedToDevice,
            designExportedToTrimple: this.DesignExportedToTrimple,
            excelImportLoad: this.ExcelImportLoad,
            filterGroupsUsed: this.filterGroupsUsed,
            filterUsed: this.FilterUsed,
            importExistingDesign: this.ImportExistingDesign,
            menuOpened: this.MenuOpened,
            onlineTechnicalInformation: this.OnlineTechnicalInformation,
            redo: this.Redo,
            reportCreatedWithCustomTemplate: this.ReportCreatedWithCustomTemplate,
            reportCreatedWithDefaultTemplate: this.ReportCreatedWithDefaultTemplate,
            reportCreatedWithItemNumbers: this.ReportCreatedWithItemNumbers,
            reportCreatedWithUserTemplate: this.ReportCreatedWithUserTemplate,
            reportsCreated: this.ReportsCreated,
            shortReportsCreated: this.ShortReportsCreated,
            specificationTextExport: this.SpecificationTextExport,
            twoDEditor: this.TwoDEditor,
            undo: this.Undo,
            zoom: this.Zoom,
            duplicateDesign: this.DuplicateDesign
        };
    }

    public override resetCounter(): void {
        super.resetCounter();
    }

}