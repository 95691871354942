import { Context3d } from '@profis-engineering/gl-model/context-3d';
import { MaterialCacheCW } from './cache/material-cache';
import { MeshCacheCW } from './cache/mesh-cache';

const contextCache: { [key: string]: Context3d<MaterialCacheCW, MeshCacheCW> } = {};

export function getOrCreateContext3d<T extends Context3d<MaterialCacheCW, MeshCacheCW>>(key: string, create: (key: string) => T): T {
    if (contextCache[key] == null) {
        contextCache[key] = create(key);
    }

    return contextCache[key] as T;
}
