import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { IModelCW } from '../../../gl-model/base-component';
import { Design } from '../../../entities/design';
import { ILipStrengthClip } from '../components/anchor-channel';
import { BaseUpdateCW } from '../../../gl-model/base-update';


export class AnchorChannelLipUpdater extends BaseUpdateCW {
    @propertyValueChanged(UIProperty.AnchorChannel_CW_LipStrengthClip)
    private AnchorChannel_CW_LipStrengthClip(_value: number, model: IModelCW, design: Design) {
        if (design.lipStrengthClip) {
            model.anchoringSystems.forEach((acs) => {
                acs.anchorChannel.lipStrengthClip = {
                    flangeWidth: design.lipStrengthClip?.flangeWidth,
                    length: design.lipStrengthClip?.length,
                    thickness: design.lipStrengthClip?.thickness
                } as ILipStrengthClip;
            });
        } else {
            model.anchoringSystems.forEach(acs => {
                acs.anchorChannel.lipStrengthClip = undefined;
            });
        }
    }
}