import { CodeList } from '../code-list';
import { DrillingMethodEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class DrillingMethods extends CodeList {
    constructor(codeList: DrillingMethodEntity) {
        super(codeList);
    }

    public static fromService(codeList: DrillingMethodEntity) {
        return new DrillingMethods(CodeList.baseFromService('DrillingMethodEntity', codeList));
    }

    public override cloneDeep(): DrillingMethods {
        return super.cloneDeep() as DrillingMethods;
    }
}
