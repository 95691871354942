import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { DesignCodeList } from '../../entities/enums/design-code-list';
import { IPropertyMetaData, PropertyMetaData } from '../../entities/properties';
import { CodelistHelper } from '../../helpers/codelist-helper';
import { CalculationService } from '../../services/calculation.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { Constants } from '../../entities/constants';
import { ComponentsHelper } from '../../helpers/components-helper';

@Component({
    templateUrl: './select-anchor-channel-length.component.html',
    styleUrls: ['./select-anchor-channel-length.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectAnchorChannelLengthComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;
    public dataLoaded = false;

    public propertyMetaData = PropertyMetaData;
    public channelLength: DropdownProps<number> = {};

    public selectedChannelLengthId: number;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private calculationService: CalculationService,
        private numberService: NumberService,
        private unitService: UnitService,
        private elementRef: ElementRef<HTMLElement>) {
            this.selectedChannelLengthId = -1;
        }

    ngOnInit(): void {
        this.dataLoaded = true;

        this.loadChannelLengths();
    }

    public get design() {
        return this.userService.design;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public async setPropertiesValues(properties: Array<[value: number, propertyId: IPropertyMetaData]>) {
        if (this.design.isReadOnlyDesignMode) {
            return;
        }

        await this.calculationService.calculateAsync(this.design, d => {
            properties.forEach(property => {
                d.model[property[1].id] = property[0];
            });
        });
    }

    public loadChannelLengths() {
        this.channelLength = ComponentsHelper.createDropdownComponent(
            'select-anchor-channel-length-dropdown-channel-length-dropdown',
            this.translate('Agito.Hilti.CW.SelectAnchorChannelLength.ChannelLength'),
            CodelistHelper.translateDropdownItems(
                this.design,
                this.userService,
                this.unitService,
                this.localizationService,
                this.numberService,
                CodelistHelper.getCodeListItems(this.design, DesignCodeList.AnchorChannelLength, PropertyMetaData.AnchorChannel_CW_Length.id),
                DesignCodeList.AnchorChannelLength)
        );

        const dropdownItems: DropdownItem<number>[] = this.channelLength?.items ?? [];

        if (dropdownItems.length > 0) {
            this.selectedChannelLengthId = dropdownItems[0].value;
            const userDefinedIdIndex = dropdownItems.findIndex(x => x.value == Constants.UserDefinedId);

            if (userDefinedIdIndex >= 0) {
                this.channelLength.items?.splice(userDefinedIdIndex, 1);
            }
        }
    }

    public async save() {
        if (this.submitted) {
            return;
        }

        this.submitted = true;

        //submit
        const values: Array<[value: number, propertyId: IPropertyMetaData]> = [];
        values.push([this.selectedChannelLengthId, this.propertyMetaData.AnchorChannel_CW_Length]);

        await this.setPropertiesValues(values);

        this.submitted = false;

        this.modalInstance.close();
    }
}
