import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IBaseplateSizeComponentInput } from '../../../../entities/baseplate-size';
import { Design, IDesignState } from '../../../../entities/design';
import { BasePlateEntity } from '../../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { LocalizationService } from '../../../../services/localization.service';
import { UnitService } from '../../../../services/unit.service';
import { MainMenuModal, updateMainMenuControl } from '../../MainMenuHelper';
import { IPlateSizeProps, PlateSize } from './PlateSize';

export function initializePlateSizeControl(
    controlProps: IPlateSizeProps,
    design: Design | undefined,
    uiPropertyId: number,
    modals: Record<number, (input?: object) => IModalOpened>,
    localizationService: LocalizationService,
    unitService: UnitService,
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu
) {
    controlProps.type = PlateSize;

    const formatText = (baseplateSize: BasePlateEntity) => {
        if (baseplateSize == null) {
            return localizationService.getString('Agito.Hilti.CW.BaseplateSize.ButtonNoTitle');
        }

        const defaultUnit = unitService.getDefaultUnit(UnitGroup.Length);
        const internalUnit = unitService.getInternalUnit(UnitGroup.Length);
        const precision = unitService.getPrecision(defaultUnit);

        const width = baseplateSize.width != null ? unitService.convertUnitValueArgsToUnit(baseplateSize.width, internalUnit, defaultUnit) : null;
        const height = baseplateSize.length != null ? unitService.convertUnitValueArgsToUnit(baseplateSize.length, internalUnit, defaultUnit) : null;
        const thickness = baseplateSize.thickness != null ? unitService.convertUnitValueArgsToUnit(baseplateSize.thickness, internalUnit, defaultUnit) : null;

        const widthFormated = width != null ? `${localizationService.getString('Agito.Hilti.CW.BaseplateSize.WidthSign')}: ${unitService.formatNumber(width, precision)}` : null;
        const heightFormated = height != null ? `${localizationService.getString('Agito.Hilti.CW.BaseplateSize.HeightSign')}: ${unitService.formatNumber(height, precision)}` : null;
        const thicknessFormated = thickness != null ? `${localizationService.getString('Agito.Hilti.CW.BaseplateSize.ThicknessSign')}: ${unitService.formatNumber(thickness, precision)}` : null;

        let result = '';

        result += widthFormated + ' ';
        result += heightFormated + ' ';
        result += thicknessFormated + ' ';

        return result;
    };

    const formatTitle = (title?: string) => {
        if (title == null || title == '') {
            return;
        }

        const defaultUnit = unitService.getDefaultUnit(UnitGroup.Length);
        const defaultUnitFormated = unitService.formatUnit(defaultUnit);

        return `${title} [${defaultUnitFormated}]`;
    };

    const propertyValue = design?.model[uiPropertyId] as BasePlateEntity;

    controlProps.baseplateSize = propertyValue;
    controlProps.baseplateSizeFormatted = formatText(propertyValue);
    controlProps.controlTitleFormatted = formatTitle(controlProps.title);

    controlProps.clicked = () => {
        if (modals != null) {
            const modalOpenFn = modals[MainMenuModal.basePlateSize];
            const modalInput: IBaseplateSizeComponentInput = {
                onSave: (baseplateSize) => {
                    // change menu
                    setState(menu => updateMainMenuControl<IPlateSizeProps>(menu, controlProps.controlId, {
                        baseplateSize,
                        baseplateSizeFormatted: formatText(baseplateSize)
                    } as any));
                }
            };
            modalOpenFn(modalInput);
        }
    };

    const onStateChanged = (state: IDesignState) => {
        return ({
            controlTitleFormatted: formatTitle(controlProps.title),
            baseplateSize: state.model[uiPropertyId],
            baseplateSizeFormatted: formatText(state.model[uiPropertyId] as BasePlateEntity)
        } as any) as IPlateSizeProps;
    };

    return {
        controlProps,
        onStateChanged
    };

}