import { Injectable } from '@angular/core';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    ConstantParameter,
    NumericalParameter,
    TemplateParameter,
    TranslationFormat,
    TranslationParameter,
    TranslatableParameter
} from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities';
import { TranslationParameterTypes } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { LocalizationService } from './localization.service';
import { UnitService } from './unit.service';

@Injectable({
    providedIn: 'root'
})
export class TranslationFormatService {
    constructor(
        private localizationService: LocalizationService,
        private unitService: UnitService
    ) { }

    public getLocalizedStringWithTranslationFormat(
        translationFormat?: TranslationFormat,
        roundValue = true,
        preTransformedParams?: Record<string, string>
    ): string | undefined {
        if (translationFormat == null) {
            return undefined;
        }

        let localizedString = '';

        // Transforms the translation parameters array into a JSON object.
        const transformedParams = this.transformTranslationParameters(translationFormat.translationParameters, roundValue, preTransformedParams);

        // Gets the template from the attribute or checks the template object (recursive call, because it's a nested object of the same type).
        const translation = translationFormat.template != null
            ? translationFormat.template
            : this.getLocalizedStringWithTranslationFormat(translationFormat.templateFormat, roundValue);

        // Sets the values based on the template and parameter array.
        if (translation == null) {
            translationFormat.translationParameters.forEach((key: { name: string | number }) => {
                transformedParams[key.name] = transformedParams[key.name].split('{').join(`{${key.name}_`);
                localizedString += formatKeyValue(`{${key.name}}`, transformedParams) + '\n';
            });
        }
        else {
            localizedString = formatKeyValue(translation, transformedParams);
        }

        return localizedString.trimEnd();
    }

    public transformTranslationParameters(
        translationParameters: TranslationParameter[],
        roundValue: boolean,
        preTransformedParams?: Record<string, string>,
        customParameterTransformation?: (parameter: TranslationParameter, roundValue: boolean) => string | undefined
    ): Record<string, string> {
        const keys: Record<string, string> = {};

        translationParameters.forEach((parameter) => {
            let handled = false;

            if (preTransformedParams != null && parameter.name in preTransformedParams) {
                keys[parameter.name] = preTransformedParams[parameter.name];
                handled = true;
            }
            else if (customParameterTransformation != undefined) {
                const value = customParameterTransformation(parameter, roundValue);
                if (value != undefined) {
                    keys[parameter.name] = value;
                    handled = true;
                }
            }

            if (handled) {
                return;
            }

            switch (parameter.parameterType) {
                case TranslationParameterTypes.Numerical: {
                    keys[parameter.name] = this.getNumericalParameterValue(parameter, roundValue);
                    break;
                }

                case TranslationParameterTypes.Translatable: {
                    keys[parameter.name] = this.getTranslatableParameterValue(parameter);
                    break;
                }

                case TranslationParameterTypes.Constant: {
                    const constantParameter = parameter as ConstantParameter;
                    keys[parameter.name] = constantParameter.value;
                    break;
                }

                case TranslationParameterTypes.Template: {
                    const templateParameter = parameter as TemplateParameter;
                    keys[parameter.name] = this.getLocalizedStringWithTranslationFormat(templateParameter.value, roundValue) ?? '';
                    break;
                }

                default: {
                    throw new Error('Unknown parameter type');
                }
            }
        });

        return keys;
    }

    private getNumericalParameterValue(parameter: TranslationParameter, roundValue: boolean): string {
        const numericalParameter = parameter as NumericalParameter;
        if (numericalParameter.value == null) {
            return '';
        }

        // The number might not have a unit
        return numericalParameter.unitGroup != null
            ? this.getNumericalValueWithUnit(numericalParameter, roundValue)
            : this.getNumericalValue(numericalParameter, roundValue);
    }

    private getNumericalValueWithUnit(numericalParameter: NumericalParameter, roundValue: boolean) {
        const defaultPrecision = this.unitService.getDefaultPrecision();
        const internalUnit = this.unitService.getInternalUnit(numericalParameter.unitGroup as unknown as UnitGroup);
        const defaultUnit = this.unitService.getDefaultUnit(numericalParameter.unitGroup as unknown as UnitGroup);
        const precision = roundValue
            ? this.unitService.getPrecision(defaultUnit) + (numericalParameter.additionalPrecision ?? 0)
            : defaultPrecision;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let unitValueNumber = this.unitService.convertUnitValueArgsToUnit(numericalParameter.value!, internalUnit, defaultUnit);

        if (typeof unitValueNumber === 'string') {
            unitValueNumber = this.unitService.parseNumber(unitValueNumber);
        }

        return this.unitService.formatUnitValueArgs(unitValueNumber, defaultUnit, precision);
    }

    private getNumericalValue(numericalParameter: NumericalParameter, roundValue: boolean) {
        const defaultPrecision = this.unitService.getDefaultPrecision();
        const precision = roundValue
            ? this.unitService.getPrecision(UnitType.None) + (numericalParameter.additionalPrecision ?? 0)
            : defaultPrecision;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.unitService.formatNumber(numericalParameter.value!, precision);
    }

    private getTranslatableParameterValue(parameter: TranslationParameter): string {
        const translatableParameter = parameter as TranslatableParameter;

        let translated: string;
        if (translatableParameter.value == null || translatableParameter.value.trim() == '') {
            translated = '';
        }
        else {
            translated = this.localizationService.getString(translatableParameter.value);
        }

        return translated;
    }
}
