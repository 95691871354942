import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { Design } from '../../../entities/design';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { IAnchoringSystem, IAnchoringSystemGeometry, IBasePlateSystem, IModelCW } from '../../../gl-model/base-component';
import { BaseUpdateCW } from '../../../gl-model/base-update';
import { IAnchorChannel, IRebarChannel } from '../components/anchor-channel';
import { IRebarPlate } from '../components/rebar-plate';
import { IBolt } from '../components/bolt';
import { IPiBracket, IPlateBracket, IPlateStandoff, ISlottedHole } from '../components/plate-bracket';
import { toGLVector3, toVector3 } from '../helpers/geometry-mappers';
import { NumberedPoint3D } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design.Geometry';
import { Vector2 } from '@babylonjs/core/Maths/math.vector';

export class AnchorChannelUpdater extends BaseUpdateCW {
    @propertyValueChanged()
    private UpdateAll(model: IModelCW, design: Design) {
        model.isPostInstallAnchorProduct = design.isPostInstallAnchorProduct();

        // Add and adjust model
        for (let i = 0; i < design.projectDesign.anchoringSystems.length; i++) {
            let acs = model.anchoringSystems[i];

            if (!acs) {
                acs = this.createEmptyAnchoringSystem();
                model.anchoringSystems.push(acs);
            }

            const designAcs = design.projectDesign.anchoringSystems[i];

            acs.id = designAcs.id;
            acs.geometry.position = toGLVector3(designAcs.geometry.position);
            acs.geometry.rotation = toGLVector3(designAcs.geometry.rotation);
            acs.isCorner = designAcs.isCorner;

            acs.anchorChannel.anchorPositions = designAcs.product.anchorChannel?.anchorPositions.map((p: NumberedPoint3D) => {
                return { id: p.id, position: toVector3(p) };
            }) ?? [];

            for (let j = 0; j < designAcs.basePlateSystems.length; j++) {
                let bps = acs.basePlateSystems[j];

                if (!bps) {
                    bps = this.createEmptyBasePlateSystem();
                    acs.basePlateSystems.push(bps);
                }

                const designBps = designAcs.basePlateSystems[j];
                bps.id = designBps.id;
                bps.parentId = designAcs.basePlateSystems[j - 1]?.id;
                bps.plateBracket.isSunken = designBps.basePlate.isSunken;
            }

        }

        // Remove model if too many
        this.checkAndRemoveExcessSystems(model, design);
    }

    private createEmptyAnchoringSystem(): IAnchoringSystem {
        return {
            id: '',
            geometry: {} as IAnchoringSystemGeometry,
            anchorChannel: {
                rebarChannel: {} as IRebarChannel
            } as IAnchorChannel,
            rebarPlate: {} as IRebarPlate,
            basePlateSystems: [this.createEmptyBasePlateSystem()],
            isCorner: false
        } as IAnchoringSystem;
    }

    private createEmptyBasePlateSystem(): IBasePlateSystem {
        return {
            id: '',
            bolt: {} as IBolt,
            plateBracket: {
                piBracket: {} as IPiBracket,
                standoff: {} as IPlateStandoff,
                slottedHole: {} as ISlottedHole,
                loadEccentricity: Vector2.Zero()
            } as IPlateBracket,
        } as IBasePlateSystem;
    }

    private checkAndRemoveExcessSystems(model: IModelCW, design: Design): void {
        // Remove anchoring systems accoring to design data
        if (model.anchoringSystems.length > design.projectDesign.anchoringSystems.length) {
            model.anchoringSystems = model.anchoringSystems.slice(0, design.projectDesign.anchoringSystems.length - model.anchoringSystems.length);
        }

        // Remove base plate systems in anchoring systems accoring to design data
        for (let i = 0; i < design.projectDesign.anchoringSystems.length; i++) {
            const acs = model.anchoringSystems[i];
            const designAcs = design.projectDesign.anchoringSystems[i];

            if (acs.basePlateSystems.length > designAcs.basePlateSystems.length) {
                acs.basePlateSystems = acs.basePlateSystems.slice(0, designAcs.basePlateSystems.length - acs.basePlateSystems.length);
            }
        }
    }

    @propertyValueChanged(UIProperty.AnchorChannel_CW_Length)
    private AnchorChannel_CW_Length(_value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach(ac => {
            ac.anchorChannel.channelLength = design.anchorChannelLength?.channelLength ?? 0;
        });
    }

    @propertyValueChanged(UIProperty.AnchorChannel_CW_UserDefinedLength)
    private AnchorChannel_CW_UserDefinedLength(_value: number, model: IModelCW, design: Design) {
        if (design.anchorChannelLength == undefined) {
            model.anchoringSystems.forEach(ac => {
                ac.anchorChannel.channelLength = _value ?? 0;
            });
        }
    }

    @propertyValueChanged(UIProperty.AnchorChannel_CW_EmbedmentDepth)
    private AnchorChannel_CW_EmbedmentDepth(_value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach(ac => {
            ac.anchorChannel.b_ch = design.anchorChannelEmbedmentDepth?.b_ch ?? 0;
            ac.anchorChannel.h_ch = design.anchorChannelEmbedmentDepth?.h_ch ?? 0;
            ac.anchorChannel.crossSectionNodes = design.designData.projectDesign?.product.anchorChannel.crossSectionNodes ?? [];
            ac.anchorChannel.l_a = design.anchorChannelEmbedmentDepth?.t_h ?? 0;
            ac.anchorChannel.d_1 = design.anchorChannelEmbedmentDepth?.d_1 ?? 0;
            ac.anchorChannel.d_2 = design.anchorChannelEmbedmentDepth?.d_2 ?? 0;
            ac.anchorChannel.t_h = design.anchorChannelEmbedmentDepth?.t_h ?? 0;
            ac.anchorChannel.h_ef = design.anchorChannelEmbedmentDepth?.h_ef ?? 0;
            ac.anchorChannel.rebarChannel = {
                d_s: design.anchorChannelEmbedmentDepth?.rebarChannel?.d_s ?? 0,
                l: design.anchorChannelEmbedmentDepth?.rebarChannel?.l ?? 0,
                b: design.anchorChannelEmbedmentDepth?.rebarChannel?.b ?? 0,
                d_b: design.anchorChannelEmbedmentDepth?.rebarChannel?.d_b ?? 0,
                k: design.anchorChannelEmbedmentDepth?.rebarChannel?.k ?? 0,
                kinkAngle: design.anchorChannelEmbedmentDepth?.rebarChannel?.kinkAngle ?? 0,
                hasKink: design.anchorChannelEmbedmentDepth?.rebarChannel?.hasKink ?? false
            } as IRebarChannel;
        });
    }

    @propertyValueChanged(UIProperty.AnchorChannel_CW_Projection)
    private AnchorChannel_CW_Projection(_value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach(ac => {
            ac.anchorChannel.projection = design.anchorChannelLength?.projection ?? 0;
        });
    }

    @propertyValueChanged(UIProperty.AnchorChannel_CW_Spacing)
    private AnchorChannel_CW_Spacing(_value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach(ac => {
            ac.anchorChannel.spacing = design.anchorChannelLength?.spacing ?? 0;
        });
    }

    @propertyValueChanged(UIProperty.AnchorChannel_CW_NumberOfAnchors)
    private AnchorChannel_CW_NumberOfAnchors(_value: number, model: IModelCW, design: Design) {
        model.anchoringSystems.forEach(ac => {
            ac.anchorChannel.numberOfAnchors = design.projectDesign?.product.anchorChannel.numberOfAnchors ?? 0;
        });
    }
}
