import {
    CodeList as CodeListBase,
    ICodeListConstructor as ICodeListConstructorBase
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { TooltipType } from '@profis-engineering/pe-ui-common/services/code-list.common';
import { LocalizationServiceBase } from '@profis-engineering/pe-ui-common/services/localization.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';

export interface ICodeListTextDeps {
    localizationService?: LocalizationServiceBase;
    numberService?: NumberServiceBase;
}

export function getCodeListTextDeps(
    localizationService?: LocalizationServiceBase,
    numberService?: NumberServiceBase
) {
    return {
        localizationService,
        numberService
    } as ICodeListTextDeps;
}

export interface ICodeListConstructor extends ICodeListConstructorBase {
    tooltipType?: TooltipType;
    tooltipDisabledType?: TooltipType;
}

export abstract class CodeList extends CodeListBase {
    public declare tooltipType?: TooltipType;
    public declare tooltipDisabledType?: TooltipType;

    constructor(codeList: ICodeListConstructor) {
        super(codeList);
    }

    protected static override baseFromService<TConstructor extends ICodeListConstructor>(
        displayKeyPrefix: string,
        codeList: { [property: string]: any },
        constructor?: TConstructor
    ): ICodeListConstructor & TConstructor {
        return {
            id: codeList['id'],
            nameResourceKey: codeList['displayKey'] != null && codeList['displayKey'] != '' ? `Agito.Hilti.CW.CodeList.${displayKeyPrefix}.${codeList['displayKey']}` : null,
            name: codeList['name'],
            image: codeList['image'] != null && codeList['image'] != '' ? 'sprite-' + codeList['image'] : null,
            displayKey: codeList['displayKey'],
            tooltipDisplayKey: codeList['tooltipDisplayKey'],
            tooltipTitleDisplayKey: codeList['tooltipTitleDisplayKey'],
            tooltipDisabledDisplayKey: codeList['tooltipDisabledDisplayKey'],
            tooltipDisabledTitleDisplayKey: codeList['tooltipDisabledTitleDisplayKey'],
            tooltipType: codeList['tooltipType'] != null ? codeList['tooltipType'] : TooltipType.Normal,
            tag: codeList['tested'] != null && !(codeList['tested'] as boolean) ? `Agito.Hilti.CW.Rebars.Untested.Tag` : null,
            ...(constructor as TConstructor)
        };
    }
}
