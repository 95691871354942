<pe-modal-header [modalInstance]="modalInstance" [title]="translate(title)"></pe-modal-header>

<pe-modal-body>
    <div *ngFor="let item of items">
        <div *ngIf="isItemSubHeading(item)" class="info-dialog-subheading">
            <span class="info-dialog-subheading" l10n="{{item.value}}"></span>
            <span *ngIf="objectContainsProperty(item, 'appendText')" class="info-dialog-subheading">{{ item.appendText }}</span>
        </div>
        <div *ngIf="isItemText(item)" class="info-dialog-description">
            <span class="info-dialog-description" l10n="{{item.value}}"></span>
            <span *ngIf="objectContainsProperty(item, 'appendText')" class="info-dialog-description">{{ item.appendText }}</span>
        </div>
        <div *ngIf="isItemListItem(item)" class="info-dialog-list-item">
            &bull;&nbsp;
            <span class="info-dialog-list-item" l10n="{{item.value}}"></span>
            <span *ngIf="objectContainsProperty(item, 'appendText')" class="info-dialog-list-item">{{ item.appendText }}</span>
        </div>
        <div *ngIf="isItemImage(item)" class="info-dialog-image">
            <img src="{{item.value}}" alt="image">
        </div>
        <div *ngIf="isItemLinkTitle(item)">
            <a [href]="infoLinkValue" target="_blank"><span [l10n]="infoLinkTitle"></span></a>
        </div>
        <div *ngIf="isItemTable(item)">
            <cw-info-dialog-table [table]="table"></cw-info-dialog-table>
        </div>
        <div *ngIf="isItemUnorderedList(item)">
            <cw-info-dialog-unordered-list [unorderedList]="unorderedList"></cw-info-dialog-unordered-list>
        </div>
        <div *ngIf="isItemLineBreak(item)">
            <br>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button id="info-dialog-ok-button" [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')">
    </pe-button>
</pe-modal-footer>
