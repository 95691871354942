import sortBy from 'lodash-es/sortBy';
import { UserService } from '../services/user.service';
import { DesignCodeList } from '../entities/enums/design-code-list';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { LocalizationService } from '../services/localization.service';
import { NumberService } from '../services/number.service';
import { PropertyMetaData } from '../entities/properties';
import { SelectProductSortBy } from '../entities/enums/select-product-sort-by';
import { Injectable } from '@angular/core';
import { IBolt } from '../entities/bolt';
import { BoltFamily } from '../entities/code-lists/bolt-family';

@Injectable({
    providedIn: 'root'
})
export class BoltService {
    private bolts: IBolt[] = [];
    private boltsData: IBolt[] = [];

    constructor(
        private localizationService: LocalizationService,
        private userService: UserService,
        private numberService: NumberService
    ) { }

    public get design() {
        return this.userService.design;
    }

    public get boltFamilies() {
        return this.design.designData.designCodeLists[DesignCodeList.BoltFamilies] as BoltFamily[];
    }

    public get selectedBoltProperty() {
        return this.design.properties.get(PropertyMetaData.Bolt_CW_Type.id);
    }

    private translate(key?: string) {
        return this.localizationService.getString(key ?? '');
    }

    public loadBolts() {
        let bolts = this.boltFamilies;
        const allowedValues = this.selectedBoltProperty.allowedValues;

        if (allowedValues != null) {
            bolts = bolts.filter(p => allowedValues.includes(p.id ?? 0));
        }

        const boltsIds = bolts.map(p => p.id);

        this.setBoltsData(boltsIds);
        return this.filterBolts();
    }

    public updateProductNameText() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        this.bolts.forEach(boltData => {
            const bolt = this.boltFamilies.find((r) => r.id == boltData.id);
            boltData.name = bolt?.getTranslatedNameText(codeListDeps) as string;
        });
    }

    private setBoltsData(boltsIds?: (number | undefined)[]) {
        const bolts = this.boltFamilies.filter(p => boltsIds?.some((boltId) => boltId == p.id));
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        this.boltsData = bolts.map((p): IBolt => ({
            id: p.id,
            defaultSortOrder: p.sortOrder as number,
            image: `sprite-${p.image}`,
            name: p.getTranslatedNameText(codeListDeps) ?? '',
            tag: p.tag != null && p.tag.length > 0 ? this.translate(p.tag) : '',
        }));
    }

    private filterBolts() {
        let bolts = this.boltsData.slice();

        // allowed values
        const allowedValues = this.selectedBoltProperty.allowedValues;
        if (allowedValues != null) {
            bolts = bolts.filter(p => allowedValues.includes(p.id));
        }

        // sort by
        bolts = this.sortBoltsFamilyBy(bolts ?? [], SelectProductSortBy.default);

        return bolts;
    }

    private sortBoltsFamilyBy(bolts: IBolt[], selectBy: SelectProductSortBy) {
        if (selectBy == null) {
            return bolts ?? [];
        }

        switch (selectBy) {
            case SelectProductSortBy.default:
                return sortBy(bolts, p => p.defaultSortOrder);

            case SelectProductSortBy.name:
                return sortBy(bolts, p => p.name.toLowerCase());

            default:
                throw new Error('Invalid sort by.');
        }
    }
}
