import { LocalizationService } from '../services/localization.service';

export function getDesignTypeSpecificKey(localization: LocalizationService, key: string, designTypeId = 107 /*DesignTypeId*/) {
    const designTypeIdEnum = {
        CurtainWall: 107  // DesignTypeId.CurtainWall
    };

    let keySuffix = '';
    const designType = Object.entries(designTypeIdEnum)
        .find(([, value]) => value == designTypeId);
    if (designType != null) {
        keySuffix = designType[0];
    }

    const newKey = `${key}.${keySuffix}`;
    return localization.getKeyExists(newKey) ? newKey : key;
}
