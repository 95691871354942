import { CodeList } from '../code-list';
import { LoadCombinationEquationEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';
import { DesignMethodGroups } from '../generated-modules/Hilti.CW.CalculationService.Shared.Enums';

export class LoadCombinationEquation extends CodeList {
    public descriptionFormat?: string;
    public descriptionParams?: number[];
    public designMethodGroup?: DesignMethodGroups;
    public foreignId?: number;

    constructor(codeList: LoadCombinationEquationEntity) {
        super(codeList);

        if (codeList != null) {
            this.descriptionFormat = codeList.descriptionFormat,
                this.descriptionParams = codeList.descriptionParams,
                this.designMethodGroup = codeList.designMethodGroup,
                this.foreignId = codeList.foreignId;
        }
    }

    public static fromService(codeList: LoadCombinationEquationEntity) {
        return new LoadCombinationEquation(CodeList.baseFromService('LoadCombinationEquationEntity', codeList, {
            id: codeList.id,
            displayKey: codeList.displayKey,
            descriptionFormat: codeList.descriptionFormat,
            descriptionParams: codeList.descriptionParams,
            designMethodGroup: codeList.designMethodGroup,
            foreignId: codeList.foreignId
        } as LoadCombinationEquationEntity));
    }

    public override cloneDeep(): LoadCombinationEquation {
        const codeList = super.cloneDeep() as LoadCombinationEquation;
        codeList.descriptionFormat = this.descriptionFormat;
        codeList.descriptionParams = this.descriptionParams;
        codeList.designMethodGroup = this.designMethodGroup;
        codeList.foreignId = this.foreignId;

        return codeList;
    }
}