import { CodeList, ICodeListTextDeps } from '../code-list';
import { AnchorFilterGroupEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';
import { AnchorFilterGroupOperator } from '../generated-modules/Hilti.CW.CalculationService.Shared.Enums';

export class AnchorFilterGroup extends CodeList {
    public groupOperator?: AnchorFilterGroupOperator;

    constructor(codeList: AnchorFilterGroupEntity) {
        super(codeList);

        if (codeList != null) {
            this.groupOperator = codeList.groupOperator;
        }
    }

    public static fromService(codeList: AnchorFilterGroupEntity) {
        const anchorFilterGroup = new AnchorFilterGroup(CodeList.baseFromService('AnchorFilterGroupEntity', codeList, {
            groupOperator: codeList.groupOperator
        } as AnchorFilterGroupEntity));

        anchorFilterGroup.nameResourceKey = `Agito.Hilti.Profis3.CodeList.AnchorFilterGroupEntity.${anchorFilterGroup.displayKey}`;
        return anchorFilterGroup;
    }

    public override cloneDeep(): AnchorFilterGroup {
        const codeList = super.cloneDeep() as AnchorFilterGroup;
        codeList.groupOperator = this.groupOperator;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
}
