import { Injectable } from '@angular/core';
import {
    AppSettings, IDesignInfo, IDesignListInfo, IImportDesignProvider, IModuleInitialData,
    IModulePreInitialData, IQuickStartApplication, IServiceNameMapping, IUserSettingsInfo
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';

import { environment } from '../../environments/environmentCW';
import { ApplicationProviderService } from './application-provider.service';
import { ImportDesignProviderService } from './import-design-provider.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataService {

    constructor(
        private applicationProviderService: ApplicationProviderService,
        private importDesignProviderService: ImportDesignProviderService
    ) { }

    /**
     * Gets the pre initial data (static data) which pe-ui needs before anything else like authenticationRequiredServices and serviceNameMapping
     */
    public getPreInitialData(): IModulePreInitialData {
        return {
            // Common
            authenticationRequiredServices: this.getAuthenticationRequiredServices(),
            serviceNameMapping: this.getServiceNameMapping(),
            importFileExtensions: this.getImportFileExtensions(),
            userSettingsInfo: this.getUserSettingsInfo()
        };
    }

    /**
     * Gets the initial data like quick start buttons, design info, app settings data,...
     */
    public getInitialData(): IModuleInitialData {
        return {
            // Quick start
            quickStartApplications: this.getQuickStartApplications(),

            // App settings
            appSettingsData: this.getAppSettingsData(),

            virtualTours: [],

            // Design info
            designInfo: this.getDesignInfo(),

            // List of designs
            designListInfo: this.getDesignListInfo(),

            // Import design provider
            importDesignProvider: this.getImportDesignProvider(),

            // Design types
            designTypes: this.applicationProviderService.getDesignTypes,

            // Favorites
            favoritesInfo: this.applicationProviderService.getFavoritesInfo()
        };
    }

    public updateModuleData(): IModuleInitialData {
        return {
            quickStartApplications: this.getQuickStartApplications(),
            appSettingsData: this.getAppSettingsData(),
            virtualTours: [],
            designInfo: this.getDesignInfo(),
            designListInfo: this.getDesignListInfo(),
        };
    }

    private getAuthenticationRequiredServices(): string[] {
        return [
            environment.calculationServiceUrl,
            environment.cwTranslationsServiceUrl
        ];
    }

    private getServiceNameMapping(): IServiceNameMapping {
        const mapping: IServiceNameMapping = {
            [environment.calculationServiceUrl]: 'cw-calculation-service',
            [environment.cwTranslationsServiceUrl]: 'cw-translations-service'
        };

        return mapping;
    }

    private getImportFileExtensions(): string[] {
        return ImportDesignProviderService.GetAllowedImportExtensions();
    }

    private getQuickStartApplications(): IQuickStartApplication[] {
        return this.applicationProviderService.getQuickStartApplications();
    }

    private getAppSettingsData(): AppSettings[] {
        return this.applicationProviderService.getAppSettings();
    }

    private getDesignInfo(): IDesignInfo[] {
        return [this.applicationProviderService.getDesignInfo()];
    }

    private getDesignListInfo(): IDesignListInfo[] {
        return this.applicationProviderService.getDesignListInfo();
    }

    private getImportDesignProvider(): IImportDesignProvider {
        return this.importDesignProviderService;
    }

    private getUserSettingsInfo(): IUserSettingsInfo {
        return this.applicationProviderService.getUserSettingsInfo();
    }
}
