import { CodeList } from '../code-list';
import { StandoffTypesEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class StandoffTypes extends CodeList {
    public allowed?: boolean;
    public disabled?: boolean;

    constructor(codeList: StandoffTypesEntity) {
        super(codeList);

        if (codeList != null) {
            this.image = codeList.image;
        }
    }

    public static fromService(codeList: StandoffTypesEntity) {
        return new StandoffTypes(CodeList.baseFromService('StandoffTypesEntity', codeList, {
            image: codeList.image
        } as StandoffTypesEntity));
    }

    public override cloneDeep(): StandoffTypes {
        const codeList = super.cloneDeep() as StandoffTypes;
        codeList.image = this.image;

        return codeList;
    }
}
