import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';

/**
 * Helper class for creating component elements
 */
export class ComponentsHelper {

    /**
     * Method which returns dropdown component properties
     * @param id
     * @param translatedTitle
     * @param items
     * @param selectedValue
     * @param disabled
     * @returns
     */
    public static createDropdownComponent<TValue>(id: string, translatedTitle?: string, items?: DropdownItem<TValue>[], selectedValue?: TValue, disabled?: boolean) {
        const dropdown: DropdownProps<TValue> = {
            id: id,
            title: translatedTitle,
            items,
            selectedValue,
            disabled: disabled
        };

        return dropdown;
    }
}