import { Design } from '../entities/design';
import { UtilizationItemEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.DesignReportData';
import { utilizations } from '../entities/utilization';

export class UtilizationsHelper {

    public static getTensionMaxUtilization(design: Design | undefined, loadCombinationId: string) {
        return this.getUtilizationMaxValueDetail(design, 'tension', loadCombinationId);
    }

    public static getShearMaxUtilization(design: Design | undefined, loadCombinationId: string) {
        return this.getUtilizationMaxValueDetail(design, 'shear', loadCombinationId);
    }

    public static getCombinationMaxUtilization(design: Design | undefined, loadCombinationId: string) {
        return this.getUtilizationMaxValueDetail(design, 'combination', loadCombinationId);
    }

    public static getGeneralMaxUtilizationValue(design: Design | undefined, loadCombinationId: string) {
        const maxGroup = this.getGeneralMaxUtilizationDetail(design, loadCombinationId);
        return this.getValueOfUtilization(maxGroup);
    }

    public static getGeneralMaxUtilizationDetail(design: Design | undefined, loadCombinationId: string) {
        let max = 0;
        let maxGroup: UtilizationItemEntity | undefined;

        for (const util of utilizations(design?.isAnchorChannelWithXtProductFamily)) {
            const group = this.getUtilizationMaxValueDetail(design, util.property, loadCombinationId);
            const groupValue = this.getValueOfUtilization(group);
            if (groupValue > max) {
                max = groupValue;
                maxGroup = group;
            }
        }

        return maxGroup;
    }

    public static isGeneralUtilizationInvalid(design: Design | undefined, loadCombinationId: string) {
        // Please note that for general invalid utilization status we must check if any of utilization is invalid, not just general max value which could be valid in some cases
        for (const util of utilizations(design?.isAnchorChannelWithXtProductFamily)) {
            for (const detail of util.details) {
                const item = this.getUtilization(design, detail.property, loadCombinationId);
                if (this.isUtilizationInvalid(item))
                    return true;
            }
        }

        return false;
    }

    public static isUtilizationInvalid(util: UtilizationItemEntity | undefined) {
        // Check if backend has overriden invalid status othervise check value
        return util?.isInvalidStatusOverriden ?? this.isValueInvalid(this.getValueOfUtilization(util));
    }

    public static isValueInvalid(utilValue: number) {
        return utilValue > 100;
    }

    public static getUtilizationMaxValue(design: Design | undefined, utilization: string, loadCombinationId: string): number {
        return this.getValueOfUtilization(this.getUtilizationMaxValueDetail(design, utilization, loadCombinationId));
    }

    public static getUtilizationMaxValueDetail(design: Design | undefined, utilization: string, loadCombinationId: string): UtilizationItemEntity | undefined {
        let maxUtilization = -1;
        let maxUtilizationDetail: UtilizationItemEntity | undefined;

        const category = utilizations(design?.isAnchorChannelWithXtProductFamily).find(x => x.property == utilization);

        for (const detail of category?.details ?? []) {
            const item = this.getUtilization(design, detail.property, loadCombinationId);
            const value = this.getValueOfUtilization(item);
            if (value > maxUtilization && item) {
                maxUtilizationDetail = item;
                maxUtilization = value;
            }
        }

        return maxUtilizationDetail;
    }

    public static getValueOfUtilization(util: UtilizationItemEntity | undefined) {
        const value = Number(util?.value.value);
        return isNaN(value) ? 0 : value;
    }

    public static getUtilization(design: Design | undefined, utilization: string, loadCombinationId: string): UtilizationItemEntity | undefined {
        return this.getAllUtilizations(design, loadCombinationId).find(x => x.name == utilization);
    }

    public static showUtilizationsChecker(design: Design | undefined, loadCombinationId: string) {
        return this.getAllUtilizations(design, loadCombinationId).length > 0;
    }

    public static showConcreteCompressionForceChecker(design: Design | undefined, loadCombinationId: string) {
        return this.getAllUtilizations(design, loadCombinationId).filter(it => it.name == 'ResultingCompression').length > 0;
    }

    public static getAllUtilizations(design: Design | undefined, loadCombinationId: string): UtilizationItemEntity[] {
        const utilData = design?.designData.reportData?.utilizationsData[loadCombinationId];
        return utilData?.generalUtilizations?.concat(utilData?.supplementaryReinforcementUtilizations ?? []) ?? [];
    }
}
