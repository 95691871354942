
import { CodeList } from '../code-list';
import { SeismicCategoryTypeEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class SeismicCategoryTypes extends CodeList {
    constructor(codeList: SeismicCategoryTypeEntity) {
        super(codeList);
    }

    public static fromService(codeList: SeismicCategoryTypeEntity) {
        return new SeismicCategoryTypes(CodeList.baseFromService('SeismicCategoryTypeEntity', codeList));
    }

    public override cloneDeep(): SeismicCategoryTypes {
        return super.cloneDeep() as SeismicCategoryTypes;
    }
}
