import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import { ImportService } from '../../services/import.service';
import { DocumentService } from '../../services/document.service';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { ImportDesignProviderService } from '../../services/import-design-provider.service';
import { DesignEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { CalculationService } from '../../services/calculation.service';
import { environment } from '../../../environments/environmentCW';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { IDesignSectionComponent } from '@profis-engineering/pe-ui-common/entities/design-section';

@Component({
    templateUrl: './design-section.component.html',
    styleUrls: ['./design-section.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class DesignSectionComponent implements IDesignSectionComponent {

    @ViewChild('openDesignInput')
    public inputElement!: ElementRef<HTMLInputElement>;

    @Input()
    public openFile = () => this.selectOpenFile();

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public closeExportReport = () => {};

    public allowedExtensions: string;

    public openDesign = false;

    public loading = false;

    public duplicating = false;

    constructor(
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        public offlineService: OfflineService,
        public modalService: ModalService,
        private userService: UserService,
        private elementRef: ElementRef<HTMLElement>,
        private importService: ImportService,
        private documentService: DocumentService,
        private calculationService: CalculationService
    ) {
        includeSprites(this.elementRef.nativeElement,
            'sprite-export-design',
            'sprite-duplicate-design',
            'sprite-openfile-d-light',
            'sprite-long-arrow-right-white');

        this.allowedExtensions = ImportDesignProviderService.GetAllowedImportExtensions()
            .sort((a, b) => a.localeCompare(b))
            .join(',');
    }

    public get design() {
        return this.userService.design;
    }

    public get generateReportDisabled() {
        if (this.design && this.design.isTemplate) {
            return true;
        }

        return this.design && (this.design.pendingCalculation || !this.design.reportData?.canExportReport);
    }

    public get isDesignInReadOnlyMode() {
        return this.design && (this.design.isTemplate || this.design.isReadOnlyDesignMode);
    }

    public openExport() {
        this.modalService.openExportDesignDialog();
    }

    public openExportReport() {
        this.loading = true;

        this.modalService.openExportReportDialog();

        this.loading = false;
    }

    public get isStateChanged() {
        return this.design.stateChanged();
    }

    public selectOpenFile() {
        if (this.openDesign) {
            return;
        }

        if (this.inputElement.nativeElement.value == null || this.inputElement.nativeElement.value == '') {
            setTimeout(() => {
                this.inputElement.nativeElement.click();
            });
        }
    }

    public importFileSelected() {
        if (this.inputElement.nativeElement.value != null
            && this.inputElement.nativeElement.value != ''
            && this.inputElement.nativeElement.files != null) {
            const file = this.inputElement.nativeElement.files[0];
            // clear input
            this.inputElement.nativeElement.value = '';
            this.import(file);
        }
    }

    public duplicateDesign() {
        if (this.duplicating) {
            return;
        }

        this.duplicating = true;

        this.design.usageCounter.DuplicateDesign++;

        const projectId = this.design.projectId;

        const projectDesign = { ...this.design.designData.projectDesign } as DesignEntity;
        projectDesign.designName += ' (1)';

        this.calculationService.createAndOpenFromProjectDesign(projectDesign, projectId, this.design.templateId)
            .finally(() => {
                this.duplicating = false;
            })
            .then((calculationResult) => {
                const openUrl = `${environment.baseUrl.replace(/\/+$/, '')}${UrlPath.main}${calculationResult.design.id}`;
                    window.open(openUrl, '_blank');

                    calculationResult.design.dispose();
            });
    }

    private import(projectDesign: File | Blob, name?: string) {
        this.openDesign = true;
        const currentProject = Object.values(this.documentService.projects).find((proj) => proj.id == this.design.projectId) as Project;
        return this.importService.import(currentProject, this.design, projectDesign, name as string, !this.offlineService.isOffline)
            .finally(() => {
                this.openDesign = false;
            });
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
