<div class="control-row section-units-params" *ngIf="!collapsed">
    <div class="control-column length-dropdown">
        <pe-dropdown
            [id]="lengthDropdown.id"
            [title]="lengthDropdown.title"
            [items]="lengthDropdown.items"
            [selectedValue]="data.length"
            (selectedValueChange)="data.length = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column area-dropdown">
        <pe-dropdown
            [id]="areaDropdown.id"
            [title]="areaDropdown.title"
            [items]="areaDropdown.items"
            [selectedValue]="data.area"
            (selectedValueChange)="data.area = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column stress-dropdown">
        <pe-dropdown
            [id]="stressDropdown.id"
            [title]="stressDropdown.title"
            [items]="stressDropdown.items"
            [selectedValue]="data.stress"
            (selectedValueChange)="data.stress = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column force-dropdown">
        <pe-dropdown
            [id]="forceDropdown.id"
            [title]="forceDropdown.title"
            [items]="forceDropdown.items"
            [selectedValue]="data.force"
            (selectedValueChange)="data.force = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column moment-dropdown">
        <pe-dropdown
            [id]="momentDropdown.id"
            [title]="momentDropdown.title"
            [items]="momentDropdown.items"
            [selectedValue]="data.moment"
            (selectedValueChange)="data.moment = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column temperature-dropdown">
        <pe-dropdown
            [id]="temperatureDropdown.id"
            [title]="temperatureDropdown.title"
            [items]="temperatureDropdown.items"
            [selectedValue]="data.temperature"
            (selectedValueChange)="data.temperature = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column force-per-length-dropdown">
        <pe-dropdown
            [id]="forcePerLengthDropdown.id"
            [title]="forcePerLengthDropdown.title"
            [items]="forcePerLengthDropdown.items"
            [selectedValue]="data.forcePerLength"
            (selectedValueChange)="data.forcePerLength = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column moment-per-length-dropdown">
        <pe-dropdown
            [id]="momentPerLengthDropdown.id"
            [title]="momentPerLengthDropdown.title"
            [items]="momentPerLengthDropdown.items"
            [selectedValue]="data.momentPerLength"
            (selectedValueChange)="data.momentPerLength = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column density-dropdown">
        <pe-dropdown
            [id]="densityDropdown.id"
            [title]="densityDropdown.title"
            [items]="densityDropdown.items"
            [selectedValue]="data.density"
            (selectedValueChange)="data.density = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>

    <div class="control-column base-plate-factor">
        <pe-numeric-text-box
            [id]="basePlateFactorTextBox.id"
            [title]="basePlateFactorTextBox.title"
            [tooltip]="basePlateFactorTextBox.tooltip"
            [placeholder]="basePlateFactorTextBox.placeholder"
            [defaultStepperValue]="basePlateFactorTextBox.defaultStepperValue"
            [value]="data.basePlateFactor"
            (valueChange)="data.basePlateFactor= $any($event).detail"
            [minValue]="basePlateFactorTextBox.minValue"
            [maxValue]="basePlateFactorTextBox.maxValue"
            [updateOnBlur]="true"
            [disabled]="disabled">
        </pe-numeric-text-box>
    </div>

    <div class="control-column safety-factor-perm-load" *ngIf="isEuropeanDesignStandard">
        <pe-numeric-text-box
            [id]="safetyFactorPermLoad.id"
            [title]="safetyFactorPermLoad.title"
            [tooltip]="safetyFactorPermLoad.tooltip"
            [placeholder]="safetyFactorPermLoad.placeholder"
            [defaultStepperValue]="safetyFactorPermLoad.defaultStepperValue"
            [value]="data.safetyFactorPermLoad"
            (valueChange)="data.safetyFactorPermLoad = $any($event).detail"
            [minValue]="safetyFactorPermLoad.minValue"
            [maxValue]="safetyFactorPermLoad.maxValue"
            [updateOnBlur]="true"
            [disabled]="disabled">
        </pe-numeric-text-box>
    </div>

    <div class="control-column safety-factor-var-load" *ngIf="isEuropeanDesignStandard">
        <pe-numeric-text-box
            [id]="safetyFactorVarLoad.id"
            [title]="safetyFactorVarLoad.title"
            [tooltip]="safetyFactorVarLoad.tooltip"
            [placeholder]="safetyFactorVarLoad.placeholder"
            [defaultStepperValue]="safetyFactorVarLoad.defaultStepperValue"
            [value]="data.safetyFactorVarLoad"
            (valueChange)="data.safetyFactorVarLoad = $any($event).detail"
            [minValue]="safetyFactorVarLoad.minValue"
            [maxValue]="safetyFactorVarLoad.maxValue"
            [updateOnBlur]="true"
            [disabled]="disabled">
        </pe-numeric-text-box>
    </div>

    <div class="control-column min-anchor-profile-dist" *ngIf="isEuropeanDesignStandard">
        <pe-numeric-text-box
            [id]="minAnchorProfileDist.id"
            [title]="minAnchorProfileDist.title"
            [tooltip]="minAnchorProfileDist.tooltip"
            [placeholder]="minAnchorProfileDist.placeholder"
            [defaultStepperValue]="minAnchorProfileDist.defaultStepperValue"
            [value]="data.minAnchorProfileDist"
            (valueChange)="data.minAnchorProfileDist = $any($event).detail"
            [unit]="data.length"
            [minValue]="minAnchorProfileDist.minValue"
            [maxValue]="minAnchorProfileDist.maxValue"
            [updateOnBlur]="true"
            [disabled]="disabled">
        </pe-numeric-text-box>
    </div>

    <div class="control-column min-concrete-cover" *ngIf="isEuropeanDesignStandard">
        <pe-numeric-text-box
            [id]="minConcreteCover.id"
            [title]="minConcreteCover.title"
            [tooltip]="minConcreteCover.tooltip"
            [placeholder]="minConcreteCover.placeholder"
            [defaultStepperValue]="minConcreteCover.defaultStepperValue"
            [value]="data.minConcreteCover"
            (valueChange)="data.minConcreteCover = $any($event).detail"
            [unit]="data.length"
            [minValue]="minConcreteCover.minValue"
            [maxValue]="minConcreteCover.maxValue"
            [updateOnBlur]="true"
            [disabled]="disabled">
        </pe-numeric-text-box>
    </div>

    <div class="control-column concrete-safety-factor-gamma-c" *ngIf="isEuropeanDesignStandard">
        <pe-dropdown
            [id]="concreteSafetyFactorGammaC.id"
            [title]="concreteSafetyFactorGammaC.title"
            [items]="concreteSafetyFactorGammaC.items"
            [selectedValue]="data.concreteSafetyFactorGammaC"
            (selectedValueChange)="data.concreteSafetyFactorGammaC = $any($event).detail"
            [validators]="[requiredValidator]"
            [disabled]="disabled">
        </pe-dropdown>
    </div>


</div>
