<pe-modal-header
    id="select-v-para-solution-dialog"
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.CW.SelectVParaSolution.Title')"
    [closeButtonDisabled]="false"
    [closeButtonId]="'select-v-para-solution-close-button'">
</pe-modal-header>

<form class="form" #ngForm="ngForm" (ngSubmit)="save()" [ngClass]="{ 'submitted': 'submitted' }" novalidate>
    <pe-modal-body id="select-v-para-solution-dialog" *ngIf="dataLoaded">
        <div class="component-section">
            <div class="control-row">
                <pe-label
                    [id]="'select-v-para-solution-label'"
                    [text]="translate('Agito.Hilti.CW.SelectVParaSolution.Description')">
                </pe-label>
            </div>

            <div class="control-row" id="select-v-para-solution-channel-family">
                <pe-dropdown
                    [id]="channelFamily.id"
                    [title]="channelFamily.title"
                    [items]="channelFamily.items"
                    [selectedValue]="selectedChannelFamilyId"
                    (selectedValueChange)="selectedChannelFamilyId = $any($event).detail; onChannelFamilyChange($any($event).detail)">
                </pe-dropdown>
            </div>

            <div class="control-row" id="select-v-para-solution-bolt-family">
                <pe-dropdown
                    [id]="boltFamily.id"
                    [title]="boltFamily.title"
                    [items]="boltFamily.items"
                    [selectedValue]="selectedBoltFamilyId"
                    (selectedValueChange)="selectedBoltFamilyId = $any($event).detail; onBoltFamilyChange($any($event).detail)">
                </pe-dropdown>
            </div>

            <div class="control-row" id="select-v-para-solution-bolt-size">
                <pe-dropdown
                    [id]="boltSize.id"
                    [title]="boltSize.title"
                    [items]="boltSize.items"
                    [selectedValue]="selectedBoltSizeId"
                    (selectedValueChange)="selectedBoltSizeId = $any($event).detail">
                </pe-dropdown>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'select-v-para-solution-cancel-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [l10n]="'Agito.Hilti.CW.SelectVParaSolution.Cancel'"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            [id]="'select-v-para-solution-save-button'"
            [look]="'Cta'"
            class="modal-button"
            type="submit"
            [disabled]="submitted">
            <pe-loading-button
                [loading]="false"
                [l10n]="'Agito.Hilti.CW.SelectVParaSolution.OK'">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
