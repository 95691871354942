import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './cw/app.module';
import { environment } from './environments/environmentCW';
import { registerGlobalStyles } from './global-styles';

declare const Zone: any;
declare const ngDevMode: boolean;

// correctly check if we are in pe-ui-cw zone
NgZone.isInAngularZone = () => Zone.current.get('zoneName') === 'pe-ui-cw';

registerGlobalStyles();

if (environment.production) {
    enableProdMode();
}

interface WindowModuleDefinition extends Window {
    modules: Module[];
}

interface Module {
    name: string;
    bootstrap: () => void;
}

const windowModuleDefinition = window as unknown as WindowModuleDefinition;
const modules = windowModuleDefinition.modules = windowModuleDefinition.modules ?? [];

modules.push({
    name: 'pe-ui-cw',
    bootstrap
});

function bootstrap() {
    // mark the zone with pe-ui-cw flag so we can correctly check in NgZone.isInAngularZone
    const ngZone = new NgZone({
        enableLongStackTrace: typeof ngDevMode === 'undefined' ? false : !!ngDevMode,
        shouldCoalesceEventChangeDetection: false,
        shouldCoalesceRunChangeDetection: false,
    });
    ngZone.runOutsideAngular = <T>(fn: (...args: any[]) => T): T => {
        return Zone.root.run(fn);
    };

    const zone = (ngZone as any)._inner;
    zone._properties.zoneName = 'pe-ui-cw';

    platformBrowserDynamic()
        .bootstrapModule(AppModule, { ngZone })
        .catch(err => console.error(err));
}
