export function sanitizePropertyValueForJson(value: any): any {
    if (value == null) {
        return null;
    }

    if (typeof value === 'number') {

        // Handle Infinity
        if (value == Number.POSITIVE_INFINITY) {
            return '∞';
        }
        if (value == Number.NEGATIVE_INFINITY) {
            return '-∞';
        }
    }

    return value;
}