import { CodeList } from '../code-list';
import { InspectionTypesEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class InspectionTypes extends CodeList {

    constructor(codeList: InspectionTypesEntity) {
        super(codeList);
    }

    public static fromService(codeList: InspectionTypesEntity) {
        return new InspectionTypes(CodeList.baseFromService('InspectionTypesEntity', codeList));
    }

    public override cloneDeep(): InspectionTypes {
        return super.cloneDeep() as InspectionTypes;
    }
}
