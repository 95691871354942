<div class="load-forces box-section box-section-white" *ngIf="showLoadForces">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static load-forces-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.CW.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-cw-sprite pe-ui-cw-sprite-anchor-loads"></div>
            </div>
            <span class="bar-title" [innerHtml]="title"></span>
        </div>

        <pe-collapse
            [container]="'load-forces'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>
    <div class="load-forces-table" *ngIf="!collapsed">
        <table>
            <caption>Loads forces table</caption>
            <tbody>
                <tr>
                    <th [innerHtml]="tableIdTitle"></th>
                    <th class="notranslate" [innerHtml]="getLocalizedTextAndForceUnit('Agito.Hilti.CW.LoadForces.Table.N')"></th>
                    <th class="notranslate" [innerHtml]="getLocalizedTextAndForceUnit('Agito.Hilti.CW.LoadForces.Table.Qx')"></th>
                    <th class="notranslate" [innerHtml]="getLocalizedTextAndForceUnit('Agito.Hilti.CW.LoadForces.Table.Qy')"></th>
                </tr>
                <tr *ngFor="let force of forces; trackBy: trackLoadForceById" class="notranslate force-row">
                    <td [innerHtml]="force.id"></td>
                    <td [innerHtml]="force.N"></td>
                    <td [innerHtml]="force.Qx"></td>
                    <td [innerHtml]="force.Qy"></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="resize-element" (mousedown)="beginResizing($event)" *ngIf="showResizer"></div>
</div>
