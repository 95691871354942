import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { LocalizationService } from '../../services/localization.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { NumberService } from '../../services/number.service';
import { getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonRegion as Region } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Validators } from '@angular/forms';
import { UnitService } from '../../services/unit.service';
import { IPropertyMetaData, PropertyMetaData } from '../../entities/properties';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import { ConcreteSafetyFactorGammaC } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { ProjectCodeList } from '../../entities/enums/project-code-list';
import { DesignStandardHelper } from '../../helpers/design-standard-helper';
import { ComponentsHelper } from '../../helpers/components-helper';

export interface IUnitlInputsData {
    // Context
    addEditType?: AddEditType;

    // Values
    length?: Unit;
    area?: Unit;
    stress?: Unit;
    force?: Unit;
    moment?: Unit;
    temperature?: Unit;
    forcePerLength?: Unit;
    momentPerLength?: Unit;
    density?: Unit;
    basePlateFactor?: number;
    safetyFactorPermLoad?: number;
    safetyFactorVarLoad?: number;
    minAnchorProfileDist?: number;
    minConcreteCover?: number;
    concreteSafetyFactorGammaC?: ConcreteSafetyFactorGammaC;
}

@Component({
    selector: 'cw-unit-inputs',
    templateUrl: './unit-inputs.component.html'
})
export class UnitInputsComponent implements OnInit, OnChanges {
    @Input()
    public collapsed!: boolean;

    @Input()
    public data!: IUnitlInputsData;

    @Input()
    public selectedRegionId!: number;

    @Input()
    public selectedDesignStandardId!: number;

    @Input()
    public disabled!: boolean;

    private dataLoaded = false;

    public lengthDropdown!: DropdownProps<Unit>;
    public areaDropdown!: DropdownProps<Unit>;
    public stressDropdown!: DropdownProps<Unit>;
    public forceDropdown!: DropdownProps<Unit>;
    public momentDropdown!: DropdownProps<Unit>;
    public temperatureDropdown!: DropdownProps<Unit>;
    public forcePerLengthDropdown!: DropdownProps<Unit>;
    public momentPerLengthDropdown!: DropdownProps<Unit>;
    public densityDropdown!: DropdownProps<Unit>;
    public basePlateFactorTextBox!: NumericTextBoxProps;
    public safetyFactorPermLoad!: NumericTextBoxProps;
    public safetyFactorVarLoad!: NumericTextBoxProps;
    public minAnchorProfileDist!: NumericTextBoxProps;
    public minConcreteCover!: NumericTextBoxProps;
    public concreteSafetyFactorGammaC!: DropdownProps<ConcreteSafetyFactorGammaC>;

    public requiredValidator = Validators.required;

    constructor(
        private localization: LocalizationService,
        private numberService: NumberService,
        private unit: UnitService,
        private codeList: CodeListService,
        private commonCodeList: CommonCodeListService) { }

    ngOnInit(): void {
        if (!this.dataLoaded && this.codeList.projectCodeLists != undefined) {
            this.lengthDropdown = this.createCommonCodeListDropdown('Length', CommonCodeList.UnitLength, this.unit.getDefaultUnit(UnitGroup.Length));
            this.areaDropdown = this.createCommonCodeListDropdown('Area', CommonCodeList.UnitArea, Unit.mm2);
            this.stressDropdown = this.createCommonCodeListDropdown('Stress', CommonCodeList.UnitStress, Unit.N_mm);
            this.forceDropdown = this.createCommonCodeListDropdown('Force', CommonCodeList.UnitForce, Unit.kN);
            this.momentDropdown = this.createCommonCodeListDropdown('Moment', CommonCodeList.UnitMoment, Unit.kNm);
            this.temperatureDropdown = this.createCommonCodeListDropdown('Temperature', CommonCodeList.UnitTemperature, Unit.C);
            this.forcePerLengthDropdown = this.createCommonCodeListDropdown('ForcePerLength', CommonCodeList.UnitForcePerLength, Unit.kN_m);
            this.momentPerLengthDropdown = this.createCommonCodeListDropdown('MomentPerLength', CommonCodeList.UnitMomentPerLength, Unit.kNm_m);
            this.densityDropdown = this.createCommonCodeListDropdown('Density', CommonCodeList.UnitDensity, Unit.kg_m3);

            this.basePlateFactorTextBox = this.createNumericTextBox('base-plate-factor', 'BasePlateFactor', undefined, PropertyMetaData.Option_CW_BasePlateFactor);
            this.safetyFactorPermLoad = this.createNumericTextBox('safety-factor-perm-load', 'SafetyFactorPermanentLoad', undefined, PropertyMetaData.Option_CW_SafetyFactorPermanentLoad);
            this.safetyFactorVarLoad = this.createNumericTextBox('safety-factor-var-load', 'SafetyFactorVariableLoad', undefined, PropertyMetaData.Option_CW_SafetyFactorVariableLoad);
            this.minAnchorProfileDist = this.createNumericTextBox('min-anchor-profile-dist', 'MinAnchorProfileDist', undefined, PropertyMetaData.Option_CW_MinAnchorProfileDist, this.localization.getString('Agito.Hilti.CW.ApplicationSettings.MinAnchorProfileDist.Placeholder'));
            this.minConcreteCover = this.createNumericTextBox('min-concrete-cover', 'MinConcreteCover', undefined, PropertyMetaData.Option_CW_MinConcreteCover);
            this.concreteSafetyFactorGammaC = this.createInternalCodeListDropdown('ConcreteSafetyFactorGammaC', ProjectCodeList.ConcreteSafetyFactorGammaC, ConcreteSafetyFactorGammaC.GeneralUse);

            this.dataLoaded = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedRegionId'] != undefined &&
            changes['selectedRegionId'].previousValue != changes['selectedRegionId'].currentValue) {
            this.setRegionDefaults(changes['selectedRegionId'].currentValue);
        }
    }

    public get isEuropeanDesignStandard() {
        return DesignStandardHelper.isEnBasedDesignStandard(this.selectedDesignStandardId);
    }

    private createCommonCodeListDropdown<T>(key: string, codeList: CommonCodeList, defaultUnit: T) {
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        const dropdown: DropdownProps<T> = ComponentsHelper.createDropdownComponent(
            'add-edit-design-unit-' + key.toLowerCase() + '-dropdown',
            this.translateDropdownTitle(key),
            this.commonCodeList.commonCodeLists[codeList].map((unit) => {
                return {
                    id: `import-loads-${key}-${unit.id}`,
                    value: unit.id,
                    text: unit.getTranslatedNameText(codeListDeps)
                } as DropdownItem<T>;
            }),
            defaultUnit
        );

        return dropdown;
    }

    private createInternalCodeListDropdown<T>(key: string, codeList: ProjectCodeList, defaultValue: T) {
        const dropdownId = `add-edit-design-${key.toLowerCase()}-dropdown`;

        const dropdown: DropdownProps<T> = ComponentsHelper.createDropdownComponent(
            dropdownId,
            this.translateDropdownTitle(key),
            this.codeList.projectCodeLists[codeList].map((p) => {
                return {
                    id: `${dropdownId}-item-${p.id}`,
                    value: p.id,
                    text: p.nameResourceKey != null ? this.localization.getString(p.nameResourceKey) : ''
                } as DropdownItem<T>;
            }),
            defaultValue
        );

        return dropdown;
    }

    public createNumericTextBox(id: string, translationKey: string, value: number | undefined, metaData: IPropertyMetaData, placeholder?: string | number) {
        const propertyValue = this.codeList.getPropertyValue(metaData.id, this.selectedRegionId);

        return {
            id: `add-edit-design-unit-${id}`,
            title: this.localization.getString(`Agito.Hilti.CW.ApplicationSettings.${translationKey}`),
            minValue: propertyValue.minValue,
            maxValue: propertyValue.maxValue,
            placeholder: placeholder ?? propertyValue.defaultValue,
        };
    }

    private setRegionDefaults(regionId: number) {
        const region = this.commonCodeList.commonCodeLists[CommonCodeList.Region].find((codeList) => codeList.id == regionId) as Region;

        this.data.length = region.defaultUnitLength;
        this.data.area = region.defaultUnitArea;
        this.data.stress = region.defaultUnitStress;
        this.data.force = region.defaultUnitForce;
        this.data.moment = region.defaultUnitMoment;
        this.data.temperature = region.defaultUnitTemperature;
        this.data.forcePerLength = region.defaultUnitForcePerLength;
        this.data.momentPerLength = region.defaultUnitMomentPerLength;
        this.data.density = region.defaultUnitDensity;

        const propValueFactorGammaC = this.codeList.getPropertyValue(PropertyMetaData.Option_CW_ConcreteSafetyFactorGammaC.id, regionId);
        this.data.concreteSafetyFactorGammaC = propValueFactorGammaC.defaultValue;
    }

    private translateDropdownTitle(key: string): string {
        return this.localization.getString(`Agito.Hilti.CW.ApplicationSettings.${key}`);
    }
}
