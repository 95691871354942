import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { CodeList, ICodeListTextDeps } from '../code-list';
import { AnchorFamilyEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class AnchorFamily extends CodeList {
    public sortOrder?: number;
    public allowedInAnchorFilters?: number[];
    public holeDiameters?: number[];
    public fixtureThicknessMin?: number;
    public fixtureThicknessMax?: number;

    constructor(codeList: AnchorFamilyEntity) {
        super(codeList);

        if (codeList != null) {
            this.sortOrder = codeList.sortOrder;
            this.allowedInAnchorFilters = codeList.allowedInAnchorFilters;
            this.holeDiameters = codeList.holeDiameters;
            this.fixtureThicknessMax = codeList.fixtureThicknessMax;
            this.fixtureThicknessMin = codeList.fixtureThicknessMin;
        }
    }

    public static fromService(codeList: AnchorFamilyEntity) {
        return new AnchorFamily(CodeList.baseFromService('AnchorFamilyEntity', codeList, {
            image: codeList.image != null && codeList.image != '' ? `anchor-family-${codeList.image}` : 'no-photo-available',
            sortOrder: codeList.sortOrder,
            allowedInAnchorFilters: codeList.allowedInAnchorFilters,
            holeDiameters: codeList.holeDiameters,
            fixtureThicknessMin: codeList.fixtureThicknessMin,
            fixtureThicknessMax: codeList.fixtureThicknessMax
        } as AnchorFamilyEntity));
    }

    public override cloneDeep(): AnchorFamily {
        const codeList = super.cloneDeep() as AnchorFamily;
        codeList.sortOrder = this.sortOrder;
        codeList.allowedInAnchorFilters = this.allowedInAnchorFilters;
        codeList.holeDiameters = this.holeDiameters;
        codeList.fixtureThicknessMin = this.fixtureThicknessMin;
        codeList.fixtureThicknessMax = this.fixtureThicknessMax;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        if (_codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        const years = _codeListDeps.localizationService.getString('Agito.Hilti.CW.Anchors.Years');
        return formatKeyValue(this.displayKey ?? '', { years });
    }
}