import { CodeList, ICodeListTextDeps } from '../code-list';
import { AnchorEmbedmentDepthEntity } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList.PI';

export class AnchorEmbedmentDepth extends CodeList {
    public hef?: number;

    constructor(codeList: AnchorEmbedmentDepthEntity) {
        super(codeList);

        if (codeList != null) {
            this.hef = codeList.hef;
        }
    }

    public static fromService(codeList: AnchorEmbedmentDepthEntity) {
        return new AnchorEmbedmentDepth(CodeList.baseFromService('AnchorEmbedmentDepthEntity', codeList, {
            hef: codeList.hef
        } as AnchorEmbedmentDepthEntity));
    }

    public override cloneDeep(): AnchorEmbedmentDepth {
        const codeList = super.cloneDeep() as AnchorEmbedmentDepth;
        codeList.hef = this.hef;

        return codeList;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override getTranslatedNameText(_codeListDeps: ICodeListTextDeps) {
        return this.displayKey;
    }
}
