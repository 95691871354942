<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.TorquingTypepopup.Title')"></pe-modal-header>

<pe-modal-body>
    <div class="automatic-torque-content">
        <div class="automatic-torque-text">
            <p class="automatic-torque-description" [innerHtml]="translate('Agito.Hilti.Profis3.TorquingTypePopup.AT.Description')"></p>

            <p class="automatic-torque-steps-header" [innerHtml]="translate('Agito.Hilti.Profis3.TorquingTypePopup.AT.Steps')"></p>

            <div class="automatic-torque-steps-container">
                <span class="automatic-torque-step" [innerHtml]="translate('Agito.Hilti.Profis3.AutomaticTorquingTypePopup.Step1')"></span>
                <br>
                <span class="automatic-torque-step" [innerHtml]="translate('Agito.Hilti.Profis3.AutomaticTorquingTypePopup.Step2')"></span>
                <br>
                <span class="automatic-torque-step" [innerHtml]="translate('Agito.Hilti.Profis3.AutomaticTorquingTypePopup.Step3')"></span>
                <br>
                <span class="automatic-torque-step" [innerHtml]="translate('Agito.Hilti.Profis3.AutomaticTorquingTypePopup.Step4')"></span>
            </div>
        </div>

        <div class="automatic-torque-image">
            <img src="{{automaticTooltipImage}}" alt="at-tool-torquing">
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [id]="'supplementary-reinforcement-info-ok-button'" [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
