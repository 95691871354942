<div id="LC-table" class="loads-container">
    <!-- Tooltips -->
    <ng-template #staticLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.staticTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #seismicLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.seismicTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #fatigueLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.fatigueTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #fireLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.fireTooltip"></pe-content-tooltip>
    </ng-template>
    <!-- Tooltips -->

    <pe-resizer
        [disabled]="collapsed"
        [targetHtmlElement]="loadsContainer"
        [parentHtmlElement]="parentElement"
        [direction]="'up'"
        (sizeChange)="onResize()">

        <div id="load-combinations" class="control loads" *ngIf="design != null">
            <!-- Collapsed with a single decisive load combination. -->
            <div class="loads-header-collapsed" *ngIf="collapsed && selectedLoad != null">
                <div class="loads-top-header">
                    <div class="collapsed-loads-space"></div>

                    <div class="column-name" style="flex-basis: 150px;">
                        <div class="text" [l10n]="titleTranslation"></div>
                    </div>

                    <div class="column-type" style="flex-basis: 100px;">
                        <div class="text" l10n="Agito.Hilti.Profis3.Loads.Type"></div>
                    </div>

                    <div
                        class="column-loads clip"
                        *ngIf="helper.hasSustainedLoads"
                        [ngStyle]="{ 'flex-basis': '150px' }">
                        <div class="text">{{loadsHeader}}</div>
                    </div>

                    <div
                        class="column-force clip"
                        *ngIf="helper.showColumnForces"
                        [ngStyle]="{ 'flex-basis': '150px' }">
                        <div class="text">{{forcesHeader}}</div>
                    </div>

                    <div
                        class="column-moment clip"
                        *ngIf="helper.showColumnMoments"
                        [ngStyle]="{ 'flex-basis': '150px' }">
                        <div class="text">{{momentsHeader}}</div>
                    </div>

                    <div class="column-messages" *ngIf="!collapsed"></div>

                    <div class="column-collapse">
                        <button
                            id="LC-table-showhide-arrow-button-1"
                            class="collapse-button"
                            type="button"
                            (click)="collapsed = !collapsed; onCollapsedChanged()"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                            <span class="pe-ui-cw-sprite pe-ui-cw-sprite-lines"></span>
                        </button>
                    </div>
                </div>

                <div class="loads-sub-header">
                    <div class="collapsed-loads-space"></div>

                    <div class="column-name" style="flex-basis: 150px;" ngbTooltip="Load name">
                        <div class="text"><span class="clip">{{selectedLoad.name}}</span></div>
                    </div>

                    <div class="column-type" style="flex-basis: 100px;">
                        <div class="text">{{selectedLoadTypeName}}</div>
                    </div>

                    <div class="column-loads clip" *ngIf="helper.hasSustainedLoads" [ngStyle]="{ 'flex-basis': 120 + 'px' }">
                        <div class="text">{{helper.firstRowLoadLegendTranslation}}</div>
                        <div class="text">{{helper.secondRowLoadLegendTranslation}}</div>
                    </div>

                    <div
                        class="column-force clip"
                        style="display: flex; flex-direction: row;"
                        *ngIf="helper.showColumnForces"
                        [ngStyle]="{ 'flex-basis': '150px' }">
                        <div [ngStyle]="{ 'flex-basis': '150px' }" *ngIf="helper.columnVisibilities.forceVx">
                            <div class="text" id="LC-table-Vx-force-column-collapsed">Vx: {{formatForce(selectedLoad.forceX)}}</div>
                        </div>

                        <div [ngStyle]="{ 'flex-basis': '150px' }" *ngIf="helper.columnVisibilities.forceVy">
                            <div class="text" id="LC-table-Vy-force-column-collapsed">Vy: {{formatForce(selectedLoad.forceY)}}</div>
                        </div>

                        <div [ngStyle]="{ 'flex-basis': '150px' }" *ngIf="helper.columnVisibilities.forceN">
                            <div class="text" id="LC-table-N-force-column-collapsed">N: {{formatForce(selectedLoad.forceZ)}}</div>
                            <div class="text" *ngIf="helper.hasSustainedLoads">N: {{formatForce(selectedLoad.dynamicForceZ)}}</div>
                        </div>
                    </div>

                    <div
                        class="column-moment clip"
                        style="display: flex; flex-direction: row;"
                        *ngIf="helper.showColumnMoments"
                        [ngStyle]="{ 'flex-basis': '150px' }">
                        <div [ngStyle]="{ 'flex-basis': '150px' }" *ngIf="helper.columnVisibilities.momentMx">
                            <div class="text" id="LC-table-Mx-moment-column-collapsed">Mx: {{formatMoment(selectedLoad.momentX)}}</div>
                            <div class="text" *ngIf="helper.hasSustainedLoads">Mx: {{formatMoment(selectedLoad.dynamicMomentX)}}</div>
                        </div>

                        <div [ngStyle]="{ 'flex-basis': '150px' }" *ngIf="helper.columnVisibilities.momentMy">
                            <div class="text" id="LC-table-My-moment-column-collapsed">My: {{formatMoment(selectedLoad.momentY)}}</div>
                            <div class="text" *ngIf="helper.hasSustainedLoads">My: {{formatMoment(selectedLoad.dynamicMomentY)}}</div>
                        </div>

                        <div [ngStyle]="{ 'flex-basis': '150px' }" *ngIf="helper.columnVisibilities.momentMz">
                            <div class="text" id="LC-table-Mz-moment-column-collapsed">Mz: {{formatMoment(selectedLoad.momentZ)}}</div>
                        </div>
                    </div>

                    <div class="column-messages" [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-collapse"></div>
                </div>
            </div>

            <!-- Expanded. -->
            <div class="resizer-wrapper" *ngIf="!collapsed && rowsLoaded">
                <div class="loads-header">
                    <div class="header-sizer">
                        <div class="column-selected" *ngIf="helper.isSelectingCombinationEnabled"></div>

                        <div class="column-rank">
                            <div class="text">N°</div>
                        </div>

                        <div class="column-name"
                            [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.name - 1) + 'px' : null }">
                            <div class="text" l10n="Agito.Hilti.Profis3.Loads.Name"></div>
                        </div>

                        <div class="column-type"
                            [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
                            <div class="text" l10n="Agito.Hilti.Profis3.Loads.Type"></div>
                        </div>

                        <div id="LC-table-loads-section" class="column-loads" *ngIf="helper.hasSustainedLoads">
                            <div class="text">{{loadsHeader}}</div>
                        </div>

                        <div id="LC-table-forces-section" [ngClass]="helper.columnForcesClass" *ngIf="helper.showColumnForces"
                            [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.columnForcesWidth) }">
                            <div class="text">{{forcesHeader}}</div>
                        </div>

                        <div id="LC-table-moments-section" [ngClass]="helper.columnMomentsClass" *ngIf="helper.showColumnMoments"
                            [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.columnMomentsWidth) }">
                            <div class="text">{{momentsHeader}}</div>
                        </div>

                        <div id="LC-table-utilization-section" class="column-utilization" *ngIf="helper.showColumnUtilization"
                            [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">
                            <div class="text" l10n="Agito.Hilti.Profis3.Loads.Utilization"></div>
                        </div>

                        <div class="column-messages"></div>

                        <div class="column-collapse">
                            <button
                                id="LC-table-showhide-arrow-button-3"
                                class="collapse-button"
                                type="button"
                                (click)="collapsed = !collapsed; onCollapsedChanged()"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                                <span class="pe-ui-cw-sprite"
                                    [class.pe-ui-cw-sprite-lines]="collapsed"
                                    [class.pe-ui-cw-sprite-lines-expanded]="!collapsed"></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div id="loads-content" class="loads-content concrete">
                    <div class="loads-sub-header">
                        <div class="header-sizer">
                            <div id="LC-table-combination-number-section" class="column-selected" *ngIf="helper.isSelectingCombinationEnabled"></div>

                            <div class="column-rank"></div>

                            <div id="LC-table-combination-name-section" class="column-name"
                                [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? (helper.resizerColumnWidth.name - 1) + 'px' : null }">
                            </div>

                            <div id="LC-table-load-type-section"
                                class="column-type" [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
                                <div class="type-buttons btn-group" role="group">
                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Static)"
                                        id="LC-table-static-loadtype-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(helper.loadTypeEnum.Static)"
                                        [disabled]="submitted || helper.isLoadTypeDisabled(helper.loadTypeEnum.Static)"
                                        [ngbTooltip]="staticLoadTooltip">
                                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-anchor-shock"></span>
                                    </button>

                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Seismic)"
                                        id="LC-table-seismic-loadtype-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(helper.loadTypeEnum.Seismic)"
                                        [disabled]="submitted || helper.isLoadTypeDisabled(helper.loadTypeEnum.Seismic)"
                                        [ngbTooltip]="seismicLoadTooltip">
                                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-anchor-seismic"></span>
                                    </button>

                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Dynamic)"
                                        id="LC-table-fatigue-loadtype-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(helper.loadTypeEnum.Dynamic)"
                                        [disabled]="submitted || helper.isLoadTypeDisabled(helper.loadTypeEnum.Dynamic)"
                                        [ngbTooltip]="fatigueLoadTooltip">
                                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-anchor-fatigue"></span>
                                    </button>

                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Fire)"
                                        id="LC-table-fire-loadtype-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(helper.loadTypeEnum.Fire)"
                                        [disabled]="submitted || helper.isLoadTypeDisabled(helper.loadTypeEnum.Fire)"
                                        [ngbTooltip]="fireLoadTooltip">
                                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-anchor-fire-resistant"></span>
                                    </button>
                                </div>
                            </div>

                            <div class="column-loads" *ngIf="helper.hasSustainedLoads"></div>

                            <div id="LC-table-Vx-force-column" *ngIf="helper.columnVisibilities.forceVx" class="column-force column-vx"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceVx) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.Vx"></div>
                            </div>

                            <div id="LC-table-Vy-force-column" *ngIf="helper.columnVisibilities.forceVy" class="column-force column-vy"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceVy) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.Vy"></div>
                            </div>

                            <div id="LC-table-N-force-column" *ngIf="helper.columnVisibilities.forceN" class="column-force column-n"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.forceN) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.N"></div>
                            </div>

                            <div id="LC-table-Mx-moment-column" *ngIf="helper.columnVisibilities.momentMx" class="column-moment column-mx"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMx) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.Mx"></div>
                            </div>

                            <div id="LC-table-My-moment-column" *ngIf="helper.columnVisibilities.momentMy" class="column-moment column-my"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMy) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.My"></div>
                            </div>

                            <div id="LC-table-Mz-moment-column" *ngIf="helper.columnVisibilities.momentMz" class="column-moment column-mz"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.momentMz) }">
                                <div class="text" l10n="Agito.Hilti.Profis3.Loads.Mz"></div>
                            </div>

                            <div *ngIf="helper.showColumnUtilization" #utilizationColumnRef
                                [ngClass]="helper.isPostInstallAnchorProduct ? 'column-utilization-pi' : 'column-utilization'"
                                [ngStyle]="{ 'flex-basis': helper.getSizeFlexOrNull(helper.resizerColumnWidth?.utilization) }">
                                <div class="utilization-text" *ngIf="helper.isPostInstallAnchorProduct && !helper.isUtilizationCompact">
                                    <div class="centered-text">{{translate("Agito.Hilti.CW.Loads.Tension.Short")}}</div>
                                </div>

                                <div class="utilization-text" *ngIf="helper.isPostInstallAnchorProduct && !helper.isUtilizationCompact">
                                    <div class="centered-text">{{translate("Agito.Hilti.CW.Loads.Shear.Short")}}</div>
                                </div>

                                <div class="utilization-text" *ngIf="helper.isPostInstallAnchorProduct && !helper.isUtilizationCompact">
                                    <div class="centered-text">{{translate("Agito.Hilti.CW.Loads.Combination.Short")}}</div>
                                </div>

                                <div class="utilization-text">
                                    <div class="centered-text" l10n="Agito.Hilti.Profis3.Loads.Total"></div>
                                </div>
                            </div>

                            <div class="column-messages" *ngIf="!collapsed"></div>

                            <div class="column-delete">
                                <div [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DeleteInactive')"
                                    [disableTooltip]="submitted">
                                    <button
                                        id="LC-table-bin-button"
                                        type="button"
                                        class="delete-button"
                                        (click)="deleteLoads(this.loads)"
                                        [disabled]="submitted || this.deleteLoadsDisabled"
                                        [disableTooltip]="submitted"
                                        [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DeleteInactive')">
                                        <span class="pe-ui-cw-sprite pe-ui-cw-sprite-trash"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="loads-rows">
                        <virtual-scroller #scrollLoadsCW
                            [items]="loads" [scrollAnimationTime]="0"
                            [ngStyle]="{ 'height': '100%', 'overflow-x': 'hidden' }" (vsUpdate)="virtualScrollChange($any($event))">

                            <cw-loads-row
                                class="loads-row-component"
                                *ngFor="let load of scrollLoadsCW.viewPortItems; index as viewportIndex; trackBy:identifyLoad;"
                                [resizerWrapper]="resizerWrapper"
                                [helper]="helper"
                                [selectedLoadId]="selectedLoadId"
                                [decisiveLoadId]="decisiveLoadId"
                                [isAdd]="false"
                                [parentId]="'load-combinations'"
                                [load]="load"
                                [loadIndex]="scrollLoadsCW.viewPortInfo.startIndex + viewportIndex"
                                [disabled]="disabled"
                                [submitted]="submitted"
                                [isUtilizationCompact]="helper.isUtilizationCompact"
                                (onLoadSelected)="selectLoad($any($event).detail)"
                                (onChangeLoad)="changeLoad($any($event).detail)"
                                (onDeleteLoad)="deleteLoads([$any($event).detail])"
                                (columnsResize)="columnsResized()">
                            </cw-loads-row>

                            <cw-loads-row
                                class="loads-row-component"
                                [resizerWrapper]="resizerWrapper"
                                *ngIf="helper.addingNewLoad"
                                [helper]="helper"
                                [selectedLoadId]="selectedLoadId"
                                [isAdd]="true"
                                [parentId]="'load-combinations'"
                                [load]="helper.newLoad"
                                [disabled]="disabled"
                                [submitted]="submitted"
                                (onAddLoad)="addNewLoad($any($event).detail)"
                                [isUtilizationVisible] = "false">
                            </cw-loads-row>

                        </virtual-scroller>
                    </div>
                </div>
            </div>

            <div class="loads-sub-footer" *ngIf="rowsLoaded" [class.visible]="!collapsed">
                <div class="calculation-mode"
                    [hidden]="helper.isPropertyHidden(propertyMetaData.Loads_CW_LoadCombinationsCalculateType.id)">
                    <pe-radio-button
                        id="LC-table-radio-calculation-mode"
                        [look]="loadsCalculationModeRadio.look"
                        [items]="loadsCalculationModeRadio.items"
                        [selectedValue]="loadsCalculationMode"
                        (selectedValueChange)="loadsCalculationModeRadioSelectedValueChange($any($event).detail)"
                        [disabled]="submitted || helper.isPropertyDisabled(propertyMetaData.Loads_CW_LoadCombinationsCalculateType.id)">
                    </pe-radio-button>
                </div>
                <pe-button
                    id="LC-table-input-create-load-combinations-button"
                    [look]="'Default'"
                    [ngbTooltip]="loadTooltip"
                    class="modal-button button sub-footer-button"
                    (buttonClick)="openWizardWarning()"
                    [text]="openWizardTranslation"
                    [disabled]="newLoadDisabled"
                    [hidden]="loadWizardHidden">
                </pe-button>
                <pe-button
                    id="LC-table-inputcustomfactoredloads-button"
                    [look]="'Default'"
                    [ngbTooltip]="loadTooltip"
                    class="modal-button button sub-footer-button"
                    (buttonClick)="openNewLoadWarning()"
                    [text]="translate('Agito.Hilti.Profis3.Loads.AddLoad')"
                    [disabled]="newLoadDisabled || maxLoadsReached"
                    [hidden]="newLoadHidden">
                </pe-button>
                <pe-button
                    id="LC-table-importfactoredloadsfromspreadsheet-button"
                    [look]="'Default'"
                    [ngbTooltip]="loadTooltip"
                    class="modal-button button sub-footer-button"
                    (buttonClick)="importLoads()"
                    [text]="translate('Agito.Hilti.CW.Loads.ImportLoadsCombinations')"
                    [disabled]="newLoadDisabled ||  maxLoadsReached"
                    [hidden]="newLoadHidden">
                </pe-button>
            </div>
        </div>
    </pe-resizer>
</div>
