import { ValidationErrorEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Calculation';
import { LocalizationService } from '../services/localization.service';

export class MessageHelper {

    public static getValidationErrorMessage(validationError: ValidationErrorEntity | undefined, localizationService: LocalizationService): string {
        if (validationError == null)
            return '';

        return validationError.messageKey != null
            ? localizationService.getString(validationError.messageKey)
            : validationError.message;
    }
}