import { BaseComponentCW, IBaseComponentConstructorCW, IModelCW } from '../../../gl-model/base-component';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { AnchorChannelHelper } from './anchor-channel';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { PlateShapes } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { ILoad, ILoadCombination, LoadSystem } from './load-system';
import { PlateBracketHelper } from './plate-bracket';
import { GlModelConstants } from '../../../gl-model/gl-model-constants';
import { BoltsHelper } from './bolt-manager';
import { CoordinateSystem } from '@profis-engineering/gl-model/components/coordinate-system';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';

export type IForceManagerConstructor = IBaseComponentConstructorCW;

export interface ILoadValue {
    uiProperty: UIProperty;
    value: number;
}

export interface ILoadCombinationValues {
    loadCombinationId: string;
    isSelected: boolean;
    loads: ILoadValue[];
    sustainedLoads: ILoadValue[];
    hasSustainedLoads?: boolean;
}

export class LoadsManager extends BaseComponentCW {
    private loadSystems: LoadSystem[];

    constructor(private ctor: IForceManagerConstructor) {
        super(ctor);

        this.loadSystems = [];
    }

    override update(): void {
        const loadPositions = LoadsManagerHelper.getLoadValues(this.model);
        this.createLoadArrows(loadPositions);
    }

    override dispose() {
        this.loadSystems.forEach(x => {
            x.dispose();
        });

        this.loadSystems = [];
    }

    public override getBoundingBoxes(): BoundingInfo[] {
        const boundingBoxes: BoundingInfo[] = [];

        this.loadSystems.forEach(x => {
            boundingBoxes.push(...x.getBoundingBoxes());
        });

        return boundingBoxes;
    }

    private createLoadArrows(loads: ILoadCombination[]) {
        if (loads.length == 0) {
            this.loadSystems.forEach(x => x.hide());
            return;
        }

        for (let i = 0; i < loads.length; i++) {
            let arrow = this.loadSystems[i];

            if (!arrow) {
                arrow = new LoadSystem(this.ctor);
                this.loadSystems.push(arrow);
            }

            arrow.updateValues(loads[i]);
        }

        for (let i = loads.length; i < this.loadSystems.length; i++) {
            this.loadSystems[i].hide();
        }
    }
}

export class LoadsManagerHelper {
    public static getLoadValues(model: IModelCW): ILoadCombination[] {
        const loadCombinations: ILoadCombination[] = [];

        for (const anchoringSystem of model.anchoringSystems) {
            if (!model.isAnchorChannelAvailable(model, anchoringSystem.id) && !model.isPostInstallAnchorProduct)
                continue;

            for (const basePlateSystem of anchoringSystem.basePlateSystems) {
                if (!model.isBasePlateSystemSelected(basePlateSystem.id, anchoringSystem.id))
                    continue;

                // The starting position of load arrows is calculated according to the comments in the switch case
                const anchorChannelValues = AnchorChannelHelper.getAnchorChannelValues(model, anchoringSystem.id);
                const originPosition = new Vector3(0, anchorChannelValues.position.y + anchorChannelValues.size.y / 2.0, anchorChannelValues.position.z);

                // Plate
                const plate = basePlateSystem.plateBracket;
                const anchorChannel = anchoringSystem.anchorChannel;
                const plateValues = PlateBracketHelper.getPlateValues(plate, anchorChannel);
                const platePosition = originPosition.add(plateValues.platePosition);

                // Angle bracket
                const angleBracketValues = PlateBracketHelper.getAngleBracketValues(plate, anchorChannel);
                const angleBracketPosition = originPosition.add(angleBracketValues.angleBracketPosition);

                let arrowsPosition = platePosition;
                let lengthX = this.minArrowLength(plateValues.plateSize.x / 2);
                let lengthY: number | undefined, lengthZ: number | undefined;
                let noPlateLoadPositions: { id: number; position: Vector3 }[] = [];
                switch (plate.shape) {
                    case PlateShapes.BracketWithoutAngle: {
                        // Load arrows from centre of of the edge of plate
                        arrowsPosition = platePosition.add(new Vector3(0, plateValues.plateSize.y / 2, -plateValues.plateSize.z / 2 + plateValues.plateSize.y / 2));
                        lengthZ = this.minArrowLength(plateValues.plateSize.y / 2);
                        break;
                    }
                    case PlateShapes.AngleBracketTop: {
                        // Load arrows from centre of of the edge of plate lip
                        arrowsPosition = angleBracketPosition.add(new Vector3(0, angleBracketValues.angleBracketSize.y / 2, 0));
                        lengthY = this.minArrowLength(angleBracketValues.angleBracketSize.z/ 2);
                        break;
                    }
                    case PlateShapes.AngleBracketBottom: {
                        // Load arrows from centre of of the edge of plate lip
                        arrowsPosition = angleBracketPosition.add(new Vector3(0, -(angleBracketValues.angleBracketSize.y / 2), 0));
                        lengthZ = this.minArrowLength(angleBracketValues.angleBracketSize.y + angleBracketValues.angleBracketSize.z);
                        lengthY = this.minArrowLength(angleBracketValues.angleBracketSize.z/ 2);
                        break;
                    }
                    case PlateShapes.PlateDesignRight: {
                        const flangeValues = PlateBracketHelper.getFlangeValues(model, basePlateSystem.id, anchoringSystem.id);
                        arrowsPosition = originPosition.add(PlateBracketHelper.getHolePosition(plateValues.platePosition, plateValues.plateSize, flangeValues));
                        arrowsPosition.x += flangeValues.spacing / 2;
                        lengthY = this.minArrowLength(plate.offsetYNegative - flangeValues.holeBoltOffset);
                        lengthX = this.minArrowLength(plateValues.plateSize.x - flangeValues.offset - flangeValues.spacing / 2);
                        break;
                    }
                    case PlateShapes.NoPlate: {
                        const boltValues = BoltsHelper.getBoltValues(model, anchoringSystem.id, basePlateSystem.id);
                        let number = 0;
                        noPlateLoadPositions = boltValues.positions.map(x => {
                            const position = x.position.clone();
                            position.y = originPosition.y + GlModelConstants.lineConstants.loadPerBoltPositionYOffset;
                            number++;

                            return {
                                id: x.id ?? number,
                                position
                            };
                        });
                        lengthX = GlModelConstants.lineConstants.loadPerBoltLengthX;
                        lengthY = GlModelConstants.lineConstants.loadPerBoltLengthY;
                        break;
                    }
                    case PlateShapes.Rectangle: {
                        lengthY = this.minArrowLength(plateValues.plateSize.z / 2);
                        lengthZ = this.minArrowLength(plateValues.plateSize.y / 2);
                        break;
                    }
                    default:
                        // Load arrows from centre of the rectangle plate
                        break;
                }

                arrowsPosition.x += plate.loadEccentricity.x;
                arrowsPosition.z += plate.loadEccentricity.y;

                const isSelected = model.isAnchoringSystemSelected(anchoringSystem.id);
                const isNoPlate = plate.shape == PlateShapes.NoPlate;
                const isMutliplePlates = anchoringSystem.basePlateSystems.length > 1;

                let filteredLoadCombinations: ILoadCombinationValues[];

                if (isNoPlate)
                    filteredLoadCombinations = model.loadCombinations;
                else if (isMutliplePlates)
                    filteredLoadCombinations = model.loadCombinations.filter(x => x.loadCombinationId == basePlateSystem.id);
                else
                    filteredLoadCombinations = model.loadCombinations.filter(x => x.isSelected);

                let additionalOffset = 0;
                if (filteredLoadCombinations.some(x => x.hasSustainedLoads)) {
                    lengthX += GlModelConstants.forceConstants.sustainedLoadDashedLine;

                    if (lengthY)
                        lengthY += GlModelConstants.forceConstants.sustainedLoadDashedLine;

                    additionalOffset += GlModelConstants.forceConstants.loadForceOffsetXY;
                }

                for (const load of filteredLoadCombinations) {
                    const loadCombinationId = load.loadCombinationId;
                    const position = isNoPlate ? noPlateLoadPositions.find(x => x.id == Number.parseInt(loadCombinationId))?.position ?? new Vector3() : arrowsPosition;

                    const loads: ILoad[] = LoadsManagerHelper.createLoads(model, loadCombinationId, isSelected, isNoPlate, lengthX, lengthY, lengthZ, additionalOffset);

                    let sustainedLoads: ILoad[] = [];
                    if (load.hasSustainedLoads)
                        sustainedLoads = LoadsManagerHelper.createSustainedLoads(model, loadCombinationId, isSelected, isNoPlate, lengthX, lengthY, lengthZ);

                    loadCombinations.push({
                        anchorSystemId: anchoringSystem.id,
                        loadCombinationId: loadCombinationId,
                        position,
                        loads,
                        sustainedLoads
                    });
                }

            }
        }

        return loadCombinations;
    }

    private static createLoads(model: IModelCW, loadCombinationId: string, isSelected: boolean, isNoPlate: boolean, lengthX?: number, lengthY?: number, lengthZ?: number, additionalOffset = 0): ILoad[] {
        let scale: number | undefined;
        if (isNoPlate) {
            scale = GlModelConstants.lineConstants.loadPerBoltScale;
        }
        const loads: ILoad[] = [{
            uiProperty: UIProperty.LoadCombination_CW_ForceX,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.loads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_ForceX)?.value ?? 0,
            direction: isSelected ? new Vector3(0, 0, -1) : new Vector3(0, 0, 1),
            rotation: new Vector3(0, 0, -Math.PI / 2),
            isMoment: false,
            mirrorArrowDirection: !isSelected,
            length: lengthX,
            scale,
            offset: 0
        },
        {
            uiProperty: UIProperty.LoadCombination_CW_ForceY,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.loads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_ForceY)?.value ?? 0,
            direction: new Vector3(-1, 0, 0),
            rotation: new Vector3(-Math.PI / 2, 0, 0),
            isMoment: false,
            mirrorArrowDirection: true,
            length: lengthY,
            scale,
            offset: 0
        },
        {
            uiProperty: UIProperty.LoadCombination_CW_ForceZ,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.loads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_ForceZ)?.value ?? 0,
            direction: new Vector3(0, -1, 0),
            rotation: new Vector3(0, Math.PI / 2, 0),
            isMoment: false,
            mirrorArrowDirection: false,
            length: lengthZ,
            scale,
            offset: 0
        },
        {
            uiProperty: UIProperty.LoadCombination_CW_MomentX,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.loads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_MomentX)?.value ?? 0,
            direction: isSelected ? new Vector3(0, 0, -1) : new Vector3(0, 0, 1),
            rotation: new Vector3(-Math.PI / 2, 0, -Math.PI / 2),
            isMoment: true,
            mirrorArrowDirection: !isSelected,
            length: lengthX,
            scale,
            offset: GlModelConstants.forceConstants.loadMomentOffset - additionalOffset
        },
        {
            uiProperty: UIProperty.LoadCombination_CW_MomentY,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.loads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_MomentY)?.value ?? 0,
            direction: new Vector3(-1, 0, 0),
            rotation: new Vector3(Math.PI / 2, 0, 0),
            isMoment: true,
            mirrorArrowDirection: !isSelected,
            length: lengthY,
            scale,
            offset: GlModelConstants.forceConstants.loadMomentOffset - additionalOffset
        },
        {
            uiProperty: UIProperty.LoadCombination_CW_MomentZ,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.loads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_MomentZ)?.value ?? 0,
            direction: new Vector3(0, -1, 0),
            rotation: new Vector3(0, Math.PI, 0),
            isMoment: true,
            mirrorArrowDirection: !isSelected,
            length: lengthZ,
            scale,
            offset: GlModelConstants.forceConstants.loadMomentOffset
        }
        ];

        return loads;
    }

    public static createSustainedLoads(model: IModelCW, loadCombinationId: string, isSelected: boolean, isNoPlate: boolean, lengthX?: number, lengthY?: number, lengthZ?: number): ILoad[] {
        let scale: number | undefined;
        if (isNoPlate) {
            scale = GlModelConstants.lineConstants.loadPerBoltScale;
        }
        const sustainedLoads: ILoad[] = [{
            uiProperty: UIProperty.LoadCombination_CW_SustainedForceZ,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.sustainedLoads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_SustainedForceZ)?.value ?? 0,
            direction: new Vector3(0, -1, 0),
            rotation: new Vector3(0, Math.PI / 2, 0),
            isMoment: false,
            mirrorArrowDirection: false,
            length: lengthZ,
            scale,
            offset: GlModelConstants.forceConstants.sustainedLoadForceZOffset
        },
        {
            uiProperty: UIProperty.LoadCombination_CW_SustainedMomentX,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.sustainedLoads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_SustainedMomentX)?.value ?? 0,
            direction: isSelected ? new Vector3(0, 0, -1) : new Vector3(0, 0, 1),
            rotation: new Vector3(-Math.PI / 2, 0, -Math.PI / 2),
            isMoment: true,
            mirrorArrowDirection: !isSelected,
            length: lengthX,
            scale,
            offset: GlModelConstants.forceConstants.sustainedLoadMomentXYOffset
        },
        {
            uiProperty: UIProperty.LoadCombination_CW_SustainedMomentY,
            value: model.loadCombinations.find(x => x.loadCombinationId == loadCombinationId)?.sustainedLoads.find(x => x.uiProperty == UIProperty.LoadCombination_CW_SustainedMomentY)?.value ?? 0,
            direction: new Vector3(-1, 0, 0),
            rotation: new Vector3(Math.PI / 2, 0, 0),
            isMoment: true,
            mirrorArrowDirection: !isSelected,
            length: lengthY,
            scale,
            offset: GlModelConstants.forceConstants.sustainedLoadMomentXYOffset
        }
        ];

        return sustainedLoads;
    }

    private static minArrowLength(dimension: number) {
        return Math.max(CoordinateSystem.dashOver, dimension * 1.3);
    }
}
