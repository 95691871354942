import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { IScaleAndPosition, SceneCoordinateSystem as SceneCoordinateSystemBase, marginY } from '@profis-engineering/gl-model/components/scene-coordinate-system.js';
import { IModelCW } from '../../../gl-model/base-component';
import { BaseComponentHelper } from '../../../gl-model/base-component-helper';
import { UIProperty } from '../../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { EventNotifier } from '@profis-engineering/gl-model/external/event-notifier';
import { MaterialCacheCW } from '../../../gl-model/cache/material-cache';
import { MeshCacheCW } from '../../../gl-model/cache/mesh-cache';
import { Mode2d } from '@profis-engineering/gl-model/gl-model';
import { ToolTipKeyCW } from '../../../gl-model/tooltip';

export class SceneCoordinateSystem extends SceneCoordinateSystemBase<IModelCW, UIProperty, EventNotifier, MaterialCacheCW, MeshCacheCW, ToolTipKeyCW, Mode2d> {

    protected get getMarginVertical() {
        return marginY;
    }

    protected get csRotation() {
        // Default rotation of coordinate system based on application type
        if (!BaseComponentHelper.isTopView(this.model.applicationType)) {
            return Matrix.RotationX(-Math.PI / 2);
        }

        return Matrix.Identity();
    }

    protected get csRotationY() {
        return this.csRotation;
    }

    protected get csRotationZ() {
        return this.csRotation;
    }

    protected override getScaleAndPositionForReport(): IScaleAndPosition {
        return {
            scale: 2.5,
            position: new Vector3(-1, -1, 4)
        };
    }
}
