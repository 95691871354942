import { CodeList } from '../code-list';
import { EntityBase } from '../generated-modules/Hilti.CW.CalculationService.Shared.Entities.CodeList';

export class ConcreteReinforcements extends CodeList {

    constructor(codeList: EntityBase) {
        super(codeList);
    }

    public static fromService(codeList: EntityBase) {
        return new ConcreteReinforcements(CodeList.baseFromService('ConcreteReinforcementsEntity', codeList));
    }

    public override cloneDeep(): ConcreteReinforcements {
        const codeList = super.cloneDeep() as ConcreteReinforcements;
        return codeList;
    }

}
