<div class="section-row section-methods-approvals" *ngIf="!collapsed">
    <div class="section-column separator design-standard">
        <!-- Design Standard -->
        <div class="content">
            <div class="control-column length-large-dropdown">
                <pe-dropdown
                    [id]="projectDesignStandardDropdown.id"
                    [title]="projectDesignStandardDropdown.title"
                    [notSelectedText]="projectDesignStandardDropdown.notSelectedText"
                    [items]="projectDesignStandardDropdown.items"
                    [selectedValue]="projectDesignStandardDropdown.selectedValue"
                    (selectedValueChange)="onDesignStandardDropdownSelectedValueChange($any($event).detail)"
                    [validators]="[requiredValidator]"
                    [disabled]="disabled || isEdit">
                </pe-dropdown>
            </div>

            <div class="design-standard-note"
                *ngIf="selectedDesignStandardDescription != null">
                {{translate(selectedDesignStandardDescription)}}
            </div>
        </div>
    </div>

    <div class="section-column separator design-method-group">
        <!-- Design Method -->
        <div class="content">
            <div class="control-column length-large-dropdown">
                <pe-dropdown
                    [id]="projectDesignMethodDropdown.id"
                    [title]="projectDesignMethodDropdown.title"
                    [notSelectedText]="projectDesignMethodDropdown.notSelectedText"
                    [items]="projectDesignMethodDropdown.items"
                    [selectedValue]="projectDesignMethodDropdown.selectedValue"
                    (selectedValueChange)="onDesignMethodDropdownSelectedValueChange($any($event).detail)"
                    [validators]="[requiredValidator]"
                    [disabled]="disabled">
                </pe-dropdown>
            </div>

            <div class="design-method-note"
                *ngIf="selectedDesignMethodGroupDescription != null">
                {{translate(selectedDesignMethodGroupDescription)}}
            </div>
        </div>
    </div>

    <div class="section-column separator technical-data">
        <!-- Technical Data -->
        <div class="content">
            <label class="control-label sub-title">
                {{translate("Agito.Hilti.CW.AddEditDesign.TechnicalData.Title")}}
            </label>
        </div>
    </div>
</div>
